import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DownloadService {
    public downloadFile(doc, res, type?): void {
        const blob = new Blob([res], {
            type: 'text/csv'
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = (doc && doc.fileName) ? type ? `${doc.fileName}.${type}` : `${doc.fileName}.csv` : type ? `download.${type}` : 'download.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    public downloadZip(fileName, res): void {
        const blob = new Blob([res], {
            type: 'application/zip'
        });
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName || 'download';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    public downloadBase64File(doc): void {
        const linkSource = 'data:application/pdf;base64,' + doc.fileContents;
        const a = document.createElement("a");
        a.href = linkSource;
        a.download = (doc && doc.fileName) ? doc.fileName : 'download';
        a.click();
        a.remove();
    };

    public downloadPDF(fileName: string, pdfContent: string): void {
        const pdfBlob = new Blob([pdfContent], {
            type: 'application/pdf'
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(pdfBlob);
        a.download = fileName || 'download.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
};