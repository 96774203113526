import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfirmationModalConfig } from '../confirmation/confirmation.modal';

@Component({
    selector: 'unsaved-changes-modal',
    styleUrls: ['unsaved-changes.modal.css'],
    templateUrl: './unsaved-changes.modal.html'
})

export class UnsavedChangesModalComponent implements OnInit {
    @Input() public config = {} as IConfirmationModalConfig;

    public message: string = '';
    public title: string = '';

    public constructor(public unsavedChangesModal: NgbActiveModal) { };

    public ngOnInit(): void {
        this.message = this.config.message;
        this.title = this.config.title;
    };
};