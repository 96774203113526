import { Component, ViewEncapsulation, ViewChild, ViewContainerRef, OnInit, OnDestroy, ComponentFactoryResolver, Inject } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Router, ActivationEnd, Data } from '@angular/router';

import { UserView } from '../../models';
import { AuthenticationService, CacheLoaderService, UserService, SharedModalsService, RoutingService } from '../../services';

import { filter } from 'rxjs/operators';
import { IPortalClassTypes } from 'src/interfaces/portal-class-types.interface';
import { PageHelpService } from 'src/services/page-help.service';
import { Subscription, lastValueFrom } from 'rxjs';

@Component({
    selector: 'user-panel',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['user-panel.component.css'],
    templateUrl: './user-panel.component.html'
})

export class UserPanelComponent implements OnInit, OnDestroy {
    @ViewChild('pageTitleComponentContainer', { read: ViewContainerRef, static: true }) public entry: ViewContainerRef;
    public pageSubtitle: string = '';
    public pageTitle: string = '';
    public portalClass: IPortalClassTypes = 'igo';
    public pageHelpKey: string = '';
    public portalHomeUrl: string = '';
    public routeData: Data;
    public user: UserView;

    private pageSubtitleSubscription: Subscription;

    public constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authenticationService: AuthenticationService,
        private azureAuthService: MsalService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private cacheLoaderService: CacheLoaderService,
        private pageHelpService: PageHelpService,
        private router: Router,
        private routingService: RoutingService,
        private sharedModalsService: SharedModalsService,
        private userService: UserService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0)
        ).subscribe({
            next: (event: ActivationEnd) => {
                this.entry && this.entry.clear();
                this.routeData = event.snapshot && event.snapshot.data;
                if (this.routeData) {
                    this.pageSubtitle = this.routeData.subTitle ? this.routeData.subTitle : '';
                    this.pageTitle = this.routeData.title ? this.routeData.title : '';
                    this.portalClass = this.routeData.portalClass || 'igo';
                    if (this.routeData.titleComponent) {
                        this.entry && this.entry.createComponent(this.componentFactoryResolver.resolveComponentFactory(this.routeData.titleComponent));
                    };
                };
                this.portalHomeUrl = this.router.url.split('/')[1];
                this.pageHelpKey = this.getPageHelpKey(this.router.url.substring(1));
            }
        });
    };

    public ngOnInit(): void {
        this.user = this.userService.getCurrentUser();
    };

    public ngAfterViewInit(): void {
        this.pageSubtitleSubscription = this.routingService.updatePageSubTitle.subscribe({
            next: (value: string) => this.pageSubtitle = value
        });
    };

    public ngOnDestroy(): void {
        this.pageSubtitleSubscription?.unsubscribe();
        this.entry && this.entry.clear();
    };

    public get userImageUrl(): string {
        if (this.userService.currentUserImage) {
            return `url("${this.userService.currentUserImage}")`;
        };
        return null;
    };

    public async logout(): Promise<void> {
        await lastValueFrom(this.azureAuthService.initialize());
        if (this.user.email.includes('@autocab.dev')) {
            this.azureAuthService.logoutPopup().subscribe(this.unauthenticateUser);
        } else {
            this.unauthenticateUser();
        };
    };

    public openPageHelp(): void {
        this.pageHelpKey && window.open(this.pageHelpKey, '_blank');
    };

    public onShowCurrentUserProfileModal(): void {
        this.sharedModalsService.showCurrentUserProfileModal({ windowClass: this.portalClass });
    };

    private getPageHelpKey(url: string): string {
        if (url.includes("?return=true")) { return ''; };
        const [parentRoute, childRoute, secondChildRoute] = url.split('/');
        if (this.pageHelpService.links[parentRoute]) {
            if (secondChildRoute == undefined) {
                if (this.pageHelpService.links[parentRoute][childRoute]) {
                    return this.pageHelpService.links[parentRoute][childRoute].pageUrl;
                }
            } else {
                if (this.pageHelpService.links[parentRoute][childRoute][secondChildRoute]) {
                    return this.pageHelpService.links[parentRoute][childRoute][secondChildRoute].pageUrl;
                }
            }
        }
        return '';
    };

    private unauthenticateUser = (): void => {
        this.userService.currentUserImage = '';
        this.cacheLoaderService.appCacheResolved = false;
        this.authenticationService.unauthenticateUser(true);
    };
};