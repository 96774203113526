import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'no-access',
    styleUrls: ['no-access.component.css'],
    templateUrl: './no-access.component.html'
})

export class NoAccessComponent {
    public constructor() { }
};