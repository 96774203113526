import { Injectable } from "@angular/core";

declare const aptrinsic: any;

@Injectable({ providedIn: 'root' })
export class GainsightService {
    public static getGainsightCompanyDetails(): { companyId: string, companyName: string, isOperator: boolean, isOriginator: boolean; } {
        // Getting user data for Gainsight
        const currentOriginator = sessionStorage.getItem('originator');
        const currentOperator = sessionStorage.getItem('operator');
        let companyId: string, companyName: string;
        const originatorJson = currentOriginator ? JSON.parse(currentOriginator) : null;
        const operatorJson = currentOperator ? JSON.parse(currentOperator) : null;
        if (originatorJson) {
            companyName = originatorJson.name;
            companyId = originatorJson.id[0] == '2' || originatorJson.id[0] == '3' ? originatorJson.id.slice(1) : originatorJson.id;
        } else if (operatorJson) {
            companyName = operatorJson.name;
            companyId = operatorJson.id[0] == '7' ? operatorJson.id.slice(1) : operatorJson.id;
        };
        return { companyId, companyName, isOperator: !!operatorJson, isOriginator: !!originatorJson };
    };

    public static trackEvent(eventName: string, data?: any, sendCompanyDetails: boolean = true): any {
        const customData = data || {};
        sendCompanyDetails && Object.assign(customData, GainsightService.getGainsightCompanyDetails());
        aptrinsic('track', eventName, customData);
    };
};