import { LookupType } from 'src/models';
import { TableCustomisation } from '@autocab/ghost-vs-table';

export const MultipleSelectionTableCustomisation: TableCustomisation<LookupType> = {
    customisationKey: 'MultipleSelectionTableCustomisation',
    tableName: 'global.tables.selection.table_title',
    defaultColumns: {
        description: {
            width: 100,
            name: 'global.table.multiple_selection_modal.column.name',
            prop: 'description',
            show: true,
        },
    }
};