export interface AuthenticationResponse {
    access_token: string;
    token_type: string;
    expiry_in: number;
};

export interface AuthenticationUserPartial {
    id: number;
    isAdmin: boolean;
    userType: string;
};

export interface DecodedJwtToken {
    aud: number;
    cId: string;
    engineer: boolean;
    exp: number;
    exp_time: string;
    nameid: string;
    iss: string;
};

export class iGoSession {
    public apiUrl: string;
    public token: string;
    public tokenExpiry: number;
    public tokenType: string;

    public constructor(item?: iGoSession) {
        this.apiUrl = (item && item.apiUrl) ? item.apiUrl : '';
        this.token = (item && item.token) ? item.token : '';
        this.tokenExpiry = (item && typeof item.tokenExpiry == 'number') ? item.tokenExpiry : null;
        this.tokenType = (item && item.tokenType) ? item.tokenType : '';
    };
};

export class SessionUser {
    public id: number;
    public isAdmin: boolean;
    public userType: string;

    public constructor(item: SessionUser) {
        this.id = (item && item.id) ? item.id : null;
        this.isAdmin = (item && item.isAdmin) ? true : false;
        this.userType = (item && item.userType) ? item.userType : '';
    };
};