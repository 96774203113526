import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { CacheLoaderService } from './services/cache-loader.service';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from './services';

declare const zE;

@Component({
    selector: 'root',
    styleUrls: [
        'root.component.css',
        'styles/popover.css',
        '../node_modules/@autocab/ghost-vs-table/css/styles.min.css',
        '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css',
        '../node_modules/@fortawesome/fontawesome-pro/css/all.min.css',
        '../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css',
        '../node_modules/bootstrap/dist/css/bootstrap.min.css',
        '../node_modules/mapbox-gl/dist/mapbox-gl.css',
        'styles/autocab-icons.css',
        'styles/buttons.css',
        'styles/datepicker.css',
        'styles/form.css',
        'styles/ghost-vs-table.css',
        'styles/icons.css',
        'styles/images.css',
        'styles/innersidebar.css',
        'styles/mapbox.css',
        'styles/material.css',
        'styles/modals.css',
        'styles/ngbprogressbar.css',
        'styles/ngbrating.css',
        'styles/ngxcharts.css',
        'styles/ngxcolorpicker.css',
        'styles/ngxdatatable.css',
        'styles/table-footer.css',
        'styles/tabset.css',
        'styles/tooltips.css',
        'styles/validation.css'
    ],
    templateUrl: './root.component.html',
    encapsulation: ViewEncapsulation.None
})

export class RootComponent implements OnDestroy {
    private routerSubscription: Subscription;

    constructor(
        public authenticationService: AuthenticationService,
        public cacheLoaderService: CacheLoaderService,
        private router: Router
    ) {
        StorageService.deleteItem('urlToLoad');
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe({
            next: (event: NavigationStart) => {
                if (event.url.toLowerCase().includes('/activity?disputeid') && !StorageService.getItem('urlToLoad')) { // If we start navigation to a URL that we haven't already cached.
                    const [route, queryParams] = event.url.split('?'); // Separates the URL into 2 parts (the route, and the queryParams)
                    const url = `${route.toLowerCase()}?${queryParams}`; // Re-constructs the full URL with the route in lowercase.
                    StorageService.setItem('urlToLoad', url); // Cache the URL in localStorage.
                    this.authenticationService.authenticated && this.router.navigate(['/portals']); // Navigate to the portals page to make sure the correct operator/originator is loaded for the cached route.
                };
            }
        });
    };

    public ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
    };

    public openWebchat = (): void => zE("webWidget", "open");
};