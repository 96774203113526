import { ObjectUtilities } from "src/shared/object.utilities";
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";
import { BookingJourneyDetailsApi, BookingJourneyPointApi, BookingView } from "./activity.model";
import { CoordinateApi } from "./coordinate.model";
import { HalLinksApi } from "./hal-link.model";
import { RegionApi, RegionView } from "./region.model";

const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';

export class GetAllTestEmulatorTestsResponseApi extends HalLinksApi {
    public testEmulatorsList: TestEmulatorTestApi[];

    public constructor(item?: GetAllTestEmulatorTestsResponseApi) {
        super(item);
        this.testEmulatorsList = (item && item.testEmulatorsList) ? item.testEmulatorsList.map(i => new TestEmulatorTestApi(i)) : [];
    };
};

export class GetAllTestEmulatorTestsResponseView extends GetAllTestEmulatorTestsResponseApi {
    declare public testEmulatorsList: TestEmulatorTestView[];

    public constructor(item?: GetAllTestEmulatorTestsResponseView) {
        super(item);
        this.testEmulatorsList = (item && item.testEmulatorsList) ? item.testEmulatorsList.map(i => new TestEmulatorTestView(i)) : [];
    };
};

export class CreateTestEmulatorPayloadApi extends HalLinksApi {
    public description: string;
    public id: string;
    public marketplaceId: string;
    public name: string;
    public operatorId: string;
    public originatorId: string;
    public testRules: CreateTestEmulatorTestRuleApi[];

    public constructor(item?: CreateTestEmulatorPayloadApi) {
        super(item);
        this.description = (item && item.description) ? item.description : '';
        this.id = (item && item.id) ? item.id : '';
        this.marketplaceId = (item && item.marketplaceId) ? item.marketplaceId : '';
        this.name = (item && item.name) ? item.name : '';
        this.operatorId = (item && item.operatorId) ? item.operatorId : '';
        this.originatorId = (item && item.originatorId) ? item.originatorId : '';
        this.testRules = (item && item.testRules) ? item.testRules.map(i => new CreateTestEmulatorTestRuleApi(i)) : [];
    };
};

export class TestEmulatorTestApi extends CreateTestEmulatorPayloadApi {
    public statsId: string;
    public status: string;
    declare public testRules: TestEmulatorTestRuleApi[];

    public constructor(item?: TestEmulatorTestApi) {
        super(item);
        this.statsId = (item && item.statsId) ? item.statsId : '';
        this.status = (item && item.status) ? item.status : '';
        this.testRules = (item && item.testRules) ? item.testRules.map(i => new TestEmulatorTestRuleApi(i)) : [];
    };
};

export class TestEmulatorTestView extends TestEmulatorTestApi {
    public marketplaceName: string;
    public operatorName: string;
    declare public testRules: TestEmulatorTestRuleView[];
    public updatingStatus: boolean;

    public constructor(item?: TestEmulatorTestView) {
        super(item);
        this.marketplaceName = (item && item.marketplaceName) ? item.marketplaceName : '';
        this.operatorName = (item && item.operatorName) ? item.operatorName : '';
        this.testRules = (item && item.testRules) ? item.testRules.map((i, index) => new TestEmulatorTestRuleView(i, index + 1)) : [];
        this.updatingStatus = (item && item.updatingStatus) ? true : false;
    };
};

export class CreateTestEmulatorTestPayload {
    public description: string;
    public marketplaceId: string;
    public name: string;
    public originatorId: string;
    public operatorId: string;
    public testRules: CreateTestEmulatorTestRuleApi[];

    public constructor(item?: CreateTestEmulatorTestPayload) {
        this.description = (item && item.description) ? item.description : '';
        this.marketplaceId = (item && item.marketplaceId) ? item.marketplaceId : '';
        this.name = (item && item.name) ? item.name : '';
        this.originatorId = (item && item.originatorId) ? item.originatorId : '';
        this.operatorId = (item && item.operatorId) ? item.operatorId : '';
        this.testRules = (item && item.testRules) ? item.testRules.map(i => new CreateTestEmulatorTestRuleApi(i)) : [];
    };
};

export class CreateTestEmulatorTestRuleApi {
    public endTime: string;
    public fromAreaId: string;
    public jobTypes: string[];
    public maxPrice: number;
    public numJobs: number;
    public startTime: string;
    public toAreaId: string;
    public vehicleTypes: string[];
    public weekDays: string[];

    public constructor(item?: CreateTestEmulatorTestRuleApi) {
        this.endTime = (item && item.endTime) ? item.endTime : '';
        this.fromAreaId = (item && item.fromAreaId) ? item.fromAreaId : '';
        this.jobTypes = (item && item.jobTypes) ? item.jobTypes : [];
        this.maxPrice = (item && typeof item.maxPrice == 'number') ? item.maxPrice : null;
        this.numJobs = (item && typeof item.numJobs == 'number') ? item.numJobs : null;
        this.startTime = (item && item.startTime) ? item.startTime : '';
        this.toAreaId = (item && item.toAreaId) ? item.toAreaId : '';
        this.vehicleTypes = (item && item.vehicleTypes) ? item.vehicleTypes : [];
        this.weekDays = (item && item.weekDays) ? item.weekDays : [];
    };
};

export class TestEmulatorTestRuleApi extends CreateTestEmulatorTestRuleApi {
    public created: string;
    public createdBy: string;
    public deleted: boolean;
    public id: string;
    public updated: string;
    public updatedBy: string;

    public constructor(item?: TestEmulatorTestRuleApi) {
        super(item);
        this.created = (item && item.created) ? item.created : '';
        this.createdBy = (item && item.createdBy) ? item.createdBy : '';
        this.deleted = (item && item.deleted) ? true : false;
        this.id = (item && item.id) ? item.id : '';
        this.updated = (item && item.updated) ? item.updated : '';
        this.updatedBy = (item && item.updatedBy) ? item.updatedBy : '';
    };
};

export class TestEmulatorTestRuleView extends TestEmulatorTestRuleApi {
    public createdView: string;
    public fromArea: RegionView;
    public index: number;
    public jobTypesView: string;
    public maxPriceCurrency: string;
    public toArea: RegionView;
    public updatedView: string;
    public vehicleTypesView: string;
    public weekDaysView: string;

    public constructor(item?: TestEmulatorTestRuleView, index?: number) {
        super(item);
        this.createdView = (item && item.created) ? datePipeShort.transform(item.created) : '';
        this.fromArea = (item && item.fromArea) ? new RegionView(item.fromArea) : new RegionView();
        this.index = index ? index : (item && typeof item.index == 'number') ? item.index : null;
        this.jobTypesView = (item && item.jobTypes) ? item.jobTypes.join(', ') : '';
        this.maxPriceCurrency = (item && typeof item.maxPrice == 'number') ? new CurrencyGlobalPipe().transform(item.maxPrice) : '';
        this.toArea = (item && item.toArea) ? new RegionView(item.toArea) : new RegionView();
        this.updatedView = (item && item.updated) ? datePipeShort.transform(item.updated) : '';
        this.vehicleTypesView = (item && item.vehicleTypes) ? item.vehicleTypes.join(', ') : '';
        this.weekDaysView = (item && item.weekDays) ? item.weekDays.join(', ') : '';
    };

    public setNewTestRuleValues(): TestEmulatorTestRuleView {
        this.endTime = '23:59:59';
        this.numJobs = 10;
        this.startTime = '00:00:00';
        return this;
    };
};

export class UpdateGeneratedBookingPayload {
    public bookingTime: string;
    public id: string;
    public jobType: string;
    public journeyInfo: BookingJourneyDetailsApi;
    public vehicleType: string;

    public constructor(item?: TestEmulatorBookingView) {
        this.bookingTime = item?.testEmulatorBookingMessage?.agentAvailabilityRequest?.agentBookingAvailabilityRequest?.bookingParameters?.bookingTimeDate.toISOString();
        this.id = item?.id;
        this.jobType = item?.testEmulatorBookingMessage?.jobType;
        this.journeyInfo = new BookingJourneyDetailsApi(item?.bookingMapDetails?.journey);
        this.vehicleType = item?.testEmulatorBookingMessage?.vehicleType;
    };
};

export class GetGeneratedTestBookingsResponseApi extends HalLinksApi {
    public testBookingsList: TestEmulatorBookingApi[];

    public constructor(item?: GetGeneratedTestBookingsResponseApi) {
        super(item);
        this.testBookingsList = (item && item.testBookingsList) ? item.testBookingsList.map(i => new TestEmulatorBookingApi(i)) : [];
    };
};

export class GetGeneratedTestBookingsResponseView extends GetGeneratedTestBookingsResponseApi {
    declare public testBookingsList: TestEmulatorBookingView[];

    public constructor(item?: GetGeneratedTestBookingsResponseApi) {
        super(item);
        this.testBookingsList = (item && item.testBookingsList) ? item.testBookingsList.map(i => new TestEmulatorBookingView(i)) : [];
    };
};

export class TestEmulatorBookingApi extends HalLinksApi {
    public asapLatenessFilter: string;
    public asapTimeToArriveFilter: string;
    public baseFrom: CoordinateApi;
    public baseTo: CoordinateApi;
    public completionFilter: string;
    public deleted: boolean;
    public id: string;
    public preBookingFilter: string;
    public status: string;
    public testEmulatorBookingMessage: TestEmulatorBookingMessageApi;
    public testId: string;
    public timeToDispatchFilter: string;

    public constructor(item?: TestEmulatorBookingApi) {
        super(item);
        this.asapLatenessFilter = (item && item.asapLatenessFilter) ? item.asapLatenessFilter : '';
        this.asapTimeToArriveFilter = (item && item.asapTimeToArriveFilter) ? item.asapTimeToArriveFilter : '';
        this.baseFrom = (item && item.baseFrom) ? new CoordinateApi(item.baseFrom) : null;
        this.baseTo = (item && item.baseTo) ? new CoordinateApi(item.baseTo) : null;
        this.completionFilter = (item && item.completionFilter) ? item.completionFilter : '';
        this.deleted = (item && item.deleted) ? true : false;
        this.id = (item && item.id) ? item.id : '';
        this.preBookingFilter = (item && item.preBookingFilter) ? item.preBookingFilter : '';
        this.status = (item && item.status) ? item.status : '';
        this.testEmulatorBookingMessage = (item && item.testEmulatorBookingMessage) ? new TestEmulatorBookingMessageApi(item.testEmulatorBookingMessage) : new TestEmulatorBookingMessageApi();
        this.testId = (item && item.testId) ? item.testId : '';
        this.timeToDispatchFilter = (item && item.timeToDispatchFilter) ? item.timeToDispatchFilter : '';
    };
};

export class TestEmulatorBookingView extends TestEmulatorBookingApi {
    public bookingMapDetails: Partial<BookingView> = {};
    declare public testEmulatorBookingMessage: TestEmulatorBookingMessageView;

    public constructor(item?: TestEmulatorBookingApi) {
        super(item);
        this.setBookingMapDetails(item);
        this.testEmulatorBookingMessage = (item && item.testEmulatorBookingMessage) ? new TestEmulatorBookingMessageView(item.testEmulatorBookingMessage) : new TestEmulatorBookingMessageView();
    };

    private setBookingMapDetails(item?: TestEmulatorBookingApi) {
        const testBookingDetails = item?.testEmulatorBookingMessage?.agentAvailabilityRequest?.agentBookingAvailabilityRequest?.bookingParameters;
        this.bookingMapDetails.baseFrom = item?.baseFrom;
        this.bookingMapDetails.baseTo = item?.baseTo;
        this.bookingMapDetails.journey = new BookingJourneyDetailsApi({
            from: new BookingJourneyPointApi({
                address: testBookingDetails?.journey?.from?.data, coordinate: new CoordinateApi(testBookingDetails?.journey?.from?.coordinate)
            }),
            to: new BookingJourneyPointApi({
                address: testBookingDetails?.journey?.to?.data, coordinate: new CoordinateApi(testBookingDetails?.journey?.to?.coordinate)
            }),
            vias: []
        });

        if (ObjectUtilities.equal(this.bookingMapDetails.baseFrom, this.bookingMapDetails.journey.from.coordinate) && ObjectUtilities.equal(this.bookingMapDetails.baseTo, this.bookingMapDetails.journey.to.coordinate)) {
            this.bookingMapDetails.hasMovedMapMarker = false;
        } else {
            this.bookingMapDetails.hasMovedMapMarker = true;
        };
    };
};

export class TestEmulatorBookingMessageApi {
    public agentAvailabilityRequest: {
        agentBookingAvailabilityRequest: TestEmulatorBookingAgentAvailabilityRequestApi;
    };
    public agentId: number;
    public agentPassword: string;
    public jobType: string;
    public marketplaceName: string;
    public testBookingId: string;
    public vehicleType: string;
    public vendorId: number;

    public constructor(item?: TestEmulatorBookingMessageApi) {
        if (item && item.agentAvailabilityRequest && item.agentAvailabilityRequest.agentBookingAvailabilityRequest) {
            this.agentAvailabilityRequest = { agentBookingAvailabilityRequest: new TestEmulatorBookingAgentAvailabilityRequestApi(item.agentAvailabilityRequest.agentBookingAvailabilityRequest) };
        } else {
            this.agentAvailabilityRequest = { agentBookingAvailabilityRequest: new TestEmulatorBookingAgentAvailabilityRequestApi() };
        };
        this.agentId = (item && typeof item.agentId == 'number') ? item.agentId : null;
        this.agentPassword = (item && item.agentPassword) ? item.agentPassword : '';
        this.jobType = (item && item.jobType) ? item.jobType : '';
        this.marketplaceName = (item && item.marketplaceName) ? item.marketplaceName : '';
        this.testBookingId = (item && item.testBookingId) ? item.testBookingId : '';
        this.vehicleType = (item && item.vehicleType) ? item.vehicleType : '';
        this.vendorId = (item && typeof item.vendorId == 'number') ? item.vendorId : null;
    };
};

export class TestEmulatorBookingMessageView extends TestEmulatorBookingMessageApi {
    declare public agentAvailabilityRequest: {
        agentBookingAvailabilityRequest: TestEmulatorBookingAgentAvailabilityRequestView;
    };

    public constructor(item?: TestEmulatorBookingMessageApi) {
        super(item);
        if (item && item.agentAvailabilityRequest && item.agentAvailabilityRequest.agentBookingAvailabilityRequest) {
            this.agentAvailabilityRequest = { agentBookingAvailabilityRequest: new TestEmulatorBookingAgentAvailabilityRequestView(item.agentAvailabilityRequest.agentBookingAvailabilityRequest) };
        } else {
            this.agentAvailabilityRequest = { agentBookingAvailabilityRequest: new TestEmulatorBookingAgentAvailabilityRequestView() };
        };
    };
};

export class TestEmulatorBookingAgentAvailabilityRequestApi {
    public agent: TestEmulatorBookingAgentApi;
    public bookingParameters: TestEmulatorAgentBookingParametersApi;
    public vendor: {
        id: number;
    };

    public constructor(item?: TestEmulatorBookingAgentAvailabilityRequestApi) {
        this.agent = (item && item.agent) ? new TestEmulatorBookingAgentApi(item.agent) : new TestEmulatorBookingAgentApi();
        this.bookingParameters = (item && item.bookingParameters) ? new TestEmulatorAgentBookingParametersApi(item.bookingParameters) : new TestEmulatorAgentBookingParametersApi();
        this.vendor = (item && item.vendor) ? item.vendor : { id: null };
    };
};

export class TestEmulatorBookingAgentAvailabilityRequestView extends TestEmulatorBookingAgentAvailabilityRequestApi {
    declare public bookingParameters: TestEmulatorAgentBookingParametersView;

    public constructor(item?: TestEmulatorBookingAgentAvailabilityRequestApi) {
        super(item);
        this.bookingParameters = (item && item.bookingParameters) ? new TestEmulatorAgentBookingParametersView(item.bookingParameters) : new TestEmulatorAgentBookingParametersView();
    };
};

export class TestEmulatorBookingAgentApi {
    public id: number;
    public password: string;
    public time: string;

    public constructor(item?: TestEmulatorBookingAgentApi) {
        this.id = (item && typeof item.id == 'number') ? item.id : null;
        this.password = (item && item.password) ? item.password : '';
        this.time = (item && item.time) ? item.time : '';
    };
};

export class TestEmulatorAgentBookingParametersApi {
    public availability: string;
    public bookingTime: string;
    public includeTestVendors: boolean;
    public journey: TestEmulatorAgentBookingJourneyApi;
    public passengerDetails: TestEmulatorAgentBookingPassengerApi;
    public pricing: TestEmulatorAgentBookingPricingApi;
    public ride: TestEmulatorAgentBookingRideApi;
    public source: string;
    public vendorRating: string;

    public constructor(item?: TestEmulatorAgentBookingParametersApi) {
        this.availability = (item && item.availability) ? item.availability : '';
        this.bookingTime = (item && item.bookingTime) ? item.bookingTime : '';
        this.includeTestVendors = (item && item.includeTestVendors) ? true : false;
        this.journey = (item && item.journey) ? new TestEmulatorAgentBookingJourneyApi(item.journey) : new TestEmulatorAgentBookingJourneyApi();
        this.passengerDetails = (item && item.passengerDetails) ? new TestEmulatorAgentBookingPassengerApi(item.passengerDetails) : new TestEmulatorAgentBookingPassengerApi();
        this.pricing = (item && item.pricing) ? new TestEmulatorAgentBookingPricingApi(item.pricing) : new TestEmulatorAgentBookingPricingApi();
        this.ride = (item && item.ride) ? new TestEmulatorAgentBookingRideApi(item.ride) : new TestEmulatorAgentBookingRideApi();
        this.source = (item && item.source) ? item.source : '';
        this.vendorRating = (item && item.vendorRating) ? item.vendorRating : '';
    };
};

export class TestEmulatorAgentBookingParametersView extends TestEmulatorAgentBookingParametersApi {
    public bookingTimeDate: Date;
    public bookingTimeView: string;

    public constructor(item?: TestEmulatorAgentBookingParametersApi) {
        super(item);
        this.bookingTimeDate = (item && item.bookingTime) ? new Date(item.bookingTime) : null;
        this.bookingTimeView = (item && item.bookingTime) ? datePipeShort.transform(item.bookingTime) : '';
    };
};

export class TestEmulatorAgentBookingJourneyApi {
    public from: {
        coordinate: CoordinateApi;
        data: string;
        type: string;
    };
    public to: {
        coordinate: CoordinateApi;
        data: string;
        type: string;
    };

    public constructor(item?: TestEmulatorAgentBookingJourneyApi) {
        this.from = (item && item.from) ? item.from : null;
        this.to = (item && item.to) ? item.to : null;
    };
};

export class TestEmulatorAgentBookingPassengerApi {
    public emailAddress: string;
    public name: string;
    public telephoneNumber: number;

    public constructor(item?: TestEmulatorAgentBookingPassengerApi) {
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : '';
        this.name = (item && item.name) ? item.name : '';
        this.telephoneNumber = (item && typeof item.telephoneNumber == 'number') ? item.telephoneNumber : null;
    };
};

export class TestEmulatorAgentBookingPricingApi {
    public currency: string;
    public marketPlace: string;
    public maxPrice: number;
    public paymentType: string;
    public quotedPrice: number;
    public setPrice: number;

    public constructor(item?: TestEmulatorAgentBookingPricingApi) {
        this.currency = (item && item.currency) ? item.currency : '';
        this.marketPlace = (item && item.marketPlace) ? item.marketPlace : '';
        this.maxPrice = (item && typeof item.maxPrice == 'number') ? item.maxPrice : null;
        this.paymentType = (item && item.paymentType) ? item.paymentType : '';
        this.quotedPrice = (item && typeof item.quotedPrice == 'number') ? item.quotedPrice : null;
        this.setPrice = (item && typeof item.setPrice == 'number') ? item.setPrice : null;
    };
};

export class TestEmulatorAgentBookingRideApi {
    public count: number;
    public driverType: string;
    public facilities: string;
    public luggage: number;
    public type: string;
    public vehicleCategory: string;

    public constructor(item?: TestEmulatorAgentBookingRideApi) {
        this.count = (item && typeof item.count == 'number') ? item.count : null;
        this.driverType = (item && item.driverType) ? item.driverType : '';
        this.facilities = (item && item.facilities) ? item.facilities : '';
        this.luggage = (item && typeof item.luggage == 'number') ? item.luggage : null;
        this.type = (item && item.type) ? item.type : '';
        this.vehicleCategory = (item && item.vehicleCategory) ? item.vehicleCategory : '';
    };
};

export class TestEmulatorStatsApi extends HalLinksApi {
    public asapLatenessStats: TestEmulatorAsapLatenessStatsApi;
    public asapTimeToArriveStats: TestEmulatorAsapTimeToArriveStatsApi;
    public completionStats: TestEmulatorCompletionStatsApi;
    public id: string;
    public preBookingStats: TestEmulatorPreBookingStatsApi;
    public timeToDispatchStats: TestEmulatorTimeToDispatchStatsApi;

    public constructor(item?: TestEmulatorStatsApi) {
        super(item);
        this.asapLatenessStats = (item && item.asapLatenessStats) ? new TestEmulatorAsapLatenessStatsApi(item.asapLatenessStats) : new TestEmulatorAsapLatenessStatsApi();
        this.asapTimeToArriveStats = (item && item.asapTimeToArriveStats) ? new TestEmulatorAsapTimeToArriveStatsApi(item.asapTimeToArriveStats) : new TestEmulatorAsapTimeToArriveStatsApi();
        this.completionStats = (item && item.completionStats) ? new TestEmulatorCompletionStatsApi(item.completionStats) : new TestEmulatorCompletionStatsApi();
        this.id = (item && item.id) ? item.id : '';
        this.preBookingStats = (item && item.preBookingStats) ? new TestEmulatorPreBookingStatsApi(item.preBookingStats) : new TestEmulatorPreBookingStatsApi();
        this.timeToDispatchStats = (item && item.timeToDispatchStats) ? new TestEmulatorTimeToDispatchStatsApi(item.timeToDispatchStats) : new TestEmulatorTimeToDispatchStatsApi();
    };
};

export class TestEmulatorAsapLatenessStatsApi {
    public fiveToTen: number;
    public id: string;
    public tenPlus: number;
    public twoToFive: number;
    public zeroToTwo: number;

    public constructor(item?: TestEmulatorAsapLatenessStatsApi) {
        this.fiveToTen = (item && typeof item.fiveToTen == 'number') ? item.fiveToTen : null;
        this.id = (item && item.id) ? item.id : '';
        this.tenPlus = (item && typeof item.tenPlus == 'number') ? item.tenPlus : null;
        this.twoToFive = (item && typeof item.twoToFive == 'number') ? item.twoToFive : null;
        this.zeroToTwo = (item && typeof item.zeroToTwo == 'number') ? item.zeroToTwo : null;
    };
};

export class TestEmulatorAsapTimeToArriveStatsApi {
    public fifteenPlus: number;
    public fiveToTen: number;
    public id: string;
    public tenToFifteen: number;
    public zeroToFive: number;

    public constructor(item?: TestEmulatorAsapTimeToArriveStatsApi) {
        this.fifteenPlus = (item && typeof item.fifteenPlus == 'number') ? item.fifteenPlus : null;
        this.fiveToTen = (item && typeof item.fiveToTen == 'number') ? item.fiveToTen : null;
        this.id = (item && item.id) ? item.id : '';
        this.tenToFifteen = (item && typeof item.tenToFifteen == 'number') ? item.tenToFifteen : null;
        this.zeroToFive = (item && typeof item.zeroToFive == 'number') ? item.zeroToFive : null;
    };
};

export class TestEmulatorCompletionStatsApi {
    public completed: number;
    public completedWithErrors: number;
    public id: string;
    public notCompleted: number;

    public constructor(item?: TestEmulatorCompletionStatsApi) {
        this.completed = (item && typeof item.completed == 'number') ? item.completed : null;
        this.completedWithErrors = (item && typeof item.completedWithErrors == 'number') ? item.completedWithErrors : null;
        this.id = (item && item.id) ? item.id : '';
        this.notCompleted = (item && typeof item.notCompleted == 'number') ? item.notCompleted : null;
    };
};

export class TestEmulatorPreBookingStatsApi {
    public fiveToTen: number;
    public id: string;
    public tenPlus: number;
    public twoToFive: number;
    public zeroToTwo: number;

    public constructor(item?: TestEmulatorPreBookingStatsApi) {
        this.fiveToTen = (item && typeof item.fiveToTen == 'number') ? item.fiveToTen : null;
        this.id = (item && item.id) ? item.id : '';
        this.tenPlus = (item && typeof item.tenPlus == 'number') ? item.tenPlus : null;
        this.twoToFive = (item && typeof item.twoToFive == 'number') ? item.twoToFive : null;
        this.zeroToTwo = (item && typeof item.zeroToTwo == 'number') ? item.zeroToTwo : null;
    };
};

export class TestEmulatorTimeToDispatchStatsApi {
    public eightPlus: number;
    public fiveToSix: number;
    public fourToFive: number;
    public id: string;
    public oneToTwo: number;
    public sevenToEight: number;
    public sixToSeven: number;
    public threeToFour: number;
    public twoToThree: number;
    public zeroToOne: number;

    public constructor(item?: TestEmulatorTimeToDispatchStatsApi) {
        this.eightPlus = (item && typeof item.eightPlus == 'number') ? item.eightPlus : null;
        this.fiveToSix = (item && typeof item.fiveToSix == 'number') ? item.fiveToSix : null;
        this.fourToFive = (item && typeof item.fourToFive == 'number') ? item.fourToFive : null;
        this.id = (item && item.id) ? item.id : '';
        this.oneToTwo = (item && typeof item.oneToTwo == 'number') ? item.oneToTwo : null;
        this.sevenToEight = (item && typeof item.sevenToEight == 'number') ? item.sevenToEight : null;
        this.sixToSeven = (item && typeof item.sixToSeven == 'number') ? item.sixToSeven : null;
        this.threeToFour = (item && typeof item.threeToFour == 'number') ? item.threeToFour : null;
        this.twoToThree = (item && typeof item.twoToThree == 'number') ? item.twoToThree : null;
        this.zeroToOne = (item && typeof item.zeroToOne == 'number') ? item.zeroToOne : null;
    };
};

export class GetAllTestRegionsResponseApi extends HalLinksApi {
    public testEmulatorMapAreaList: RegionApi[];

    public constructor(item?: GetAllTestRegionsResponseApi) {
        super(item);
        this.testEmulatorMapAreaList = (item && item.testEmulatorMapAreaList) ? item.testEmulatorMapAreaList.map(i => new RegionApi(i)) : [];
    };
};

export class GetAllTestRegionsResponseView extends GetAllTestRegionsResponseApi {
    declare public testEmulatorMapAreaList: RegionView[];

    public constructor(item?: GetAllTestRegionsResponseView) {
        super(item);
        this.testEmulatorMapAreaList = (item && item.testEmulatorMapAreaList) ? item.testEmulatorMapAreaList.map(i => new RegionView(i)) : [];
    };
};

export class CreateTestRegionPayload {
    public geoPoints: CoordinateApi[];
    public name: string;
    public originatorId: string;

    public constructor(item: RegionApi, originatorId: string) {
        this.geoPoints = [...item.points];
        this.name = (item && item.name) ? item.name : '';
        this.originatorId = originatorId;
    };
};