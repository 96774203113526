import { BookingView } from 'src/models';
import { TableCustomisation } from '@autocab/ghost-vs-table';

export const InvoiceBookingsTableCustomisation: TableCustomisation<BookingView> = {
    customisationKey: 'InvoiceBookingsTableCustomisation',
    tableName: 'global.tables.invoice.bookings.table_title',
    defaultColumns: {
        operator: {
            width: 33,
            name: 'global.modals.invoice.bookings.table.operator',
            orderBy: 'operatorName',
            prop: 'operatorName',
            show: true
        },
        pickupAddress: {
            width: 33,
            name: 'global.modals.invoice.bookings.table.pickup',
            orderBy: 'journey.from.address',
            prop: 'journey.from.address',
            show: true
        },
        destinationAddress: {
            width: 33,
            name: 'global.modals.invoice.bookings.table.destination',
            orderBy: 'journey.to.address',
            prop: 'journey.to.address',
            show: true
        },
        status: {
            width: 10,
            name: 'global.modals.invoice.bookings.table.status',
            orderBy: 'statusView',
            prop: 'statusView',
            show: false
        },
        originator: {
            width: 13,
            name: 'global.modals.invoice.bookings.table.originator',
            orderBy: 'originatorName',
            prop: 'originatorName',
            show: false
        },
        completionTime: {
            width: 15,
            name: 'global.modals.invoice.bookings.table.completed_at',
            orderBy: 'timing.completionTime',
            prop: 'timing.completionTimeView',
            show: false
        },
        bookingId: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.booking_id',
            orderBy: 'bookingId',
            prop: 'bookingId',
            show: false
        },
        driverName: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.driver_name',
            orderBy: 'driver.fullName',
            prop: 'driver.fullName',
            show: false
        },
        currency: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.currency',
            orderBy: 'pricing.currency',
            prop: 'pricing.currency',
            show: false
        },
        extras: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.extras',
            orderBy: 'pricing.extrasPrice',
            prop: 'pricing.extrasPriceCurrency',
            show: false
        },
        fare: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.fare',
            orderBy: 'pricing.fare',
            prop: 'pricing.fareCurrency',
            show: false
        },
        fees: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.fees',
            orderBy: 'pricing.fees',
            prop: 'pricing.feesCurrency',
            show: false
        },
        gratuity: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.gratuity',
            orderBy: 'pricing.gratuity',
            prop: 'pricing.gratuityCurrency',
            show: false
        },
        originatorCommission: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.originator_commission',
            orderBy: 'pricing.originatorCommission',
            prop: 'pricing.originatorCommissionCurrency',
            show: false
        },
        price: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.price',
            orderBy: 'pricing.price',
            prop: 'pricing.priceCurrency',
            show: false
        },
        priceNET: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.net_price',
            orderBy: 'pricing.priceNET',
            prop: 'pricing.priceNETCurrency',
            show: false
        },
        quotedPrice: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.quoted_price',
            orderBy: 'pricing.quotedPrice',
            prop: 'pricing.quotedPriceCurrency',
            show: false
        },
        serviceCharge: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.service_charge',
            orderBy: 'pricing.serviceCharge',
            prop: 'pricing.serviceChargeCurrency',
            show: false
        },
        waitingTimeMinutes: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.waiting_time_minutes',
            orderBy: 'pricing.waitingTimeMinutes',
            prop: 'pricing.waitingTimeMinutes',
            show: false
        },
        waitingTimePrice: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.waiting_time_price',
            orderBy: 'pricing.waitingTimePrice',
            prop: 'pricing.waitingTimePriceCurrency',
            show: false
        },
        passengers: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.passengers',
            orderBy: 'ride.passengerCount',
            prop: 'ride.passengerCount',
            show: false
        },
        vehicleType: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.vehicle_type',
            orderBy: 'ride.vehicleType',
            prop: 'ride.vehicleType',
            show: false
        },
        vehicleCategory: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.vehicle_category',
            orderBy: 'ride.vehicleCategory',
            prop: 'ride.vehicleCategory',
            show: false
        },
        source: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.source',
            orderBy: 'source',
            prop: 'source',
            show: false
        },
        bookedAtTime: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.booked_at',
            orderBy: 'timing.bookedAtTime',
            prop: 'timing.bookedAtTimeView',
            show: false
        },
        dispatchTime: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.dispatched_at',
            orderBy: 'timing.dispatchTime',
            prop: 'timing.dispatchTimeView',
            show: false
        },
        etaAtDispatch: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.eta_at_dispatch',
            orderBy: 'timing.etaAtDispatchView',
            prop: 'timing.etaAtDispatchView',
            show: false
        },
        pickedUpTime: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.picked_up_at',
            orderBy: 'timing.pickedUpTime',
            prop: 'timing.pickedUpTimeView',
            show: false
        },
        pickupDueTime: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.pickup_due',
            orderBy: 'timing.pickupDueTime',
            prop: 'timing.pickupDueTimeView',
            show: false
        },
        quotedETA: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.quoted_eta',
            orderBy: 'timing.quotedETAView',
            prop: 'timing.quotedETAView',
            show: false
        },
        vehicleArrivalTime: {
            width: 15,
            name: 'global.modals.invoice.bookings.table.vehicle_arrival',
            orderBy: 'quotedEtaAccuracyInSeconds',
            prop: 'quotedEtaAccuracyLatenessDisplayString',
            show: false
        },
        vehicleMake: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.vehicle_make',
            orderBy: 'vehicle.make',
            prop: 'vehicle.make',
            show: false
        },
        vehicleModel: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.vehicle_model',
            orderBy: 'vehicle.model',
            prop: 'vehicle.model',
            show: false
        },
        vehicleRegistration: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.vehicle_registration',
            orderBy: 'vehicle.registration',
            prop: 'vehicle.registration',
            show: false
        },
        authorizationReference: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.authorization_reference',
            orderBy: 'authorizationReference',
            prop: 'authorizationReference',
            show: false
        },
        bookingCreated: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.booking_created',
            orderBy: 'bookingCreated',
            prop: 'bookingCreatedView',
            show: false
        },
        bookingReference: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.booking_reference',
            orderBy: 'bookingReference',
            prop: 'bookingReference',
            show: false
        },
        cabExchangeAgentId: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.cab_exchange_agent_id',
            orderBy: 'cabExchangeAgentId',
            prop: 'cabExchangeAgentId',
            show: false
        },
        cabExchangeVendorId: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.cab_exchange_vendor_id',
            orderBy: 'cabExchangeVendorId',
            prop: 'cabExchangeVendorId',
            show: false
        },
        originatorBookingReference: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.originator_booking_reference',
            orderBy: 'originatorBookingReference',
            prop: 'originatorBookingReference',
            show: false
        },
        emailAddress: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.passenger_email_address',
            orderBy: 'passenger.emailAddress',
            prop: 'passenger.emailAddress',
            show: false
        },
        name: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.passenger_name',
            orderBy: 'passenger.name',
            prop: 'passenger.name',
            show: false
        },
        telephoneNumber: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.passenger_telephone_number',
            orderBy: 'passenger.telephoneNumber',
            prop: 'passenger.telephoneNumber',
            show: false
        },
        amountPaidByOperator: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.amount_paid_by_operator',
            orderBy: 'payment.amountPaidByOperator',
            prop: 'payment.amountPaidByOperator',
            show: false
        },
        amountPaidByOriginator: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.amount_paid_by_originator',
            orderBy: 'payment.amountPaidByOriginator',
            prop: 'payment.amountPaidByOriginator',
            show: false
        },
        marketPlace: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.market_place',
            orderBy: 'payment.marketPlace',
            prop: 'payment.marketPlace',
            show: false
        },
        marketPlacePaymentMode: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.market_place_payment_mode',
            orderBy: 'payment.marketPlacePaymentMode',
            prop: 'payment.marketPlacePaymentMode',
            show: false
        },
        operatorCurrency: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.operator_currency',
            orderBy: 'payment.operatorCurrency',
            prop: 'payment.operatorCurrency',
            show: false
        },
        paymentPoint: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.payment_point',
            orderBy: 'payment.paymentPoint',
            prop: 'payment.paymentPoint',
            show: false
        },
        cancellationCharge: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.cancellation_charge',
            orderBy: 'pricing.cancellationCharge',
            prop: 'pricing.cancellationChargeCurrency',
            show: false
        },
        driverType: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.driverType',
            orderBy: 'rider.driverType',
            prop: 'rider.driverType',
            show: false
        },
        facilities: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.facilities',
            orderBy: 'ride.facilities',
            prop: 'ride.facilities',
            show: false
        },
        quotedAvgDispatchSeconds: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.quoted_avg_dispatch_seconds',
            orderBy: 'timing.quotedAvgDispatchSeconds',
            prop: 'timing.quotedAvgDispatchSeconds',
            show: false
        },
        quotedAvgETASeconds: {
            width: 25,
            name: 'global.modals.invoice.bookings.table.quoted_avg_eta_seconds',
            orderBy: 'timing.quotedAvgETASeconds',
            prop: 'timing.quotedAvgETASeconds',
            show: false
        },
    }
};