import { AddressDetailsApi } from "./address.model";
import { ArrayUtilities } from "src/shared/array.utilities";
import { BankAccountDetailsApi } from "./banking.model";
import { CoordinateApi, LatLng } from "./coordinate.model";
import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";
import { HalLinksApi } from "./hal-link.model";
import { PartialNameAndId } from "./global.model";

const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';

export class iGoCompanyPartialApi extends HalLinksApi {
    public id: string;
    public name: string;

    public constructor(item?: iGoCompanyPartialApi) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class GetAllIGoCompaniesResponseApi extends HalLinksApi {
    public companies: iGoCompanyApi[];

    public constructor(item: GetAllIGoCompaniesResponseApi) {
        super(item);
        this.companies = item.companies || [];
    };
};

export class GetAllIGoCompaniesResponseView extends GetAllIGoCompaniesResponseApi {
    declare public companies: iGoCompanyView[];

    public constructor(item: GetAllIGoCompaniesResponseView) {
        super(item);
        this.companies = item.companies || [];
    };
};

export class iGoCompanyApi extends iGoCompanyPartialApi {
    public email: string;

    public constructor(item?: iGoCompanyApi) {
        super(item);
        this.email = (item && item.email) ? item.email : '';
    };
};

export class iGoCompanyView extends iGoCompanyApi {
    public accessingProfile: boolean;

    public constructor(item?: iGoCompanyView) {
        super(item);
        this.accessingProfile = (item && item.accessingProfile) ? true : false;
    };
};

export class GetAllOriginatorsResponseApi extends HalLinksApi {
    public originators: iGoCompanyOriginatorApi[];

    public constructor(item: GetAllOriginatorsResponseApi) {
        super(item);
        this.originators = item.originators ? item.originators.map(i => new iGoCompanyOriginatorApi(i)) : [];
    };
};

export class GetAllOriginatorsResponseView extends GetAllOriginatorsResponseApi {
    declare public originators: iGoCompanyOriginatorView[];

    public constructor(item: GetAllOriginatorsResponseView) {
        super(item);
        this.originators = item.originators ? item.originators.map(i => new iGoCompanyOriginatorView(i)) : [];
    };
};

export class GetAllCompanyOriginatorsResponseApi extends HalLinksApi {
    public originators: iGoCompanyOriginatorPartialApi[];

    public constructor(item: GetAllCompanyOriginatorsResponseApi) {
        super(item);
        this.originators = item.originators ? item.originators.map(i => new iGoCompanyOriginatorPartialApi(i)) : [];
    };
};

export class GetAllCompanyOriginatorsResponseView extends GetAllCompanyOriginatorsResponseApi {
    declare public originators: iGoCompanyOriginatorPartialView[];

    public constructor(item: GetAllCompanyOriginatorsResponseView) {
        super(item);
        this.originators = item.originators ? item.originators.map(i => new iGoCompanyOriginatorPartialView(i)) : [];
    };
};

export class iGoCompanyOriginatorPartialApi extends HalLinksApi {
    public companyId: string;
    public id: string;
    public name: string;

    public constructor(item?: iGoCompanyOriginatorPartialApi) {
        super(item);
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class iGoCompanyOriginatorPartialView extends iGoCompanyOriginatorPartialApi {
    public accessingProfile: boolean;
    public displayName: string;

    public constructor(item?: iGoCompanyOriginatorPartialView) {
        super(item);
        this.accessingProfile = (item && item.accessingProfile) ? true : false;
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
    };
};

export class iGoCompanyOriginatorApi extends iGoCompanyOriginatorPartialApi {
    public active: boolean;
    public address: AddressDetailsApi;
    public cabExchangeAgentId: string;
    declare public companyId: string;
    public companyRegNo: string;
    public companyVatNo: string;
    public contactInfo: iGoCompanyContactInfoApi;
    public description: string;
    public global: boolean;
    public iGoContactInfo: iGoCompanyContactInfoApi;
    public invoiceTemplate: number;
    public location: string;
    public logo: string;
    public marketplaceSettings: OriginatorMarketplaceCommissionSettingsApi;
    public operatorContactInfo: iGoCompanyContactInfoApi;
    public originatorMarketplaces: iGoCompanyOriginatorMarketplaceApi[];
    public phoneNumber: string;

    public constructor(item?: iGoCompanyOriginatorApi) {
        super(item);
        this.active = (item && item.active) ? true : false;
        this.address = (item && item.address) ? new AddressDetailsApi(item.address) : new AddressDetailsApi();
        this.cabExchangeAgentId = (item && item.cabExchangeAgentId) ? item.cabExchangeAgentId : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.companyRegNo = (item && item.companyRegNo) ? item.companyRegNo : '';
        this.companyVatNo = (item && item.companyVatNo) ? item.companyVatNo : '';
        this.contactInfo = (item && item.contactInfo && Object.values(item.contactInfo).length) ? new iGoCompanyContactInfoApi(item.contactInfo) : null;
        this.description = (item && item.description) ? item.description : '';
        this.global = (item && item.global) ? true : false;
        this.iGoContactInfo = (item && item.iGoContactInfo && Object.values(item.iGoContactInfo).length) ? new iGoCompanyContactInfoApi(item.iGoContactInfo) : null;
        this.invoiceTemplate = (item && typeof item.invoiceTemplate == 'number') ? item.invoiceTemplate : null;
        this.location = (item && item.location) ? item.location : '';
        this.logo = (item && item.logo) ? item.logo : '';
        this.marketplaceSettings = (item && item.marketplaceSettings) ? new OriginatorMarketplaceCommissionSettingsApi(item.marketplaceSettings) : new OriginatorMarketplaceCommissionSettingsApi();
        this.operatorContactInfo = (item && item.operatorContactInfo && Object.values(item.operatorContactInfo).length) ? new iGoCompanyContactInfoApi(item.operatorContactInfo) : null;
        this.originatorMarketplaces = (item && item.originatorMarketplaces) ? ArrayUtilities.sortAlphabetically(item.originatorMarketplaces.map(i => new iGoCompanyOriginatorMarketplaceApi(i)), 'name') : [];
        this.phoneNumber = (item && item.phoneNumber) ? item.phoneNumber : '';
    };
};

export class iGoCompanyOriginatorView extends iGoCompanyOriginatorApi {
    public accessingProfile: boolean;
    declare public companyId: string;
    public companyName: string;
    public displayName: string;
    public invoiceTemplateName: string;
    public originatorMarketplacesIds: string[];
    public originatorMarketplacesNames: string = '';

    public constructor(item?: iGoCompanyOriginatorView) {
        super(item);
        this.accessingProfile = (item && item.accessingProfile) ? true : false;
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.companyName = (item && item.companyName) ? item.companyName : '';
        this.contactInfo = (item && item.contactInfo && Object.values(item.contactInfo).length) ? new iGoCompanyContactInfoApi(item.contactInfo) : new iGoCompanyContactInfoApi();
        this.displayName = (item && item.cabExchangeAgentId && item.name) ? `${item.cabExchangeAgentId} - ${item.name}` : '';
        this.iGoContactInfo = (item && item.iGoContactInfo && Object.values(item.iGoContactInfo).length) ? new iGoCompanyContactInfoApi(item.iGoContactInfo) : new iGoCompanyContactInfoApi();
        this.invoiceTemplateName = (item && item.invoiceTemplateName) ? item.invoiceTemplateName : '';
        this.operatorContactInfo = (item && item.operatorContactInfo && Object.values(item.operatorContactInfo).length) ? new iGoCompanyContactInfoApi(item.operatorContactInfo) : new iGoCompanyContactInfoApi();
        this.originatorMarketplacesIds = (item && item.originatorMarketplacesIds) ? item.originatorMarketplacesIds : (item && item.originatorMarketplaces) ? item.originatorMarketplaces.map(i => i.id) : [];
        this.originatorMarketplacesNames = (item && item.originatorMarketplaces) ? item.originatorMarketplaces.map(i => i.name).join(', ') : '';
    };
};

export class CXAgentView extends PartialNameAndId {
    public agentId: number;
    declare public displayName: string;

    public constructor(item?: CXAgentView) {
        super(item);
        this.agentId = (item && item.id) ? Number(item.id) : null;
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
    };
};

export class CXVendorView extends PartialNameAndId {
    declare public displayName: string;
    public vendorId: number;

    public constructor(item?: CXVendorView) {
        super(item);
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
        this.vendorId = (item && item.id) ? Number(item.id) : null;
    };
};

export class iGoCompanyOriginatorMarketplaceApi {
    public id: string;
    public name: string;

    public constructor(item?: iGoCompanyOriginatorMarketplaceApi) {
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class UpdateIGoOriginatorAsIGoPayload {
    public commissionPercentage: number;
    public commissionVatPercentage: number;
    public companyId: string;
    public originatorMarketplaces: string[];

    public constructor(item?: iGoCompanyOriginatorView) {
        this.commissionPercentage = (item && item.marketplaceSettings.commissionPercentage) ? item.marketplaceSettings.commissionPercentage : null;
        this.commissionVatPercentage = (item && item.marketplaceSettings.commissionVATPercentage) ? item.marketplaceSettings.commissionVATPercentage : null;
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.originatorMarketplaces = (item && item.originatorMarketplacesIds) ? item.originatorMarketplacesIds : (item && item.originatorMarketplaces) ? item.originatorMarketplaces.map(i => i.id) : [];
    };
};

export class GetAllCXOperatorsResponseApi extends HalLinksApi {
    public vendors: PartialNameAndId[];

    public constructor(item?: GetAllCXOperatorsResponseApi) {
        super(item);
        this.vendors = (item && item.vendors) ? item.vendors.map(i => new PartialNameAndId(i)) : [];
    };
};

export class GetAllCXOperatorsResponseView extends GetAllCXOperatorsResponseApi {
    declare public vendors: CXVendorView[];

    public constructor(item?: GetAllCXOperatorsResponseView) {
        super(item);
        this.vendors = (item && item.vendors) ? item.vendors.map(i => new CXVendorView(i)) : [];
    };
};

export class GetAllCXOriginatorsResponseApi extends HalLinksApi {
    public agents: PartialNameAndId[];

    public constructor(item?: GetAllCXOriginatorsResponseApi) {
        super(item);
        this.agents = (item && item.agents) ? item.agents.map(i => new PartialNameAndId(i)) : [];
    };
};

export class GetAllCXOriginatorsResponseView extends GetAllCXOriginatorsResponseApi {
    declare public agents: CXAgentView[];

    public constructor(item?: GetAllCXOriginatorsResponseView) {
        super(item);
        this.agents = (item && item.agents) ? item.agents.map(i => new CXAgentView(i)) : [];
    };
};

export class CXOperatorApi {
    public address: string;
    public city: string;
    public country: string;
    public customerId: string;
    public description: string;
    public emailAddress: string;
    public enabled: boolean;
    public facebook: string;
    public id: string;
    public marketPlaceRegions: CXOperatorMarketplaceRegionApi[];
    public name: string;
    public telephoneNumber: string;
    public twitter: string;

    public constructor(item: CXOperatorApi) {
        this.address = item.address || '';
        this.city = item.city || '';
        this.country = item.country || '';
        this.customerId = item.customerId || '';
        this.description = item.description || '';
        this.emailAddress = item.emailAddress || '';
        this.enabled = item.enabled || false;
        this.facebook = item.facebook || '';
        this.id = item.id || '';
        this.marketPlaceRegions = item.marketPlaceRegions && item.marketPlaceRegions.map(i => new CXOperatorMarketplaceRegionApi(i)) || [];
        this.name = item.name || '';
        this.telephoneNumber = item.telephoneNumber || '';
        this.twitter = item.twitter || '';
    };
};

export class CXOperatorView extends CXOperatorApi {
    public displayName: string;

    public constructor(item?: CXOperatorView) {
        super(item);
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
    };
};

export class CXOperatorMarketplaceRegionApi {
    public polygon: CoordinateApi[];

    public constructor(item: CXOperatorMarketplaceRegionApi) {
        this.polygon = item.polygon && item.polygon.map(i => new CoordinateApi(i));
    };
};

export class GetOperatorsPayload {
    public count: number;
    public name: string;
    public operatorId: string;
    public totalFilters: number;

    public getTotalFilters(): void {
        const filterKeys: string[] = ['name', 'operatorId'];
        const keys: string[] = Object.keys(this).filter(k => filterKeys.indexOf(k) >= 0);
        this.totalFilters = 0;
        keys.forEach(key => {
            if (typeof this[key] == 'number') {
                this[key] > 0 && this.totalFilters++;
            } else {
                this[key] && this[key].length && this.totalFilters++;
            };
        });
    };

    public constructor(item?: GetOperatorsPayload) {
        this.count = 100;
        this.name = (item && item.name) ? item.name : null;
        this.operatorId = (item && item.operatorId) ? item.operatorId : null;
        this.getTotalFilters();
    };
};

export class GetAllOperatorsResponseApi extends HalLinksApi {
    public operators: iGoCompanyOperatorApi[];

    public constructor(item: GetAllOperatorsResponseApi) {
        super(item);
        this.operators = item.operators ? item.operators.map(i => new iGoCompanyOperatorApi(i)) : [];
    };
};

export class GetAllOperatorsResponseView extends GetAllOperatorsResponseApi {
    declare public operators: iGoCompanyOperatorView[];

    public constructor(item: GetAllOperatorsResponseView) {
        super(item);
        this.operators = item.operators ? item.operators.map(i => new iGoCompanyOperatorView(i)) : [];
    };
};

export class GetAllCompanyOperatorsResponseApi extends HalLinksApi {
    public operators: iGoCompanyOperatorPartialApi[];

    public constructor(item: GetAllCompanyOperatorsResponseApi) {
        super(item);
        this.operators = item.operators ? item.operators.map(i => new iGoCompanyOperatorPartialApi(i)) : [];
    };
};

export class GetAllCompanyOperatorsResponseView extends GetAllCompanyOperatorsResponseApi {
    declare public operators: iGoCompanyOperatorPartialView[];

    public constructor(item: GetAllCompanyOperatorsResponseView) {
        super(item);
        this.operators = item.operators ? item.operators.map(i => new iGoCompanyOperatorPartialView(i)) : [];
    };
};

export class iGoCompanyOperatorPartialApi extends HalLinksApi {
    public cabExchangeVendorId: string;
    public companyId: string;
    public id: string;
    public name: string;

    public constructor(item?: iGoCompanyOperatorPartialApi) {
        super(item);
        this.cabExchangeVendorId = (item && item.cabExchangeVendorId) ? item.cabExchangeVendorId : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class iGoCompanyOperatorPartialView extends iGoCompanyOperatorPartialApi {
    public accessingProfile: boolean;
    public displayName: string;

    public constructor(item?: iGoCompanyOperatorPartialView) {
        super(item);
        this.accessingProfile = (item && item.accessingProfile) ? true : false;
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
    };
};

export class iGoCompanyOperatorApi extends HalLinksApi {
    public active: boolean;
    public appDownloadLink: string;
    public cabExchangeVendorId: string;
    public companyId: string;
    public contactInfo: iGoCompanyContactInfoApi;
    public cornicheEnabled: boolean;
    public details: iGoCompanyOperatorDetailsApi;
    public facebook: string;
    public id: string;
    public iGoContactInfo: iGoCompanyContactInfoApi;
    public kycStatus: boolean;
    public location: string;
    public logo: string;
    public marketplaceSettings: OperatorLiveTestResponseApi;
    public name: string;
    public operatorMapAreas: OperatorMapAreaApi[];
    public originatorContactInfo: iGoCompanyContactInfoApi;
    public region: PartialNameAndId;
    public regionId: string;
    public twitter: string;
    public website: string;

    public constructor(item?: iGoCompanyOperatorApi) {
        super(item);
        this.active = (item && item.active) ? true : false;
        this.appDownloadLink = (item && item.appDownloadLink) ? item.appDownloadLink : '';
        this.cabExchangeVendorId = (item && item.cabExchangeVendorId) ? item.cabExchangeVendorId : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.contactInfo = (item && item.contactInfo && Object.values(item.contactInfo).length) ? new iGoCompanyContactInfoApi(item.contactInfo) : new iGoCompanyContactInfoApi();
        this.cornicheEnabled = (item && item.cornicheEnabled) ? true : false;
        this.details = (item && item.details) ? new iGoCompanyOperatorDetailsApi(item.details) : new iGoCompanyOperatorDetailsApi();
        this.facebook = (item && item.facebook) ? item.facebook : '';
        this.id = (item && item.id) ? item.id : '';
        this.iGoContactInfo = (item && item.iGoContactInfo && Object.values(item.iGoContactInfo).length) ? new iGoCompanyContactInfoApi(item.iGoContactInfo) : new iGoCompanyContactInfoApi();
        this.kycStatus = (item && item.kycStatus) ? true : false;
        this.location = (item && item.location) ? item.location : '';
        this.logo = (item && item.logo) ? item.logo : '';
        this.marketplaceSettings = (item && item.marketplaceSettings) ? new OperatorLiveTestResponseApi(item.marketplaceSettings) : null;
        this.name = (item && item.name) ? item.name : '';
        this.operatorMapAreas = (item && item.operatorMapAreas) ? item.operatorMapAreas.map(i => new OperatorMapAreaApi(i)) : [];
        this.originatorContactInfo = (item && item.originatorContactInfo && Object.values(item.originatorContactInfo).length) ? new iGoCompanyContactInfoApi(item.originatorContactInfo) : new iGoCompanyContactInfoApi();
        this.region = (item && item.region) ? new PartialNameAndId(item.region) : null;
        this.regionId = (item && item.regionId) ? item.regionId : (item && item.region) ? item.region.id : '';
        this.twitter = (item && item.twitter) ? item.twitter : '';
        this.website = (item && item.website) ? item.website : '';
    };
};

export class iGoCompanyOperatorView extends iGoCompanyOperatorApi {
    public accessingProfile: boolean;
    declare public companyId: string;
    public companyName: string;
    public displayName: string;
    declare public operatorMapAreas: OperatorMapAreaView[];

    public constructor(item?: iGoCompanyOperatorView) {
        super(item);
        this.accessingProfile = (item && item.accessingProfile) ? true : false;
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.companyName = (item && item.companyName) ? item.companyName : '';
        this.displayName = (item && item.cabExchangeVendorId && item.name) ? `${item.cabExchangeVendorId} - ${item.name}` : '';
        this.operatorMapAreas = (item && item.operatorMapAreas && item.operatorMapAreas.length) ? ArrayUtilities.sortAlphabetically(item.operatorMapAreas.map(i => new OperatorMapAreaView(i)), 'name') : [];
    };
};

export class iGoCompanyOperatorCoverageAreaApi {
    public polygons: OperatorMapAreaApi[];

    public constructor(item?: iGoCompanyOperatorCoverageAreaApi) {
        this.polygons = (item && item.polygons && item.polygons.length) ? item.polygons.map(i => new OperatorMapAreaApi(i)) : [];
    };
};

export class iGoCompanyOperatorCoverageAreaView extends iGoCompanyOperatorCoverageAreaApi {
    declare public polygons: OperatorMapAreaView[];

    public constructor(item?: iGoCompanyOperatorCoverageAreaView) {
        super(item);
        this.polygons = (item && item.polygons && item.polygons.length) ? item.polygons.map(i => new OperatorMapAreaView(i)) : [];
    };
};

export class iGoCompanyContactInfoApi {
    public email: string;
    public name: string;
    public number: string;

    public constructor(item?: iGoCompanyContactInfoApi) {
        this.email = (item && item.email) ? item.email : null;
        this.name = (item && item.name) ? item.name : null;
        this.number = (item && item.number) ? item.number : null;
    };
};

export class DiscoverOperatorContactDetailsApi {
    public name: string;
    public emailAddress: string;
    public telephoneNumber: string;

    public constructor(item?: DiscoverOperatorContactDetailsApi) {
        this.name = (item && item.name) ? item.name : null;
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : null;
        this.telephoneNumber = (item && item.telephoneNumber) ? item.telephoneNumber : null;
    };
};

export class OperatorProfileContactInfoApi {
    public contactName: string;
    public emailAddress: string;
    public telephoneNumber: string;
    public twitter: string;
    public facebook: string;

    public constructor(item?: OperatorProfileContactInfoApi) {
        this.contactName = (item && item.contactName) ? item.contactName : null;
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : null;
        this.telephoneNumber = (item && item.telephoneNumber) ? item.telephoneNumber : null;
        this.twitter = (item && item.twitter) ? item.twitter : null;
        this.facebook = (item && item.facebook) ? item.facebook : null;
    };
};

export class iGoCompanyOperatorDetailsApi {
    public address: AddressDetailsApi;
    public bank: BankAccountDetailsApi;
    public companyRegNo: string;
    public companyTradingName: string;
    public noOfVehicles?: number;
    public vatTaxNo: string;

    public constructor(item?: iGoCompanyOperatorDetailsApi) {
        this.address = (item && item.address) ? new AddressDetailsApi(item.address) : new AddressDetailsApi();
        this.bank = (item && item.bank) ? new BankAccountDetailsApi(item.bank) : new BankAccountDetailsApi();
        this.companyRegNo = (item && item.companyRegNo) ? item.companyRegNo : '';
        this.companyTradingName = (item && item.companyTradingName) ? item.companyTradingName : '';
        this.noOfVehicles = (item) ? item.noOfVehicles : null;
        this.vatTaxNo = (item && item.vatTaxNo) ? item.vatTaxNo : '';
    };
};

export class StatusAuditApi {
    public status: boolean;
    public updated: Date;
    public updatedBy: PartialNameAndId;

    public constructor(item?: StatusAuditApi) {
        this.status = (item && item.status) ? true : false;
        this.updated = (item && item.updated) ? new Date(item.updated) : null;
        this.updatedBy = (item && item.updatedBy) ? new PartialNameAndId(item.updatedBy) : null;
    };
};

export class StatusAuditView extends StatusAuditApi {
    public index: number;
    public updatedView: string;

    public constructor(item?: StatusAuditView, index?: number) {
        super(item);
        this.index = index ? index : (item && typeof item.index == 'number') ? item.index : null;
        this.updatedView = (item && item.updated) ? getStatusAuditUpdatedDateString(item.updated) : '';
    };
};

export class EnabledAuditsListApi {
    public audits: EnabledAuditApi[];

    public constructor(item?: EnabledAuditsListApi) {
        this.audits = (item && item.audits?.length) ? item.audits.map(i => new EnabledAuditApi(i)) : [];
    };
};

export class EnabledAuditsListView extends EnabledAuditsListApi {
    declare public audits: EnabledAuditView[];

    public constructor(item?: EnabledAuditsListView) {
        super(item);
        this.audits = (item && item.audits?.length) ? item.audits.map((i, index) => new EnabledAuditView(i, index + 1)) : [];
    };
};

export class EnabledAuditApi extends StatusAuditApi {
    public enabled: boolean;

    public constructor(item?: EnabledAuditApi) {
        super(item);
        this.enabled = (item && item.enabled) ? true : false;
    };
};
export class EnabledAuditView extends EnabledAuditApi {
    public index: number;
    public updatedView: string;

    public constructor(item?: EnabledAuditView, index?: number) {
        super(item);
        this.index = index ? index : (item && typeof item.index == 'number') ? item.index : null;
        this.updatedView = (item && item.updated) ? getStatusAuditUpdatedDateString(item.updated) : '';
    };
};

const getStatusAuditUpdatedDateString = (date: Date): string => {
    const timezoneOffset: number = date ? new Date(date).getTimezoneOffset() / -60 : null;
    const updatedDate = date ? new Date(date) : null;
    updatedDate && updatedDate.setHours(updatedDate.getHours() + timezoneOffset);
    return updatedDate ? datePipeShort.transform(updatedDate.toString()) : '';
};

export class LinkIGoOperatorPayload {
    public address: AddressDetailsApi;
    public bank: BankAccountDetailsApi;
    public cabExchangeVendorId: string;
    public companyId: string;
    public companyRegNo: string;
    public companyTradingName: string;
    public vatTaxNo: string;

    public constructor(item?: iGoCompanyOperatorView) {
        this.address = (item && item.details && item.details.address) ? new AddressDetailsApi(item.details.address) : null;
        this.bank = (item && item.details && item.details.bank) ? new BankAccountDetailsApi(item.details.bank) : new BankAccountDetailsApi();
        this.cabExchangeVendorId = (item && item.cabExchangeVendorId) ? item.cabExchangeVendorId : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.companyRegNo = (item && item.details && item.details.companyRegNo) ? item.details.companyRegNo : '';
        this.companyTradingName = (item && item.details && item.details.companyTradingName) ? item.details.companyTradingName : '';
        this.vatTaxNo = (item && item.details && item.details.vatTaxNo) ? item.details.vatTaxNo : '';
    };
};

export class CreateIGoOperatorPayload extends iGoCompanyOperatorView {
    public address: AddressDetailsApi;
    public companyRegNo: string;
    public companyTradingName: string;
    public customerId: string;
    public emailAddress: string;
    public MarketplaceSettings: CreateOperatorMarketplaceSettingsApi;
    public telephoneNumber: string;
    public vatTaxNo: string;

    public constructor(item?: CreateIGoOperatorPayload) {
        super(item);
        this.address = (item && item.details && item.details.address && item.details.address) ? new AddressDetailsApi(item.details.address) : new AddressDetailsApi();
        this.companyRegNo = (item && item.details && item.details.companyRegNo) ? item.details.companyRegNo : '';
        this.companyTradingName = (item && item.details && item.details.companyTradingName) ? item.details.companyTradingName : '';
        this.customerId = (item && item.customerId) ? item.customerId : null;
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : '';
        this.MarketplaceSettings = (item && item.MarketplaceSettings) ? new CreateOperatorMarketplaceSettingsApi(item.MarketplaceSettings) : new CreateOperatorMarketplaceSettingsApi();
        this.telephoneNumber = (item && item.telephoneNumber) ? item.telephoneNumber : '';
        this.vatTaxNo = (item && item.details && item.details.vatTaxNo) ? item.details.vatTaxNo : '';
    };
};

export class CreateOperatorMarketplaceSettingsApi {
    public PaymentMode: string;
    public VATSettings: CreateOperatorMarketplaceSettingsVATSettingsApi;

    public constructor(item?: CreateOperatorMarketplaceSettingsApi) {
        this.PaymentMode = (item && item.PaymentMode) ? item.PaymentMode : '';
        this.VATSettings = (item && item.VATSettings) ? new CreateOperatorMarketplaceSettingsVATSettingsApi(item.VATSettings) : new CreateOperatorMarketplaceSettingsVATSettingsApi();
    };
};

export class CreateOperatorMarketplaceSettingsVATSettingsApi {
    public Mode: string;
    public ServiceChargePercentage: number;
    public VatPercentage: number;

    public constructor(item?: CreateOperatorMarketplaceSettingsVATSettingsApi) {
        this.Mode = (item && item.Mode) ? item.Mode : 'None';
        this.ServiceChargePercentage = (item && item.ServiceChargePercentage) ? item.ServiceChargePercentage : 0;
        this.VatPercentage = (item && item.VatPercentage) ? item.VatPercentage : 0;
    };
};

export class LinkIGoOriginatorPayload {
    public cabExchangeAgentId: string;
    public companyId: string;

    public constructor(item?: iGoCompanyOriginatorView) {
        this.cabExchangeAgentId = (item && item.cabExchangeAgentId) ? item.cabExchangeAgentId : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
    };
};

export class CreateIGoOriginatorPayload {
    public Address: AddressDetailsApi;
    public companyId: string;
    public CustomerId: string;
    public Description: string;
    public EmailAddress: string;
    public Enabled: boolean;
    public EventAuthorizationSettings: CreateOriginatorEventAuthorizationSettingsApi;
    public MarketPlaceSettings: OriginatorMarketplaceSettingsApi;
    public Name: string;
    public TelephoneNumber: string;
    public Url: string;

    public constructor(item?: CreateIGoOriginatorPayload) {
        this.Address = (item && item.Address) ? new AddressDetailsApi(item.Address) : new AddressDetailsApi();
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.CustomerId = (item && item.CustomerId) ? item.CustomerId : null;
        this.Description = (item && item.Description) ? item.Description : '';
        this.EmailAddress = (item && item.EmailAddress) ? item.EmailAddress : '';
        this.Enabled = (item && item.Enabled) ? true : false;
        this.EventAuthorizationSettings = (item && item.EventAuthorizationSettings) ? new CreateOriginatorEventAuthorizationSettingsApi(item.EventAuthorizationSettings) : new CreateOriginatorEventAuthorizationSettingsApi();
        this.MarketPlaceSettings = (item && item.MarketPlaceSettings) ? new OriginatorMarketplaceSettingsApi(item.MarketPlaceSettings) : new OriginatorMarketplaceSettingsApi();
        this.Name = (item && item.Name) ? item.Name : '';
        this.TelephoneNumber = (item && item.TelephoneNumber) ? item.TelephoneNumber : '';
        this.Url = (item && item.Url) ? item.Url : '';
    };
};

export class CreateOriginatorEventAuthorizationSettingsApi {
    public BasicAuth: OriginatorBasicAuthApi;
    public Mode: string;
    public OAuth2: OriginatorOAuth2Api;

    public constructor(item?: CreateOriginatorEventAuthorizationSettingsApi) {
        this.BasicAuth = (item && item.Mode == 'BasicAuth' && item.BasicAuth) ? new OriginatorBasicAuthApi(item.BasicAuth) : new OriginatorBasicAuthApi();
        this.Mode = (item && item.Mode) ? item.Mode : 'None';
        this.OAuth2 = (item && item.Mode == 'OAuth2' && item.OAuth2) ? new OriginatorOAuth2Api(item.OAuth2) : new OriginatorOAuth2Api();
    };
};

export class OriginatorMarketplaceSettingsApi {
    public CommissionPercentage: number;
    public CommissionVATPercentage: number;

    public constructor(item?: OriginatorMarketplaceSettingsApi) {
        this.CommissionPercentage = (item && item.CommissionPercentage) ? item.CommissionPercentage : 0;
        this.CommissionVATPercentage = (item && item.CommissionVATPercentage) ? item.CommissionVATPercentage : 0;
    };
};

export class OriginatorMarketplaceCommissionSettingsApi {
    public commissionPercentage: number;
    public commissionVATPercentage: number;

    public constructor(item?: OriginatorMarketplaceCommissionSettingsApi) {
        this.commissionPercentage = (item && item.commissionPercentage) ? item.commissionPercentage : 0;
        this.commissionVATPercentage = (item && item.commissionVATPercentage) ? item.commissionVATPercentage : 0;
    };
};

export class OriginatorBasicAuthApi {
    public Password: string;
    public Username: string;

    public constructor(item?: OriginatorBasicAuthApi) {
        this.Password = (item && item.Password) ? item.Password : null;
        this.Username = (item && item.Username) ? item.Username : null;
    };
};

export class OriginatorOAuth2Api {
    public AuthUrl: string;
    public ClientId: string;
    public ClientSecret: string;
    public Scope: string;

    public constructor(item?: OriginatorOAuth2Api) {
        this.AuthUrl = (item && item.AuthUrl) ? item.AuthUrl : null;
        this.ClientId = (item && item.ClientId) ? item.ClientId : null;
        this.ClientSecret = (item && item.ClientSecret) ? item.ClientSecret : null;
        this.Scope = (item && item.Scope) ? item.Scope : null;
    };
};

export class OperatorMapAreaApi {
    public coordinates: LatLng[];
    public id: string;

    public constructor(item?: OperatorMapAreaApi) {
        this.coordinates = (item && item.coordinates) ? item.coordinates.map(i => new LatLng(i)) : [];
        this.id = (item && item.id) ? item.id : Math.random().toString();
    };
};

export class OperatorMapAreaView extends OperatorMapAreaApi {
    public selected: boolean;

    public constructor(item?: OperatorMapAreaView) {
        super(item);
        this.selected = (item && item.selected) ? true : false;
    };
};

export class UpdateIGoOperatorPayload {
    public address: AddressDetailsApi;
    public bankDetails: BankAccountDetailsApi;
    public appDownloadLink: string;
    public companyId: string;
    public companyRegNo: string;
    public companyTradingName: string;
    public cornicheEnabled: boolean;
    public facebook: string;
    public kycStatus: boolean;
    public name: string;
    public twitter: string;
    public vatTaxNo: string;
    public website: string;

    public constructor(item?: iGoCompanyOperatorView) {
        this.address = (item && item.details.address) ? new AddressDetailsApi(item.details.address) : new AddressDetailsApi();
        this.bankDetails = (item && item.details.bank) ? new BankAccountDetailsApi(item.details.bank) : item.details.bank == null ? null : new BankAccountDetailsApi();
        this.appDownloadLink = (item && item.appDownloadLink) ? item.appDownloadLink : '';
        this.companyId = (item && item.companyId) ? item.companyId : '';
        this.companyRegNo = (item && item.details.companyRegNo) ? item.details.companyRegNo : '';
        this.companyTradingName = (item && item.details.companyTradingName) ? item.details.companyTradingName : '';
        this.cornicheEnabled = (item && item.cornicheEnabled) ? true : false;
        this.facebook = (item && item.facebook) ? item.facebook : '';
        this.kycStatus = (item && item.kycStatus) ? true : false;
        this.name = (item && item.name) ? item.name : '';
        this.twitter = (item && item.twitter) ? item.twitter : '';
        this.vatTaxNo = (item && item.details.vatTaxNo) ? item.details.vatTaxNo : '';
        this.website = (item && item.website) ? item.website : '';
    };
};

export class OperatorLiveTestResponseApi {
    public mode: string;
    public modeTimeout: string;
    public secondsToTimeout: number;

    public constructor(item?: OperatorLiveTestResponseApi) {
        this.mode = (item && item.mode) ? item.mode : '';
        this.modeTimeout = (item && item.modeTimeout) ? item.modeTimeout : '';
        this.secondsToTimeout = (item && typeof item.secondsToTimeout == 'number') ? item.secondsToTimeout : null;
    };
};