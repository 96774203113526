import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[proceedOnEnter]'
})

export class ProceedOnEnterDirective {
    private _ENTER_KEY_CODE = 13;
    private _ELEMENT_CLASS_NAME = 'proceedOnEnter';
    private _ELEMENT_BUTTON = 'button';
    private _ELEMENT_INPUT = 'input';
    private _ELEMENT_SELECT = 'select';
    public navigationContainerCollection = [];

    public constructor(element: ElementRef) {
        this.navigationContainerCollection[0] = element.nativeElement.getElementsByClassName(this._ELEMENT_CLASS_NAME);
    };

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        if (event.which === this._ENTER_KEY_CODE) {
            this.proceed(event, this.navigationContainerCollection[0]);
            event.preventDefault();
        };
    };

    private proceed(evt, elements): void {
        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            if (element === evt.target) {
                var nextElement = elements[i + 1];
                if (nextElement && !nextElement.disabled) {
                    var tagName = nextElement.tagName.toLowerCase();
                    if (tagName === this._ELEMENT_INPUT) {
                        this.selectNextInput(nextElement);
                    } else if (tagName === this._ELEMENT_BUTTON || tagName === this._ELEMENT_SELECT) {
                        this.focusNextInput(nextElement);
                    };
                } else {
                    this.proceed({
                        target: nextElement
                    }, elements);
                };
            };
        };
    };

    private focusNextInput(element): void {
        if (element) {
            element.focus();
        };
    };

    private selectNextInput(element): void {
        if (element) {
            element.select();
        };
    };
};