import { BeneficiaryAccountsApi, BeneficiaryAccountsView, GetAllCornicheWithdrawHistoryApi, CornicheAccountView, CornicheAccountApi, CornicheAccountTransactionView, GetAccountTransactionPayload, CornicheWithdrawFundsPayload, CornicheWithdrawFundsApi } from "src/models";
import { Endpoints } from 'src/shared/endpoints';
import { GetAllCornicheWithdrawHistoryView, GetAllCornicheWithdrawHistoryPayload, AccountTransactionExportPayload } from './../models/corniche.model';
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { RequestService } from "./request.service";

@Injectable({ providedIn: 'root' })
export class CornicheService {

    public constructor(
        private http: RequestService
    ) { };

    public getAllCornicheWithdrawHistoryObservable(payload: GetAllCornicheWithdrawHistoryPayload, url: string): Observable<GetAllCornicheWithdrawHistoryView> {
        return this.http.authenticatedPost<GetAllCornicheWithdrawHistoryApi>(url, payload, 'global.request.loading_withdraw_history').pipe(
            map(res => new GetAllCornicheWithdrawHistoryView(res as GetAllCornicheWithdrawHistoryView, payload.currencyCode))
        );
    };

    public getBeneficiaryAccountsObservable(): Observable<BeneficiaryAccountsView> {
        return this.http.authenticatedGet<BeneficiaryAccountsApi>(Endpoints.API_URL + Endpoints.OPERATORS._OPERATORS + Endpoints.OPERATORS._BENEFICIARY_INFORMATION, {}, 'global.request.loading_beneficiary_accounts').pipe(
            map(res => new BeneficiaryAccountsView(res as BeneficiaryAccountsView))
        );
    };

    public getCornicheAccountsObservable(url: string): Observable<CornicheAccountView[]> {
        return this.http.authenticatedGet<CornicheAccountApi[]>(url, {}, 'global.request.loading_accounts').pipe(
            map(res => res.map(i => new CornicheAccountView(i as CornicheAccountView)))
        );
    };

    public getCornicheAccountTransactionObservable(payload: GetAccountTransactionPayload, url: string): Observable<CornicheAccountTransactionView[]> {
        return this.http.authenticatedPost<CornicheAccountTransactionView[]>(url, payload, 'global.request.loading_transactions').pipe(
            map(res => res.map((i) => new CornicheAccountTransactionView(i, payload.currencyCode)))
        );
    };

    public withdrawFundsObservable(payload: CornicheWithdrawFundsPayload, url: string): Observable<CornicheWithdrawFundsApi> {
        return this.http.authenticatedPost<CornicheWithdrawFundsApi>(url, payload, 'global.request.processing_withdraw_request');
    };

    public getTransactionsExportObservable(url: string, payload: AccountTransactionExportPayload): Observable<any> {
        return this.http.authenticatedPost<any>(url, payload, { responseType: 'blob' }, 'global.request.exporting_transactions').pipe(catchError(async err => {
            const errors = await err.text();
            const error = JSON.parse(errors)?.errors[""][0];
            throw new Error(error);
        }));
    };
};