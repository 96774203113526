export class CoordinateApi {
    public latitude: number;
    public longitude: number;

    constructor(item?: CoordinateApi) {
        this.latitude = (item && item.latitude) ? item.latitude : 0;
        this.longitude = (item && item.longitude) ? item.longitude : 0;
    };
};

export class LatLng {
    public lat: number;
    public lng: number;

    constructor(item?: LatLng) {
        this.lat = (item && item.lat) ? item.lat : 0;
        this.lng = (item && item.lng) ? item.lng : 0;
    };
};