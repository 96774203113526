import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { HalLinksApi } from "./hal-link.model";

export class CommissionTemplateApi extends HalLinksApi {
    public commissions: CommissionTemplateExceptionApi[];
    public defaultCommission: number;
    public defaultCommissionVat: number;
    public defaultMinimumAmount: number;
    public id: string;
    public name: string;
    public originatorId: string;

    public constructor(item?: CommissionTemplateApi) {
        super(item);
        this.commissions = (item && item.commissions) ? item.commissions.map(i => new CommissionTemplateExceptionApi(i)) : [];
        this.defaultCommission = (item && item.defaultCommission) ? item.defaultCommission : 0;
        this.defaultCommissionVat = (item && item.defaultCommissionVat) ? item.defaultCommissionVat : 0;
        this.defaultMinimumAmount = (item && item.defaultMinimumAmount) ? item.defaultMinimumAmount : 0;
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
        this.originatorId = (item && item.originatorId) ? item.originatorId : '';
    };
};

export class CommissionTemplateView extends CommissionTemplateApi {
    public commissionsView: CommissionTemplateExceptionView[];
    public defaultCommissionView: string;
    public defaultCommissionVatView: string;
    public defaultMinimumAmountView: string;
    public nameAndSource: string;

    public constructor(item?: CommissionTemplateView, isIGo?: boolean) {
        super(item);
        this.commissionsView = (item && item.commissions) ? item.commissions.map((i, index) => new CommissionTemplateExceptionView(i as CommissionTemplateExceptionView, index + 1)) : [];
        this.defaultCommissionView = (item && item.defaultCommission) ? `${item.defaultCommission}%` : '';
        this.defaultCommissionVatView = (item && item.defaultCommissionVat) ? `${item.defaultCommissionVat}%` : '';
        this.defaultMinimumAmountView = (item && item.defaultMinimumAmount) ? new CurrencyGlobalPipe().transform(item.defaultMinimumAmount) : '';
        this.nameAndSource = (item && item.name) ? (isIGo ? `${item.name} (iGo)` : item.name) : '';
    };
};

export class CommissionTemplateExceptionApi {
    public commission: number;
    public minimumAmount: number;
    public dateRangeTemplate: CommissionTemplateExceptionScheduleApi;

    public constructor(item?: CommissionTemplateExceptionApi) {
        this.commission = (item && item.commission) ? item.commission : 0;
        this.minimumAmount = (item && item.minimumAmount) ? item.minimumAmount : 0;
        this.dateRangeTemplate = (item && item.dateRangeTemplate && item.dateRangeTemplate.id) ? new CommissionTemplateExceptionScheduleApi(item.dateRangeTemplate) : null;
    };
};

export class CommissionTemplateExceptionView extends CommissionTemplateExceptionApi {
    public commissionView: string;
    public index: number;
    public minimumAmountView: string;
    declare public dateRangeTemplate: CommissionTemplateExceptionScheduleView;

    public constructor(item?: CommissionTemplateExceptionView, index?: number) {
        super(item);
        this.commissionView = (item && typeof item.commission == 'number') ? `${item.commission}%` : '';
        this.index = index ? index : (item && typeof item.index == 'number') ? item.index : null;
        this.minimumAmountView = (item && typeof item.minimumAmount == 'number') ? new CurrencyGlobalPipe().transform(item.minimumAmount) : '';
        this.dateRangeTemplate = (item && item.dateRangeTemplate) ? new CommissionTemplateExceptionScheduleView(item.dateRangeTemplate) : new CommissionTemplateExceptionScheduleView();
    };
};

export class CommissionTemplateExceptionScheduleApi {
    public id: string;

    public constructor(item?: CommissionTemplateExceptionScheduleApi) {
        this.id = (item && item.id) ? item.id : '';
    };
};

export class CommissionTemplateExceptionScheduleView extends CommissionTemplateExceptionScheduleApi {
    public name: string;

    public constructor(item?: CommissionTemplateExceptionScheduleView) {
        super(item);
        this.name = (item && item.name) ? item.name : '';
    };
};