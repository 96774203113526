import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { LookupType } from 'src/models';
import { MultipleSelectionTableCustomisation } from './multiple-selection-table-customisation.modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RowEvent, TableComponent, TableCustomisation } from '@autocab/ghost-vs-table';

@Component({
    selector: 'multiple-selection-modal',
    styleUrls: ['./multiple-selection.modal.css'],
    templateUrl: './multiple-selection.modal.html'
})

export class MultipleSelectionModalComponent implements AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @Input() public elements: LookupType[] = [];
    @Input() public title: string = '';
    @Input() public selectedItems: LookupType[] = [];
    @Input() public windowClass: string;
    public selected: LookupType[] = [];
    public tableCustomisation: TableCustomisation<LookupType> = MultipleSelectionTableCustomisation;
    public tableFooterItems: Array<ITableFooterItem>;

    public constructor(
        public multipleSelectionModal: NgbActiveModal
    ) { };

    public ngAfterViewInit(): void {
        this.table.selectRows(this.selectedItems);
        this.tableFooterItems = [{ key: 'global.tables.selection.total_options', description: this.elements.length }];
    };

    public onAdd(): void {
        this.multipleSelectionModal.close(this.selected);
    };

    public onRowSelect($event: RowEvent<LookupType>): void {
        this.selected = Object.values($event.selected);
    };
};