import { Component, Input, ViewChild } from '@angular/core';
import { CreateCXMarketplacePayload, CXMarketplaceApi } from 'src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { IHalLink } from 'src/interfaces/hal-link.interface';
import { MarketplacesService, SettingsService, SharedModalsService } from 'src/services';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'create-cx-marketplace-modal',
    templateUrl: './create-cx-marketplace.modal.html'
})

export class CreateCXMarketplaceModalComponent {
    @ViewChild('form') public form: NgForm;
    @Input() public _links: IHalLink = {};
    public creating: boolean = false;
    public currencyCode: string = null;
    public marketplaceNameRegex = '^[A-Za-z0-9_@./#&+-]*$';
    public payload: CreateCXMarketplacePayload = new CreateCXMarketplacePayload();

    private noDataChange = (): boolean => !this.form.dirty;

    public constructor(
        public createCXMarketplaceModal: NgbActiveModal,
        private marketplacesService: MarketplacesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) {
        this.currencyCode = SettingsService.getCurrencyCode();
    };

    public onChangeMarketplaceName(): void {
        this.payload.name = this.payload.name.toUpperCase().replace(' ', '-');
    };

    public onCreate(): void {
        const url = this._links.CreateCabExchangeMarketplace.href;
        if (!this.creating && url) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.creating = true;
                this.marketplacesService.createCXMarketplaceAsync(url, this.payload).subscribe({
                    next: this.onSuccess,
                    error: this.onFailure
                });
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.marketplace.create_cx_marketplace.create_marketplace'), this.form);
            };
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.creating = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.marketplace.create_cx_marketplace.create_marketplace'));
    };

    private onSuccess = (res: CXMarketplaceApi): void => this.createCXMarketplaceModal.close(res);

};