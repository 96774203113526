import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityService, SharedModalsService, UserService } from 'src/services';
import { BookingView, CreateDisputePayload, DisputeView } from 'src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'activity-dispute-reason-modal',
    templateUrl: './activity-dispute-reason.modal.html'
})

export class ActivityDisputeReasonModalComponent implements OnInit, AfterViewInit {
    @ViewChild('form') public form: NgForm;
    @Input() public booking: BookingView;
    @Input() public windowClass: string;
    public confirming: boolean = false;
    public maxReasonLength: number = 100;
    public payload: CreateDisputePayload = new CreateDisputePayload();
    public title: string = '';

    public constructor(
        public activityDisputeReasonModal: NgbActiveModal,
        private activityService: ActivityService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) { };

    public ngOnInit(): void {
        const isIGoUser: boolean = this.userService.getCurrentUser().isIgo;
        this.title = this.translateService.instant(isIGoUser ? 'global.modals.activity_dispute_reason.initiate_dispute' : 'global.modals.activity_dispute_reason.help');
    };

    public ngAfterViewInit(): void {
        this.payload.bookingId = this.booking.id;
    };

    public onCreateDispute(): void {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            this.confirming = true;
            this.activityService.createDisputeAsync(this.booking._links.CreateDispute.href, this.payload).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.title, this.form, { windowClass: this.windowClass });
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.title);
    };

    private onSuccess = (res: DisputeView): void => {
        this.activityDisputeReasonModal.close(res);
    };
};