import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule
    ],
    selector: 'modal-header',
    templateUrl: './modal-header.component.html'
})

export class ModalHeaderComponent {
    @Input() public modalRef: NgbModalRef;
    @Input() public subTitle: string = '';
    @Input() public showCloseButton: boolean = true;
    @Input() public title: string = '';
    @Input() public windowClass: string = '';

    public constructor() { };
};