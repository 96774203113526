import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class EngineerRouteGuardService implements CanActivate {
    constructor() { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return false;
    };
};