import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { LookupType } from 'src/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RowEvent, TableComponent, TableCustomisation } from '@autocab/ghost-vs-table';
import { SingleSelectionTableCustomisation } from './single-selection-table-customisation.modal';

@Component({
    selector: 'single-selection-modal',
    styleUrls: ['./single-selection.modal.css'],
    templateUrl: './single-selection.modal.html'
})

export class SingleSelectionModalComponent implements AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @Input() public elements: LookupType[] = [];
    @Input() public header: string;
    @Input() public selectedItem: LookupType;
    @Input() public title: string = '';
    @Input() public windowClass: string;
    public selected: LookupType;
    public tableCustomisation: TableCustomisation<LookupType> = SingleSelectionTableCustomisation;
    public tableFooterItems: Array<ITableFooterItem>;

    public constructor(
        public singleSelectionModal: NgbActiveModal
    ) { };

    public ngAfterViewInit(): void {
        this.table.selectRow(this.selectedItem);
        this.tableFooterItems = [{ key: 'global.tables.selection.total_options', description: this.elements.length }];
    };

    public onAdd(): void {
        this.singleSelectionModal.close(this.selected);
    };

    public onDoubleClick($event: RowEvent<LookupType>): void {
        this.selected = $event.selected as LookupType;
        this.singleSelectionModal.close(this.selected);
    };

    public onRowSelect($event: RowEvent<LookupType>): void {
        this.selected = $event.selected as LookupType;
    };
};