import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BookingView, CreateFundsRequestPayload, DisputeFundsRequestView, DisputeView } from 'src/models';
import { CurrencyGlobalPipe } from 'src/shared/pipes/currency.pipe';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { SettingsService, SharedModalsService, UserService } from 'src/services';

export interface IDisputeReasonModalConfig {
    booking?: BookingView;
    buttonText: string;
    callback: Function;
    confirmationMessage: string;
    confirmationTitle: string;
    currencyCode?: string;
    dispute?: DisputeView;
    fundRequest?: DisputeFundsRequestView;
    requestingFundsFromName?: string;
    showAmountField?: boolean;
    showiGoRefundAmount?: boolean;
    successMessage: string;
    successTitle: string;
    title: string;
};

@Component({
    selector: 'dispute-reason-modal',
    templateUrl: './dispute-reason.modal.html'
})

export class DisputeReasonModalComponent implements OnInit, AfterViewInit {
    @ViewChild('amountInput') public amountInput: ElementRef;
    @ViewChild('reasonInput') public reasonInput: ElementRef;
    @ViewChild('form') public form: NgForm;
    @Input() public config: IDisputeReasonModalConfig;
    @Input() public windowClass: string;
    public confirming: boolean = false;
    public currencyCode: string = null;
    public editMode: boolean = false;
    public maxReasonLength: number = 100;
    public maximumFundRequestAmount: number = null;
    public maximumFundRequestAmountCurrency: string = '';
    public payload: CreateFundsRequestPayload = new CreateFundsRequestPayload();

    private noDataChange = (): boolean => ObjectUtilities.equal(new CreateFundsRequestPayload(), this.payload);

    public constructor(
        public disputeReasonModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService
    ) { }

    public ngOnInit(): void {
        if (this.config.showAmountField || this.config.showiGoRefundAmount) {
            const currentPartnerType: string = UserService.getPartnerTypeDetails().type;
            if (currentPartnerType == 'Operator') {
                this.maximumFundRequestAmount = this.config.booking.availableFundRequest.forOperator;
                this.maximumFundRequestAmountCurrency = this.config.booking.availableFundRequest.forOperatorCurrency;
            } else {
                this.maximumFundRequestAmount = this.config.booking.availableFundRequest.forOriginator;
                this.maximumFundRequestAmountCurrency = this.config.booking.availableFundRequest.forOriginatorCurrency;
            };
        };
        this.currencyCode = this.config.currencyCode ? this.config.currencyCode : SettingsService.getCurrencyCode();
    };

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.config.showAmountField ? this.amountInput.nativeElement.focus() : this.reasonInput.nativeElement.focus();
        });
    };

    public onConfirm(): void {
        if (!this.confirming) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.showConfirmation();
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.config.title, this.form, { windowClass: this.windowClass });
            };
        };
    };

    private confirm(): void {
        this.confirming = true;
        this.config.callback(this.disputeReasonModal, this.payload, this.config.fundRequest);
    };

    private showConfirmation(): void {
        let message: string = this.config.confirmationMessage;
        const currencyPipe = new CurrencyGlobalPipe();
        currencyPipe.currency = this.currencyCode;
        if (this.config.requestingFundsFromName) {
            message = this.config.confirmationMessage.replace('{0}', currencyPipe.transform(this.payload.amount)).replace('{1}', this.config.requestingFundsFromName);
        };
        this.sharedModalsService.showConfirmationModal(this.config.confirmationTitle, message, null, null, { windowClass: this.windowClass }).result.then(() => {
            this.confirm();
        }).catch(() => { });
    };
};