
import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[max][formControlName],[max][formControl],[max][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxNumberValidatorDirective), multi: true }
    ]
})

export class MaxNumberValidatorDirective implements Validator {
    @Input('max') set max(value: string | number) {
        if (value) {
            this.maxNumber = value;
        };
    };
    private maxNumber = null;

    public constructor(@Attribute('max') private _max) {
        this.maxNumber = this._max;
    };

    public validate(control: AbstractControl): { [key: string]: any } {
        let maxNumber = typeof this.maxNumber === 'number' ? this.maxNumber : parseInt(this.maxNumber);
        if (typeof maxNumber == 'number' && control.value > maxNumber) {
            return {
                max: {
                    max: typeof maxNumber == 'number' ? maxNumber : 'empty',
                    value: control.value
                }
            };
        };
        return null;
    };
};