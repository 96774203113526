export class ColourApi {
    public r: number;
    public g: number;
    public b: number;

    public constructor(colour: any) {
        this.r = colour.r;
        this.g = colour.g;
        this.b = colour.b;
    };
};

export class ColourView {
    public r: number;
    public g: number;
    public b: number;

    public constructor(colour?: ColourApi) {
        this.r = (colour && colour.r ) ? colour.r : 0;
        this.g = (colour && colour.g ) ? colour.g : 0;
        this.b = (colour && colour.b ) ? colour.b : 0;
    };

    get pickerValue(): string {
        return 'rgb(' + this.r + ',' + this.g + ',' + this.b + ')';
    };

    set pickerValue(value: string) {
        var values: string[] = value.split('rgb(')[1].split(')')[0].split(',');
        this.r = Number(values[0]);
        this.g = Number(values[1]);
        this.b = Number(values[2]);
    };
};