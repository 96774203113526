import { HalLinksApi } from "./hal-link.model";
import { Month, Weekday } from "src/enums/date-range.enum";
import { DateUtilities } from "src/shared/date.utilities";

export class DateRangeTemplateApi extends HalLinksApi {
    public description: string;
    public id: string;
    public name: string;
    public originatorId: string;
    public schedules: ScheduleApi[];

    public constructor(item?: DateRangeTemplateApi) {
        super(item);
        this.description = (item && item.description) ? item.description : '';
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
        this.originatorId = (item && item.originatorId) ? item.originatorId : '';
        this.schedules = (item && item.schedules) ? item.schedules : [];
    };
};

export class ScheduleApi {
    public dateRange: DateRangeApi;
    public dayOfMonth: number;
    public daysOfWeek: Weekday[];
    public frequency: string;
    public inclusion: string;
    public months: Month[];
    public name: string;
    public occurrence: string;
    public oneOffDate: string;
    public startEndDateTimeRange: DateTimeRangeApi;
    public timeRange: TimeRangeApi;
    public weekRange: WeekRange;

    public constructor(item?: ScheduleApi) {
        this.dateRange = (item && item.dateRange) ? item.dateRange : null;
        this.dayOfMonth = (item && item.dayOfMonth) ? item.dayOfMonth : null;
        this.daysOfWeek = (item && item.daysOfWeek) ? item.daysOfWeek : [];
        this.frequency = (item && item.frequency) ? item.frequency : null;
        this.inclusion = (item && item.inclusion) ? item.inclusion : null;
        this.months = (item && item.months) ? item.months : [];
        this.name = (item && item.name) ? item.name : null;
        this.occurrence = (item && item.occurrence) ? item.occurrence : null;
        this.oneOffDate = (item && item.oneOffDate) ? item.oneOffDate : null;
        this.startEndDateTimeRange = (item && item.startEndDateTimeRange) ? item.startEndDateTimeRange : null;
        this.timeRange = (item && item.timeRange) ? item.timeRange : null;
        this.weekRange = (item && item.weekRange) ? item.weekRange : null;
    };
};

export class WeekRange {
    public endDay?: string;
    public endTime?: string;
    public startDay?: string;
    public startTime?: string;

    public constructor(startDay?: string, endDay?: string, startTime?: string, endTime?: string) {
        this.endDay = endDay;
        this.endTime = endTime;
        this.startDay = startDay;
        this.startTime = startTime;
    };
};

export class TimeRangeApi {
    public from?: string;
    public fullDay?: boolean;
    public to?: string;

    public constructor(from?: string, to?: string, fullday?: boolean) {
        this.from = from;
        this.fullDay = fullday ? fullday : false;
        this.to = to;
    };
};

export class DateRangeApi {
    public from?: string;
    public to?: string;

    public constructor(from?: string, to?: string) {
        this.from = from;
        this.to = to;
    };
};

export class DateTimeRangeApi {
    public from?: string;
    public to?: string;

    public constructor(from?: string, to?: string) {
        this.from = from;
        this.to = to;
    };
};

export class ScheduleView extends ScheduleApi {
    declare public name: string;
    public index: number;

    public constructor(item?: ScheduleView, index?: number) {
        super(item);
        this.name = DateRangeHelper.generateName(item);
        this.index = index;
    };
};

export class DateRangeHelper {

    public static generateName(item: ScheduleView): string {
        if (!item) return '';

        let yearly = item.frequency && item.frequency != 'Yearly';

        return `
            ${item.inclusion}:   
            ${this.getFrequency(item.frequency)} 
            ${item.timeRange ? this.getTimeRange(item.timeRange) : ''}  
            ${item.dateRange ? this.getDayRange(item.dateRange, yearly) : ''}  
            ${item.startEndDateTimeRange ? this.getDateTimeRange(item.startEndDateTimeRange, yearly) : ''}  
            ${item.frequency == 'Weekly' ? this.getWeekdays(item.daysOfWeek) : ''} 
            ${item.frequency == 'Monthly' && item.dayOfMonth ? this.onDayOfMonth(item.dayOfMonth) : ''} 
            ${item.occurrence && item.occurrence ? this.getOccurrence(item.occurrence, item.daysOfWeek) : ''} 
            ${item.frequency == 'Yearly' && item.months && item.months.length > 0 ? this.getMonths(item.months) : ''} 
            `;
    };

    private static getMonths(months: Month[]) {
        return `in ${months.join(', ')}`;
    };

    private static getDateTimeRange(range: DateTimeRangeApi, displayYear: boolean) {
        return `from ${DateUtilities.getReadableDateTimeFromISOString(range.from, displayYear)} to ${DateUtilities.getReadableDateTimeFromISOString(range.to, displayYear)}`;
    }

    private static getDayRange(dateRange: DateRangeApi, displayYear: boolean) {
        return `from ${DateUtilities.getReadableDateTimeFromISOString(dateRange.from.substring(0, 11), displayYear)} to ${DateUtilities.getReadableDateTimeFromISOString(dateRange.to.substring(0, 11), displayYear)}`;
    }

    private static getOccurrence(occurrence: string, daysOfWeek: Weekday[]) {
        return `on ${occurrence.toLowerCase()} ${daysOfWeek.join(', ')}`;
    }

    private static getWeekdays(weekdays: Weekday[]): string {
        if (weekdays && weekdays.length > 0) return ` on ${weekdays.join(', ')}`;
        return '';
    }

    private static onDayOfMonth(day: number): string {
        return `on day number ${day}`;

    }

    private static getTimeRange(timeRange: TimeRangeApi): string {
        if (timeRange.fullDay) {
            return "Midnight to midnight";
        } else {
            return `between ${timeRange.from.slice(0, 8)} and ${timeRange.to.slice(0, 8)}`;
        }
    }

    private static getFrequency(frequency: string): string {

        switch (frequency) {
            case 'Daily': {
                return 'Every Day';
            };
            case 'Weekly': {
                return 'Weekly';
            };
            case 'Monthly': {
                return 'Every month';
            };
            case 'Yearly': {
                return 'Every year';
            };
            case 'Once': {
                return 'Once';
            };
            default: {
                return;
            };
        };
    }
};

export class DateRangeTemplateView extends DateRangeTemplateApi {
    public schedulesView: ScheduleView[];

    public constructor(item?: DateRangeTemplateView) {
        super(item);
        this.schedulesView = (item && item.schedules) ? item.schedules.map((i, index) => new ScheduleView(i as ScheduleView, index + 1)) : [];
    };
};