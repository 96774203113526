import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { SettingsService } from "./settings.service";

function padNumber(value: number): string {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    };
};

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
};

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
};

@Injectable({ providedIn: 'root' })
export class DateFormatterService extends NgbDateParserFormatter {
    public parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            };
        };
        return null;
    };

    public format(date: NgbDateStruct): string {
        if (date) {
            let _date = new Date(date.year, date.month ? date.month - 1 : 0, date.day);

            return new DatePipe(SettingsService.LanguageCode).transform(_date, 'shortDate');
        };

        return null;
    };
};