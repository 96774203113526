import { Component, ViewChild, Input, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { RegionView } from 'src/models';
import { SharedModalsService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'test-region-name-modal',
    templateUrl: 'test-region-name.modal.html'
})

export class TestRegionNameModalComponent {
    @ViewChild('form') public form: NgForm;
    @ViewChild('input') public input: ElementRef;
    @Input() public region: RegionView = null;
    @Input() public testRegions: RegionView[] = [];
    public name: string = '';

    public constructor(
        public newTestRegionNameModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) { };

    public onConfirm(): void {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            if (this.testRegions.find(area => area.name == this.name)) {
                // If the name has been changed to the same name as an existing area
                const modalTitle: string = this.translateService.instant('global.modals.test_regions.new_test_region_name.test_region_name');
                const modalMessage: string = this.translateService.instant('global.modals.test_regions.new_test_region_name.test_region_name_message').replace('{0}', this.name);
                this.sharedModalsService.showAlertModal(modalTitle, modalMessage, { windowClass: 'originator' }).result.then(() => {
                    this.input.nativeElement.focus();
                }).catch(() => {
                    this.input.nativeElement.focus();
                });
            } else {
                this.newTestRegionNameModal.close(this.name);
            };
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.test_regions.new_test_region_name.test_region_name'), this.form, { windowClass: 'originator' });
        };
    };
};