import { Injectable } from "@angular/core";
import { Endpoints } from "../shared/endpoints";
import { GoogleAddressApi, GoogleAddressView } from "../models/address.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { RequestService } from "./request.service";
import { UserService } from "./user.service";

declare var google;

@Injectable({ providedIn: 'root' })
export class AddressService {
    public constructor(private requestService: RequestService, private userService: UserService) { };

    public getAddressesAsync(params): Observable<any> {
        return this.requestService.authenticatedGet<any>(Endpoints.API_URL + Endpoints.ADDRESSES + params);
    };

    public getGoogleAddressesObservable(query): Observable<GoogleAddressApi> {
        return this.requestService.authenticatedGet<any>(Endpoints.API_URL + Endpoints.GOOGLE_ADDRESSES + '?text=' + query).pipe(
            map(response => {
                return response && response.predictions.length ? response.predictions.map(prediction => new GoogleAddressApi(prediction)) : [];
            })
        );
    };

    public getGooglePlaceByIdObservable(placeId, googlePlaceAddressText, onComplete, onFail?): void {
        this.requestService.authenticatedGet<any>(Endpoints.API_URL + Endpoints.GOOGLE_ADDRESSES_DETAILS + '?placeid=' + placeId).subscribe({
            next: (res: any) => {
                if (res && res.results && res.results.length) {
                    onComplete(this.createGooglePlaceAddress(res.results[0], googlePlaceAddressText));
                } else {
                    if (onFail) {
                        onFail(status);
                    };
                };
            }
        });
    };

    private createGooglePlaceAddress(googlePlace, googlePlaceAddressText): GoogleAddressView {
        var googlePlaceAddressComponents = this.getGooglePlaceAddressComponents(googlePlace);
        var googlePlaceAddress: GoogleAddressView = {
            coordinate: {
                latitude: googlePlace.geometry.location.lat,
                longitude: googlePlace.geometry.location.lng
            },
            house: googlePlaceAddressComponents.streetNumber,
            source: 'TeleAtlas',
            street: googlePlaceAddressComponents.route,
            text: googlePlace.formatted_address,
            town: googlePlaceAddressComponents.postalTown || googlePlaceAddressComponents.administrativeAreaLevel3 || googlePlaceAddressComponents.administrativeAreaLevel2,
            zoneId: null
        };
        if (googlePlace.types && googlePlace.types.length) {
            if (googlePlace.types.indexOf('point_of_interest') !== -1) {
                if (googlePlaceAddress.street) {
                    googlePlaceAddress.street = googlePlace.name + ', ' + googlePlaceAddress.street;
                } else {
                    googlePlaceAddress.street = googlePlace.name;
                };
            };
        };
        if (!googlePlaceAddress.house) {
            var googlePlaceAddressTextComponentsSplitBySpace = googlePlaceAddressText.split(' ');
            if (googlePlaceAddressTextComponentsSplitBySpace.length) {
                var houseNumber = Number(googlePlaceAddressTextComponentsSplitBySpace[0]);
                if (!isNaN(houseNumber)) {
                    googlePlaceAddress.house = houseNumber;
                };
            };
        };
        return googlePlaceAddress;
    };

    private getGooglePlaceAddressComponents(googlePlace: any): any {
        var formattedAddress: any = {};
        if (googlePlace) {
            if (googlePlace.address_components && googlePlace.address_components.length) {
                for (var i = 0; i < googlePlace.address_components.length; i++) {
                    var component = googlePlace.address_components[i];
                    if (component) {
                        for (var j = 0; j < component.types.length; j++) {
                            var type = component.types[j];
                            switch (type) {
                                case 'street_number':
                                    formattedAddress.streetNumber = component.long_name;
                                    break;
                                case 'route':
                                    formattedAddress.route = component.long_name;
                                    break;
                                case 'postal_town':
                                    formattedAddress.postalTown = component.long_name;
                                    break;
                                case 'administrative_area_level_1':
                                    formattedAddress.administrativeAreaLevel1 = component.long_name;
                                    break;
                                case 'administrative_area_level_2':
                                    formattedAddress.administrativeAreaLevel2 = component.long_name;
                                    break;
                                case 'administrative_area_level_3':
                                    formattedAddress.administrativeAreaLevel3 = component.long_name;
                                    break;
                                case 'country':
                                    formattedAddress.country = component.long_name;
                                    break;
                                case 'postal_code':
                                    formattedAddress.postalCode = component.long_name;
                                    break;
                                default:
                                    break;
                            };
                        };
                    };
                };
            };
        };
        return formattedAddress;
    };
};