import { CoordinateApi, LatLng } from "./coordinate.model";
import { HalLinksApi } from "./hal-link.model";

export class GetAllRegionsResponseApi extends HalLinksApi {
    public regions: RegionApi[];

    public constructor(item?: GetAllRegionsResponseApi) {
        super(item);
        this.regions = (item && item.regions) ? item.regions.map(i => new RegionApi(i)) : [];
    };
};

export class GetAllRegionsResponseView extends GetAllRegionsResponseApi {
    declare public regions: RegionView[];

    public constructor(item?: GetAllRegionsResponseView) {
        super(item);
        this.regions = (item && item.regions) ? item.regions.map(i => new RegionView(i)) : [];
    };
};

export class RegionApi extends HalLinksApi {
    public id: string;
    public name: string;
    public points: CoordinateApi[];
    public discoverPoints: LatLng[];

    public constructor(item?: RegionApi) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
        this.points = (item && item.points) ? item.points.map(i => new CoordinateApi(i)) : [];
        this.discoverPoints = (item && item.points) ? item.points.map(coords => {
            return new LatLng({ lat: coords.latitude, lng: coords.longitude });
        }) : [];
    };
};
export class RegionView extends RegionApi {
    public regionPolygonId?: string;
    public selected: boolean;

    public constructor(item?: RegionView) {
        super(item);
        this.regionPolygonId = (item && item.regionPolygonId) ? item.regionPolygonId : '';
        this.selected = (item && item.selected) ? true : false;
    };
};
export class MapAreaApi {
    public area: { geoPoints: CoordinateApi[]; };
    public id: string;

    public constructor(item?: MapAreaApi) {
        this.area = (item && item.area) ? item.area : { geoPoints: [] };
        this.id = (item && item.id) ? item.id : '';
    };
};

export class MapAreaView extends MapAreaApi {
    public mapAreaPolygonId?: string;

    public constructor(item?: MapAreaView) {
        super(item);
        this.mapAreaPolygonId = (item && item.mapAreaPolygonId) ? item.mapAreaPolygonId : '';
    };
};