import { Injectable } from "@angular/core";
import { CompanySettingsApi, OperatorSettingsApi, OriginatorSettingsApi } from "src/models/admin-settings.model";
import { Endpoints } from "src/shared/endpoints";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { RequestService } from "./request.service";
import { UserService } from "./user.service";

export interface ITimezone {
    name: string;
    offset: string;
};

@Injectable({ providedIn: 'root' })
export class AdminSettingsService {
    public static timezonesTypes: Array<ITimezone> = [
        { name: "(UTC-12:00) International Date Line West", offset: '-12:00' },
        { name: "(UTC-11:00) Coordinated Universal Time-11", offset: '-11:00' },
        { name: "(UTC-10:00) Aleutian Islands", offset: '-10:00' },
        { name: "(UTC-10:00) Hawaii", offset: '-10:00' },
        { name: "(UTC-09:30) Marquesas Islands", offset: '-09:30' },
        { name: "(UTC-09:00) Alaska", offset: '-09:00' },
        { name: "(UTC-09:00) Coordinated Universal Time-09", offset: '-09:00' },
        { name: "(UTC-08:00) Baja California", offset: '-08:00' },
        { name: "(UTC-08:00) Coordinated Universal Time-08", offset: '-08:00' },
        { name: "(UTC-08:00) Pacific Time (US &amp; Canada)", offset: '-08:00' },
        { name: "(UTC-07:00) Arizona", offset: '-07:00' },
        { name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan", offset: '-07:00' },
        { name: "(UTC-07:00) Mountain Time (US &amp; Canada)", offset: '-07:00' },
        { name: "(UTC-07:00) Yukon", offset: '-07:00' },
        { name: "(UTC-06:00) Central America", offset: '-06:00' },
        { name: "(UTC-06:00) Central Time (US &amp; Canada)", offset: '-06:00' },
        { name: "(UTC-06:00) Easter Island", offset: '-06:00' },
        { name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", offset: '-06:00' },
        { name: "(UTC-06:00) Saskatchewan", offset: '-06:00' },
        { name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco", offset: '-05:00' },
        { name: "(UTC-05:00) Chetumal", offset: '-05:00' },
        { name: "(UTC-05:00) Eastern Time (US &amp; Canada)", offset: '-05:00' },
        { name: "(UTC-05:00) Haiti", offset: '-05:00' },
        { name: "(UTC-05:00) Havana", offset: '-05:00' },
        { name: "(UTC-05:00) Indiana (East)", offset: '-05:00' },
        { name: "(UTC-05:00) Turks and Caicos", offset: '-05:00' },
        { name: "(UTC-04:00) Asuncion", offset: '-04:00' },
        { name: "(UTC-04:00) Atlantic Time (Canada)", offset: '-04:00' },
        { name: "(UTC-04:00) Caracas", offset: '-04:00' },
        { name: "(UTC-04:00) Cuiaba", offset: '-04:00' },
        { name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan", offset: '-04:00' },
        { name: "(UTC-04:00) Santiago", offset: '-04:00' },
        { name: "(UTC-03:30) Newfoundland", offset: '-03:30' },
        { name: "(UTC-03:00) Araguaina", offset: '-03:00' },
        { name: "(UTC-03:00) Brasilia", offset: '-03:00' },
        { name: "(UTC-03:00) Cayenne, Fortaleza", offset: '-03:00' },
        { name: "(UTC-03:00) City of Buenos Aires", offset: '-03:00' },
        { name: "(UTC-03:00) Greenland", offset: '-03:00' },
        { name: "(UTC-03:00) Montevideo", offset: '-03:00' },
        { name: "(UTC-03:00) Punta Arenas", offset: '-03:00' },
        { name: "(UTC-03:00) Saint Pierre and Miquelon", offset: '-03:00' },
        { name: "(UTC-03:00) Salvador", offset: '-03:00' },
        { name: "(UTC-02:00) Coordinated Universal Time-02", offset: '-02:00' },
        { name: "(UTC-02:00) Mid-Atlantic - Old", offset: '-02:00' },
        { name: "(UTC-01:00) Azores", offset: '-01:00' },
        { name: "(UTC-01:00) Cabo Verde Is.", offset: '-01:00' },
        { name: "(UTC) Coordinated Universal Time", offset: ') Coor' },
        { name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", offset: '+00:00' },
        { name: "(UTC+00:00) Monrovia, Reykjavik", offset: '+00:00' },
        { name: "(UTC+00:00) Sao Tome", offset: '+00:00' },
        { name: "(UTC+01:00) Casablanca", offset: '+01:00' },
        { name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", offset: '+01:00' },
        { name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", offset: '+01:00' },
        { name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", offset: '+01:00' },
        { name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", offset: '+01:00' },
        { name: "(UTC+01:00) West Central Africa", offset: '+01:00' },
        { name: "(UTC+02:00) Amman", offset: '+02:00' },
        { name: "(UTC+02:00) Athens, Bucharest", offset: '+02:00' },
        { name: "(UTC+02:00) Beirut", offset: '+02:00' },
        { name: "(UTC+02:00) Cairo", offset: '+02:00' },
        { name: "(UTC+02:00) Chisinau", offset: '+02:00' },
        { name: "(UTC+02:00) Damascus", offset: '+02:00' },
        { name: "(UTC+02:00) Gaza, Hebron", offset: '+02:00' },
        { name: "(UTC+02:00) Harare, Pretoria", offset: '+02:00' },
        { name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", offset: '+02:00' },
        { name: "(UTC+02:00) Jerusalem", offset: '+02:00' },
        { name: "(UTC+02:00) Juba", offset: '+02:00' },
        { name: "(UTC+02:00) Kaliningrad", offset: '+02:00' },
        { name: "(UTC+02:00) Khartoum", offset: '+02:00' },
        { name: "(UTC+02:00) Tripoli", offset: '+02:00' },
        { name: "(UTC+02:00) Windhoek", offset: '+02:00' },
        { name: "(UTC+03:00) Baghdad", offset: '+03:00' },
        { name: "(UTC+03:00) Istanbul", offset: '+03:00' },
        { name: "(UTC+03:00) Kuwait, Riyadh", offset: '+03:00' },
        { name: "(UTC+03:00) Minsk", offset: '+03:00' },
        { name: "(UTC+03:00) Moscow, St. Petersburg", offset: '+03:00' },
        { name: "(UTC+03:00) Nairobi", offset: '+03:00' },
        { name: "(UTC+03:00) Volgograd", offset: '+03:00' },
        { name: "(UTC+03:30) Tehran", offset: '+03:30' },
        { name: "(UTC+04:00) Abu Dhabi, Muscat", offset: '+04:00' },
        { name: "(UTC+04:00) Astrakhan, Ulyanovsk", offset: '+04:00' },
        { name: "(UTC+04:00) Baku", offset: '+04:00' },
        { name: "(UTC+04:00) Izhevsk, Samara", offset: '+04:00' },
        { name: "(UTC+04:00) Port Louis", offset: '+04:00' },
        { name: "(UTC+04:00) Saratov", offset: '+04:00' },
        { name: "(UTC+04:00) Tbilisi", offset: '+04:00' },
        { name: "(UTC+04:00) Yerevan", offset: '+04:00' },
        { name: "(UTC+04:30) Kabul", offset: '+04:30' },
        { name: "(UTC+05:00) Ashgabat, Tashkent", offset: '+05:00' },
        { name: "(UTC+05:00) Ekaterinburg", offset: '+05:00' },
        { name: "(UTC+05:00) Islamabad, Karachi", offset: '+05:00' },
        { name: "(UTC+05:00) Qyzylorda", offset: '+05:00' },
        { name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", offset: '+05:30' },
        { name: "(UTC+05:30) Sri Jayawardenepura", offset: '+05:30' },
        { name: "(UTC+05:45) Kathmandu", offset: '+05:45' },
        { name: "(UTC+06:00) Astana", offset: '+06:00' },
        { name: "(UTC+06:00) Dhaka", offset: '+06:00' },
        { name: "(UTC+06:00) Omsk", offset: '+06:00' },
        { name: "(UTC+06:30) Yangon (Rangoon)", offset: '+06:30' },
        { name: "(UTC+07:00) Bangkok, Hanoi, Jakarta", offset: '+07:00' },
        { name: "(UTC+07:00) Barnaul, Gorno-Altaysk", offset: '+07:00' },
        { name: "(UTC+07:00) Hovd", offset: '+07:00' },
        { name: "(UTC+07:00) Krasnoyarsk", offset: '+07:00' },
        { name: "(UTC+07:00) Novosibirsk", offset: '+07:00' },
        { name: "(UTC+07:00) Tomsk", offset: '+07:00' },
        { name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", offset: '+08:00' },
        { name: "(UTC+08:00) Irkutsk", offset: '+08:00' },
        { name: "(UTC+08:00) Kuala Lumpur, Singapore", offset: '+08:00' },
        { name: "(UTC+08:00) Perth", offset: '+08:00' },
        { name: "(UTC+08:00) Taipei", offset: '+08:00' },
        { name: "(UTC+08:00) Ulaanbaatar", offset: '+08:00' },
        { name: "(UTC+08:45) Eucla", offset: '+08:45' },
        { name: "(UTC+09:00) Chita", offset: '+09:00' },
        { name: "(UTC+09:00) Osaka, Sapporo, Tokyo", offset: '+09:00' },
        { name: "(UTC+09:00) Pyongyang", offset: '+09:00' },
        { name: "(UTC+09:00) Seoul", offset: '+09:00' },
        { name: "(UTC+09:00) Yakutsk", offset: '+09:00' },
        { name: "(UTC+09:30) Adelaide", offset: '+09:30' },
        { name: "(UTC+09:30) Darwin", offset: '+09:30' },
        { name: "(UTC+10:00) Brisbane", offset: '+10:00' },
        { name: "(UTC+10:00) Canberra, Melbourne, Sydney", offset: '+10:00' },
        { name: "(UTC+10:00) Guam, Port Moresby", offset: '+10:00' },
        { name: "(UTC+10:00) Hobart", offset: '+10:00' },
        { name: "(UTC+10:00) Vladivostok", offset: '+10:00' },
        { name: "(UTC+10:30) Lord Howe Island", offset: '+10:30' },
        { name: "(UTC+11:00) Bougainville Island", offset: '+11:00' },
        { name: "(UTC+11:00) Chokurdakh", offset: '+11:00' },
        { name: "(UTC+11:00) Magadan", offset: '+11:00' },
        { name: "(UTC+11:00) Norfolk Island", offset: '+11:00' },
        { name: "(UTC+11:00) Sakhalin", offset: '+11:00' },
        { name: "(UTC+11:00) Solomon Is., New Caledonia", offset: '+11:00' },
        { name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky", offset: '+12:00' },
        { name: "(UTC+12:00) Auckland, Wellington", offset: '+12:00' },
        { name: "(UTC+12:00) Coordinated Universal Time+12", offset: '+12:00' },
        { name: "(UTC+12:00) Fiji", offset: '+12:00' },
        { name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old", offset: '+12:00' },
        { name: "(UTC+12:45) Chatham Islands", offset: '+12:45' },
        { name: "(UTC+13:00) Coordinated Universal Time+13", offset: '+13:00' },
        { name: "(UTC+13:00) Nuku'alofa", offset: '+13:00' },
        { name: "(UTC+13:00) Samoa", offset: '+13:00' },
        { name: "(UTC+14:00) Kiritimati Island", offset: '+14:00' }
    ];

    public constructor(
        private http: RequestService
    ) { };

    public getOperatorSettingsObservable(): Observable<OperatorSettingsApi> {
        return this.http.authenticatedGet<OperatorSettingsApi>(Endpoints.API_URL + Endpoints.OPERATOR_SETTINGS, {}, 'global.request.loading_settings').pipe(
            map(res => new OperatorSettingsApi(res)),
        );
    };

    public getOriginatorSettingsObservable(url: string): Observable<OriginatorSettingsApi> {
        return this.http.authenticatedGet<OriginatorSettingsApi>(url, {}, 'global.request.loading_settings').pipe(
            map(res => new OriginatorSettingsApi(res)),
        );
    };

    public getCompanySettingsObservable(): Observable<CompanySettingsApi> {
        const companyId = UserService.getPartnerTypeDetails().companyId;
        return this.http.authenticatedGet<CompanySettingsApi>(Endpoints.API_URL + Endpoints.COMPANY_SETTINGS + '/' + companyId, {}, 'global.request.loading_settings').pipe(
            map(res => new CompanySettingsApi(res)),
        );
    };

    public toDefaultTimezone(time: string): string {
        return `${time}+00`;
    };

    public updateOperatorSettingsAsync(settings: OperatorSettingsApi): Observable<OperatorSettingsApi> {
        return this.http.authenticatedPut<OperatorSettingsApi>(Endpoints.API_URL + Endpoints.OPERATOR_SETTINGS, new OperatorSettingsApi(settings), {}, 'global.request.updating_settings').pipe(
            map(res => new OperatorSettingsApi(res)),
        );
    };

    public updateOriginatorSettingsAsync(settings: OriginatorSettingsApi): Observable<OriginatorSettingsApi> {
        return this.http.authenticatedPut<OriginatorSettingsApi>(Endpoints.API_URL + Endpoints.ORIGINATOR_SETTINGS, new OriginatorSettingsApi(settings), {}, 'global.request.updating_settings').pipe(
            map(res => new OriginatorSettingsApi(res)),
        );
    };

    public updateCompanySettingsAsync(settings: CompanySettingsApi): Observable<CompanySettingsApi> {
        const payload = new CompanySettingsApi(settings);
        payload.companyId = UserService.getPartnerTypeDetails().companyId;
        return this.http.authenticatedPut<CompanySettingsApi>(Endpoints.API_URL + Endpoints.COMPANY_SETTINGS, payload, {}, 'global.request.updating_settings').pipe(
            map(res => new CompanySettingsApi(res)),
        );
    };
};