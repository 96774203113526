export class PermissionCategoryApi {
    public category: string;
    public groups: PermissionGroupApi[];
    public groupsEmpty?: boolean;

    public constructor(item?: PermissionCategoryApi) {
        this.groups = (item && item.groups) ? item.groups.map(i => new PermissionGroupApi(i)) : [];
        this.category = (item && item.category) ? item.category : '';
        this.groupsEmpty = (item && item.groupsEmpty) ? item.groupsEmpty : false;
    };
};

export class PermissionCategoryView extends PermissionCategoryApi {
    declare public groups: PermissionGroupView[];

    public constructor(item?: PermissionCategoryView) {
        super(item);
        this.groups = (item && item.groups) ? item.groups.map(i => new PermissionGroupView(i)) : [];
    };
};

export class PermissionGroupApi {
    public permissions: PermissionItemApi[];
    public title: string;
    public groupEmpty?: boolean;
    public constructor(item?: PermissionGroupApi) {
        this.permissions = (item && item.permissions) ? item.permissions.map(i => new PermissionItemApi(i)) : [];
        this.title = (item && item.title) ? item.title : '';
        this.groupEmpty = (item && item.groupEmpty) ? item.groupEmpty : false;
    };
};

export class PermissionGroupView extends PermissionGroupApi {
    declare public permissions: PermissionItemView[];

    public constructor(item?: PermissionGroupView) {
        super(item);
        this.permissions = (item && item.permissions) ? item.permissions.map(i => new PermissionItemView(i)) : [];
    };
};

export class PermissionItemApi {
    public displayName: string;
    public key: string;

    public constructor(item?: PermissionItemApi) {
        this.displayName = (item && item.displayName) ? item.displayName : '';
        this.key = (item && item.key) ? item.key : '';
    };
};

export class PermissionItemView extends PermissionItemApi {
    public selected?: boolean;

    public constructor(item?: PermissionItemView) {
        super(item);
        this.selected = (item && item.selected) ? true : false;
    };
};