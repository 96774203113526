import { Attribute, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[regexMatch]'
})
export class RegexMatchDirective {
    @HostListener('keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
        let current: string = this.elementRef.nativeElement.value;
        let next: string = current.concat(event.key);
        if (String(next).match(this.regexPattern)) {
            return;
        };
        if (next) {
            if (String(next).match(this.regexPattern)) {
                this.elementRef.nativeElement.value = next.substring(1);
            } else if (!String(next).match(this.regexPattern)) {
                this.elementRef.nativeElement.value = next.substring(next.length - 1, next.length).match(this.regexPattern) ? next.substring(next.length - 1, next.length) : this.elementRef.nativeElement.value;
                if (this.allowedKeys.indexOf(event.key) !== -1) {
                    return;
                } else {
                    event.preventDefault();
                };
            };
        };
    };
    @Input('regexMatch') set regex(value: string) {
        this.setRegex(value);
    };

    private allowedKeys: string[] = [' ', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    private regexPattern: RegExp = null;

    public constructor(
        @Attribute('regex') private regexAttributeValue,
        private elementRef: ElementRef
    ) {
        this.setRegex(this.regexAttributeValue);
    };

    private setRegex(regex: string) {
        if (regex) {
            this.regexPattern = new RegExp(regex);
        };
    };
};