import { Component, Input, OnInit } from '@angular/core';
import { CornicheService } from 'src/services';
import { CornicheWithdrawFundsPayload } from './../../../../models/corniche.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModalsService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/services';
import { UserView } from '../../../../models/user.model';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'confirm-withdraw-transaction',
    templateUrl: 'confirm-withdraw-transaction.modal.html'
})

export class ConfirmWithdrawTransactionModalComponent {
    @Input() public withdrawFundsDetails: CornicheWithdrawFundsPayload;

    public loading = false;
    public password: string = '';
    public showOkButton = false;

    public constructor(
        public confirmWithdrawTransactionModal: NgbActiveModal,
        private userService: UserService,
        private cornicheService: CornicheService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
    ) { };

    public onConfirm() {
        if (this.password != '') {
            const { email }: UserView = this.userService.getCurrentUser();
            this.withdrawFundsDetails.authentication = { email, password: this.password };
            this.withdrawFunds();
        }
    };

    private async withdrawFunds(): Promise<void> {
        const withdrawFundsUrl = this.userService.getUserHalLink('WithdrawCornicheFunds')?.href;
        if (withdrawFundsUrl) {
            try {
                this.loading = true;
                await lastValueFrom(this.cornicheService.withdrawFundsObservable(this.withdrawFundsDetails, withdrawFundsUrl));
                this.loading = false;
                this.showOkButton = true;
            } catch (error) {
                this.onFailure(error);
            };
        }
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('operator_portal.accounts.account.withdraw_funds'));
    };
};