import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[minuteOnly]'
})
export class MinuteOnlyDirective {
    @HostListener('keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
        let current: string = this.elementRef.nativeElement.value;
        let next: string = current.concat(event.key);
        if (this.specialKeys.indexOf(event.key) !== -1) {
            event.preventDefault();
            return;
        };
        if (String(next).match(this.regexToOverwrite)) {
            return;
        };
        if (next) {
            if (String(next).match(this.regexToOverwrite)) {
                this.elementRef.nativeElement.value = next.substring(1);
            } else if (!String(next).match(this.regex)) {
                this.elementRef.nativeElement.value = next.substring(next.length - 1, next.length).match(this.regex) ? next.substring(next.length - 1, next.length) : this.elementRef.nativeElement.value;
                if (this.allowedKeys.indexOf(event.key) !== -1) {
                    return;
                } else {
                    event.preventDefault();
                };
            };
        };
    };

    private regex: RegExp = new RegExp(/^([0-5]?[0-9])$/g);
    private regexToOverwrite: RegExp = new RegExp(/^([0-5][0-5][0-9])$/g);
    private specialKeys: string[] = ['End', 'Home', '-'];
    private allowedKeys: string[] = ['Backspace', 'Tab'];

    public constructor(private elementRef: ElementRef) { };
};