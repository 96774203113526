import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CoordinateApi, LookupType, RegionView, TestEmulatorTestRuleView } from 'src/models';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { RegionBuilderMapComponent } from 'src/shared/components/region-builder-map/region-builder-map.component';
import { SettingsService, SharedModalsService, TestEmulatorService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'test-emulator-test-rule-modal',
    templateUrl: './test-emulator-test-rule.modal.html',
    styleUrls: ['test-emulator-test-rule.modal.css']
})

export class TestEmulatorTestRuleModalComponent implements OnInit {
    @ViewChild('fromAreaMapComponent') fromAreaMapComponent: RegionBuilderMapComponent;
    @ViewChild('toAreaMapComponent') toAreaMapComponent: RegionBuilderMapComponent;
    @Input() public testRule: TestEmulatorTestRuleView;

    private testEmulatorService = inject(TestEmulatorService);

    public allTestRegions: RegionView[] = [];
    public centreCoords: CoordinateApi = new CoordinateApi({ latitude: 53.474414, longitude: -2.248014 });
    public confirming: boolean = false;
    public currencySymbol: string = '£';
    public dayTypes: LookupType[] = this.testEmulatorService.dayTypes;
    public editMode: boolean = false;
    public form: UntypedFormGroup = null;
    public jobTypes: LookupType[] = this.testEmulatorService.jobTypes;
    public matchPickupAndDestinationRegions: boolean = false;
    public sliderControl: UntypedFormControl;
    public testRuleCopy: TestEmulatorTestRuleView = new TestEmulatorTestRuleView().setNewTestRuleValues();
    public title: string = '';
    public vehicleTypes: LookupType[] = this.testEmulatorService.vehicleTypes;

    private formSubscription: Subscription;
    private sliderSubscription: Subscription;
    private noDataChange = (): boolean => {
        if (this.editMode) {
            return !this.form.dirty;
        };
        return ObjectUtilities.equal(new TestEmulatorTestRuleView().setNewTestRuleValues(), new TestEmulatorTestRuleView(this.testRuleCopy));
    };

    public constructor(
        public testEmulatorTestRuleModal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) {
        this.currencySymbol = SettingsService.getCurrencySymbol();
    };

    public async ngOnInit(): Promise<void> {
        this.editMode = !!this.testRule;
        this.title = this.editMode ? 'originator_portal.test_emulator.test_rule.update_test_rule' : 'originator_portal.test_emulator.test_rule.add_test_rule';
        if (this.testRule) {
            this.testRuleCopy = ObjectUtilities.deepCopy(this.testRule);
        };
        this.sliderControl = new UntypedFormControl(this.testRule?.numJobs || 10);
        this.form = this.buildForm();

        navigator.geolocation.getCurrentPosition((res: GeolocationPosition) => {
            setTimeout(() => {
                this.centreCoords = new CoordinateApi({ latitude: res.coords.latitude, longitude: res.coords.longitude });
                this.fromAreaMapComponent.moveCenter(this.centreCoords);
                this.toAreaMapComponent.moveCenter(this.centreCoords);
            });
        });

        await this.loadAllTestRegions();
        this.testRuleCopy.fromArea = this.testRuleCopy.fromAreaId ? this.allTestRegions.find(i => i.id == this.testRuleCopy.fromAreaId) : new RegionView();
        this.testRuleCopy.toArea = this.testRuleCopy.toAreaId ? this.allTestRegions.find(i => i.id == this.testRuleCopy.toAreaId) : new RegionView();
    };

    public ngAfterViewInit(): void {
        this.formSubscription = this.form.valueChanges.subscribe({
            next: (value: any) => {
                this.matchPickupAndDestinationRegions = value['originator_portal.test_emulator.test_rule.form.match_regions'];
                this.testRuleCopy.jobTypes = value['originator_portal.test_emulator.test_rule.form.job_types'];
                this.testRuleCopy.maxPrice = value['originator_portal.test_emulator.test_rule.form.max_price'];
                this.testRuleCopy.vehicleTypes = value['originator_portal.test_emulator.test_rule.form.vehicle_types'];
                this.testRuleCopy.weekDays = value['originator_portal.test_emulator.test_rule.form.days'];
            }
        });

        this.sliderSubscription = this.sliderControl.valueChanges.subscribe({
            next: (value: number) => this.testRuleCopy.numJobs = value
        });

        setTimeout(() => {
            this.fromAreaMapComponent.resize();
            this.toAreaMapComponent.resize();
        });
    };

    public ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
        this.sliderSubscription?.unsubscribe();
    };

    public onConfirm(): void {
        if (!this.confirming) {
            if (this.form.valid) {
                //TODO: Remove these 2 lines. Currently used in place of API formatting that needs to be changed.
                this.testRuleCopy.fromAreaId = this.testRuleCopy.fromArea.id;
                this.testRuleCopy.toAreaId = this.testRuleCopy.toArea.id;

                this.testEmulatorTestRuleModal.close(this.testRuleCopy);
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant(this.title), this.form, { windowClass: 'originator' });
            };
        };
    };

    public async onDrawDestinationRegion($event: RegionView): Promise<void> {
        try {
            const region = await this.showNameRegionModal($event);
            const regionView = await lastValueFrom(this.testEmulatorService.createTestRegionAsync(region));
            await this.loadAllTestRegions();
            this.testRuleCopy.toArea = regionView;
            this.testRuleCopy.toAreaId = regionView.id;
            if (this.matchPickupAndDestinationRegions) {
                this.fromAreaMapComponent.draw.deleteAll();
                this.testRuleCopy.toArea = { ...this.testRuleCopy.toArea };
            };
        } catch (error) {
            this.toAreaMapComponent.draw.deleteAll();
            this.testRuleCopy.toArea = new RegionView();
            this.testRuleCopy.toAreaId = null;
            error && this.onFailure(error);
        };
    };

    public async onDrawPickupRegion($event: RegionView): Promise<void> {
        try {
            const region = await this.showNameRegionModal($event);
            const regionView = await lastValueFrom(this.testEmulatorService.createTestRegionAsync(region));
            await this.loadAllTestRegions();
            this.testRuleCopy.fromArea = regionView;
            this.testRuleCopy.fromAreaId = regionView.id;
            if (this.matchPickupAndDestinationRegions) {
                this.toAreaMapComponent.draw.deleteAll();
                this.testRuleCopy.toArea = { ...this.testRuleCopy.fromArea };
            };
        } catch (error) {
            this.fromAreaMapComponent.draw.deleteAll();
            this.testRuleCopy.fromArea = new RegionView();
            this.testRuleCopy.fromAreaId = null;
            error && this.onFailure(error);
        };
    };

    public onSelectDay($event: string[]): void {
        this.testRuleCopy.weekDays = $event;
        this.testRuleCopy.weekDaysView = $event.join(', ');
    };

    public onSelectDestinationRegion($event: MatSelectChange): void {
        this.toAreaMapComponent.draw.deleteAll();
        this.testRuleCopy.toArea = $event && $event.value && { ...this.allTestRegions.find(i => i.id == $event.value) };
        this.testRuleCopy.toAreaId = $event && $event.value.id;
        if (this.matchPickupAndDestinationRegions) {
            this.fromAreaMapComponent.draw.deleteAll();
            this.testRuleCopy.fromArea = { ...this.testRuleCopy.toArea };
            this.testRuleCopy.fromAreaId = this.testRuleCopy.toAreaId;
        };
    };

    public onSelectJobType($event: string[]): void {
        this.testRuleCopy.jobTypes = $event;
        this.testRuleCopy.jobTypesView = $event.join(', ');
    };

    public onSelectPickupRegion($event: MatSelectChange): void {
        this.fromAreaMapComponent.draw.deleteAll();
        this.testRuleCopy.fromArea = $event && $event.value && { ...this.allTestRegions.find(i => i.id == $event.value) };
        this.testRuleCopy.fromAreaId = $event && $event.value.id;
        if (this.matchPickupAndDestinationRegions) {
            this.toAreaMapComponent.draw.deleteAll();
            this.testRuleCopy.toArea = { ...this.testRuleCopy.fromArea };
            this.testRuleCopy.toAreaId = this.testRuleCopy.fromAreaId;
        };
    };

    public onSelectVehicleType($event: string[]): void {
        this.testRuleCopy.vehicleTypes = $event;
        this.testRuleCopy.vehicleTypesView = $event.join(', ');
    };

    public onToggleMatchRegions(): void {
        if (this.testRuleCopy.fromArea.points.length) {
            this.testRuleCopy.toArea = { ...this.testRuleCopy.fromArea };
        };
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'originator_portal.test_emulator.test_rule.form.days': [this.testRuleCopy.weekDays, [Validators.required]],
            'originator_portal.test_emulator.test_rule.form.job_types': [this.testRuleCopy.jobTypes, [Validators.required]],
            'originator_portal.test_emulator.test_rule.form.match_regions': this.matchPickupAndDestinationRegions,
            'originator_portal.test_emulator.test_rule.form.max_price': [this.testRuleCopy.maxPrice, [Validators.required]],
            'originator_portal.test_emulator.test_rule.form.vehicle_types': [this.testRuleCopy.vehicleTypes, [Validators.required]],
        });
    };

    private async loadAllTestRegions(): Promise<void> {
        const allRegionsResult = await lastValueFrom(this.testEmulatorService.getAllTestRegionsObservable());
        this.allTestRegions = [...allRegionsResult?.testEmulatorMapAreaList];
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('originator_portal.test_emulator.test_rule'));
    };

    private showNameRegionModal(region: RegionView): Promise<RegionView> {
        return this.sharedModalsService.showTestRegionNameModal(this.allTestRegions, region).result.then((name: string) => {
            region.name = name;
            return region;
        });
    };
};