export class BookingStatusesResponseApi {
    public Value: string;
    public Name: string;

    public constructor(item?: BookingStatusesResponseApi) {
        this.Value = (item && item.Value) ? item.Value : '';
        this.Name = (item && item.Name) ? item.Name : '';
    };
};

export class CommonType {
    public id: number;
    public name: string;

    public constructor(item?: CommonType) {
        this.id = (item && item.id) ? item.id : 0;
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CountryType {
    public code: string;
    public name: string;

    public constructor(item: CountryType) {
        this.code = (item && item.code) ? item.code : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CultureType {
    public displayName: string;
    public name: string;

    public constructor(item: CultureType) {
        this.displayName = (item && item.displayName) ? item.displayName : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class LookupType {
    public description: string;
    public type: string;

    public constructor(item?: LookupType) {
        this.description = (item && item.description) ? item.description : '';
        this.type = (item && item.type) ? item.type : '';
    };
};