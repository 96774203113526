import { Component, Input } from '@angular/core';
import { DateRangeTemplateView } from 'src/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'date-range-template-dates-modal',
    templateUrl: './date-range-template-dates.modal.html'
})

export class DateRangeTemplateDatesModalComponent {
    @Input() public dateRangeTemplate: DateRangeTemplateView;
    @Input() public windowClass: string;

    public constructor(
        public dateRangeTemplateDatesModal: NgbActiveModal
    ) { };

    public onClose(): void {
        this.dateRangeTemplateDatesModal.dismiss();
    };
};