import { Component, Input, AfterContentInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { ValidationService } from '../../../../services/validation.service';

export interface IFormValidationErrorsModalConfig {
    form: NgForm | UntypedFormGroup;
    title?: string;
};

@Component({
    selector: 'form-validation-errors-modal',
    templateUrl: './form-validation-errors.modal.html'
})

export class FormValidationErrorsModalComponent implements AfterContentInit {
    @Input() public config = {} as IFormValidationErrorsModalConfig;

    public messages: { labelText: string; message: string; }[] = [];
    public title: string = 'Invalid Form';

    private form: NgForm | UntypedFormGroup = null;

    public constructor(public formErrorsModal: NgbActiveModal, private validationService: ValidationService) { };

    public ngAfterContentInit(): void {
        this.form = this.config.form;
        this.title = this.config.title;
        this.form instanceof NgForm && this.setValidationMessages();
        this.form instanceof UntypedFormGroup && this.setReactiveFormValidationMessages();
    };

    private setReactiveFormValidationMessages(): void {
        for (const formGroupControlKey in this.form.controls) {
            if (this.form.controls.hasOwnProperty(formGroupControlKey)) {
                const formGroupControl = this.form.controls[formGroupControlKey];
                if (!formGroupControl.valid && formGroupControl.errors) {
                    let formGroupControlMessage = this.validationService.getValidationMessage(formGroupControl, formGroupControlKey);
                    if (formGroupControlMessage) {
                        this.messages.push(formGroupControlMessage);
                    };
                };
            };
        };
    };

    private setValidationMessages(): void {
        for (const formControlKey in this.form.controls) {
            if (this.form.controls.hasOwnProperty(formControlKey)) {
                const formControl = this.form.controls[formControlKey];
                if (!formControl.valid) {
                    if (formControl instanceof UntypedFormGroup) {
                        for (const formGroupControlKey in formControl.controls) {
                            if (formControl.controls.hasOwnProperty(formGroupControlKey)) {
                                const formGroupControl = formControl.controls[formGroupControlKey];
                                if (!formGroupControl.valid && formGroupControl.errors) {
                                    let formGroupControlMessage = this.validationService.getValidationMessage(formGroupControl, formGroupControlKey);
                                    if (formGroupControlMessage) {
                                        this.messages.push(formGroupControlMessage);
                                    };
                                };
                            };
                        };
                    } else if (formControl.errors) {
                        let message = this.validationService.getValidationMessage(formControl, formControlKey);
                        if (message) {
                            this.messages.push(message);
                        };
                    };
                };
            };
        };
    };
};