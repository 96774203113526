
import { Directive, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[matchField]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: MatchFieldValidatorDirective, multi: true }
    ]
})

export class MatchFieldValidatorDirective implements Validator {
    public constructor(@Attribute('matchField') public matchField: string) { }

    public validate(control: AbstractControl): { [key: string]: any } {
        if (!control.parent.controls[this.matchField]) {
            return null;
        };
        if (control.parent.controls[this.matchField].value !== control.value) {
            return {
                matchField: {
                    field: this.matchField,
                },
            };
        };
        return null;
    };
};