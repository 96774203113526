import { Component, Input, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ArrayUtilities } from '../../../../shared/array.utilities';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

export interface ISelectionModalConfig {
    columns: { flexGrow: number, name: string, property: string }[]
    data: any[];
    selected: string | number | any[];
    selectionProperty: string;
    title: string;
};

@Component({
    selector: 'selection-modal',
    styleUrls: ['selection.modal.css'],
    templateUrl: './selection.modal.html'
})

export class SelectionModalComponent implements OnInit, AfterContentInit {
    @ViewChild(DatatableComponent) public selectionDataTable: DatatableComponent;
    @Input() public config = {} as ISelectionModalConfig;

    public columns = [];
    public data: any[] = [];
    public filterSearchTerm = '';
    public multiselect = false;
    public selected: string | number | any[];
    public selectionProperty: string = '';
    public tempData: any[] = null;
    public title: string = '';

    public constructor(public multiselectModal: NgbActiveModal) { };

    public ngOnInit(): void {
        this.columns = [...this.config.columns];
        this.data = [...this.config.data];
        this.multiselect = this.config.selected instanceof Array ? true : false;
        this.selected = this.multiselect ? [...(this.config.selected as any[])] : this.config.selected;
        this.selectionProperty = this.config.selectionProperty;
        this.tempData = [... this.data];
        this.title = this.config.title;
    };

    public ngAfterContentInit(): void {
        this.selectionDataTable.columnMode = ColumnMode.force;
    };

    public clear(): void {
        this.filterSearchTerm = '';
        this.data = [...this.config.data];
        this.selected = this.multiselect ? [] : null;
    };

    public confirm(): void {
        if (this.multiselect) {
            let data: any[] = [];
            if ((this.selected as any[]).length) {
                for (let i = 0; i < this.tempData.length; i++) {
                    for (let j = 0; j < (this.selected as any[]).length; j++) {
                        if (this.tempData[i][this.selectionProperty] === this.selected[j]) {
                            data.push(this.tempData[i]);
                        };
                    };
                };
            };
            this.multiselectModal.close(data);
        } else {
            for (let i = 0; i < this.tempData.length; i++) {
                if (this.tempData[i][this.selectionProperty] === this.selected) {
                    this.multiselectModal.close(this.tempData[i]);
                };
            };
            this.multiselectModal.close(null);
        };
    };

    public dblClick(): void {
        if (!this.multiselect) {
            this.confirm();
        };
        return;
    };

    public getRowClass = (row): { selected: boolean } => {
        return {
            selected: this.isSelected(row)
        };
    };

    public onActivate($event): void {
        if ($event.type === 'click') {
            if (this.multiselect) {
                var index = (this.selected as any[]).indexOf($event.row[this.selectionProperty]);
                if (index > -1) {
                    (this.selected as any[]).splice(index, 1);
                } else {
                    (this.selected as any[]).push($event.row[this.selectionProperty]);
                };
            } else {
                if ($event.row[this.selectionProperty] !== this.selected) {
                    this.selected = $event.row[this.selectionProperty];
                };
            };
        };
    };

    public selectAll(): void {
        this.selected = [];
        for (let i = 0; i < this.data.length; i++) {
            this.selected.push(this.data[i][this.selectionProperty]);
        };
        this.data = [...this.config.data];
    };

    public undoChanges(): void {
        this.filterSearchTerm = '';
        this.data = [...this.config.data];
        this.selected = this.multiselect ? [...(this.config.selected as any[])] : this.config.selected;
    };

    public updateFilter(): void {
        let columnProps = [];
        columnProps = this.columns.map(column => {
            return column.property;
        });

        this.data = ArrayUtilities.filterMultipleProperties(this.tempData, columnProps, this.filterSearchTerm);
    };

    private isSelected(row): boolean {
        if ((this.multiselect && (this.selected as any[]).indexOf(row[this.selectionProperty]) > -1) || (!this.multiselect && row[this.selectionProperty] === this.selected)) {
            return true;
        };
        return false;
    };
};