export class FileExportResultApi {
    public fileContents: string;
    public fileName: string;

    public constructor(item?: FileExportResultApi) {
        this.fileContents = (item && item.fileContents) ? item.fileContents : '';
        this.fileName = (item && item.fileName) ? item.fileName : '';
    };
};

export class DocumentDownloadResultApi {
    public document: DocumentDetailsApi;

    public constructor(item?: DocumentDownloadResultApi) {
        this.document = (item && item.document) ? new DocumentDetailsApi(item.document) : null;
    };
};

export class DocumentDetailsApi {
    public fileContents: string;
    public fileName: string;
    public firstDocketOnly: boolean;
    public settingsOk: boolean;

    public constructor(item?: DocumentDetailsApi) {
        this.fileContents = (item && item.fileContents) ? item.fileContents : '';
        this.fileName = (item && item.fileName) ? item.fileName : '';
        this.firstDocketOnly = (item && item.firstDocketOnly) ? true : false;
        this.settingsOk = (item && item.settingsOk) ? true : false;
    }
}