import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { iGoPortalActivationService } from "../services";
import { RootActivationService } from "../services";

import { AppComponent } from "./app.component";
import { LoginComponent } from "../login/login.component";
import { PortalsComponent } from './portals/portals.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetupUserComponent } from './setup-user/setup-user.component';

export const AppRoutes: Routes = [
    {
        path: '',
        canActivate: [RootActivationService],
        component: AppComponent,
        children: [
            {
                path: 'dashboard', title: "Dashboard",
                loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
                data: { title: 'routes.dashboard' }
            },
            {
                path: 'originatorportal', title: "Originator Dashboard",
                canActivate: ['canActivateOriginator'],
                loadChildren: () => import('./originator-portal/originator-portal.module').then(m => m.OriginatorPortalModule),
                data: { title: 'routes.originator_portal', portalClass: 'originator' }
            },
            {
                path: 'igoportal', title: "iGo Dashboard",
                canActivate: [iGoPortalActivationService],
                loadChildren: () => import('./igo-portal/igo-portal.module').then(m => m.iGoPortalModule),
                data: { title: 'routes.igo_portal', portalClass: 'igo' }
            },
            {
                path: 'operatorportal', title: "Operator Dashboard",
                canActivate: ['canActivateOperator'],
                loadChildren: () => import('./operator-portal/operator-portal.module').then(m => m.OperatorPortalModule),
                data: { title: 'routes.operator_portal', portalClass: 'operator' }
            },
            {
                path: 'portals', title: "Portals",
                component: PortalsComponent,
                data: { title: 'routes.portals', hidePageTitle: true, showAppLogo: true }
            },
        ]
    },
    { path: 'login', component: LoginComponent, title: "Login", },
    { path: 'passwordreset', component: ResetPasswordComponent, title: "Password Reset", },
    { path: 'setupuser', component: SetupUserComponent, title: "Set Up User", },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { };