import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '../../../../services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'send-reset-password-link-modal',
    templateUrl: 'send-reset-password-link.modal.html'
})

export class SendResetPasswordLinkModalComponent implements AfterViewInit {
    @ViewChild('form') public form: NgForm;
    @ViewChild('input') public input: ElementRef;
    public email: string;
    public sendingLink: boolean = false;

    public constructor(
        public sendResetPasswordLinkModal: NgbActiveModal,
        private authenticationService: AuthenticationService,
    ) { };

    public ngAfterViewInit() {
        this.input.nativeElement.focus();
    };

    public onSendLink() {
        if (!this.sendingLink) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.sendingLink = true;
                this.authenticationService.sendResetPasswordLinkAsync(this.email).subscribe({
                    next: () => this.sendResetPasswordLinkModal.close(this.email),
                    error: (error) => this.sendResetPasswordLinkModal.dismiss(error)
                }).add(() => {
                    this.sendingLink = false;
                });
            };
        };
    };
};