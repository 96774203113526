import { ArrayUtilities } from "src/shared/array.utilities";
import { HalLinksApi } from "./hal-link.model";
import { iGoCompanyPartialApi } from "./igo-company.model";
import { PartialNameAndId } from "./global.model";
import { PermissionItemApi } from "./permission.model";

export class UserApi extends HalLinksApi {
    public company: iGoCompanyPartialApi;
    public email: string;
    public firstName: string;
    public isComplete: boolean;
    public id: string;
    public isIgo: boolean;
    public lastName: string;
    public phone: string;
    public registered: boolean;
    public roles: string[];

    constructor(item?: UserApi) {
        super(item);
        this.company = (item && item.company) ? new iGoCompanyPartialApi(item.company) : null;
        this.email = (item && item.email) ? item.email : '';
        this.firstName = (item && item.firstName) ? item.firstName : '';
        this.isComplete = (item && item.isComplete) ? true : false;
        this.id = (item && item.id) ? item.id : '';
        this.isIgo = (item && item.isIgo) ? true : false;
        this.lastName = (item && item.lastName) ? item.lastName : '';
        this.phone = (item && item.phone) ? item.phone : '';
        this.registered = (item && item.registered) ? true : false;
        this.roles = (item && item.roles) ? item.roles : [];
    };
};

export class UserView extends UserApi {
    public fullName: string;
    public rolesView: string;

    public constructor(item?: UserView) {
        super(item);
        this.fullName = (item && (item.firstName || item.lastName)) ? [item.firstName, item.lastName].join(' ') : '';
        this.rolesView = (item && item.roles) ? item.roles.join(', ') : '';
    };
};

export class GetAllRolesResponseApi extends HalLinksApi {
    public roles: UserRoleApi[];

    public constructor(item: GetAllRolesResponseApi) {
        super(item);
        this.roles = item.roles || [];
    };
};

export class GetAllRolesResponseView extends GetAllRolesResponseApi {
    declare public roles: UserRoleView[];

    public constructor(item: GetAllRolesResponseView) {
        super(item);
        this.roles = item.roles || [];
    };
};

export class UserRoleApi extends PartialNameAndId {
    public globalRole: boolean;
    public permissions: PermissionItemApi[];

    public constructor(item?: UserRoleApi) {
        super(item);
        this.globalRole = (item && item.globalRole) ? true : false;
        this.permissions = (item && item.permissions) ? item.permissions.map(i => new PermissionItemApi(i)) : [];
    };
};

export class UserRoleView extends UserRoleApi {
    public disableDelete: boolean;
    public totalPermissions: string;
    public allPermissions: string;

    public constructor(item?: UserRoleView) {
        super(item);
        this.disableDelete = (item && item.globalRole) ? true : false;
        this.totalPermissions = (item && item.totalPermissions) ? item.totalPermissions : '';
        this.allPermissions = (item && item.permissions) ? item.permissions.map(per => {
            return per.displayName;
        }).join(",\n") : '';
    };
};

export class UserRolePayload {
    public name: string;
    public permissions: string[];

    public constructor(item?: UserRoleApi) {
        this.name = (item && item.name) ? item.name : '';
        this.permissions = (item && item.permissions) ? item.permissions.map(p => p.key) : [];
    };
};

export class CreateiGoUserApi {
    public email: string;
    public roles: string[];

    public constructor(item: iGoUserView) {
        this.email = item.email;
        this.roles = item.roles;
    };
};

export class SetupIGoUserApi {
    public confirmNewPassword: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public newPassword: string;

    public constructor(item?: SetupIGoUserApi | UserView) {
        this.confirmNewPassword = (item && item['confirmNewPassword']) ? item['confirmNewPassword'] : '';
        this.email = (item && item.email) ? item.email : '';
        this.firstName = (item && item.firstName) ? item.firstName : '';
        this.lastName = (item && item.lastName) ? item.lastName : '';
        this.newPassword = (item && item['newPassword']) ? item['newPassword'] : '';
    };
};

export class GetAllUsersResponseApi extends HalLinksApi {
    public users: iGoUserApi[];

    public constructor(item: GetAllUsersResponseApi) {
        super(item);
        this.users = item.users || [];
    };
};

export class GetAllUsersResponseView extends GetAllUsersResponseApi {
    declare public users: iGoUserView[];

    public constructor(item: GetAllUsersResponseView) {
        super(item);
        this.users = item.users || [];
    };
};

export class iGoUserApi extends HalLinksApi {
    public company: iGoCompanyPartialApi;
    public email: string;
    public firstName: string;
    public id: string;
    public lastName: string;
    public registered: boolean;
    public roles: string[];

    public constructor(item?: iGoUserApi) {
        super(item);
        this.company = (item && item.company) ? new iGoCompanyPartialApi(item.company) : null;
        this.email = (item && item.email) ? item.email : '';
        this.firstName = (item && item.firstName) ? item.firstName : '';
        this.id = (item && item.id) ? item.id : '';
        this.lastName = (item && item.lastName) ? item.lastName : '';
        this.registered = (item && item.registered) ? item.registered : false;
        this.roles = (item && item.roles) ? ArrayUtilities.sortAlphabetically(item.roles) : [];
    };
};

export class iGoUserView extends iGoUserApi {
    public companyName: string;
    public name: string;
    public rolesString: string;
    public rolesView: UserRoleView[];

    public constructor(item?: iGoUserView) {
        super(item);
        this.companyName = item ? (item.companyName || item.company?.name) : '';
        this.name = (item && (item.firstName || item.lastName)) ? `${item.firstName} ${item.lastName}` : '';
        this.rolesString = (item && item.roles) ? item.roles.join(', ') : '';
        this.rolesView = (item && item.rolesView) ? item.rolesView : [];
    };
};

export class ChangeUserPasswordPayload {
    public confirmNewPassword: string;
    public currentPassword: string;
    public newPassword: string;

    public constructor(item?: ChangeUserPasswordPayload) {
        this.confirmNewPassword = (item && item.confirmNewPassword) ? item.confirmNewPassword : '';
        this.currentPassword = (item && item.currentPassword) ? item.currentPassword : '';
        this.newPassword = (item && item.newPassword) ? item.newPassword : '';
    };
};

export class PasswordDetails {
    public digits: number;
    public length: number;
    public lowerCaseLetters: number;
    public specialCharacters: number;
    public upperCaseLetters: number;

    public constructor(item?: PasswordDetails) {
        this.digits = (item && item.digits) ? item.digits : 0;
        this.length = (item && item.length) ? item.length : 0;
        this.lowerCaseLetters = (item && item.lowerCaseLetters) ? item.lowerCaseLetters : 0;
        this.specialCharacters = (item && item.specialCharacters) ? item.specialCharacters : 0;
        this.upperCaseLetters = (item && item.upperCaseLetters) ? item.upperCaseLetters : 0;
    }
};