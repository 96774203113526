import { CXAgentView, CXVendorView } from 'src/models';
import { RemoveRowCellTplComponent } from 'src/shared/table-cell-templates/remove-row-cell-template.component';
import { TableCustomisation } from '@autocab/ghost-vs-table';
import { UserService } from 'src/services';

export const MarketplaceOriginatorsTableCustomisation: TableCustomisation<CXAgentView> = {
    customisationKey: 'MarketplaceOriginatorsTableCustomisation',
    tableName: 'global.tables.igo_portal.marketplaces.edit.originators.table_title',
    defaultOrderBy: ['+name'],
    defaultColumns: {
        remove: {
            width: 6,
            name: ' ',
            prop: 'remove',
            show: true,
            template: RemoveRowCellTplComponent,
            templateClickEmitter: true
        },
        id: {
            width: 30,
            name: 'global.modals.edit_marketplace.originators.table.id',
            orderBy: 'id',
            prop: 'id',
            show: true
        },
        name: {
            width: 64,
            name: 'global.modals.edit_marketplace.originators.table.name',
            orderBy: 'name',
            prop: 'name',
            show: true
        }
    }
};

export const MarketplaceOperatorsTableCustomisation: TableCustomisation<CXVendorView> = {
    customisationKey: 'MarketplaceOperatorsTableCustomisation',
    tableName: 'global.tables.igo_portal.marketplaces.edit.operators.table_title',
    defaultOrderBy: ['+name'],
    defaultColumns: {
        remove: {
            width: 6,
            name: ' ',
            prop: 'remove',
            show: true,
            template: RemoveRowCellTplComponent,
            templateClickEmitter: true
        },
        id: {
            width: 30,
            name: 'global.modals.edit_marketplace.operators.table.id',
            orderBy: 'id',
            prop: 'id',
            show: true
        },
        name: {
            width: 64,
            name: 'global.modals.edit_marketplace.operators.table.name',
            orderBy: 'name',
            prop: 'name',
            show: true
        }
    }
};

UserService.registerCustomisation(MarketplaceOriginatorsTableCustomisation);
UserService.registerCustomisation(MarketplaceOperatorsTableCustomisation);