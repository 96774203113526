import { CreditNoteView, DisputeView, InvoiceView } from '../../../../models';
import { DisputeLastMessageCellTplComponent } from 'src/shared/table-cell-templates/dispute-last-message-cell-template.component';
import { DisputeStatusCellTplComponent } from 'src/shared/table-cell-templates/dispute-status-cell-template.component';
import { SendEmailCellTplComponent } from 'src/shared/table-cell-templates/send-email-cell-template.component';
import { TableCustomisation } from '@autocab/ghost-vs-table';

export const ActivityBookingCreditNotesTableCustomisation: TableCustomisation<CreditNoteView> = {
    customisationKey: 'ActivityCreditNotesTableCustomisation',
    tableName: 'global.tables.igo_portal.activity.credit_notes.table_title',
    defaultOrderBy: ['+createdAt'],
    defaultColumns: {
        amount: {
            width: 9,
            name: 'igo_portal.activity.credit_notes.table.amount',
            orderBy: 'amount',
            prop: 'amountCurrency',
            show: true
        },
        createdAt: {
            width: 12,
            name: 'igo_portal.activity.credit_notes.table.created',
            orderBy: 'createdAt',
            prop: 'createdAtView',
            show: true
        },
        fundRequestReason: {
            width: 17,
            name: 'igo_portal.activity.credit_notes.table.fund_request_reason',
            orderBy: 'fundRequestReason',
            prop: 'fundRequestReason',
            show: true
        },
        issuerIssuedBy: {
            width: 15,
            name: 'igo_portal.activity.credit_notes.table.issuer.issued_by',
            orderBy: 'issuer.partnerName',
            prop: 'issuer.partnerName',
            show: true
        },
        issuerPartnerId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.issuer.partner_id',
            orderBy: 'issuer.partnerId',
            prop: 'issuer.partnerId',
            show: false
        },
        issuerPartnerType: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.issuer.partner_type',
            orderBy: 'issuer.partnerType',
            prop: 'issuer.partnerType',
            show: false
        },
        issuerUserName: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.issuer.user_name',
            orderBy: 'issuer.userName',
            prop: 'issuer.userName',
            show: false
        },
        issuedTo: {
            width: 15,
            name: 'igo_portal.activity.credit_notes.table.issued_to',
            orderBy: 'issuedTo.name',
            prop: 'issuedTo.name',
            show: true
        },
        issuedToId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.issued_to.issued_id',
            orderBy: 'issuedTo.id',
            prop: 'issuedTo.id',
            show: false
        },
        issuedToType: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.issued_to.issued_type',
            orderBy: 'issuedTo.type',
            prop: 'issuedTo.type',
            show: false
        },
        approvalReason: {
            width: 17,
            name: 'igo_portal.activity.credit_notes.table.approval_reason',
            orderBy: 'approvalReason',
            prop: 'approvalReason',
            show: true
        },
        approvedAt: {
            width: 12,
            name: 'igo_portal.activity.credit_notes.table.approved',
            orderBy: 'approvedAt',
            prop: 'approvedAtView',
            show: true
        },
        sendEmail: {
            width: 3,
            name: ' ',
            prop: 'email',
            show: true,
            template: SendEmailCellTplComponent,
            templateClickEmitter: true
        },
        operatorId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.operator_id',
            orderBy: 'operator.id',
            prop: 'operator.id',
            show: false
        },
        operatorName: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.operator_name',
            orderBy: 'operator.name',
            prop: 'operator.name',
            show: false
        },
        originatorId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.originator_id',
            orderBy: 'originator.id',
            prop: 'originator.id',
            show: false
        },
        originatorName: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.originator_name',
            orderBy: 'originator.name',
            prop: 'originator.name',
            show: false
        },
        bookingId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.booking_id',
            orderBy: 'bookingId',
            prop: 'bookingId',
            show: false
        },
        disputeId: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.dispute_id',
            orderBy: 'disputeId',
            prop: 'disputeId',
            show: false
        },
        email: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.email',
            orderBy: 'email',
            prop: 'email',
            show: false
        },
        invoiceNumber: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.invoice_number',
            orderBy: 'invoiceNumber',
            prop: 'invoiceNumber',
            show: false
        },
        reason: {
            width: 10,
            name: 'igo_portal.activity.credit_notes.table.reason',
            orderBy: 'reason',
            prop: 'reason',
            show: false
        },
    }
};

export const ActivityBookingDisputesTableCustomisation: TableCustomisation<DisputeView> = {
    customisationKey: 'ActivityDisputesTableCustomisation',
    tableName: 'global.tables.igo_portal.activity.disputes.table_title',
    defaultOrderBy: ['-lastMessage.time'],
    defaultColumns: {
        raisedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.raised_by',
            orderBy: 'raisedBy.partnerName',
            prop: 'raisedBy.partnerName',
            show: true
        },
        respondent: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.respondent',
            orderBy: 'respondent.name',
            prop: 'respondent.name',
            show: true
        },
        reason: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.reason',
            orderBy: 'reason',
            prop: 'reason',
            show: true
        },
        lastMessage: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.last_message',
            orderBy: 'lastMessage.time',
            prop: 'lastMessage.messageSenderAndTime',
            template: DisputeLastMessageCellTplComponent,
            show: true
        },
        status: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.status',
            orderBy: 'status',
            prop: 'status',
            template: DisputeStatusCellTplComponent,
            show: true
        },
        raisedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.raised_date',
            orderBy: 'raisedDateTimeView',
            prop: 'raisedDateTimeView',
            show: false
        },
        operatorName: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.operator_name',
            orderBy: 'operator.name',
            prop: 'operator.name',
            show: false
        },
        originatorName: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.originator_name',
            orderBy: 'originator.name',
            prop: 'originator.name',
            show: false
        },
        resolved: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved',
            orderBy: 'resolved',
            prop: 'resolved',
            show: false
        },
        resolvedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_by',
            orderBy: 'resolvedBy.partnerName',
            prop: 'resolvedBy.partnerName',
            show: false
        },
        resolvedComment: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_comment',
            orderBy: 'resolvedComment',
            prop: 'resolvedComment',
            show: false
        },
        resolvedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.resolved_date',
            orderBy: 'resolvedDateTimeView',
            prop: 'resolvedDateTimeView',
            show: false
        },
        escalated: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated',
            orderBy: 'escalated',
            prop: 'escalated',
            show: false
        },
        escalatedBy: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_by',
            orderBy: 'escalatedBy.partnerName',
            prop: 'escalatedBy.partnerName',
            show: false
        },
        escalatedComment: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_comment',
            orderBy: 'escalatedComment',
            prop: 'escalatedComment',
            show: false
        },
        escalatedDateTime: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.escalated_date',
            orderBy: 'escalatedDateTimeView',
            prop: 'escalatedDateTimeView',
            show: false
        },
        totalMessages: {
            width: 20,
            name: 'igo_portal.activity.disputes.table.total_messages',
            orderBy: 'totalMessages',
            prop: 'totalMessages',
            show: false
        },
    }
};

export const ActivityBookingIGoInvoicesTableCustomisation: TableCustomisation<InvoiceView> = {
    customisationKey: 'ActivityBookingIGoInvoicesTableCustomisation',
    tableName: 'global.tables.igo_portal.activity.invoices.table_title',
    defaultOrderBy: ['+invoiceId'],
    defaultColumns: {
        invoiceId: {
            width: 26,
            name: 'igo_portal.activity.invoices.table.invoice_number',
            orderBy: 'invoiceId',
            prop: 'invoiceId',
            show: true
        },
        originator: {
            width: 26,
            name: 'igo_portal.activity.invoices.table.originator',
            orderBy: 'originator.name',
            prop: 'originator.name',
            show: true
        },
        date: {
            width: 26,
            name: 'igo_portal.activity.invoices.table.date',
            orderBy: 'date',
            prop: 'date',
            show: true
        },
        amount: {
            width: 16,
            name: 'igo_portal.activity.invoices.table.amount',
            orderBy: 'amount',
            prop: 'grossAmountCurrency',
            show: true
        },
        sendEmail: {
            width: 6,
            name: ' ',
            prop: 'email',
            show: true,
            template: SendEmailCellTplComponent,
            templateClickEmitter: true
        },
        dateRange: {
            width: 26,
            name: 'igo_portal.activity.invoices.table.invoice_period',
            orderBy: 'dateRange',
            prop: 'dateRangeView.dateRangeFromToView',
            show: false
        },
        bookingCount: {
            width: 10,
            name: 'igo_portal.invoicing.invoices.table.bookingCount',
            orderBy: 'bookingCount',
            prop: 'bookingCount',
            show: false
        },
    }
};

export const ActivityBookingOriginatorInvoicesTableCustomisation: TableCustomisation<InvoiceView> = {
    customisationKey: 'ActivityBookingOriginatorInvoicesTableCustomisation',
    tableName: 'global.tables.igo_portal.activity.invoices.table_title',
    defaultOrderBy: ['+invoiceId'],
    defaultColumns: {
        invoiceId: {
            width: 22,
            name: 'igo_portal.activity.invoices.table.invoice_number',
            orderBy: 'invoiceId',
            prop: 'invoiceId',
            show: true
        },
        originator: {
            width: 20,
            name: 'igo_portal.activity.invoices.table.originator',
            orderBy: 'originator.name',
            prop: 'originator.name',
            show: true
        },
        operator: {
            width: 20,
            name: 'igo_portal.activity.invoices.table.operator',
            orderBy: 'operator.name',
            prop: 'operator.name',
            show: true
        },
        date: {
            width: 16,
            name: 'igo_portal.activity.invoices.table.date',
            orderBy: 'date',
            prop: 'date',
            show: true
        },
        amount: {
            width: 16,
            name: 'igo_portal.activity.invoices.table.amount',
            orderBy: 'amount',
            prop: 'netAmountCurrency',
            show: true
        },
        sendEmail: {
            width: 6,
            name: ' ',
            prop: 'email',
            show: true,
            template: SendEmailCellTplComponent,
            templateClickEmitter: true
        },
        dateRange: {
            width: 20,
            name: 'igo_portal.activity.invoices.table.invoice_period',
            orderBy: 'dateRange',
            prop: 'dateRangeView.dateRangeFromToView',
            show: false
        },
        bookingCount: {
            width: 10,
            name: 'igo_portal.invoicing.invoices.table.bookingCount',
            orderBy: 'bookingCount',
            prop: 'bookingCount',
            show: false
        },
    }
};