import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

export interface IConfirmationModalConfig {
    message: string;
    title: string;
    confirmButtonText?: string;
    dismissButtonText?: string;
};

@Component({
    selector: 'confirmation-modal',
    styleUrls: ['confirmation.modal.css'],
    templateUrl: './confirmation.modal.html'
})

export class ConfirmationModalComponent implements OnInit {
    @Input() public config = {} as IConfirmationModalConfig;

    public confirmButtonText: string = '';
    public dismissButtonText: string = '';
    public message: string = '';
    public title: string = '';

    public constructor(
        public confirmationModal: NgbActiveModal,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.confirmButtonText = this.config.confirmButtonText;
        this.dismissButtonText = this.config.dismissButtonText;
        this.message = this.translateService.instant(this.config.message);
        this.title = this.config.title;
    };
};