import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ActivityBookingCreditNotesTableCustomisation,
    ActivityBookingDisputesTableCustomisation,
    ActivityBookingIGoInvoicesTableCustomisation,
    ActivityBookingOriginatorInvoicesTableCustomisation
} from './activity-booking-table.customisation';
import { ActivityService, InvoicesService, MapboxService, SharedModalsService, TabsetService, UserService } from 'src/services';
import {
    BookingView,
    CreateFundsRequestPayload,
    CreditNoteView,
    DisputeView,
    GetAllCreditNotesResponseView,
    GetAllDisputesResponseView,
    GetAllInvoicesResponseView,
    InvoiceView,
    UserView
} from 'src/models';
import { CellClickEvent, RowEvent, TableComponent, TableCustomisation } from '@autocab/ghost-vs-table';
import { GhostMapComponent } from 'src/shared/components/ghost-map/ghost-map.component';
import { HttpErrorResponse } from '@angular/common/http';
import { IDisputeReasonModalConfig } from '../dispute-reason/dispute-reason.modal';
import { ISendEmailConfig } from '../send-email/send-email.modal';
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { NgbActiveModal, NgbModalRef, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

export interface IBookingModalConfig {
    booking: BookingView;
    showDisputeOption: boolean;
    showDisputesTab?: boolean;
    showInvoicesTab?: boolean;
};

@Component({
    selector: 'activity-booking-modal',
    styleUrls: ['activity-booking.modal.css'],
    templateUrl: './activity-booking.modal.html'
})

export class ActivityBookingModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(GhostMapComponent) public ghostMap: GhostMapComponent;
    @ViewChild('creditNotesTable') public creditNotesTable: TableComponent;
    @ViewChild('disputesTable') public disputesTable: TableComponent;
    @ViewChild('iGoInvoicesTable') public iGoInvoicesTable: TableComponent;
    @ViewChild('originatorInvoicesTable') public originatorInvoicesTable: TableComponent;
    @ViewChild('nav') public nav: NgbNav;
    @Input() public config: IBookingModalConfig;
    @Input() public windowClass: string;
    public activityBookingCreditNotesTableCustomisation: TableCustomisation<CreditNoteView> = ActivityBookingCreditNotesTableCustomisation;
    public activityBookingDisputesTableCustomisation: TableCustomisation<DisputeView> = ActivityBookingDisputesTableCustomisation;
    public activityBookingIGoInvoicesTableCustomisation: TableCustomisation<InvoiceView> = ActivityBookingIGoInvoicesTableCustomisation;
    public activityBookingOriginatorInvoicesTableCustomisation: TableCustomisation<InvoiceView> = ActivityBookingOriginatorInvoicesTableCustomisation;
    public creditNotesResult: GetAllCreditNotesResponseView = null;
    public disputesResult: GetAllDisputesResponseView = new GetAllDisputesResponseView();
    public distance: { amount?: number, unit?: string; } = null;
    public currentPartnerType: string = '';
    public emailingRiderReceipt: boolean = false;
    public iGoInvoicesResult: GetAllInvoicesResponseView = null;
    public originatorInvoicesResult: GetAllInvoicesResponseView = null;
    public loadingCreditNotes: boolean = false;
    public loadingDisputes: boolean = false;
    public loadingInvoices: boolean = false;
    public loadingModalData: boolean = false;
    public newMessage: string = '';
    public title: string = '';
    public user: UserView;
    public environment: any = environment;
    public activityBookingDisputesTableFooterItems: Array<ITableFooterItem>;
    public activityBookingCreditNotesTableFooterItems: Array<ITableFooterItem>;
    public activityBookingIGoInvoicesTableFooterItems: Array<ITableFooterItem>;
    public activityBookingOriginatorInvoicesTableFooterItems: Array<ITableFooterItem>;

    private creditNotesClickEventSubscription: Subscription;
    private disputeReasonModalRef: NgbModalRef;
    private iGoInvoicesClickEventSubscription: Subscription;
    private originatorInvoicesClickEventSubscription: Subscription;

    public constructor(
        public activityBookingModal: NgbActiveModal,
        private activityService: ActivityService,
        private invoicesService: InvoicesService,
        private mapboxService: MapboxService,
        private sharedModalsService: SharedModalsService,
        private tabsetService: TabsetService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
    };

    public ngOnInit(): void {
        this.title = `${this.translateService.instant('global.modals.activity_booking.requested_by')} ${this.config.booking.originatorName}`;
        this.user = this.userService.getCurrentUser();
        this.mapboxService.setJourneyDistanceOverlay.subscribe({
            next: distance => { this.distance = distance; }
        });
    };

    public ngAfterViewInit(): void {
        this.creditNotesTable?.resetTableCustomisation();
        this.creditNotesClickEventSubscription = this.creditNotesTable?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<CreditNoteView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'sendEmail' && this.showEmailCreditNoteModal(clickEvent.row);
            }
        });
        this.iGoInvoicesClickEventSubscription = this.iGoInvoicesTable?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<InvoiceView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'sendEmail' && this.showEmailInvoiceModal(clickEvent.row, this.iGoInvoicesTable, clickEvent.row._links.SendIGoInvoiceEmail?.href);
            }
        });
        this.originatorInvoicesClickEventSubscription = this.originatorInvoicesTable?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<InvoiceView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'sendEmail' && this.showEmailInvoiceModal(clickEvent.row, this.originatorInvoicesTable, clickEvent.row._links.SendOriginatorInvoiceEmail?.href);
            }
        });
        setTimeout(() => {
            this.ghostMap.map.instance.resize();
        });
    };

    public ngOnDestroy(): void {
        this.creditNotesClickEventSubscription?.unsubscribe();
        this.iGoInvoicesClickEventSubscription?.unsubscribe();
        this.originatorInvoicesClickEventSubscription?.unsubscribe();
    };

    public onRefundBooking(): void {
        const config: IDisputeReasonModalConfig = {
            booking: this.config.booking,
            buttonText: this.translateService.instant('global.modals.activity_booking.refund_booking'),
            callback: this.refundBooking,
            confirmationMessage: this.translateService.instant('global.modals.activity_booking.refund_booking.message').replace('{0}', this.config.booking.availableFundRequest.forOriginatorCurrency).replace('{1}', this.config.booking.originatorName),
            confirmationTitle: this.translateService.instant('global.modals.activity_booking.refund_booking.title'),
            showiGoRefundAmount: true,
            successMessage: this.translateService.instant('global.modals.activity_booking.booking_refunded.message').replace('{0}', this.config.booking.availableFundRequest.forOriginatorCurrency).replace('{1}', this.config.booking.originatorName),
            successTitle: this.translateService.instant('global.modals.activity_booking.booking_refunded.title'),
            title: this.translateService.instant('global.modals.activity_booking.refund_booking.title')
        };
        this.showDisputeReasonModal(config);
    };

    public async onSelectDispute(): Promise<void> {
        if (!this.loadingModalData) {
            this.loadingModalData = true;
            try {
                let dispute: DisputeView = await lastValueFrom(this.activityService.getDisputeByIdObservable(this.activityBookingDisputesTableCustomisation.selected.id));
                dispute && this.showDisputeModal(dispute);
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    public async onSelectInvoice($event: RowEvent<InvoiceView>): Promise<void> {
        const getInvoiceHalLink: string = $event.row.isIGoInvoice ? $event.row._links.GetIGoInvoice.href : $event.row._links.GetOriginatorInvoice.href;
        if (!this.loadingModalData && getInvoiceHalLink) {
            try {
                this.loadingModalData = true;
                const invoice = await lastValueFrom(this.invoicesService.getInvoiceByIdObservable(getInvoiceHalLink, $event.row.isIGoInvoice));
                this.loadingModalData = false;
                invoice && this.sharedModalsService.showInvoiceModal(invoice, { windowClass: 'igo' });
            } catch (error) {
                this.onFailure(error);
            };
        };
    };

    public onShowNewDisputeModal(): void {
        if (this.config.showDisputeOption) {
            this.sharedModalsService.showActivityDisputeReasonsModal(this.config.booking, { windowClass: this.windowClass }).result.then((dispute: DisputeView) => {
                this.disputesResult.disputes = this.disputesResult.disputes.concat(dispute);
                this.disputesResult.disputes = [...this.disputesResult.disputes];
                this.showDisputeModal(dispute);
            }).catch(() => { });
        };
    };

    public onTabChange($event: NgbNavChangeEvent): void {
        this.tabsetService.changeTab.next();
        switch ($event.nextId) {
            case 1:
                setTimeout(() => {
                    this.ghostMap.map.instance.resize();
                }, 250);
                break;
            case 2:
                this.config.showDisputeOption && this.loadBookingDisputes();
                break;
            case 3:
                this.loadBookingCreditNotes();
                break;
            case 4:
                this.loadBookingInvoices();
                break;
            default:
                break;
        };
    };

    public showEmailRiderReceiptModal(): void {
        if (!this.loadingModalData && !this.emailingRiderReceipt && this.config.booking._links.CreateRiderReceipt) {
            const config: ISendEmailConfig = {
                email: this.config.booking.passenger.emailAddress,
                title: this.translateService.instant('global.modals.send_email.email_rider_receipt'),
            };
            this.sharedModalsService.showSendEmailModal(config, { windowClass: this.windowClass }).result.then((email: string) => {
                this.onEmailRiderReceipt(email);
            }).catch(() => { });
        };
    };

    private async loadBookingCreditNotes(): Promise<void> {
        try {
            this.loadingCreditNotes = true;
            this.creditNotesResult = await lastValueFrom(this.activityService.getCreditNotesByBookingObservable(this.config.booking._links.GetCreditNotesByBooking.href));
            this.activityBookingCreditNotesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.credit_notes.total_credit_notes', description: this.creditNotesResult.creditNotes.length }];
        } catch (error) {
            this.activityBookingCreditNotesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.credit_notes.total_credit_notes', description: 0 }];
        };
        this.loadingCreditNotes = false;
    };

    private async loadBookingDisputes(): Promise<void> {
        try {
            this.loadingDisputes = true;
            this.disputesResult = await lastValueFrom(this.activityService.getDisputesByBookingObservable(this.config.booking._links.GetBookingDisputes.href));
            this.activityBookingDisputesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.disputes.total_disputes', description: this.disputesResult.disputes.length }];
        } catch (error) {
            this.activityBookingDisputesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.disputes.total_disputes', description: 0 }];
        };
        this.loadingDisputes = false;
    };

    private async loadBookingInvoices(): Promise<void> {
        try {
            this.loadingInvoices = true;
            this.iGoInvoicesResult = await lastValueFrom(this.activityService.getInvoicesByBookingObservable(this.config.booking._links.GetIGoInvoicesByBookingId.href, true));
            this.originatorInvoicesResult = await lastValueFrom(this.activityService.getInvoicesByBookingObservable(this.config.booking._links.GetOriginatorInvoicesByBookingId.href, false));
            this.activityBookingIGoInvoicesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.invoices.total_invoices', description: this.iGoInvoicesResult.invoices.length }];
            this.activityBookingOriginatorInvoicesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.invoices.total_invoices', description: this.originatorInvoicesResult.invoices.length }];
        } catch (error) {
            this.activityBookingIGoInvoicesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.invoices.total_invoices', description: 0 }];
            this.activityBookingOriginatorInvoicesTableFooterItems = [{ key: 'global.tables.igo_portal.activity.invoices.total_invoices', description: 0 }];
        };
        this.loadingInvoices = false;
    };

    private onEmailCreditNote(creditNote: CreditNoteView, email: string): void {
        if (!this.loadingModalData && creditNote._links.EmailCreditNote && !creditNote.sendingEmail) {
            creditNote.sendingEmail = true;
            this.creditNotesTable.updateRow(creditNote);
            this.invoicesService.emailCreditNoteAsync(creditNote._links.EmailCreditNote.href, email).subscribe({
                next: () => this.onEmailCreditNoteSuccess(creditNote, email),
                error: (error: HttpErrorResponse) => {
                    creditNote.sendingEmail = false;
                    this.creditNotesTable.updateRow(creditNote);
                    this.onFailure(error);
                }
            });
        };
    };

    private onEmailCreditNoteSuccess = (creditNote: CreditNoteView, email: string): void => {
        creditNote.sendingEmail = false;
        this.creditNotesTable.updateRow(creditNote);
        this.sharedModalsService.showAlertModal(
            this.translateService.instant('global.modals.send_email.email_sent'),
            this.translateService.instant('global.modals.send_email.credit_note_emailed').replace('{0}', email),
            { windowClass: this.windowClass }
        );
    };

    private onEmailInvoice(invoice: InvoiceView, email: string, table: TableComponent, sendEmailHalLink: string): void {
        if (!this.loadingModalData && sendEmailHalLink && !invoice.sendingEmail) {
            invoice.sendingEmail = true;
            table.updateRow(invoice);
            this.invoicesService.emailInvoiceAsync(sendEmailHalLink, email).subscribe({
                next: () => this.onEmailInvoiceSuccess(invoice, email, table),
                error: (error: HttpErrorResponse) => {
                    invoice.sendingEmail = false;
                    table.updateRow(invoice);
                    this.onFailure(error);
                }
            });
        };
    };

    private onEmailInvoiceSuccess = (invoice: InvoiceView, email: string, table: TableComponent): void => {
        invoice.sendingEmail = false;
        table.updateRow(invoice);
        this.sharedModalsService.showAlertModal(
            this.translateService.instant('global.modals.send_email.email_sent'),
            this.translateService.instant('global.modals.send_email.invoice_emailed').replace('{0}', email),
            { windowClass: 'igo' }
        );
    };

    private onEmailRiderReceipt(email: string): void {
        if (!this.loadingModalData && !this.emailingRiderReceipt && this.config.booking._links.CreateRiderReceipt) {
            this.emailingRiderReceipt = true;
            this.invoicesService.emailRiderReceiptAsync(this.config.booking._links.CreateRiderReceipt.href, email).subscribe({
                next: () => this.onEmailRiderReceiptSuccess(email),
                error: this.onFailure
            });
        };
    };

    private onEmailRiderReceiptSuccess = (email: string): void => {
        this.emailingRiderReceipt = false;
        this.sharedModalsService.showAlertModal(
            this.translateService.instant('global.modals.send_email.email_sent'),
            this.translateService.instant('global.modals.send_email.rider_receipt_emailed').replace('{0}', email),
            { windowClass: this.windowClass }
        );
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.emailingRiderReceipt = false;
        this.loadingModalData = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.title);
    };

    private refundBooking = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>): void => {
        this.activityService.refundBookingAsync(this.config.booking._links.RefundBooking.href, payload).subscribe({
            next: async (res: DisputeView) => {
                modalRef.close(res);
                delete this.config.booking._links.RefundBooking;
                this.loadBookingCreditNotes();
            },
            error: this.onFailure
        });
    };

    private showDisputeModal = (dispute: DisputeView) => {
        this.loadingModalData = false;
        dispute.booking = this.config.booking;
        this.nav.select(2);
        this.config.showDisputeOption && this.loadBookingDisputes();
        this.sharedModalsService.showActivityDisputeModal(dispute, false, { windowClass: this.windowClass }).result
            .then((res: DisputeView) => this.updateRowById(res))
            .catch((res: DisputeView) => this.updateRowById(res));
    };

    private showDisputeReasonModal(config: IDisputeReasonModalConfig): void {
        this.disputeReasonModalRef = this.sharedModalsService.showDisputeReasonModal(config, { windowClass: this.windowClass });
        this.disputeReasonModalRef.result.then((dispute: DisputeView) => {
            if (this.disputesResult) {
                this.disputesResult.disputes = this.disputesResult.disputes.concat(dispute);
                this.disputesResult.disputes = [...this.disputesResult.disputes];
            };
            if (config.successTitle && config.successMessage) {
                this.sharedModalsService.showAlertModal(config.successTitle, config.successMessage, { windowClass: this.windowClass });
            };
            this.disputeReasonModalRef = null;
        }).catch(() => { this.disputeReasonModalRef = null; });
    };

    private showEmailCreditNoteModal(creditNote: CreditNoteView): void {
        if (!this.loadingModalData && creditNote._links.EmailCreditNote && !creditNote.sendingEmail) {
            const config: ISendEmailConfig = {
                email: '',
                title: this.translateService.instant('global.modals.send_email.email_credit_note'),
            };
            this.sharedModalsService.showSendEmailModal(config, { windowClass: this.windowClass }).result.then((email: string) => {
                this.onEmailCreditNote(creditNote, email);
            }).catch(() => { });
        };
    };

    private showEmailInvoiceModal(invoice: InvoiceView, table: TableComponent, sendEmailHalLink: string): void {
        if (sendEmailHalLink) {
            this.invoicesService.showEmailInvoiceModal(invoice, { windowClass: 'igo' }).result.then((email: string) => {
                this.onEmailInvoice(invoice, email, table, sendEmailHalLink);
            }).catch(() => { });
        };
    };

    private updateRowById(res: DisputeView): void {
        if (res instanceof DisputeView) {
            // Editing the Dispute returns the full dispute object
            if (this.disputesResult.disputes.find(i => i.id == res.id)) {
                this.disputesTable.updateRow(res);
                Object.assign(this.disputesResult.disputes.find(i => i.id == res.id), res);
            };
        };
        this.disputesResult.disputes = [...this.disputesResult.disputes];
    };
};