import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { IHalLink } from 'src/interfaces/hal-link.interface';
import { PermissionCategoryView, PermissionGroupView, PermissionItemApi, PermissionItemView, UserRoleView } from 'src/models';
import { PermissionsService, RolesService, SharedModalsService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'user-role-modal',
    styleUrls: ['user-role.modal.css'],
    templateUrl: './user-role.modal.html'
})

export class UserRoleModalComponent implements OnInit {
    @ViewChild('form') public form: NgForm;
    @Input() public role: UserRoleView = null;
    @Input() public windowClass: string;
    @Input() public _links: IHalLink = {};
    public confirming: boolean = false;
    public editMode: boolean = false;
    public filterSearchTerm: string = '';
    public permissionCategories: PermissionCategoryView[] = [];
    public roleCopy: UserRoleView = new UserRoleView();
    public title: string = '';

    public filteredRows: PermissionCategoryView[] = [];
    public totalPermissions: PermissionCategoryView[] = [];
    private noDataChange = (): boolean => !this.form.dirty;

    public constructor(
        public userRoleModal: NgbActiveModal,
        private permissionsService: PermissionsService,
        private rolesService: RolesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.editMode = !!this.role;
        this.permissionCategories = ObjectUtilities.deepCopy(this.permissionsService.getPermissions());
        this.filteredRows = this.permissionCategories;
        this.totalPermissions = ObjectUtilities.deepCopy(this.permissionsService.getPermissions());
        this.title = this.editMode ? this.role.name : 'global.modals.role.create_role';
        if (this.role) {
            this.roleCopy = ObjectUtilities.deepCopy(this.role);
            this.setSelectedPermissionsFromRole();
        };
    };

    public onConfirm(): void {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            this.confirming = true;
            this.roleCopy.permissions = this.setRolePermissions();
            this.editMode ? this.updateRole() : this.createRole();
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant(this.editMode ? 'global.modals.role.edit_role' : 'global.modals.role.create_role'), this.form);
        };
    };

    public onSelectPermission(selectedCategoryIndex: number, selectedGroupIndex: number, selectedPermissionIndex: number): void {
        this.permissionCategories[selectedCategoryIndex].groups[selectedGroupIndex].permissions[selectedPermissionIndex].selected = !this.permissionCategories[selectedCategoryIndex].groups[selectedGroupIndex].permissions[selectedPermissionIndex].selected;
    };

    public onTogglePermissionGroup(permissionGroup: PermissionGroupView, enabled: boolean): void {
        permissionGroup.permissions.map(p => p.selected = enabled);
    };
    public onFilterChange(): void {
        this.filteredRows = this.totalPermissions;
        for (let categoryIndex = 0; categoryIndex < this.filteredRows.length; categoryIndex++) {
            let groups = this.filteredRows[categoryIndex].groups;
            for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                let permissions = groups[groupIndex].permissions;
                permissions = permissions.filter(permission => {
                    if (permission.displayName.toLowerCase().includes(this.filterSearchTerm.toLowerCase())) {
                        return permission;
                    }
                });
                this.permissionCategories[categoryIndex].groups[groupIndex].permissions = permissions;
            }
            this.permissionCategories[categoryIndex].groups.forEach(group => {
                group.groupEmpty = !group.permissions.length;
            });
            this.permissionCategories[categoryIndex].groupsEmpty = Object.values(this.permissionCategories[categoryIndex].groups).every(group => group.groupEmpty === true);
        }
        this.filteredRows = this.totalPermissions;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant(this.editMode ? 'global.modals.role.edit_role' : 'global.modals.role.create_role'));
    };

    private onSuccess = (res: UserRoleView | string): void => this.userRoleModal.close(res);

    private createRole(): void {
        this.rolesService.createRoleAsync(this._links.CreateRole.href, this.roleCopy).subscribe({
            next: this.onSuccess,
            error: this.onFailure
        });
    };

    private setRolePermissions(): PermissionItemApi[] {
        const allGroups: PermissionGroupView[] = this.permissionCategories.map(p => p.groups).reduce((list, items) => list.concat(items), []);
        const allPermissions: PermissionItemView[] = allGroups.map(p => p.permissions).reduce((list, items) => list.concat(items), []);
        return allPermissions.filter(p => p.selected).map(p => new PermissionItemApi(p));
    };

    private setSelectedPermissionsFromRole(): void {
        this.permissionCategories.map(i => i.groups.map(x => x.permissions.map(z => {
            const role = this.roleCopy.permissions.find(r => r.key == z.key);
            z.selected = Boolean(role);
            return z;
        })));
        this.totalPermissions.map(i => i.groups.map(x => x.permissions.map(z => {
            const role = this.roleCopy.permissions.find(r => r.key == z.key);
            z.selected = Boolean(role);
            return z;
        })));
    };

    private updateRole(): void {
        this.rolesService.updateRoleAsync(this.roleCopy).subscribe({
            next: this.onSuccess,
            error: this.onFailure
        });
    };
};