import { Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { iGoUserView, UserRoleView } from 'src/models';
import { MatSelectChange } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { RolesService, SharedModalsService, UsersService } from 'src/services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'edit-user-modal',
    templateUrl: './edit-user.modal.html'
})

export class EditUserModalComponent implements OnInit, OnDestroy {
    @Input() public user: iGoUserView;
    @Input() public windowClass: string;
    public editing: boolean = false;
    public form: UntypedFormGroup = null;
    public sendingEmail: boolean = false;
    public userCopy: iGoUserView;

    private rolesService = inject(RolesService);

    public allRoles: UserRoleView[] = ArrayUtilities.sortAlphabetically(this.rolesService.getRoles()?.roles, 'name');

    private formSubscription: Subscription;
    private noDataChange = (): boolean => !this.form.dirty;

    public constructor(
        public editUserModal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private usersService: UsersService
    ) { };

    public ngOnInit(): void {
        this.userCopy = ObjectUtilities.deepCopy(this.user);
        this.form = this.buildForm();
        this.formSubscription = this.form.valueChanges.subscribe({
            next: (value: any) => {
                this.userCopy.email = value['global.modals.edit_user.form.email'];
                this.userCopy.firstName = value['global.modals.edit_user.form.first_name'];
                this.userCopy.lastName = value['global.modals.edit_user.form.last_name'];
            }
        });
    };

    public ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    };

    public onSave(): void {
        if (!this.editing) {
            if (this.form.valid) {
                this.editing = true;
                if (this.user._links.UpdateUserRoles) {
                    this.usersService.updateUserRolesAsync(this.userCopy).subscribe({
                        next: this.updateUser,
                        error: this.onFailure
                    });
                } else {
                    this.updateUser();
                };
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.edit_user.edit_user'), this.form);
            };
        };
    };

    public onSelectRole($event: MatSelectChange): void {
        this.userCopy.roles = $event.value;
        this.userCopy.rolesView = this.userCopy.roles.map(i => this.allRoles.find(x => x.name == i));
    };

    public onSendRegistrationEmail(): void {
        if (!this.sendingEmail) {
            this.sendingEmail = true;
            this.usersService.sendRegistrationEmailAsync(this.user.id).subscribe({
                next: this.onSendEmailSuccess,
                error: this.onFailure
            });
        };
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'global.modals.edit_user.form.email': [this.userCopy.email, [Validators.required, Validators.email]],
            'global.modals.edit_user.form.first_name': [this.userCopy.firstName, [Validators.required]],
            'global.modals.edit_user.form.last_name': [this.userCopy.lastName, [Validators.required]],
            'global.modals.edit_user.form.roles': [this.userCopy.roles],
        });
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.editing = false;
        this.sendingEmail = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.edit_user.edit_user'));
    };

    private onSendEmailSuccess = (): void => {
        this.sendingEmail = false;
        const title: string = this.translateService.instant('global.modals.edit_user.email_sent.title');
        const message: string = this.translateService.instant('global.modals.edit_user.email_sent.message').replace('{0}', this.user.email);
        this.sharedModalsService.showAlertModal(title, message, { windowClass: this.windowClass });
    };

    private onSuccess = (res: iGoUserView | string): void => this.editUserModal.close(res);

    private updateUser = (): void => {
        this.usersService.updateUserAsync(this.userCopy).subscribe({
            next: res => this.onSuccess(res),
            error: this.onFailure
        });
    };
};