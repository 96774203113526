import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'stacked-arrow-left-icons',
    templateUrl: './stacked-arrow-left-icons.component.html',
    styleUrls: ['./stacked-arrow-left-icons.component.css']
})
export class StackedArrowLeftIconsComponent {
    @Input() public iconClass: 'fal' | 'far' | 'fas' | 'fad' = 'far';

    public constructor() { };
};
