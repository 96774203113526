import { IHalLink } from "src/interfaces/hal-link.interface";

export class HalLinksApi {
    public _links: IHalLink;
    
    public constructor(item?: HalLinksApi) {
        this._links = (item && item._links) ? item._links : {};
    };
};

export class HalLinkApi {
    public href: string;
    public type: string;

    public constructor(item?: HalLinkApi) {
        this.href = (item && item.href) ? item.href : '';
        this.type = (item && item.type) ? item.type : '';
    };
};