import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ElementRef } from "@angular/core";
import { iGoColourBarComponent } from './../igo-colour-bar/igo-colour-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        iGoColourBarComponent,
        TranslateModule
    ],
    selector: 'side-panel-wrapper',
    templateUrl: 'side-panel-wrapper.component.html',
    styles: [`
        :host {
            background-color: white;
            border-radius: 80px 0 0 0;
            box-shadow: -12px -12px 30px #00000029;
            display: flex;
            flex-direction: column;
            height: calc(100% - 150px);
            overflow: hidden;
            position: fixed;
            right: 0;
            bottom: 0;
            transform: translateX(500px);
            width: 400px;
            z-index: 2;
        }

        :host.show {
            animation: slideIn .5s forwards;
        }

        :host .heading {
            padding: 80px 40px 40px 40px;
        }
    `],
    host: {
        '(window:click)': 'closeSideBar($event.target)',
    },
})
export class SidePanelWrapperComponent {
    @Input() public title: string;
    @Output() public dismiss: EventEmitter<void> = new EventEmitter<void>();

    constructor(private _eref: ElementRef) { }

    public closeSideBar(target: HTMLInputElement) {
        let textContent = target.textContent;
        if (textContent.includes("Filters") && target.innerText.length < 11 || textContent.includes("Continue Editing")) {
            return;
        }
        const ignoredClasses = ["mat-pseudo-checkbox", "mat-option-pseudo-checkbox", "mat-option-text", "mdc-list-item__primary-text", "mat-option", "mat-mdc-select", "mat-mdc-option", "mat-mdc-select-trigger", "mdc-line-ripple", "mat-mdc-select-panel", "mat-option-multiple", "cdk-overlay-container", "cdk-overlay-backdrop"];
        if (ignoredClasses.some(className => target.classList.contains(className))) {
            return;
        }
        if (!this._eref.nativeElement.contains(target)) {
            this.dismiss.emit();
        }
    };

    public onDismiss(): void {
        this.dismiss.emit();
    };
};