import { BookingView, DisputePartnerDetailsApi, DisputeStatusUpdateApi } from "./activity.model";
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";
import { HalLinksApi } from "./hal-link.model";
import { iGoCompanyOperatorPartialApi, iGoCompanyOriginatorPartialApi } from "./igo-company.model";

const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';

export class GetAllCreditNotesResponseApi extends HalLinksApi {
    public creditNotes: CreditNoteApi[];

    public constructor(item?: GetAllCreditNotesResponseApi) {
        super(item);
        this.creditNotes = (item && item.creditNotes) ? item.creditNotes.map(i => new CreditNoteApi(i)) : [];
    };
};

export class GetAllCreditNotesResponseView extends GetAllCreditNotesResponseApi {
    declare public creditNotes: CreditNoteView[];

    public constructor(item?: GetAllCreditNotesResponseView) {
        super(item);
        this.creditNotes = (item && item.creditNotes) ? item.creditNotes.map(i => new CreditNoteView(i)) : [];
    };
};

export class CreditNoteApi extends HalLinksApi {
    public amount: number;
    public approvalReason: string;
    public approvedAt: string;
    public bookingId: string;
    public createdAt: string;
    public disputeId: string;
    public email: string;
    public fundRequestReason: string;
    public id: string;
    public invoiceNumber: string;
    public issuer: DisputeStatusUpdateApi;
    public issuedTo: DisputePartnerDetailsApi;
    public operator: CreditNoteOperatorPartialApi;
    public originator: CreditNoteOriginatorPartialApi;
    public reason: string;

    public constructor(item?: CreditNoteApi) {
        super(item);
        this.amount = (item && typeof item.amount == 'number') ? item.amount : null;
        this.approvalReason = (item && item.approvalReason) ? item.approvalReason : '';
        this.approvedAt = (item && item.approvedAt) ? item.approvedAt : '';
        this.bookingId = (item && item.bookingId) ? item.bookingId : '';
        this.createdAt = (item && item.createdAt) ? item.createdAt : '';
        this.disputeId = (item && item.disputeId) ? item.disputeId : '';
        this.email = (item && item.email) ? item.email : '';
        this.fundRequestReason = (item && item.fundRequestReason) ? item.fundRequestReason : '';
        this.id = (item && item.id) ? item.id : '';
        this.invoiceNumber = (item && item.invoiceNumber) ? item.invoiceNumber : '';
        this.issuer = (item && item.issuer) ? new DisputeStatusUpdateApi(item.issuer) : new DisputeStatusUpdateApi();
        this.issuedTo = (item && item.issuedTo) ? new DisputePartnerDetailsApi(item.issuedTo) : new DisputePartnerDetailsApi();
        this.operator = (item && item.operator) ? new CreditNoteOperatorPartialApi(item.operator) : new CreditNoteOperatorPartialApi();
        this.originator = (item && item.originator) ? new CreditNoteOriginatorPartialApi(item.originator) : new CreditNoteOriginatorPartialApi();
        this.reason = (item && item.reason) ? item.reason : '';
    };
};

export class CreditNoteView extends CreditNoteApi {
    public amountCurrency: string;
    public approvedAtView: string;
    public createdAtView: string;
    public sendingEmail: boolean;

    public constructor(item?: CreditNoteView) {
        super(item);
        this.amountCurrency = (item && item.amount) ? new CurrencyGlobalPipe().transform(item.amount) : '';
        this.approvedAtView = (item && item.approvedAt) ? datePipeShort.transform(item.approvedAt) : '';
        this.createdAtView = (item && item.createdAt) ? datePipeShort.transform(item.createdAt) : '';
        this.sendingEmail = (item && item.sendingEmail) ? true : false;
    };
};

export class CreditNoteOperatorPartialApi extends HalLinksApi {
    public id: string;
    public name: string;

    public constructor(item?: CreditNoteOperatorPartialApi) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CreditNoteOriginatorPartialApi extends HalLinksApi {
    public id: string;
    public name: string;

    public constructor(item?: CreditNoteOriginatorPartialApi) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};
export class GetAllInvoicesResponseApi extends HalLinksApi {
    public invoices: InvoiceApi[];

    public constructor(item?: GetAllInvoicesResponseApi) {
        super(item);
        this.invoices = (item && item.invoices) ? item.invoices.map(i => new InvoiceApi(i)) : [];
    };
};

export class GetAllInvoicesResponseView extends GetAllInvoicesResponseApi {
    declare public invoices: InvoiceView[];

    public constructor(item?: GetAllInvoicesResponseView) {
        super(item);
        this.invoices = (item && item.invoices) ? item.invoices.map(i => new InvoiceView(i)) : [];
    };
};

export class InvoiceApi extends HalLinksApi {
    public bookingCount: number;
    public culture: InvoiceCultureApi;
    public date: string;
    public dateRange: InvoiceDateRangeApi;
    public email: string;
    public grossAmount: number;
    public id: string;
    public invoiceId: string;
    public netAmount: number;
    public operator: iGoCompanyOperatorPartialApi;
    public originator: iGoCompanyOriginatorPartialApi;
    public vatAmount: number;

    public constructor(item?: InvoiceApi) {
        super(item);
        this.bookingCount = (item && typeof item.bookingCount == 'number') ? item.bookingCount : null;
        this.culture = (item && item.culture) ? new InvoiceCultureApi(item.culture) : new InvoiceCultureApi();
        this.date = (item && item.date) ? item.date : '';
        this.dateRange = (item && item.dateRange) ? new InvoiceDateRangeApi(item.dateRange) : new InvoiceDateRangeApi();
        this.email = (item && item.email) ? item.email : '';
        this.grossAmount = (item && typeof item.grossAmount == 'number') ? item.grossAmount : null;
        this.id = (item && item.id) ? item.id : '';
        this.invoiceId = (item && item.invoiceId) ? item.invoiceId : '';
        this.netAmount = (item && typeof item.netAmount == 'number') ? item.netAmount : null;
        this.operator = (item && item.operator) ? new iGoCompanyOperatorPartialApi(item.operator) : new iGoCompanyOperatorPartialApi();
        this.originator = (item && item.originator) ? new iGoCompanyOriginatorPartialApi(item.originator) : new iGoCompanyOriginatorPartialApi();
        this.vatAmount = (item && typeof item.vatAmount == 'number') ? item.vatAmount : null;
    };
};

export class InvoiceCultureApi {
    public currencySymbol: string;
    public shortDatePattern: string;

    public constructor(item?: InvoiceCultureApi) {
        this.currencySymbol = (item && item.currencySymbol) ? item.currencySymbol : '';
        this.shortDatePattern = (item && item.shortDatePattern) ? item.shortDatePattern : '';
    };
};

export class InvoiceDateRangeApi {
    public from: string;
    public to: string;

    public constructor(item?: InvoiceDateRangeApi) {
        this.from = (item && item.from) ? item.from : '';
        this.to = (item && item.to) ? item.to : '';
    };
};

export class InvoiceDateRangeView extends InvoiceDateRangeApi {
    public fromView: string;
    public toView: string;
    public dateRangeFromToView: string;

    public constructor(item?: InvoiceDateRangeApi) {
        super(item);
        this.fromView = (item && item.from) ? new GenericDatePipe().transform(String(new Date(item.from))) : '';
        this.toView = (item && item.to) ? new GenericDatePipe().transform(String(new Date(item.to))) : '';
        this.dateRangeFromToView = (item && item.from && item.to) ? `${this.fromView} - ${this.toView}` : '';
    };
};

export class InvoiceView extends InvoiceApi {
    public bookings: BookingView[];
    public dateRangeView: InvoiceDateRangeView;
    public dateView: string;
    public grossAmountCurrency: string;
    public isIGoInvoice: boolean;
    public netAmountCurrency: string;
    public sendingEmail: boolean;
    public vatAmountCurrency: string;

    public constructor(item: InvoiceView, isIGoInvoice?: boolean) {
        super(item);
        this.bookings = (item && item.bookings) ? item.bookings.map(i => new BookingView(i)) : [];
        this.dateRangeView = (item && item.dateRange) ? new InvoiceDateRangeView(item.dateRange) : new InvoiceDateRangeView();
        this.dateView = (item && item.date) ? new GenericDatePipe().transform(item.date) : '';
        this.grossAmountCurrency = item.culture.currencySymbol + (Math.round(Number(item.grossAmount) * 100) / 100).toFixed(2);
        this.isIGoInvoice = isIGoInvoice || ((item && item.isIGoInvoice) ? true : false);
        this.netAmountCurrency = item.culture.currencySymbol + (Math.round(Number(item.netAmount) * 100) / 100).toFixed(2);
        this.sendingEmail = (item && item.sendingEmail) ? true : false;
        this.vatAmountCurrency = item.culture.currencySymbol + (Math.round(Number(item.vatAmount) * 100) / 100).toFixed(2);
    };
};