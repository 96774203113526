import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ServerValidationError } from '../../../../models/server-validation-error.model';
import { SnackbarService } from 'src/services/snackbar.service';

declare const zE;

export interface IServerValidationErrorsModalConfig {
    errors: ServerValidationError[];
    instance?: string;
    title?: string;
};

@Component({
    selector: 'server-validation-errors-modal',
    templateUrl: './server-validation-errors.modal.html',
    styleUrls: ['server-validation-errors.modal.css']
})

export class ServerValidationErrorsModalComponent implements OnInit {
    @Input() public config = {} as IServerValidationErrorsModalConfig;
    public copied: boolean = false;
    public location: string;
    public selectedPortalName: string;

    public constructor(
        private router: Router,
        public serverErrorsModal: NgbActiveModal,
        private clipboard: Clipboard,
        private snackbarService: SnackbarService
    ) { };

    public ngOnInit(): void {
        this.location = this.router.url;
        this.setSelectedPortalName();
    };

    public onCopyErrorInstance(): void {
        this.config.instance && this.clipboard.copy(this.config.instance);
        this.snackbarService.showSnackbar('global.modals.copied');
    };

    public openWebchatForError(): void {
        this.clipboard.copy(this.config.instance);
        this.snackbarService.showSnackbar('global.modals.copied_error', 10);
        zE("webWidget", "open");
    };

    private setSelectedPortalName(): void {
        if (this.location.includes('operatorportal')) {
            this.selectedPortalName = 'operator';
        } else if (this.location.includes('originatorportal')) {
            this.selectedPortalName = 'originator';
        } else if (this.location.includes('igoportal')) {
            this.selectedPortalName = 'igo';
        } else {
            this.selectedPortalName = '';
        };
    };
};