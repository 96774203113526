import { AfterViewInit, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CompanyOriginatorsOrOperatorsModalComponent } from "./modals/company-originators-or-operators/company-originators-or-operators.modal";
import { forkJoin, lastValueFrom, Subject, Subscription } from "rxjs";
import { GetAllOperatorsResponseView, GetAllOriginatorsResponseView, iGoCompanyOperatorPartialView, iGoCompanyOriginatorView, PortalItem, UserView } from "src/models";
import { HttpErrorResponse } from "@angular/common/http";
import { iGoCompaniesService, ModalService, PortalsService, SharedModalsService, StorageService, UserService } from "src/services";
import { LocationStrategy } from '@angular/common';
import { ObjectUtilities } from "src/shared/object.utilities";
import { RouteUtilities } from "src/shared/route-utilities";
import { switchMap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'portals',
    styleUrls: ['portals.component.css'],
    templateUrl: './portals.component.html'
})
export class PortalsComponent implements OnInit, AfterViewInit, OnDestroy {
    private portalsService = inject(PortalsService);

    public loadingIGo: boolean = false;
    public operators: GetAllOperatorsResponseView = null;
    public originators: GetAllOriginatorsResponseView = null;
    public portals: PortalItem[] = ObjectUtilities.deepCopy(this.portalsService.portals);
    public user: UserView;

    private backToIGo$: Subject<void> = new Subject<void>();
    private cachedUrlToLoad: string = null;
    private operatorIdToLoad: string = null;
    private originatorIdToLoad: string = null;
    private queryParamsSubscription: Subscription;
    private returnedToPortals: boolean = false;
    private selectPortalOption$: Subject<PortalItem> = new Subject<PortalItem>();

    public constructor(
        private iGoCompaniesService: iGoCompaniesService,
        private location: LocationStrategy,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private router: Router,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        this.iGoCompaniesService.cacheCurrentOperator(null);
        this.iGoCompaniesService.cacheCurrentOriginator(null);
        this.user = this.userService.getCurrentUser();

        this.queryParamsSubscription = this.route.queryParams.subscribe({
            next: (queryParams: Params) => {
                this.returnedToPortals = Boolean(queryParams.return);
            }
        });

        // Disable being able to click the "back" button in the browser.
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });

        this.cachedUrlToLoad = StorageService.getItem('urlToLoad');
        this.operatorIdToLoad = this.cachedUrlToLoad?.split('operatorId=')[1]?.split('&')[0]; // Takes the value of the operatorId from the URL if it exists.
        this.originatorIdToLoad = this.cachedUrlToLoad?.split('originatorId=')[1]?.split('&')[0]; // Takes the value of the originatorId from the URL if it exists.
    };

    public ngOnInit(): void {
        forkJoin([
            this.iGoCompaniesService.getOriginatorsObservable(),
            this.iGoCompaniesService.getOperatorsObservable()
        ]).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });
    };

    public ngAfterViewInit(): void {
        let selectedPortal: PortalItem = null;
        this.selectPortalOption$.pipe(switchMap((portal?: PortalItem) => {
            selectedPortal = portal;
            return this.userService.getCurrentUserObservable();
        })).subscribe({
            next: () => this.navigateSelectedPortalOption(selectedPortal),
            error: this.onFailure
        });

        this.backToIGo$.pipe(switchMap(res => this.iGoCompaniesService.returnUserAccessAsync())).subscribe({
            next: async () => {
                const iGoOriginator = await lastValueFrom(this.iGoCompaniesService.getGlobalOriginatorObservable());
                iGoOriginator && this.iGoCompaniesService.cacheCurrentOriginator(iGoOriginator);
                iGoOriginator && this.iGoCompaniesService.cacheCurrentOperator(null);

                this.selectPortalOption$.next(null);
            },
            error: this.onFailure
        });
    };

    public ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
    };

    public onBackToIGoPortal(): void {
        if (!this.loadingIGo) {
            this.loadingIGo = true;
            this.backToIGo$.next(null);
        };
    };

    public onSelectPortal(portal: PortalItem): void {
        if (portal.class == 'originator' && this.originators.originators.length == 1) {
            this.loadSelectedOriginatorOrOperator(portal, this.originators.originators[0]);
            return;
        }
        if (portal.class == 'operator' && this.operators.operators.length == 1) {
            this.loadSelectedOriginatorOrOperator(portal, this.operators.operators[0]);
            return;
        }
        if (portal.options.length) {
            this.portals.map(p => p.selected = false);
            this.portals.find(p => p.name == portal.name).selected = true;
            this.showCompanyOptions(portal);
        };
    };

    private loadOperatorById(): void {
        const portalItem = this.portals.find(i => i.class == 'operator');
        const operator = this.operators.operators.find(i => i.id == this.operatorIdToLoad);
        this.loadSelectedOriginatorOrOperator(portalItem, operator);
    };

    private loadOriginatorById(): void {
        const portalItem = this.portals.find(i => i.class == 'originator');
        const originator = this.originators.originators.find(i => i.id == this.originatorIdToLoad);
        this.loadSelectedOriginatorOrOperator(portalItem, originator);
    };

    private loadSelectedOriginatorOrOperator(portal: PortalItem, selected: iGoCompanyOperatorPartialView | iGoCompanyOriginatorView): void {
        if (selected instanceof iGoCompanyOriginatorView) {
            this.iGoCompaniesService.cacheCurrentOperator(null);
            this.iGoCompaniesService.cacheCurrentOriginator(selected);
        } else {
            this.iGoCompaniesService.cacheCurrentOriginator(null);
            this.iGoCompaniesService.cacheCurrentOperator(selected);
        };

        this.selectPortalOption$.next(portal);
    };

    private navigateSelectedPortalOption = async (selectedPortal: PortalItem) => {
        if (selectedPortal?.class == "originator") {
            const route = UserService.currentUser.isComplete ? selectedPortal.route : '/originatorportal/profile';
            await this.router.navigate(selectedPortal ? [route] : ['/igoportal/network']);
        } else {
            if (this.cachedUrlToLoad) {
                const queryParams = RouteUtilities.parseUrlQueryParams(this.cachedUrlToLoad);
                await this.router.navigate([this.cachedUrlToLoad.split('?')[0]], { queryParams });
                StorageService.deleteItem('urlToLoad');
            } else {
                await this.router.navigate(selectedPortal ? [selectedPortal.route] : ['/igoportal/network']);
            };
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loadingIGo = false;
        this.sharedModalsService.showServerValidationErrorsModal(res);
    };

    private onInitialiseData = (): void => {
        this.operators = this.iGoCompaniesService.getOperators();
        this.originators = this.iGoCompaniesService.getOriginators();
        this.portals.find(i => i.class == 'operator').options = this.operators?.operators;
        this.portals.find(i => i.class == 'originator').options = this.originators?.originators;

        if (this.operatorIdToLoad) {
            this.loadOperatorById();
            return;
        } else if (this.originatorIdToLoad) {
            this.loadOriginatorById();
            return;
        };
        if (!this.returnedToPortals) {
            if (!this.operators.operators.length) {
                const portalItem = this.portals.find(i => i.class == 'originator');
                if (this.originators.originators.length == 1) {
                    this.loadSelectedOriginatorOrOperator(portalItem, this.originators.originators[0]);
                } else if (this.operators.operators.length >= 1) {
                    this.onSelectPortal(portalItem);
                };
            } else if (!this.originators.originators.length) {
                const portalItem = this.portals.find(i => i.class == 'operator');
                if (this.operators.operators.length == 1) {
                    this.loadSelectedOriginatorOrOperator(portalItem, this.operators.operators[0]);
                } else if (this.operators.operators.length >= 1) {
                    this.onSelectPortal(portalItem);
                };
            };
        }
    };

    private showCompanyOptions(portal: PortalItem): void {
        const modalRef = this.modalService.open(CompanyOriginatorsOrOperatorsModalComponent, { size: 'sm', windowClass: portal.class });
        modalRef.componentInstance.options = portal.options;
        modalRef.componentInstance.title = this.translateService.instant(portal.class == 'originator' ? 'portals.select_originator' : 'portals.select_operator');
        modalRef.result.then((selected: iGoCompanyOperatorPartialView | iGoCompanyOriginatorView) => {
            this.loadSelectedOriginatorOrOperator(portal, selected);
        }).catch(() => { });
    };
};