import { Directive, ElementRef, HostListener, Input, Attribute, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NumberUtilities } from '../../../shared/number.utilities';

// [numberOnly]="true" will only allow integers.
@Directive({
    selector: '[numberOnly]'
})
export class NumberOnlyDirective {
    private _decimals: number = null;
    private isTypeNumber: boolean = false;
    private prevKey = null;
    private regex: RegExp = new RegExp(/^-?\d*\.?\d*$/g);
    private specialKeys: string[] = ['Alt', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'Backspace', 'Control', 'Delete', 'End', 'Enter', 'Escape', 'Home', 'Space', 'Tab'];

    @HostListener('input', ['$event']) private input(e: any) {
        if (this.isTypeNumber && this.decimals && e.data !== '-' && this.specialKeys.indexOf(e.data) === -1) {
            if (e.data === '.' && !this.elementRef.nativeElement.value) {
                return;
            };
            let [integer, mantissa] = String(this.elementRef.nativeElement.value).split('.');
            if (mantissa) {
                this.ngControl.control.setValue(NumberUtilities.truncate(this.elementRef.nativeElement.value, this.decimals));
            };
        };
    };

    @HostListener('keydown', ['$event']) private keydown(event: KeyboardEvent) {
        if (this.isTypeNumber && (event.key === '-' || (!this.numberOnly && event.key === '.')) && this.elementRef.nativeElement.value === '') {
            this.elementRef.nativeElement.value = '';
            return;
        };
        if (this.numberOnly) {
            if (Number.isInteger(Number(event.key)) || this.specialKeys.indexOf(event.key) >= 0 || this.isCombinationKey(event)) {
                return;
            };
            if (event.key === '-') {
                if (this.elementRef.nativeElement.value.indexOf('-') === -1) {
                    this.ngControl.control.setValue(event.key + this.elementRef.nativeElement.value);
                };
            };
            event.preventDefault();
        } else {
            if (this.isTypeNumber) {
                if (event.key === '.') {
                    if (this.elementRef.nativeElement.value.indexOf('.') >= 0 || (this.elementRef.nativeElement.value && this.prevKey === '.')) {
                        event.preventDefault();
                    };
                };
                if (event.key === '-') {
                    event.preventDefault();
                    if (this.elementRef.nativeElement.value.indexOf('-') === -1) {
                        this.ngControl.control.setValue(event.key + (this.decimals ? NumberUtilities.truncate(this.elementRef.nativeElement.value, this.decimals) : this.elementRef.nativeElement.value))
                    };
                };
                this.prevKey = event.key;
            } else {
                if ((this.specialKeys.indexOf(event.key) !== -1) || this.isCombinationKey(event)) {
                    return;
                };
                event.preventDefault()
                let current = this.elementRef.nativeElement.value;
                let position = this.elementRef.nativeElement.selectionStart
                let next = current.slice(0, position) + event.key + current.slice(position, current.length);
                if (next && String(next).match(this.regex)) {
                    this.ngControl.control.setValue(this.decimals ? NumberUtilities.truncate(next, this.decimals) : next);
                    this.renderer.setProperty(this.elementRef.nativeElement, 'selectionStart', position + 1);
                    this.renderer.setProperty(this.elementRef.nativeElement, 'selectionEnd', position + 1);
                };
            };
        };
    };

    @Input() public set decimals(value: number) {
        if (value) {
            this._decimals = value;
        };
    };
    public get decimals(): number { return this._decimals };
    @Input() public numberOnly: boolean = false;

    public constructor(@Attribute('type') private typeAttributeValue: string, private elementRef: ElementRef, private ngControl: NgControl, private renderer: Renderer2) {
        this.isTypeNumber = this.typeAttributeValue.toLowerCase() === 'number' ? true : false;
    };

    private isCombinationKey(e: KeyboardEvent) {
        return (e.keyCode == 65 || e.keyCode == 86 || e.keyCode == 67) && (e.ctrlKey === true || e.metaKey === true);
    };
};