import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[min][formControlName],[min][formControl],[min][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MinNumberValidatorDirective), multi: true }
    ]
})

export class MinNumberValidatorDirective implements Validator {
    @Input('min') set min(value: string | number) {
        this.setMinNumber(value);
    };
    private minNumber = null;

    public constructor(@Attribute('min') private minAttributeValue) {
        this.setMinNumber(this.minAttributeValue);
    };

    public validate(control: AbstractControl): { [key: string]: any } {
        let value = typeof control.value == 'number' ? Number(control.value) : null;
        if (value != null && typeof this.minNumber === 'number' && (value < this.minNumber || Number.isNaN(value))) {
            return {
                min: {
                    min: this.minNumber,
                    value: value
                }
            };
        };
        return null;
    };

    private setMinNumber(min: number | string) {
        // We need to check if min value is zero too.
        // Doing if(min) will count zero as falsy value!
        if (min != null) {
            this.minNumber = typeof min === 'number' ? min : parseInt(min);
        };
    };
};