import { Injectable } from "@angular/core";
import { IChartColorScheme, IShowPieChartsValues, IChartConfigMap } from '../interfaces/charts.interface';

@Injectable({ providedIn: 'root' })
export class ChartsService {
    public readonly singleColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')'
        ],
    };

    public readonly originatorChartsSingleColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')'
        ],
    };

    public readonly dualColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')'
        ],
    };

    public readonly iGoDualColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')'
        ],
    };

    public readonly operatorDualColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')'
        ],
    };

    public readonly originatorDualColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')'
        ],
    };

    public readonly originatorGradientColourScheme: IChartColorScheme = {
        domain: [
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', 1)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .8)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .6)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .4)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .2)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .4)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .6)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', .8)',
            'rgba(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ', 1)',
        ],
    };

    public readonly multiColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')'
        ],
    };

    public readonly iGoMultiColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')'
        ],
    };

    public readonly operatorMultiColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')'
        ],
    };

    public readonly originatorMultiColourScheme: IChartColorScheme = {
        domain: [
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-originator-blue') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-dark-grey') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-operator-green') + ')',
            'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--igo-cyan') + ')'
        ],
    };

    public activityDisputesChartConfig: IChartConfigMap = {
        all: { name: 'igo_portal.activity.disputes.charts.all', data: [], extra: 'all', value: 0 },
        escalated: { name: 'igo_portal.activity.disputes.charts.escalated', data: [], extra: 'escalated', value: 0, showIfZero: true },
        lastMessageIsSent: { name: 'igo_portal.activity.disputes.charts.sent', data: [], extra: 'lastMessageIsSent', value: 0, showIfZero: true },
        lastMessageIsReceived: { name: 'igo_portal.activity.disputes.charts.received', data: [], extra: 'lastMessageIsReceived', value: 0, showIfZero: true },
        open: { name: 'igo_portal.activity.disputes.charts.open', data: [], extra: 'open', value: 0, showIfZero: true },
        resolved: { name: 'igo_portal.activity.disputes.charts.resolved', data: [], extra: 'resolved', value: 0, showIfZero: true },
    };

    public iGoCompaniesOriginatorsChartConfig: IChartConfigMap = {
        all: { name: 'igo_portal.companies.originators.charts.all', data: [], extra: 'all', value: 0 },
        disabled: { name: 'igo_portal.companies.originators.charts.disabled', data: [], extra: 'disabled', value: 0 },
        enabled: { name: 'igo_portal.companies.originators.charts.enabled', data: [], extra: 'enabled', value: 0 },
    };

    public iGoNetworkChartConfig: IChartConfigMap = {
        active: { name: 'igo_portal.network.charts.active', data: [], extra: 'active', value: 0 },
        all: { name: 'igo_portal.network.charts.all', data: [], extra: 'all', value: 0 },
        disconnected: { name: 'igo_portal.network.charts.disconnected', data: [], extra: 'disconnected', value: 0 },
        gracePeriod: { name: 'igo_portal.network.charts.grace_period', data: [], extra: 'gracePeriod', value: 0 },
        suspendedOrTest: { name: 'igo_portal.network.charts.suspended_or_test', data: [], extra: 'suspendedOrTest', value: 0 },
    };

    public operatorDemandGeneratorsChartConfig: IChartConfigMap = {
        active: { name: 'operator_portal.demand.my_demand_generators.charts.active', data: [], extra: 'active', value: 0 },
        all: { name: 'operator_portal.demand.my_demand_generators.charts.all', data: [], extra: 'all', value: 0 },
        disconnected: { name: 'operator_portal.demand.my_demand_generators.charts.disconnected', data: [], extra: 'disconnected', value: 0 },
        suspendedOrTest: { name: 'operator_portal.demand.my_demand_generators.charts.suspended_or_test', data: [], extra: 'suspendedOrTest', value: 0 },
    };

    public originatorNetworkChartConfig: IChartConfigMap = {
        active: { name: 'originator_portal.network.charts.active', data: [], extra: 'active', value: 0 },
        all: { name: 'originator_portal.network.charts.all', data: [], extra: 'all', value: 0 },
        disconnected: { name: 'originator_portal.network.charts.disconnected', data: [], extra: 'disconnected', value: 0 },
        gracePeriod: { name: 'originator_portal.network.charts.grace_period', data: [], extra: 'gracePeriod', value: 0 },
        rejected: { name: 'originator_portal.network.charts.rejected', data: [], extra: 'rejected', value: 0 },
        suspendedOrTest: { name: 'originator_portal.network.charts.suspended_or_test', data: [], extra: 'suspendedOrTest', value: 0 },
    };

    public originatorTestEmulatorTestsChartConfig: IChartConfigMap = {
        active: { name: 'originator_portal.test_emulator.charts.active', data: [], extra: 'active', value: 0, showIfZero: true },
        all: { name: 'originator_portal.test_emulator.charts.all', data: [], extra: 'all', value: 0 },
        completed: { name: 'originator_portal.test_emulator.charts.completed', data: [], extra: 'completed', value: 0, showIfZero: true },
        readyToStart: { name: 'originator_portal.test_emulator.charts.ready_to_start', data: [], extra: 'readyToStart', value: 0, showIfZero: true },
        pending: { name: 'originator_portal.test_emulator.charts.pending', data: [], extra: 'pending', value: 0, showIfZero: true },
        stopped: { name: 'originator_portal.test_emulator.charts.stopped', data: [], extra: 'stopped', value: 0, showIfZero: true },
    };

    public showPieChartsValues: IShowPieChartsValues[] = [];

    public testBookingsStatsChartConfig: IChartConfigMap = {
        all: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.all', data: [], extra: 'all', value: 0 },
        asapLatenessZeroToTwo: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.zero_to_two', data: [], extra: 'asapLatenessZeroToTwo', value: 0, showIfZero: true },
        asapLatenessTwoToFive: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.two_to_five', data: [], extra: 'asapLatenessTwoToFive', value: 0, showIfZero: true },
        asapLatenessFiveToTen: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.five_to_ten', data: [], extra: 'asapLatenessFiveToTen', value: 0, showIfZero: true },
        asapLatenessTenPlus: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.ten_plus', data: [], extra: 'asapLatenessTenPlus', value: 0, showIfZero: true },
        asapTimeToArriveZeroToFive: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.zero_to_five', data: [], extra: 'asapTimeToArriveZeroToFive', value: 0, showIfZero: true },
        asapTimeToArriveFiveToTen: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.five_to_ten', data: [], extra: 'asapTimeToArriveFiveToTen', value: 0, showIfZero: true },
        asapTimeToArriveTenToFifteen: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.ten_to_fifteen', data: [], extra: 'asapTimeToArriveTenToFifteen', value: 0, showIfZero: true },
        asapTimeToArriveFifteenPlus: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.fifteen_plus', data: [], extra: 'asapTimeToArriveFifteenPlus', value: 0, showIfZero: true },
        completed: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.completed', data: [], extra: 'completed', value: 0, showIfZero: true },
        completedWithErrors: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.with_errors', data: [], extra: 'completedWithErrors', value: 0, showIfZero: true },
        notCompleted: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.not_completed', data: [], extra: 'notCompleted', value: 0, showIfZero: true },
        prebookingLatenessZeroToTwo: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.zero_to_two', data: [], extra: 'prebookingLatenessZeroToTwo', value: 0, showIfZero: true },
        prebookingLatenessTwoToFive: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.two_to_five', data: [], extra: 'prebookingLatenessTwoToFive', value: 0, showIfZero: true },
        prebookingLatenessFiveToTen: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.five_to_ten', data: [], extra: 'prebookingLatenessFiveToTen', value: 0, showIfZero: true },
        prebookingLatenessTenPlus: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.ten_plus', data: [], extra: 'prebookingLatenessTenPlus', value: 0, showIfZero: true },
        timeToDispatchZeroToOne: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.zero_to_one', data: [], extra: 'timeToDispatchZeroToOne', value: 0, showIfZero: true },
        timeToDispatchOneToTwo: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.one_to_two', data: [], extra: 'timeToDispatchOneToTwo', value: 0, showIfZero: true },
        timeToDispatchTwoToThree: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.two_to_three', data: [], extra: 'timeToDispatchTwoToThree', value: 0, showIfZero: true },
        timeToDispatchThreeToFour: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.three_to_four', data: [], extra: 'timeToDispatchThreeToFour', value: 0, showIfZero: true },
        timeToDispatchFourToFive: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.four_to_five', data: [], extra: 'timeToDispatchFourToFive', value: 0, showIfZero: true },
        timeToDispatchFiveToSix: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.five_to_six', data: [], extra: 'timeToDispatchFiveToSix', value: 0, showIfZero: true },
        timeToDispatchSixToSeven: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.six_to_seven', data: [], extra: 'timeToDispatchSixToSeven', value: 0, showIfZero: true },
        timeToDispatchSevenToEight: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.seven_to_eight', data: [], extra: 'timeToDispatchSevenToEight', value: 0, showIfZero: true },
        timeToDispatchEightPlus: { name: 'originator_portal.test_emulator.test.bookings_stats.charts.eight_plus', data: [], extra: 'timeToDispatchEightPlus', value: 0, showIfZero: true }
    };

    public getShowPieChartsValue(type: string) {
        let showPieChartsValues = localStorage.getItem('showPieChartsValues');
        if (showPieChartsValues != null) {
            this.showPieChartsValues = JSON.parse(showPieChartsValues);
        }
        return this.showPieChartsValues.find(x => x.type == type)?.value;
    };

    public resetChartData(chartConfigKey: string): void {
        for (const key in this[chartConfigKey]) {
            this[chartConfigKey][key].data = [];
            this[chartConfigKey][key].value = 0;
        };
    };

    public setChartValues(chartConfigKey: string): IChartConfigMap {
        for (const key in this[chartConfigKey]) {
            this[chartConfigKey][key].value = this[chartConfigKey][key].value || this[chartConfigKey][key].data.length;
        };
        this[chartConfigKey] = { ... this[chartConfigKey] };
        return this[chartConfigKey];
    };

    public setShowPieChartsValue(data: IShowPieChartsValues) {
        const index = this.showPieChartsValues.findIndex((e) => e.type === data.type);
        if (index === -1) {
            this.showPieChartsValues.push(data);
        } else {
            this.showPieChartsValues[index] = data;
        }
        localStorage.setItem('showPieChartsValues', JSON.stringify(this.showPieChartsValues));
    };
};