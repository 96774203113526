import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { iGoColourBarComponent } from '../igo-colour-bar/igo-colour-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        iGoColourBarComponent,
        TranslateModule
    ],
    selector: 'form-group-section-header',
    styles: [`
        .form-group-section-header.text-center > div { justify-content: center; }
    `],
    templateUrl: './form-group-section-header.component.html'
})

export class FormGroupSectionHeaderComponent {
    @Input() public class: string = '';
    @Input() public hideColourBar: boolean = false;
    @Input() public title: string = '';
    @Input() public showFaArrowLeft: boolean = false;
    @Input() public showFaArrowRight: boolean = false;
    public constructor() { };
};