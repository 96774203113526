import { Component, Input } from '@angular/core';
import { DisputeView } from 'src/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dispute-status-modal',
    templateUrl: './dispute-status.modal.html',
    styles: [`
        .reason { font-kerning: none; font-size: 14px !important };
    `]
})

export class DisputeStatusModalComponent {
    @Input() public dispute: DisputeView;
    @Input() public windowClass: string;
    public title: string = '';

    public constructor(
        public disputeStatusModal: NgbActiveModal,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        switch (this.dispute.status.toLowerCase()) {
            case 'resolved':
                this.title = this.translateService.instant('global.modals.dispute_status.resolved');
                this.windowClass = 'status-card-success';
                break;
            case 'escalated':
                this.title = this.translateService.instant('global.modals.dispute_status.escalated');
                this.windowClass = 'status-card-danger';
                break;
            case 'open':
                this.title = this.translateService.instant('global.modals.dispute_status.open');
                this.windowClass = 'status-card';
                break;
            default:
                this.title = this.translateService.instant('global.modals.dispute_status');
                this.windowClass = 'status-card';
                break;
        };
    };
};