import { ArrayUtilities } from "src/shared/array.utilities";
import { CXAgentView, CXVendorView } from "./igo-company.model";
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { HalLinksApi } from "./hal-link.model";
import { PartialNameAndId } from "./global.model";


export class AgentCommissionRulesApi {
    public defaultCommission: number;
    public defaultCommissionVAT: number;
    public defaultMinimumAmount: number;

    public constructor(item?: AgentCommissionRulesApi) {
        this.defaultCommission = (item && typeof item.defaultCommission == 'number') ? item.defaultCommission : 0;
        this.defaultCommissionVAT = (item && typeof item.defaultCommissionVAT == 'number') ? item.defaultCommissionVAT : 0;
        this.defaultMinimumAmount = (item && typeof item.defaultMinimumAmount == 'number') ? item.defaultMinimumAmount : 0;
    };
};

export class AgentCommissionRulesView extends AgentCommissionRulesApi {
    public defaultMinimumAmountCurrency: string;

    public constructor(item?: AgentCommissionRulesView) {
        super(item);
        this.defaultMinimumAmountCurrency = (item && item.defaultMinimumAmount) ? new CurrencyGlobalPipe().transform(this.defaultMinimumAmount) : '0';
    };
};

export class GetAllCXMarketplacesResponseApi extends HalLinksApi {
    public marketplaces: CXMarketplaceApi[];

    public constructor(item?: GetAllCXMarketplacesResponseApi) {
        super(item);
        this.marketplaces = (item && item.marketplaces) ? item.marketplaces.map(i => new CXMarketplaceApi(i)) : [];
    };
};

export class GetAllCXMarketplacesResponseView extends GetAllCXMarketplacesResponseApi {
    declare public marketplaces: CXMarketplaceView[];

    public constructor(item?: GetAllCXMarketplacesResponseView) {
        super(item);
        this.marketplaces = (item && item.marketplaces) ? item.marketplaces.map(i => new CXMarketplaceView(i)) : [];
    };
};

export class CXMarketplaceApi extends HalLinksApi {
    public agents: PartialNameAndId[];
    public agentCommissionRules: AgentCommissionRulesApi;
    public name: string;
    public vendors: PartialNameAndId[];

    public constructor(item?: CXMarketplaceApi) {
        super(item);
        this.agents = (item && item.agents) ? item.agents.map(i => new PartialNameAndId(i)) : [];
        this.agentCommissionRules = (item && item.agentCommissionRules) ? new AgentCommissionRulesApi(item.agentCommissionRules) : new AgentCommissionRulesApi();
        this.name = (item && item.name) ? item.name : '';
        this.vendors = (item && item.vendors) ? item.vendors.map(i => new PartialNameAndId(i)) : [];
    };
};

export class CXMarketplaceView extends CXMarketplaceApi {
    declare public agents: CXAgentView[];
    declare public agentCommissionRules: AgentCommissionRulesView;
    declare public vendors: CXVendorView[];

    public constructor(item?: CXMarketplaceView) {
        super(item);
        this.agents = (item && item.agents?.length > 0) ? ArrayUtilities.sortAlphabetically(item.agents.map(i => new CXAgentView(i)), 'displayName') : [];
        this.agentCommissionRules = (item && item.agentCommissionRules) ? new AgentCommissionRulesView(item.agentCommissionRules) : new AgentCommissionRulesView();
        this.vendors = (item && item.vendors?.length > 0) ? ArrayUtilities.sortAlphabetically(item.vendors.map(i => new CXVendorView(i)), 'displayName') : [];
    };
};

export class UpdateCXMarketplacePayload {
    public agents: string[];
    public agentCommissionRules: AgentCommissionRulesApi;
    public name: string;
    public vendors: string[];

    public constructor(item?: CXMarketplaceView) {
        this.agents = (item && item.agents) ? item.agents.map(i => i.id) : [];
        this.agentCommissionRules = (item && item.agentCommissionRules) ? new AgentCommissionRulesApi(item.agentCommissionRules) : null;
        this.name = (item && item.name) ? item.name : '';
        this.vendors = (item && item.vendors) ? item.vendors.map(i => i.id) : [];
    };
};

export class GetConnectedMarketplacesResponseApi {
    public marketplaces: OriginatorMarketplacePartialApi[];

    public constructor(item?: GetConnectedMarketplacesResponseApi) {
        this.marketplaces = (item && item.marketplaces) ? item.marketplaces.map(i => new OriginatorMarketplacePartialApi(i)) : [];
    };
};

export class GetConnectedCXMarketplacesResponseApi {
    public agents: PartialNameAndId[];
    public marketplaces: ConnectedCXMarketplaceApi[];
    public vendors: PartialNameAndId[];

    public constructor(item?: GetConnectedCXMarketplacesResponseApi) {
        this.agents = (item && item.agents) ? item.agents.map(i => new PartialNameAndId(i)) : [];
        this.marketplaces = (item && item.marketplaces) ? item.marketplaces.map(i => new ConnectedCXMarketplaceApi(i)) : [];
        this.vendors = (item && item.vendors) ? item.vendors.map(i => new PartialNameAndId(i)) : [];
    };
};
export class ConnectedCXMarketplaceApi extends HalLinksApi {
    public agents: string[];
    public agentCommissionRules: AgentCommissionRulesApi;
    public name: string;
    public vendors: string[];

    public constructor(item: ConnectedCXMarketplaceApi) {
        super(item);
        this.agents = item.agents;
        this.agentCommissionRules = item.agentCommissionRules;
        this.name = item.name;
        this.vendors = item.vendors;
    };
};

export class OriginatorMarketplacePartialApi extends HalLinksApi {
    public id: string;
    public name: string;

    public constructor(item?: OriginatorMarketplacePartialApi) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CreateCXMarketplacePayload extends CXMarketplaceApi {
    public agentId: string;

    public constructor(item?: CreateCXMarketplacePayload) {
        super(item);

        this.agentId = (item && item.agentId) ? item.agentId : '';
    };
};