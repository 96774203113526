import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { GhostVSTableModule } from '@autocab/ghost-vs-table';
import { NgbModalModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DirectivesModule } from '../directives/directives.module';
import { SharedModule } from 'src/shared/shared.module';
import { ToggleSwitchModule } from '../../shared/components/toggle-switch/toggle-switch.module';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { ActivityBookingModalComponent } from './modals/activity-booking/activity-booking.modal';
import { ActivityDisputeModalComponent } from './modals/activity-dispute/activity-dispute.modal';
import { ActivityDisputeReasonModalComponent } from './modals/activity-dispute-reason/activity-dispute-reason.modal';
import { AlertModalComponent } from './modals/alert/alert.modal';
import { BankAccountInformationModalComponent } from './modals/bank-account-information/bank-account-information.modal';
import { ConfirmationModalComponent } from './modals/confirmation/confirmation.modal';
import { ConfirmWithdrawTransactionModalComponent } from './modals/confirm-withdraw-transaction/confirm-withdraw-transaction.modal';
import { CreateCXMarketplaceModalComponent } from './modals/create-cx-marketplace/create-cx-marketplace.modal';
import { CreateUserModalComponent } from './modals/create-user/create-user.modal';
import { CurrentUserProfileModalComponent } from './modals/current-user-profile/current-user-profile.modal';
import { CXConnectionModalComponent } from './modals/cx-connection/cx-connection.modal';
import { DateRangeTemplateDatesModalComponent } from './modals/date-range-template-dates/date-range-template-dates.modal';
import { DisputeReasonModalComponent } from './modals/dispute-reason/dispute-reason.modal';
import { DisputeStatusModalComponent } from './modals/dispute-status/dispute-status.modal';
import { DocumentSelectionModalComponent } from './modals/document-selection/document-selection.modal';
import { EditCxMarketplaceModalComponent } from './modals/edit-cx-marketplace/edit-cx-marketplace.modal';
import { EditUserModalComponent } from './modals/edit-user/edit-user.modal';
import { FormValidationErrorsModalComponent } from './modals/form-validation-errors/form-validation-errors.modal';
import { InvoiceModalComponent } from './modals/invoice/invoice.modal';
import { ParseJsonInputModalComponent } from './modals/parse-json-input/parse-json-input.modal';
import { RegionModalComponent } from './modals/region/region.modal';
import { SelectionModalComponent } from './modals/selection/selection.modal';
import { SendEmailModalComponent } from './modals/send-email/send-email.modal';
import { SendResetPasswordLinkModalComponent } from './modals/send-reset-password-link/send-reset-password-link.modal';
import { ServerValidationErrorsModalComponent } from './modals/server-validation-errors/server-validation-errors.modal';
import { TestEmulatorTestRuleModalComponent } from './modals/test-emulator-test-rule/test-emulator-test-rule.modal';
import { TestRegionNameModalComponent } from './modals/test-region-name/test-region-name.modal';
import { UnsavedChangesModalComponent } from './modals/unsaved-changes/unsaved-changes.modal';
import { UserRoleModalComponent } from './modals/user-role/user-role.modal';
import { WarningModalComponent } from './modals/warning/warning.modal';

// Standalone Components
import { CommissionBreakdownComponent } from '../../app/standalone/commission-breakdown/commission-breakdown.component';
import { FormGroupSectionHeaderComponent } from '../../app/standalone/form-group-section-header/form-group-section-header.component';
import { ModalHeaderComponent } from '../../app/standalone/modal-header/modal-header.component';
import { PasswordRequirementDetailsComponent } from '../../app/standalone/password-requirement-details/password-requirement-details.component';
import { SidePanelWrapperComponent } from '../../app/standalone/side-panel-wrapper/side-panel-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        CommissionBreakdownComponent,
        DirectivesModule,
        FormsModule,
        FormGroupSectionHeaderComponent,
        GhostVSTableModule,
        ModalHeaderComponent,
        NgbModalModule,
        NgbNavModule,
        NgbTooltipModule,
        NgxDatatableModule,
        PasswordRequirementDetailsComponent,
        ReactiveFormsModule,
        SharedModule,
        SidePanelWrapperComponent,
        ToggleSwitchModule,
        TranslateModule
    ],
    declarations: [
        ActivityBookingModalComponent,
        ActivityDisputeModalComponent,
        ActivityDisputeReasonModalComponent,
        AlertModalComponent,
        BankAccountInformationModalComponent,
        ConfirmationModalComponent,
        CreateCXMarketplaceModalComponent,
        CreateUserModalComponent,
        CurrentUserProfileModalComponent,
        CXConnectionModalComponent,
        ConfirmWithdrawTransactionModalComponent,
        DateRangeTemplateDatesModalComponent,
        DisputeReasonModalComponent,
        DisputeStatusModalComponent,
        DocumentSelectionModalComponent,
        EditCxMarketplaceModalComponent,
        EditUserModalComponent,
        FormValidationErrorsModalComponent,
        InvoiceModalComponent,
        ParseJsonInputModalComponent,
        RegionModalComponent,
        SelectionModalComponent,
        SendEmailModalComponent,
        SendResetPasswordLinkModalComponent,
        ServerValidationErrorsModalComponent,
        TestEmulatorTestRuleModalComponent,
        TestRegionNameModalComponent,
        UnsavedChangesModalComponent,
        UserRoleModalComponent,
        WarningModalComponent
    ],
    providers: [
        NgModel
    ]
})
export class SharedModalsModule { };