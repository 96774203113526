import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    imports: [
        CommonModule
    ],
    selector: 'igo-colour-bar',
    styleUrls: ['igo-colour-bar.component.css'],
    templateUrl: './igo-colour-bar.component.html'
})

export class iGoColourBarComponent {
    @Input() public invert: boolean;
    @Input() public style: 'horizontal' | 'vertical' = 'horizontal';

    public colours: { colour: string; }[] = [
        { colour: 'rgb(var(--igo-originator-blue))' },
        { colour: 'rgb(var(--igo-cyan))' },
        { colour: 'rgb(var(--igo-operator-green))' },
        { colour: 'rgb(var(--primary-white))' },
        { colour: 'rgb(var(--igo-dark-grey))' },
        { colour: '#000000' },
    ];

    public constructor() { };

    public ngOnInit(): void {
        if (this.invert) {
            this.colours.reverse();
        };
    };
};