
import { Injectable } from '@angular/core';
import {
    AuthenticationResponse,
    BookingApi,
    BookingView,
    CreateDisputePayload,
    CreateFundsRequestPayload,
    DisputeApi,
    DisputeEscalatedEventApi,
    DisputeEscalatedEventView,
    DisputeFundsRequestApi,
    DisputeFundsRequestView,
    DisputeMessageApi,
    DisputeMessageView,
    DisputeResolvedEventApi,
    DisputeResolvedEventView,
    DisputeView,
    GetAllBookingsResponseApi,
    GetAllBookingsResponseView,
    GetAllCreditNotesResponseApi,
    GetAllCreditNotesResponseView,
    GetAllDisputesResponseApi,
    GetAllDisputesResponseView,
    GetAllInvoicesResponseApi,
    GetAllInvoicesResponseView,
    GetBookingsPayload
} from 'src/models';
import { ChartsService } from './charts.service';
import { Endpoints } from 'src/shared/endpoints';
import { GainsightService } from './gainsight.service';
import { IChartConfigMap } from 'src/interfaces/charts.interface';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RequestService } from './request.service';
import { UserService } from './user.service';
import { DateUtilities } from 'src/shared/date.utilities';

@Injectable({ providedIn: 'root' })
export class ActivityService {
    public bookings: GetAllBookingsResponseView = null;
    public disputes: GetAllDisputesResponseView = null;

    public constructor(
        private chartService: ChartsService,
        private http: RequestService
    ) { };

    public getBookings = (): GetAllBookingsResponseView => this.bookings;
    public getDisputes = (): GetAllDisputesResponseView => this.disputes;

    public approveFundsRequestAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeFundsRequestView> {
        return this.http.authenticatedPost<DisputeFundsRequestApi>(url, { reason: payload.reason }, {}, 'global.request.approving_fund_request').pipe(
            map(res => new DisputeFundsRequestView(res as DisputeFundsRequestView))
        );
    };

    public cancelFundsRequestAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeFundsRequestView> {
        return this.http.authenticatedPost<DisputeFundsRequestApi>(url, { reason: payload.reason }, {}, 'global.request.cancelling_fund_request').pipe(
            map(res => new DisputeFundsRequestView(res as DisputeFundsRequestView))
        );
    };

    public createDisputeAsync(url: string, payload: CreateDisputePayload): Observable<any> {
        return this.http.authenticatedPost<any>(url, new CreateDisputePayload(payload), {}, 'global.request.creating_dispute').pipe(
            tap(res => {
                res && GainsightService.trackEvent('CreateDispute');
            }),
            map(this.mapDisputeToViewModel)
        );
    };

    public createFundsRequestAsync(url: string, payload: CreateFundsRequestPayload): Observable<DisputeFundsRequestView> {
        return this.http.authenticatedPost<DisputeFundsRequestApi>(url, payload, {}, 'global.request.creating_fund_request').pipe(
            map(res => new DisputeFundsRequestView(res as DisputeFundsRequestView))
        );
    };

    public escalateDisputeAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeEscalatedEventView> {
        return this.http.authenticatedPost<DisputeEscalatedEventApi>(url, { text: payload.reason }, {}, 'global.request.escalating_dispute').pipe(
            map(res => new DisputeEscalatedEventView(res as DisputeEscalatedEventView))
        );
    };

    public filterDisputes(filteredDisputes?: DisputeView[]): IChartConfigMap {
        let disputes = this.disputes.disputes;
        if (filteredDisputes) {
            disputes = filteredDisputes;
        };

        this.chartService.resetChartData('activityDisputesChartConfig');

        disputes.forEach(dispute => {
            //TODO: Complete chart filtering
            this.chartService.activityDisputesChartConfig.all.data.push(dispute);
            if (dispute.resolved) {
                this.chartService.activityDisputesChartConfig.resolved.data.push(dispute);
            } else if (dispute.escalated) {
                this.chartService.activityDisputesChartConfig.escalated.data.push(dispute);
            } else {
                this.chartService.activityDisputesChartConfig.open.data.push(dispute);
            };

            if (dispute.lastMessage) {
                if (dispute.lastMessage.sentByCurrentUserPartner) {
                    this.chartService.activityDisputesChartConfig.lastMessageIsSent.data.push(dispute);
                } else {
                    this.chartService.activityDisputesChartConfig.lastMessageIsReceived.data.push(dispute);
                };
            };
        });

        return this.chartService.setChartValues('activityDisputesChartConfig');
    };

    public formatBookingsSearchPayload(payload: GetBookingsPayload, start: number): Partial<GetBookingsPayload> {
        const payloadCopy = { ...payload, start };
        payloadCopy.from = payload.from && DateUtilities.getMidnightUTC(new Date(payload.from)).toISOString().slice(0, -5);
        payloadCopy.to = payload.to && DateUtilities.getEndOfDayUTC(new Date(payload.to)).toISOString().slice(0, -5);
        return payloadCopy;
    };

    public getBookingByIdObservable(bookingId: string): Observable<BookingView> {
        return this.http.authenticatedGet<BookingApi>(Endpoints.API_URL + Endpoints.BOOKINGS._BOOKINGS + `/${bookingId}`, {}, 'global.request.loading_booking').pipe(
            map(res => this.mapBookingToViewModel(res))
        );
    };

    public getBookingsObservable(payload: GetBookingsPayload, start: number = 0): Observable<GetAllBookingsResponseView> {
        const payloadCopy: Partial<GetBookingsPayload> = this.formatBookingsSearchPayload(payload, start);
        return this.http.authenticatedPost<GetAllBookingsResponseApi>(Endpoints.API_URL + Endpoints.BOOKINGS._BOOKINGS + Endpoints.BOOKINGS._FIND, { bookingFilter: payloadCopy }, {}, 'global.request.loading_bookings').pipe(
            tap(res => {
                res && GainsightService.trackEvent('SearchBookings');
            }),
            map(res => {
                let existingBookings: BookingView[] = [];
                if (start !== 0 && this.bookings?.bookings) {
                    existingBookings = ObjectUtilities.deepCopy(this.bookings?.bookings);
                };
                const bookingsResultView = new GetAllBookingsResponseView(res as GetAllBookingsResponseView);
                this.bookings = ObjectUtilities.deepCopy(bookingsResultView);
                this.bookings.bookings = existingBookings.concat(this.bookings.bookings);

                return bookingsResultView;
            })
        );
    };

    public getBookingsExportObservable(url: string, payload: GetBookingsPayload, start: number = 0): Observable<any> {
        const payloadCopy: Partial<GetBookingsPayload> = this.formatBookingsSearchPayload(payload, start);
        return this.http.authenticatedPost<any>(url, { bookingFilter: payloadCopy }, { responseType: 'raw' }, 'global.request.loading_bookings_export').pipe(
            tap(res => {
                res && GainsightService.trackEvent('ExportBookings');
            })
        );
    };

    public getBookingsCountObservable(payload: GetBookingsPayload, start: number = 0): Observable<number> {
        const payloadCopy: Partial<GetBookingsPayload> = this.formatBookingsSearchPayload(payload, start);
        return this.http.authenticatedPost<{ count: number; }>(Endpoints.API_URL + Endpoints.BOOKINGS._BOOKINGS + Endpoints.BOOKINGS._COUNT, { bookingFilter: payloadCopy }, {}, 'global.request.loading_bookings').pipe(
            map(res => res.count)
        );
    };

    public getCreditNotesByBookingObservable(url: string): Observable<GetAllCreditNotesResponseView> {
        return this.http.authenticatedGet<GetAllCreditNotesResponseApi>(url, {}, 'global.request.loading_credit_notes').pipe(
            map(res => new GetAllCreditNotesResponseView(res as GetAllCreditNotesResponseView))
        );
    };

    public getDisputeAccessTokenObservable(url: string): Observable<AuthenticationResponse> {
        return this.http.authenticatedPost<AuthenticationResponse>(url, {}, {}, 'global.request.loading_live_chat');
    };

    public getDisputeByIdObservable(disputeId: string): Observable<DisputeView> {
        return this.http.authenticatedGet<DisputeApi>(Endpoints.API_URL + Endpoints.DISPUTES._DISPUTES + `/${disputeId}`, {}, 'global.request.loading_dispute').pipe(
            map(this.mapDisputeToViewModel)
        );
    };

    public getDisputesObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllDisputesResponseApi>(url, {}, 'global.request.loading_disputes').pipe(
            map(res => {
                this.disputes = new GetAllDisputesResponseView({
                    disputes: res.disputes.map(this.mapDisputeToViewModel),
                    _links: res._links
                });
            })
        );
    };

    public getDisputesByBookingObservable(url: string): Observable<GetAllDisputesResponseView> {
        return this.http.authenticatedGet<GetAllDisputesResponseApi>(url, {}, 'global.request.loading_disputes').pipe(
            map(res => new GetAllDisputesResponseView({
                disputes: res.disputes.map(this.mapDisputeToViewModel),
                _links: res._links
            }))
        );
    };

    public getInvoicesByBookingObservable(url: string, isIGo: boolean): Observable<GetAllInvoicesResponseView> {
        return this.http.authenticatedGet<GetAllInvoicesResponseApi>(url, {}, 'global.request.loading_invoices').pipe(
            map(res => {
                const invoicesResultView = new GetAllInvoicesResponseView(res as GetAllInvoicesResponseView);
                invoicesResultView.invoices.map(invoice => {
                    invoice.isIGoInvoice = isIGo;
                    return invoice;
                });
                return invoicesResultView;
            })
        );
    };

    public mapDisputeToViewModel = (dispute: DisputeApi): DisputeView => {
        let disputeView: DisputeView = new DisputeView(dispute as DisputeView);
        if (disputeView.lastMessage) {
            const currentPartnerType: string = UserService.getPartnerTypeDetails().type;
            disputeView.lastMessage.sentByCurrentUserPartner = Boolean(disputeView.lastMessage.sender.partnerType.toLowerCase() == currentPartnerType.toLowerCase());
        };
        return disputeView;
    };

    public refundBookingAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeView> {
        return this.http.authenticatedPost<DisputeApi>(url, { reason: payload.reason }, {}, 'global.request.refunding_booking').pipe(
            map(this.mapDisputeToViewModel)
        );
    };

    public rejectFundsRequestAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeFundsRequestView> {
        return this.http.authenticatedPost<DisputeFundsRequestApi>(url, { reason: payload.reason }, {}, 'global.request.rejecting_fund_request').pipe(
            map(res => new DisputeFundsRequestView(res as DisputeFundsRequestView))
        );
    };

    public resolveDisputeAsync(url: string, payload: Partial<CreateFundsRequestPayload>): Observable<DisputeResolvedEventView> {
        return this.http.authenticatedPost<DisputeResolvedEventApi>(url, { text: payload.reason }, {}, 'global.request.resolving_dispute').pipe(
            map(res => new DisputeResolvedEventView(res as DisputeResolvedEventView))
        );
    };

    public sendDisputeMessageAsync(url: string, text: string): Observable<DisputeMessageApi> {
        return this.http.authenticatedPost<DisputeMessageView>(url, { text }, {}, 'global.request.sending_message').pipe(
            map(res => new DisputeMessageView(res))
        );
    };

    private mapBookingToViewModel(booking: BookingApi): BookingView {
        return new BookingView(booking as BookingView);
    };
};