import { HalLinksApi } from "./hal-link.model";
import { iGoCompanyOriginatorPartialView, iGoCompanyOperatorPartialView, iGoCompanyOperatorDetailsApi } from "./igo-company.model";
import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";
import { UserService } from "src/services";
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";

const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';

export class GetCXConnectionsFilters {
    public marketplaceNames: string[];
    public operatorIds: string[];
    public originatorIds: string[];
    public totalFilters: number;

    public getTotalFilters(): void {
        const filterKeys: string[] = ['marketplaceNames', 'operatorIds', 'originatorIds'];
        const keys: string[] = Object.keys(this).filter(k => filterKeys.indexOf(k) >= 0);
        this.totalFilters = 0;
        keys.forEach(key => {
            this[key].length && this.totalFilters++;
        });
    };

    public constructor(item?: GetCXConnectionsFilters) {
        this.marketplaceNames = (item && item.marketplaceNames) ? item.marketplaceNames : [];
        this.operatorIds = (item && item.operatorIds) ? item.operatorIds : [];
        this.originatorIds = (item && item.originatorIds) ? item.originatorIds : [];
        this.getTotalFilters();
    };
};

export class GetAllCXConnectionsResponseApi extends HalLinksApi {
    public connections: CXConnectionApi[];

    public constructor(item: GetAllCXConnectionsResponseApi) {
        super(item);
        this.connections = item.connections || [];
    };
};

export class GetAllCXConnectionsResponseView extends GetAllCXConnectionsResponseApi {
    declare public connections: CXConnectionView[];

    public constructor(item: GetAllCXConnectionsResponseView) {
        super(item);
        this.connections = item.connections || [];
    };
};

export class CXConnectionApi {
    public commission: ConnectionCommissionApi;
    public displayStates: ConnectionDisplayStatesApi;
    public id: string;
    public marketplace: string;
    public operator: CXConnectionOperatorApi;
    public originator: CXConnectionOriginatorApi;
    public state: string;

    public constructor(item?: CXConnectionApi) {
        this.commission = (item && item.commission) ? new ConnectionCommissionApi(item.commission) : new ConnectionCommissionApi();
        this.displayStates = (item && item.displayStates) ? new ConnectionDisplayStatesApi(item.displayStates) : new ConnectionDisplayStatesApi();
        this.id = (item && item.id) ? item.id : '';
        this.marketplace = (item && item.marketplace) ? item.marketplace : '';
        this.operator = (item && item.operator) ? new CXConnectionOperatorApi(item.operator) : null;
        this.originator = (item && item.originator) ? new CXConnectionOriginatorApi(item.originator) : null;
        this.state = (item && item.state) ? item.state : '';
    };
};

export class CXConnectionView extends CXConnectionApi {
    declare public commission: ConnectionCommissionView;
    public index: number;
    declare public operator: CXConnectionOperatorView;
    declare public originator: CXConnectionOriginatorView;
    public stateView: string;
    public uniqueOperatorOriginatorMarketplaceName: string;

    public constructor(item?: CXConnectionView, index?: number) {
        super(item);
        this.commission = (item && item.commission) ? new ConnectionCommissionView(item.commission) : new ConnectionCommissionView();
        this.index = index ? index : (item && typeof item.index == 'number') ? item.index : null;
        this.operator = (item && item.operator) ? new CXConnectionOperatorView(item.operator) : null;
        this.originator = (item && item.originator) ? new CXConnectionOriginatorView(item.originator) : null;
        this.stateView = (item && item.displayStates) ? item.displayStates[UserService.getPartnerTypeDetails().type] : '';
        this.uniqueOperatorOriginatorMarketplaceName = ((item?.operator?.id) + (item?.originator?.id) + (item.marketplace)) || '';
    };
};

export class CXConnectionOperatorApi extends iGoCompanyOperatorPartialView {
    public contact: CXConnectionContactDetailsApi;
    public details: iGoCompanyOperatorDetailsApi;
    declare public id: string;
    declare public name: string;

    public constructor(item?: CXConnectionOperatorApi) {
        super(item);
        this.contact = (item && item.contact) ? new CXConnectionContactDetailsApi(item.contact) : new CXConnectionContactDetailsApi();
        this.details = (item && item.details) ? new iGoCompanyOperatorDetailsApi(item.details) : new iGoCompanyOperatorDetailsApi();
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CXConnectionOperatorView extends CXConnectionOperatorApi {
    declare public contact: CXConnectionContactDetailsView;
    declare public details: iGoCompanyOperatorDetailsApi;

    public constructor(item?: CXConnectionOperatorView) {
        super(item);
        this.contact = (item && item.contact) ? new CXConnectionContactDetailsView(item.contact) : new CXConnectionContactDetailsView();
        this.details = (item && item.details) ? new iGoCompanyOperatorDetailsApi(item.details) : new iGoCompanyOperatorDetailsApi();
    };
};

export class CXConnectionOriginatorApi extends iGoCompanyOriginatorPartialView {
    public contact: CXConnectionContactDetailsApi;
    declare public id: string;
    declare public name: string;

    public constructor(item?: CXConnectionOriginatorApi) {
        super(item);
        this.contact = (item && item.contact) ? new CXConnectionContactDetailsApi(item.contact) : new CXConnectionContactDetailsApi();
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CXConnectionOriginatorView extends CXConnectionOriginatorApi {
    declare public contact: CXConnectionContactDetailsView;

    public constructor(item?: CXConnectionOriginatorView) {
        super(item);
        this.contact = (item && item.contact) ? new CXConnectionContactDetailsView(item.contact) : new CXConnectionContactDetailsView();
    };
};

export class CXConnectionContactDetailsApi {
    public address: string;
    public city: string;
    public country: string;
    public emailAddress: string;
    public facebook: string;
    public telephoneNumber: string;
    public twitter: string;

    public constructor(item?: CXConnectionContactDetailsApi) {
        this.address = (item && item.address) ? item.address : '';
        this.city = (item && item.city) ? item.city : '';
        this.country = (item && item.country) ? item.country : '';
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : '';
        this.facebook = (item && item.facebook) ? item.facebook : '';
        this.telephoneNumber = (item && item.telephoneNumber) ? item.telephoneNumber : '';
        this.twitter = (item && item.twitter) ? item.twitter : '';
    };
};

export class CXConnectionContactDetailsView extends CXConnectionContactDetailsApi {
    public emailAddressList: string[];
    public hasContactDetails: boolean;

    public constructor(item?: CXConnectionContactDetailsView) {
        super(item);
        this.emailAddressList = (item && item.emailAddress) ? item.emailAddress.split(';') : [];
        const contactDetailsKeys = ['emailAddress', 'facebook', 'telephoneNumber', 'twitter'];
        for (let key of contactDetailsKeys) {
            this.hasContactDetails = this.hasContactDetails || Boolean(this[key]);
        };
    };
};

export class ConnectionCommissionApi {
    public defaultCommission: number;
    public defaultCommissionVat: number;
    public defaultCommissionVAT: number;
    public defaultMinimumAmount: number;

    public constructor(item?: ConnectionCommissionApi) {
        this.defaultCommission = (item && typeof item.defaultCommission == 'number') ? item.defaultCommission : 0;
        this.defaultCommissionVat = (item && item.defaultCommissionVat && typeof item.defaultCommissionVat == 'number')
            ? item.defaultCommissionVat : (item && item.defaultCommissionVAT && typeof item.defaultCommissionVAT == 'number') ? item.defaultCommissionVAT : 0;
        this.defaultMinimumAmount = (item && typeof item.defaultMinimumAmount == 'number') ? item.defaultMinimumAmount : 0;
    };
};

export class ConnectionCommissionView extends ConnectionCommissionApi {
    public defaultCommissionView: string;
    public defaultCommissionVatView: string;
    public defaultMinimumAmountView: string;

    public constructor(item?: ConnectionCommissionView) {
        super(item);
        this.defaultCommissionView = (item && typeof item.defaultCommission == 'number') ? `${item.defaultCommission}%` : '';
        this.defaultCommissionVatView = (item && typeof item.defaultCommissionVat == 'number') ? `${item.defaultCommissionVat}%` : this.defaultCommissionVat ? `${this.defaultCommissionVat}%` : '';
        this.defaultMinimumAmountView = (item && typeof item.defaultMinimumAmount == 'number') ? new CurrencyGlobalPipe().transform(item.defaultMinimumAmount) : '';
    };
};

export class ConnectionDisplayStatesApi {
    public Originator: string;
    public Operator: string;
    public IGo: string;

    public constructor(item?: ConnectionDisplayStatesApi) {
        this.Originator = (item && item.Originator) ? item.Originator : '';
        this.Operator = (item && item.Operator) ? item.Operator : '';
        this.IGo = (item && item.IGo) ? item.IGo : '';
    };
};

export class CreateCXConnectionPayloadApi {
    public name: string;
    public operatorId: string;

    public constructor(item?: CreateCXConnectionPayloadApi) {
        this.name = (item && item.name) ? item.name : '';
        this.operatorId = (item && item.operatorId) ? item.operatorId : '';
    };
};

export class CreateCXConnectionPayloadView extends CreateCXConnectionPayloadApi {
    public operatorName: string;

    public constructor(item?: CreateCXConnectionPayloadView) {
        super(item);
        this.operatorName = (item && item.operatorName) ? item.operatorName : '';
    };
};