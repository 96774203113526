import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityBookingsFiltersComponent } from 'src/shared/components/activity-bookings-filters/activity-bookings-filters.component';
import { BookingView, GetAllBookingsResponseView, GetBookingsPayload, InvoiceView } from 'src/models';
import { DateUtilities } from 'src/shared/date.utilities';
import { HttpErrorResponse } from '@angular/common/http';
import { InvoicesService, SharedModalsService } from 'src/services';
import { IBookingModalConfig } from '../activity-booking/activity-booking.modal';
import { InvoiceBookingsTableCustomisation } from './invoice-bookings-table.customisation';
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TableComponent, TableCustomisation } from '@autocab/ghost-vs-table';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'invoice-modal',
    templateUrl: './invoice.modal.html'
})

export class InvoiceModalComponent implements OnInit, AfterViewInit {
    @ViewChild(ActivityBookingsFiltersComponent) public bookingFilters: ActivityBookingsFiltersComponent;
    @ViewChild(TableComponent) public table: TableComponent;
    @Input() public invoice: InvoiceView;
    @Input() public windowClass: string;
    public filters: GetBookingsPayload = new GetBookingsPayload();
    public hideNextPageButton: boolean = true;
    public invoiceBookingsResult: GetAllBookingsResponseView = null;
    public invoiceBookingsTableCustomisation: TableCustomisation<BookingView> = InvoiceBookingsTableCustomisation;
    public invoiceCopy: InvoiceView = null;
    public loading: boolean = false;
    public maxDate: Date;
    public minDate: Date;
    public rows: BookingView[] = [];
    public showFilters: boolean = false;
    public showLoadingMoreSpinner: boolean = false;
    public start: number = 0;
    public tableFooterItems: Array<ITableFooterItem>;
    public tempRows: BookingView[] = [];
    public title: string = '';
    public totalBookings: number = 0;

    private getBookings$: Subject<BookingView[]> = new Subject<BookingView[]>();
    private getBookingsCount$: Subject<number> = new Subject<number>();

    public constructor(
        public invoiceModal: NgbActiveModal,
        private invoicesService: InvoicesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) { };

    public ngOnInit(): void {
        this.getBookings$.pipe(switchMap(() => {
            return this.invoicesService.getBookingsByInvoiceObservable(this.invoice, this.filters, this.start);
        })).subscribe({
            next: this.onInitialiseData,
            error: this.onFailure
        });

        this.getBookingsCount$.pipe(switchMap(() => {
            return this.invoicesService.getInvoiceBookingsCountObservable(this.invoice, this.filters, this.start);
        })).subscribe({
            next: this.setTableFooterItems,
            error: this.onFailure
        });

        this.title = `${this.translateService.instant('global.modals.invoice.invoice')} ${this.invoice.invoiceId}`;
        this.invoiceCopy = ObjectUtilities.deepCopy(this.invoice);
        this.filters.from = new Date(this.invoice.dateRange.from);
        this.filters.to = new Date(this.invoice.dateRange.to);
        this.minDate = this.filters.from;
        this.maxDate = this.filters.to;
    };

    public ngAfterViewInit(): void {
        this.recalculateData(true);
    };

    public onChangeDateRange({ fromDate, toDate }): void {
        this.filters.from = fromDate ? DateUtilities.getMidnight(fromDate) : null;
        this.filters.to = toDate ? DateUtilities.getEndOfDay(toDate) : null;
        this.filters = new GetBookingsPayload(this.filters);
        this.filters.from && this.filters.to && this.onSearch();
    };

    public onClose(): void {
        this.invoiceModal.close();
    };

    public onDismissFiltersPanel(): void {
        if (!ObjectUtilities.equal(new GetBookingsPayload(this.filters), new GetBookingsPayload(this.bookingFilters.filtersCopy))) {
            this.sharedModalsService.showUnsavedChangesModal().result.then(() => {
                this.dismissFiltersPanel();
            }).catch(() => { });
        } else {
            this.dismissFiltersPanel();
        };
    };

    public onReset($event?: GetBookingsPayload): void {
        if ($event) {
            $event.from = new Date(this.invoice.dateRange.from);
            $event.to = new Date(this.invoice.dateRange.to);
            this.filters = new GetBookingsPayload($event);
        };
    };

    public onSearch($event?: GetBookingsPayload): void {
        if ($event) {
            this.filters = new GetBookingsPayload($event);
        };
        this.showFilters = false;
        this.recalculateData(true);
    };

    public onSelect(): void {
        const config: IBookingModalConfig = {
            booking: this.invoiceBookingsTableCustomisation.selected,
            showDisputeOption: false
        };
        this.sharedModalsService.showActivityBookingModal(config, { windowClass: this.windowClass });
    };

    public recalculateData = async (reset: boolean = false): Promise<void> => {
        try {
            this.showLoadingMoreSpinner = !Boolean(reset);
            if (reset) {
                this.loading = true;
                this.start = 0;
                this.getBookingsCount$.next(null);
            };
            this.getBookings$.next(null);
        } catch (error) {
            this.onFailure(error);
        };
    };

    public showEmailInvoiceModal(): void {
        this.invoicesService.showEmailInvoiceModal(this.invoice, { windowClass: this.windowClass }).result.then((email: string) => {
            this.onEmailInvoice(this.invoice, email);
        }).catch(() => { });
    };

    private dismissFiltersPanel(): void {
        this.filters = new GetBookingsPayload(this.filters);
        this.showFilters = false;
    };

    private onEmailInvoice(invoice: InvoiceView, email: string): void {
        const sendEmailHalLink: string = this.invoice.isIGoInvoice ? this.invoice._links.SendIGoInvoiceEmail?.href : this.invoice._links.SendOriginatorInvoiceEmail?.href;
        if (sendEmailHalLink && !invoice.sendingEmail) {
            invoice.sendingEmail = true;
            this.invoicesService.emailInvoiceAsync(sendEmailHalLink, email).subscribe({
                next: () => this.onEmailInvoiceSuccess(invoice, email),
                error: (error: HttpErrorResponse) => {
                    invoice.sendingEmail = false;
                    this.onFailure(error);
                }
            });
        };
    };

    private onEmailInvoiceSuccess = (invoice: InvoiceView, email: string): void => {
        invoice.sendingEmail = false;
        this.sharedModalsService.showAlertModal(
            this.translateService.instant('global.modals.send_email.email_sent'),
            this.translateService.instant('global.modals.send_email.invoice_emailed').replace('{0}', email),
            { windowClass: this.windowClass }
        );
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.invoice.invoice'));
    };

    private onInitialiseData = (res: GetAllBookingsResponseView): void => {
        this.loading = false;
        this.showLoadingMoreSpinner = false;

        this.invoiceBookingsResult = this.invoicesService.getInvoiceBookings();
        this.tempRows = [...this.invoiceBookingsResult.bookings];
        this.rows = [...this.tempRows];

        this.table.onAfterDataInit(() => {
            res.bookings[0] && this.table.selectActiveRow(res.bookings[0], true);
        });

        this.start = this.tempRows.length;
        this.hideNextPageButton = Boolean(this.tempRows.length >= this.invoice.bookingCount);
    };

    private setTableFooterItems = (count: number): void => {
        this.totalBookings = count;
        let bookings = this.totalBookings == this.invoice.bookingCount ? this.totalBookings : this.totalBookings + ' (' + this.invoice.bookingCount + ' Total)';
        this.tableFooterItems = [
            { key: 'global.modals.invoice.table.total_bookings', description: bookings },
            { key: 'global.modals.invoice.table.net_amount', description: this.invoice.netAmountCurrency },
            { key: 'global.modals.invoice.table.gross_amount', description: this.invoice.grossAmountCurrency },
            { key: 'global.modals.invoice.table.invoice_date', description: this.invoice.dateView },
            { key: 'global.modals.invoice.table.invoice_period', description: `${this.invoice.dateRangeView.dateRangeFromToView}` }
        ];
    };
};