import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { CellClickEvent, TableComponent, TableCustomisation } from '@autocab/ghost-vs-table';
import { CXAgentView, CXMarketplaceView, CXVendorView } from 'src/models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GhostVSTableService, MarketplacesService, SettingsService, SharedModalsService } from 'src/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ITableFooterItem } from 'src/shared/components/ghost-vs-table-footer/ghost-vs-table-footer.component';
import { MarketplaceOperatorsTableCustomisation, MarketplaceOriginatorsTableCustomisation } from './edit-cx-marketplace-table.customisation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadInputComponent } from 'src/shared/components/typeahead-input/typeahead-input.component';

@Component({
    selector: 'edit-cx-marketplace-modal',
    templateUrl: './edit-cx-marketplace.modal.html',
    styleUrls: ['edit-cx-marketplace.modal.css']
})

export class EditCxMarketplaceModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('marketplaceOperatorsTable') public marketplaceOperatorsTable: TableComponent;
    @ViewChild('marketplaceOriginatorsTable') public marketplaceOriginatorsTable: TableComponent;
    @ViewChild('cxMarketplaceOriginatorTypeahead') cxMarketplaceOriginatorTypeahead: TypeaheadInputComponent;
    @ViewChild('cxMarketplaceOperatorTypeahead') cxMarketplaceOperatorTypeahead: TypeaheadInputComponent;
    @Input() public allOperators: CXVendorView[] = [];
    @Input() public allOriginators: CXAgentView[] = [];
    @Input() public marketplace: CXMarketplaceView;

    public currencyCode: string = null;
    public form: UntypedFormGroup = null;
    public marketplaceCopy: CXMarketplaceView;
    public selectedOperatorToAdd: CXVendorView;
    public selectedOriginatorToAdd: CXAgentView;

    public marketplaceOperatorsTableCustomisation: TableCustomisation<CXVendorView> = MarketplaceOperatorsTableCustomisation;
    public marketplaceOriginatorsTableCustomisation: TableCustomisation<CXAgentView> = MarketplaceOriginatorsTableCustomisation;
    public marketplaceOriginatorsTableFooterItems: Array<ITableFooterItem>;
    public marketplaceOperatorsTableFooterItems: Array<ITableFooterItem>;

    private operatorClickEventSubscription: Subscription;
    private originatorClickEventSubscription: Subscription;
    private noDataChange = (): boolean => !this.form.dirty && ObjectUtilities.equal(new CXMarketplaceView(this.marketplace), new CXMarketplaceView(this.marketplaceCopy));

    public constructor(
        public editMarketplaceModal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private ghostVSTableService: GhostVSTableService,
        private marketplacesService: MarketplacesService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
    ) {
        this.currencyCode = SettingsService.getCurrencyCode();
    };

    public ngOnInit(): void {
        this.marketplaceCopy = ObjectUtilities.deepCopy(this.marketplace);
        this.form = this.buildForm();
    };

    public ngAfterViewInit(): void {
        this.marketplaceOperatorsTable?.resetTableCustomisation();
        this.marketplaceOriginatorsTable?.resetTableCustomisation();
        this.operatorClickEventSubscription = this.marketplaceOperatorsTable?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<CXVendorView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'remove' && this.onRemoveOperator(clickEvent.row);

            }
        });
        this.originatorClickEventSubscription = this.marketplaceOriginatorsTable?.cellClick.subscribe({
            next: (clickEvent: CellClickEvent<CXAgentView>) => {
                clickEvent.event.stopPropagation();
                clickEvent.columnKey === 'remove' && this.onRemoveOriginator(clickEvent.row);
            }
        });
        this.initialiseData();
    };

    public ngOnDestroy(): void {
        this.operatorClickEventSubscription?.unsubscribe();
        this.originatorClickEventSubscription?.unsubscribe();
    };

    public async onAddAgentOrVendor(type: string): Promise<void> {
        if (type == 'vendor' && this.selectedOperatorToAdd) {
            this.marketplaceCopy.vendors.push(this.selectedOperatorToAdd);
            this.marketplaceCopy.vendors = [...this.marketplaceCopy.vendors];
            const index = this.allOperators.indexOf(this.selectedOperatorToAdd);
            this.allOperators.splice(index, 1);
            this.showOperatorInTable();
            this.cxMarketplaceOperatorTypeahead.initialiseFilteredOptions();
        } else if (this.selectedOriginatorToAdd) {
            this.marketplaceCopy.agents.push(this.selectedOriginatorToAdd);
            this.marketplaceCopy.agents = [...this.marketplaceCopy.agents];
            const index = this.allOriginators.indexOf(this.selectedOriginatorToAdd);
            this.allOriginators.splice(index, 1);
            this.selectedOriginatorToAdd = null;
            this.showOriginatorInTable();
            this.cxMarketplaceOriginatorTypeahead.initialiseFilteredOptions();
        };
    };

    public onRemoveOperator(operator: CXVendorView): void {
        this.updateOperatorRowById(operator);
    };

    public onRemoveOriginator(originator: CXAgentView): void {
        this.updateOriginatorRowById(originator);
    };

    public onSave(): void {
        if (this.marketplace._links.UpdateCabExchangeMarketplace) {
            // agentCommissionRules are passed as null so they are not cleared on the back-end.
            // These fields are readonly on the front-end.
            this.marketplaceCopy.agentCommissionRules = null;
            this.marketplacesService.updateMarketplaceAsync(this.marketplaceCopy).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        };
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'global.modals.edit_marketplace.form.name': [this.marketplaceCopy.name],
            'global.modals.edit_marketplace.form.agent_commission_rules.default_commission': [this.marketplaceCopy.agentCommissionRules.defaultCommission, [Validators.required, Validators.min(0), Validators.max(100)]],
            'global.modals.edit_marketplace.form.agent_commission_rules.default_commission_vat': [this.marketplaceCopy.agentCommissionRules.defaultCommissionVAT, [Validators.required, Validators.min(0), Validators.max(100)]],
            'global.modals.edit_marketplace.form.agent_commission_rules.default_minimum_amount': [this.marketplaceCopy.agentCommissionRules.defaultMinimumAmount, [Validators.required]],
        });
    };

    private initialiseData(): void {
        this.marketplaceCopy.vendors.forEach((item) => {
            const index = this.allOperators.findIndex(operator => operator.id === item.id);
            this.allOperators.splice(index, 1);
        });
        this.initialiseOperatorsTypeahead();

        this.marketplaceCopy.agents.forEach((item) => {
            const index = this.allOriginators.findIndex(originators => originators.id === item.id);
            this.allOriginators.splice(index, 1);
        });
        this.initialiseOriginatorsTypeahead();
        this.marketplaceOperatorsTableFooterItems = [{ key: 'igo_portal.companies.operators.table.total_operators', description: this.marketplaceCopy.vendors.length }];
        this.marketplaceOriginatorsTableFooterItems = [{ key: 'global.tables.igo_portal.companies.originators.total_originators', description: this.marketplaceCopy.agents.length }];
    };

    private initialiseOriginatorsTypeahead(): void {
        this.allOriginators = ArrayUtilities.sortAlphabetically(this.allOriginators, 'displayName');
        this.cxMarketplaceOriginatorTypeahead.initialiseFilteredOptions();
    };

    private initialiseOperatorsTypeahead(): void {
        this.allOperators = ArrayUtilities.sortAlphabetically(this.allOperators, 'displayName');
        this.cxMarketplaceOperatorTypeahead.initialiseFilteredOptions();
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.edit_marketplace.edit_marketplace'));
    };

    private async showOperatorInTable(): Promise<void> {
        await this.marketplaceOperatorsTable.setRowsCollection(this.marketplaceCopy.vendors, this.marketplaceCopy.vendors, true);
        const timeout = setTimeout(() => {
            this.marketplaceOperatorsTable.scrollToRow(this.selectedOperatorToAdd, false);
            this.marketplaceOperatorsTable.selectActiveRow(this.selectedOperatorToAdd);
            this.selectedOperatorToAdd = null;
            clearTimeout(timeout);
        });
    };

    private async showOriginatorInTable(): Promise<void> {
        await this.marketplaceOriginatorsTable.setRowsCollection(this.marketplaceCopy.agents, this.marketplaceCopy.agents, true);
        const timeout = setTimeout(() => {
            this.marketplaceOriginatorsTable.scrollToRow(this.selectedOriginatorToAdd, false);
            this.marketplaceOriginatorsTable.selectActiveRow(this.selectedOriginatorToAdd);
            this.selectedOriginatorToAdd = null;
            clearTimeout(timeout);
        });
    };

    private updateOperatorRowById(vendor: CXVendorView): void {
        this.ghostVSTableService.handleRowDelete<CXVendorView>(vendor.id, this.marketplaceOperatorsTable, this.marketplaceCopy.vendors, this.marketplaceCopy.vendors);
        this.marketplaceCopy.vendors = [...this.marketplaceCopy.vendors];
        this.allOperators.push(vendor);
        this.allOperators = ArrayUtilities.sortAlphabetically(this.allOperators, 'displayName');
        this.initialiseOperatorsTypeahead();
    };

    private updateOriginatorRowById(originator: CXAgentView): void {
        this.ghostVSTableService.handleRowDelete<CXAgentView>(originator.id, this.marketplaceOriginatorsTable, this.marketplaceCopy.agents, this.marketplaceCopy.agents);
        this.marketplaceCopy.agents = [...this.marketplaceCopy.agents];
        this.allOriginators.push(originator);
        this.allOriginators = ArrayUtilities.sortAlphabetically(this.allOriginators, 'displayName');
        this.initialiseOriginatorsTypeahead();
    };

    public onSelectOriginatorTypeahead($event) {
        this.selectedOriginatorToAdd = $event;
    };

    public onSelectOperatorTypeahead($event) {
        this.selectedOperatorToAdd = $event;
    };

    private onSuccess = (res: CXMarketplaceView): void => this.editMarketplaceModal.close(res);
};