import { Injectable, Injector } from '@angular/core';
import { Endpoints } from '../shared/endpoints';
import { GhostTranslationService } from './ghost-translation.service';
import { iGoCompaniesService } from './igo-companies.service';
import { iGoSession, AuthenticationResponse, DecodedJwtToken } from '../models/session.model';
import { interval, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { iGoUserApi, iGoUserView } from 'src/models';
import { UsersService } from './users.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public authenticated = false;
    public sessionStorageKey = 'igo_session';
    public static PasswordRegex = new RegExp('^(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[0-9]{1,})(?=.*[!@#$&*]{1,}).{8,}$');
    public constructor(
        private http: RequestService,
        private injector: Injector,
        private router: Router,
        private userService: UserService,
        private usersService: UsersService
    ) {
        SessionService.session = new iGoSession(StorageService.getItem(this.sessionStorageKey));
        if (SessionService.session && SessionService.session.token && !this.isTokenExpired()) {
            this.authenticated = true;
            this.runTokenValidatation();
        } else {
            this.clearLocalStorage();
        };
    };

    public authenticateUser(res: AuthenticationResponse, apiUrl: string): void {
        SessionService.session = new iGoSession({
            apiUrl: apiUrl || Endpoints.API_URL,
            token: res.access_token,
            tokenExpiry: res.expiry_in,
            tokenType: res.token_type,
        });
        StorageService.setItem(this.sessionStorageKey, SessionService.session);
        this.authenticated = true;
        this.runTokenValidatation();
        this.userService.getCurrentUserObservable().subscribe({
            next: () => {
                this.navigateCurrentUser();
            }
        });
    };

    public parseToken = (token: string): DecodedJwtToken => {
        var base64 = token.split('.')[1];
        return JSON.parse(window.atob(base64.replace('-', '+').replace('_', '/')));
    };

    public resetUserPasswordAsync(payload: { NewPassword: string, ConfirmNewPassword: string; }): Observable<iGoUserView> {
        return this.http.authenticatedPut<iGoUserApi>(Endpoints.API_URL + Endpoints.USERS._USERS + Endpoints.USERS._PASSWORD_RESET, payload).pipe(
            map(this.usersService.mapUserToViewModel)
        );
    };

    public sendResetPasswordLinkAsync(to: string): Observable<void> {
        return this.http.post(Endpoints.API_URL + Endpoints.USERS._USERS + Endpoints.USERS._SEND_PASSWORD_RESET, { to });
    };

    public unauthenticateUser(redirectToLogin?: boolean): void {
        redirectToLogin ? this.router.navigate(['/login']) : null;
        this.clearLocalStorage();
        SessionService.session = null;
        this.injector.get(GhostTranslationService).setSystemLanguageTranslation().subscribe();
        this.injector.get(UserService).setCurrentUser(null);
        this.authenticated = false;
    };

    private clearLocalStorage(): void {
        StorageService.deleteItem(this.sessionStorageKey);
        StorageService.deleteItem('languageCode');
        StorageService.deleteItem('originator');
        StorageService.deleteItem('operator');
        sessionStorage.removeItem('originator');
        sessionStorage.removeItem('operator');
    };

    private isTokenExpired(): boolean {
        var token = SessionService.getToken();
        if (token) {
            try {
                var jwt = this.parseToken(token);
                if (jwt && jwt.exp) {
                    var expiryDate = new Date(jwt.exp * 1000);
                    if (expiryDate < new Date(new Date().getTime() + (30 * 60000)) && expiryDate < new Date()) {
                        return true;
                    };
                };
            } catch (err) {
                return false;
            };
        };
        return false;
    };

    private navigateCurrentUser = async (): Promise<void> => {
        if (UserService.currentUser.isIgo) {
            const iGoOriginator = await lastValueFrom(this.injector.get(iGoCompaniesService).getGlobalOriginatorObservable());

            iGoOriginator && this.injector.get(iGoCompaniesService).cacheCurrentOriginator(iGoOriginator);
            this.router.navigate(['/igoportal/network']);
        } else {
            this.router.navigate(['/portals']);
        };
    };

    private renewToken(): void {
        this.injector.get(RequestService).authenticatedPost<AuthenticationResponse>(Endpoints.API_URL + Endpoints.AUTH._AUTH + Endpoints.AUTH._RENEW, {}).subscribe(
            res => {
                if (res && res.access_token) {
                    SessionService.setToken(res.access_token);
                    StorageService.setItem(this.sessionStorageKey, { ...StorageService.getItem(this.sessionStorageKey), ...res });
                };
            },
            err => {
                console.log('Renew token error: ', err);
            }
        );
    };

    private runTokenValidatation(): void {
        interval(1000).subscribe({
            next: () => {
                if (this.authenticated && this.shouldRenewToken()) {
                    this.renewToken();
                };
            },
            error: (err: any) => console.log('Error: ' + err),
            complete: () => console.log('Completed'),
        });
    };

    private shouldRenewToken(): boolean {
        var token = SessionService.getToken();
        if (token) {
            try {
                var jwt = this.parseToken(token);
                if (jwt && jwt.exp) {
                    var expiryDate = new Date(jwt.exp * 1000);
                    if (expiryDate < new Date(new Date().getTime() + (30 * 60000)) && expiryDate > new Date()) {
                        return true;
                    };
                };
            } catch (err) {
                return false;
            };
        };
        return false;
    };
};