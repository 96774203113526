import { Injectable } from "@angular/core";
import { ArrayUtilities } from "../shared/array.utilities";
import { TableCustomisation } from "@autocab/ghost-vs-table";

@Injectable({ providedIn: 'root' })
export class FilterService {
    constructor() { };

    public filterGhostVSTableResults<T, K extends keyof T>(extendedSearch, searchTerm, tempRows, tableCustomisation: TableCustomisation<T>): T[] {
        let columnProps = [];
        if (extendedSearch) {
            columnProps = Object.values(tableCustomisation.defaultColumns).map(column => column.prop);
        } else {
            if (tableCustomisation.customisedColumns) {
                Object.keys(tableCustomisation.customisedColumns).forEach(key => columnProps.push(tableCustomisation.defaultColumns[key].prop));
            } else {
                Object.values(tableCustomisation.defaultColumns).forEach(column => column.show && columnProps.push(column.prop));
            };
        };

        return ArrayUtilities.filterMultipleProperties<T, K>(tempRows, columnProps, searchTerm);
    };
};