import { iGoCompanyOperatorPartialView, iGoCompanyOriginatorView } from "./igo-company.model";

export class PortalItem {
    public class: string;
    public description: string;
    public name: string;
    public options: iGoCompanyOperatorPartialView[] | iGoCompanyOriginatorView[]
    public route: string;
    public selected: boolean;

    public constructor(item: PortalItem) {
        this.class = (item && item.class) ? item.class : '';
        this.description = (item && item.description) ? item.description : '';
        this.name = (item && item.name) ? item.name : '';
        this.options = (item && item.options) ? item.options : [];
        this.route = (item && item.route) ? item.route : '';
        this.selected = (item && item.selected) ? true : false;
    };
};