// Modules
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DirectivesModule } from '../directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GhostVSTableConfig } from '../../shared/ghost-vs-table/ghost-vs-table.config';
import { GhostVSTableModule } from '@autocab/ghost-vs-table';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpInterceptorService } from 'src/services/http-interceptor.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocaleModule } from '../locale/locale.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
    NgbAlertModule,
    NgbDateAdapter,
    NgbDateNativeAdapter,
    NgbDateParserFormatter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbRatingModule,
    NgbNavModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from 'src/shared/shared.module';
import { SharedModalsModule } from '../shared-modals/shared-modals.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DateFormatterService } from 'src/services';
import { UnsavedChangesGuard } from '../../shared/guards/unsaved-changes-route.guard';

// Components
import { AccessCompanyProfileCellTplComponent } from '../../shared/table-cell-templates/access-company-profile-cell-template.component';
import { AddToRowCellTplComponent } from '../../shared/table-cell-templates/add-to-row-cell-template.component';
import { BookingStatusCellTplComponent } from 'src/shared/table-cell-templates/booking-status-cell-template.component';
import { BooleanCellTplComponent } from '../../shared/table-cell-templates/boolean-cell-template.component';
import { CheckBoxCellTplComponent } from '../../shared/table-cell-templates/check-box-cell-template.component';
import { DeleteRowCellTplComponent } from '../../shared/table-cell-templates/delete-row-cell-template.component';
import { DisputeLastMessageCellTplComponent } from 'src/shared/table-cell-templates/dispute-last-message-cell-template.component';
import { DisputeStatusCellTplComponent } from 'src/shared/table-cell-templates/dispute-status-cell-template.component';
import { LoadingComponent } from '../../loading/loading.component';
import { LoginComponent } from '../../login/login.component';
import { LogoCellTplComponent } from '../../shared/table-cell-templates/logo-cell-template.component';
import { RemoveRowCellTplComponent } from '../../shared/table-cell-templates/remove-row-cell-template.component';
import { SendEmailCellTplComponent } from '../../shared/table-cell-templates/send-email-cell-template.component';
import { TestEmulatorUpdateStatusCellTplComponent } from '../../shared/table-cell-templates/test-emulator-update-status-cell-template.component';
import { UserStatusCellTplComponent } from 'src/shared/table-cell-templates/user-status-cell-template.component';
import { ValidationPopoverComponent } from '../../shared/components/validation/validation-popover.component';

// Standalone Components
import { StackedArrowRightIconsComponent } from 'src/app/standalone/stacked-arrow-right-icons/stacked-arrow-right-icons.component';

@NgModule({
    imports: [
        ClipboardModule,
        DirectivesModule,
        FormsModule,
        LocaleModule,
        GhostVSTableModule.forRoot(GhostVSTableConfig),
        HttpClientModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        NgbAlertModule,
        NgbDatepickerModule,
        NgbModalModule,
        NgbDropdownModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbRatingModule,
        NgbNavModule,
        NgbTooltipModule,
        NgxChartsModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        SharedModule,
        SharedModalsModule,
        StackedArrowRightIconsComponent,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './app/shared/assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        AccessCompanyProfileCellTplComponent,
        AddToRowCellTplComponent,
        BooleanCellTplComponent,
        BookingStatusCellTplComponent,
        CheckBoxCellTplComponent,
        DeleteRowCellTplComponent,
        DisputeLastMessageCellTplComponent,
        DisputeStatusCellTplComponent,
        LoadingComponent,
        LoginComponent,
        LogoCellTplComponent,
        RemoveRowCellTplComponent,
        SendEmailCellTplComponent,
        TestEmulatorUpdateStatusCellTplComponent,
        UserStatusCellTplComponent,
        ValidationPopoverComponent,
    ],
    exports: [
        // Modules
        FormsModule,
        ReactiveFormsModule,
        // Components
        LoadingComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: NgbDateParserFormatter, useClass: DateFormatterService },
        UnsavedChangesGuard,
    ]
})
export class CoreModule { };