import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationResponse } from 'src/models';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationService, GhostTranslationService, RequestService, SharedModalsService } from '../services';
import { Endpoints } from '../shared/endpoints';
import { environment } from 'src/environments/environment';
import { filter, first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { NgForm } from '@angular/forms';
import { Subject, Subscription, lastValueFrom, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, AfterViewInit {
    @ViewChild('form') public form: NgForm;
    @ViewChild('emailInputField') public emailInputField: ElementRef;
    @ViewChild('passwordInputField') public passwordInputField: ElementRef;
    public apiUrl: string = '';
    public apiUrls: string[] = [
        'https://localhost:5001',
        environment.apiUrl
    ];
    public authenticated: boolean = false;
    public environment: any = environment;
    public loggingIn: boolean = false;
    public model = {
        credentials: {
            Email: null,
            Password: null,
        },
        error: null
    };

    private _LOGIN_URL: string = '';
    private attemptingAutoLogin: boolean = false;
    private queryParamsSubscription: Subscription;
    private sendingPasswordLink: boolean = false;
    private readonly _destroying$ = new Subject<void>();

    // User lockout options
    public lockedOut = false;
    public userLockedOutCounterError = false;
    public countDown: Subscription;
    private defaultLockoutTimeSpan = 15; //minutes;
    private timeCounter: number = 0;

    public constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authenticationService: AuthenticationService,
        private azureAuthService: MsalService,
        private azureBroadcastService: MsalBroadcastService,
        private ghostTranslationService: GhostTranslationService,
        private requestService: RequestService,
        private route: ActivatedRoute,
        private sharedModalsService: SharedModalsService,
        private translate: TranslateService
    ) {
        this.apiUrl = environment.apiUrl;
    };

    public async ngOnInit(): Promise<void> {
        this.authenticationService.unauthenticateUser();
        this.ghostTranslationService.setDefaultLanguageTranslation().subscribe();

        this.azureBroadcastService.msalSubject$.pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            takeUntil(this._destroying$)
        ).subscribe({
            next: (result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.azureAuthService.instance.setActiveAccount(payload.account);
            }
        });

        this.azureBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        ).subscribe({
            // Only need to handle this when using "Redirect" authentication
            next: () => {
                // Set the active account
                const activeAccount = this.azureAuthService.instance.getActiveAccount();
                if (!activeAccount && this.azureAuthService.instance.getAllAccounts().length > 0) {
                    const accounts = this.azureAuthService.instance.getAllAccounts();
                    this.azureAuthService.instance.setActiveAccount(accounts[0]);
                };

                // Get the token and authenticate the user
                activeAccount && this.azureAuthService.instance.acquireTokenSilent({
                    scopes: [`${environment.azureClientURI}/IGo.Engineer`],
                    account: this.azureAuthService.instance.getAllAccounts()[0]
                }).then(authResult => {
                    authResult?.accessToken && this.authenticateUser(authResult.accessToken);
                });
            }
        });

        await lastValueFrom(this.azureAuthService.initialize());
    };

    public ngAfterViewInit(): void {
        if (!this.attemptingAutoLogin) {
            this.emailInputField.nativeElement.focus();
            this.queryParamsSubscription = this.route.queryParams.subscribe({
                next: (queryParams: Params) => {
                    if (queryParams.email) {
                        setTimeout(() => {
                            this.model.credentials.Email = queryParams.email;
                            this.model.credentials.Password = '';
                        }, 1000);
                    };
                }
            });
        };
    };

    public ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
        if (this.countDown) {
            this.countDown.unsubscribe();
        };
    };

    public onLogin(): void {
        if (this.model.credentials.Email.includes('@autocab.dev')) {
            this.login(true);
        } else {
            this.isValidModel() && this.login();
        };
    };

    public login(engineer?: boolean): void {
        if (engineer) {
            this.loginWithAzure();
        } else {
            this.authenticateUser();
        };
    };

    public onKeyDown($event: KeyboardEvent): void {
        if ($event.which === 13 && (<HTMLInputElement>$event.target).id === 'password') {
            this.onLogin();
        };
    };

    public onSelectApiUrl($event: MatSelectChange): void {
        if (environment.showDeveloperTools) {
            this.apiUrl = $event?.value;
        };
    };

    public isValidModel = (): boolean => this.model.credentials.Email && this.model.credentials.Password;

    public sendResetPasswordLink(): void {
        if (!this.sendingPasswordLink) {
            if (this.model.credentials.Email) {
                this.sendingPasswordLink = true;
                this.authenticationService.sendResetPasswordLinkAsync(this.model.credentials.Email).subscribe({
                    next: () => this.showResetPasswordLinkSentConfirmation(this.model.credentials.Email),
                    error: this.onFailure
                }).add(() => {
                    this.sendingPasswordLink = false;
                });
            } else {
                this.sharedModalsService.showSendResetPasswordLinkModal().result.then((username: string) => {
                    this.showResetPasswordLinkSentConfirmation(username);
                }).catch((error) => {
                    error && this.onFailure(error);
                });
            };
        };
    };

    private authenticateUser(accessToken?: string): void {
        this.loggingIn = true;
        this.model.error = null;
        if (accessToken) {
            this._LOGIN_URL = this.apiUrl + Endpoints.AUTH._AUTH + Endpoints.AUTH._AZURE_LOGIN;
            this.requestService.post(this._LOGIN_URL, this.model.credentials, {
                headers: { 'AzureAuthentication': accessToken }
            }).pipe(first()).subscribe({
                next: this.onLoginSuccess,
                error: this.onLoginFailure
            });
        } else {
            this._LOGIN_URL = this.apiUrl + Endpoints.AUTH._AUTH + Endpoints.AUTH._LOGIN;
            this.requestService.post(this._LOGIN_URL, this.model.credentials).pipe(first()).subscribe({
                next: this.onLoginSuccess,
                error: this.onLoginFailure
            });
        };
    };

    private loginWithAzure(): void {
        if (this.msalGuardConfig.authRequest) {
            this.azureAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.azureAuthService.loginRedirect();
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.sharedModalsService.showServerValidationErrorsModal(res);
    };

    private onLoginFailure = (res): void => {
        // If user locked out from backend 
        if (res.status === 400 && res.type === "UserLockedOutException") {
            this.userLockedOutCounterError = true;
            //Backend return a number in title string here we can get that number and assigned to our frontend lockout time span
            //With this time span we disable login button and start a count down timer, after timeout button will enable
            this.defaultLockoutTimeSpan = res.title.match(/\d+/)[0];
            this.lockOutUser();
        };

        if (res.detail) {
            this.model.error = res.detail;
        } else if (res && res.status && res.status === 401) {
            this.model.error = 'Incorrect username or password';
        } else if (res && res.message) {
            this.model.error = res.message;
        } else {
            this.model.error = 'Server is Unavailable';
        };
        this.loggingIn = false;
        setTimeout(() => {
            this.passwordInputField.nativeElement.focus();
        });
    };

    private onLoginSuccess = (res: AuthenticationResponse): void => {
        this.authenticated = true;
        this.authenticationService.authenticateUser(res, this.apiUrl);
    };

    private showResetPasswordLinkSentConfirmation(username: string) {
        this.sharedModalsService.showAlertModal(
            this.translate.instant('login.form.reset_password'),
            this.translate.instant('login.form.reset_password_sent').replace('{0}', username),
            { windowClass: 'originator' }
        );
    };

    private lockOutUser() {
        this.lockedOut = true;
        this.timeCounter = this.defaultLockoutTimeSpan * 60;
        this.countDown = timer(0, 1000).subscribe({
            next: () => {
                if (this.timeCounter > 0) {
                    --this.timeCounter;
                } else {
                    this.defaultLockoutTimeSpan = 15;
                    this.lockedOut = false;
                    this.model.error = null;
                    this.userLockedOutCounterError = false;
                    this.countDown.unsubscribe();
                };
            }
        });
    };
};
