import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { iGoCompanyOperatorView, iGoCompanyOriginatorView } from 'src/models';

@Component({
    selector: 'company-originators-or-operators-modal',
    styleUrls: ['company-originators-or-operators-modal.css'],
    templateUrl: './company-originators-or-operators.modal.html'
})

export class CompanyOriginatorsOrOperatorsModalComponent {
    @Input() public options: iGoCompanyOperatorView[] | iGoCompanyOriginatorView[];
    @Input() public title: string = '';

    public constructor(public companyOriginatorsOrOperatorsModal: NgbActiveModal) { };

    public onAccessProfile(selected: iGoCompanyOperatorView | iGoCompanyOriginatorView): void {
        this.companyOriginatorsOrOperatorsModal.close(selected);
    };
};