import { Injectable } from '@angular/core';
import {
    CreateCXConnectionPayloadApi,
    CreateCXConnectionPayloadView,
    CXConnectionApi,
    CXConnectionView,
    GetAllCXConnectionsResponseApi,
    GetAllCXConnectionsResponseView,
    GetCXConnectionsFilters,
    iGoCompanyOperatorPartialView,
    iGoCompanyOriginatorPartialView,
} from 'src/models';
import { ChartsService } from './charts.service';
import { Endpoints } from 'src/shared/endpoints';
import { IChartConfigMap } from 'src/interfaces/charts.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'root' })
export class ConnectionsService {
    public iGoActiveConnections: GetAllCXConnectionsResponseView = null;
    public operatorActiveConnections: GetAllCXConnectionsResponseView = null;
    public originatorActiveConnections: GetAllCXConnectionsResponseView = null;

    // CX Connections
    public allCXMarketplaces: string[] = [];
    public allCXOperators: iGoCompanyOperatorPartialView[] = [];
    public allCXOriginators: iGoCompanyOriginatorPartialView[] = [];

    public constructor(
        private chartService: ChartsService,
        private http: RequestService
    ) { };

    // CX Connections
    public getAllCXMarketplaces = (): string[] => this.allCXMarketplaces;
    public getAllCXOperators = (): iGoCompanyOperatorPartialView[] => this.allCXOperators;
    public getAllCXOriginators = (): iGoCompanyOriginatorPartialView[] => this.allCXOriginators;

    public getIGoActiveConnections = (): GetAllCXConnectionsResponseView => this.iGoActiveConnections;
    public getOperatorActiveConnections = (): GetAllCXConnectionsResponseView => this.operatorActiveConnections;
    public getOriginatorActiveConnections = (): GetAllCXConnectionsResponseView => this.originatorActiveConnections;

    public createCXConnectionAsync(payload: CreateCXConnectionPayloadView): Observable<CXConnectionView[]> {
        return this.http.authenticatedPost<{ connections: CXConnectionApi[]; }>(Endpoints.API_URL + Endpoints.CONNECTIONS._ADD_CX_CONNECTION, new CreateCXConnectionPayloadApi(payload), {}, 'global.request.creating_connection').pipe(
            map(res => res.connections.map(this.mapCXConnectionToViewModel))
        );
    };

    public filterCXConnectionsByFilterOptions(connections: CXConnectionView[], filters: GetCXConnectionsFilters): CXConnectionView[] {
        const connectionsCopy = [...connections];
        return connectionsCopy.filter(connection => {
            let matchedFilters: boolean = false;
            if (filters.totalFilters == 0) {
                return connection;
            } else {
                if (filters.marketplaceNames.length) {
                    matchedFilters = filters.marketplaceNames.indexOf(connection.marketplace) >= 0;
                    if (!matchedFilters) return;
                };
                if (filters.operatorIds.length) {
                    matchedFilters = filters.operatorIds.indexOf(connection.operator.id) >= 0;
                    if (!matchedFilters) return;
                };
                if (filters.originatorIds.length) {
                    matchedFilters = filters.originatorIds.indexOf(connection.originator.id) >= 0;
                    if (!matchedFilters) return;
                };
                return matchedFilters;
            };
        });
    };

    public filterIGoNetwork(filteredConnections?: CXConnectionView[]): IChartConfigMap {
        let connections = this.iGoActiveConnections.connections;
        if (filteredConnections) {
            connections = filteredConnections;
        };

        this.chartService.resetChartData('iGoNetworkChartConfig');

        connections.forEach(connection => {
            this.chartService.iGoNetworkChartConfig.all.data.push(connection);
            if (connection.stateView == 'Active') {
                this.chartService.iGoNetworkChartConfig.active.data.push(connection);
            } else if (connection.stateView == 'Disconnected') {
                this.chartService.iGoNetworkChartConfig.disconnected.data.push(connection);
            } else if (connection.stateView == 'Suspended/Test') {
                this.chartService.iGoNetworkChartConfig.suspendedOrTest.data.push(connection);
            };
        });

        return this.chartService.setChartValues('iGoNetworkChartConfig');
    };

    public filterOperatorDemandGenerators(filteredConnections?: CXConnectionView[]): IChartConfigMap {
        let connections = this.operatorActiveConnections.connections;
        if (filteredConnections) {
            connections = filteredConnections;
        };

        this.chartService.resetChartData('operatorDemandGeneratorsChartConfig');

        connections.forEach(connection => {
            this.chartService.operatorDemandGeneratorsChartConfig.all.data.push(connection);
            if (connection.stateView == 'Active') {
                this.chartService.operatorDemandGeneratorsChartConfig.active.data.push(connection);
            } else if (connection.stateView == 'Disconnected') {
                this.chartService.operatorDemandGeneratorsChartConfig.disconnected.data.push(connection);
            } else if (connection.stateView == 'Suspended/Test') {
                this.chartService.operatorDemandGeneratorsChartConfig.suspendedOrTest.data.push(connection);
            };
        });

        return this.chartService.setChartValues('operatorDemandGeneratorsChartConfig');
    };

    public filterOriginatorNetwork(filteredConnections?: CXConnectionView[]): IChartConfigMap {
        let connections = this.originatorActiveConnections.connections;
        if (filteredConnections) {
            connections = filteredConnections;
        };

        this.chartService.resetChartData('originatorNetworkChartConfig');

        connections.forEach(connection => {
            this.chartService.originatorNetworkChartConfig.all.data.push(connection);
            if (connection.stateView == 'Active') {
                this.chartService.originatorNetworkChartConfig.active.data.push(connection);
            } else if (connection.stateView == 'Disconnected') {
                this.chartService.originatorNetworkChartConfig.disconnected.data.push(connection);
            } else if (connection.stateView == 'Suspended/Test') {
                this.chartService.originatorNetworkChartConfig.suspendedOrTest.data.push(connection);
            } else if (connection.stateView == 'Rejected') {
                this.chartService.originatorNetworkChartConfig.dismissed.data.push(connection);
            };
        });

        return this.chartService.setChartValues('originatorNetworkChartConfig');
    };

    public getIGoActiveConnectionsObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllCXConnectionsResponseApi>(url, {}, 'global.request.loading_network').pipe(
            map(res => {
                this.resetCachedCXFilters();
                const activeConnections = res.connections.map(this.mapCXConnectionToViewModel);
                this.iGoActiveConnections = new GetAllCXConnectionsResponseView({ connections: activeConnections, _links: res._links });
            })
        );
    };

    public getOperatorActiveConnectionsObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllCXConnectionsResponseApi>(url, {}, 'global.request.loading_demand_generators').pipe(
            map(res => {
                this.resetCachedCXFilters();
                const activeConnections = res.connections.map(this.mapCXConnectionToViewModel);
                this.operatorActiveConnections = new GetAllCXConnectionsResponseView({ connections: activeConnections, _links: res._links });
            })
        );
    };

    public getOriginatorActiveConnectionsObservable(url: string): Observable<void> {
        return this.http.authenticatedGet<GetAllCXConnectionsResponseApi>(url, {}, 'global.request.loading_network').pipe(
            map(res => {
                this.resetCachedCXFilters();
                const activeConnections = res.connections.map(this.mapCXConnectionToViewModel);
                this.originatorActiveConnections = new GetAllCXConnectionsResponseView({ connections: activeConnections, _links: res._links });
            })
        );
    };

    private mapCXConnectionToViewModel = (connection: CXConnectionApi): CXConnectionView => {
        if (this.allCXMarketplaces.findIndex(marketplace => marketplace == connection.marketplace) < 0) {
            this.allCXMarketplaces.push(connection.marketplace);
        };
        if (this.allCXOperators.findIndex(operator => operator.id == connection.operator.id) < 0) {
            this.allCXOperators.push(new iGoCompanyOperatorPartialView(connection.operator));
        };
        if (this.allCXOriginators.findIndex(originator => originator.id == connection.originator.id) < 0) {
            this.allCXOriginators.push(new iGoCompanyOriginatorPartialView(connection.originator));
        };
        return new CXConnectionView(connection as CXConnectionView);
    };

    private resetCachedCXFilters(): void {
        this.allCXMarketplaces = [];
        this.allCXOperators = [];
        this.allCXOriginators = [];
    };
};