import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";

export class OperatorDocumentApi {
    public added: boolean;
    public approved: boolean;
    public description: string;
    public expiryDate: Date | string;
    public id: string;
    public marketplaces: number;
    public name: string;

    public constructor(item?: OperatorDocumentApi) {
        this.added = (item && item.added) ? true : false;
        this.approved = (item && item.approved) ? true : false;
        this.description = (item && item.description) ? item.description : '';
        this.expiryDate = (item && item.expiryDate) ? item.expiryDate : '';
        this.id = (item && item.id) ? item.id : '';
        this.marketplaces = (item && typeof item.marketplaces == 'number') ? item.marketplaces : null;
        this.name = (item && item.name) ? item.name : '';
    };
};

export class OperatorDocumentView extends OperatorDocumentApi {
    public expiryDateShort: string;
    public selected: boolean;

    public constructor(item?: OperatorDocumentApi | OperatorDocumentView) {
        super(item);
        this.expiryDateShort = (item && item.expiryDate) ? new GenericDatePipe().transform(String(item.expiryDate)) : '';
        this.selected = (item && item instanceof OperatorDocumentView && item.selected) ? true : false;
    };
};