import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { SharedModalsService } from 'src/services/shared-modals.service';

export interface ISendEmailConfig {
    email: string;
    title: string;
};

@Component({
    selector: 'send-email-modal',
    templateUrl: 'send-email.modal.html'
})

export class SendEmailModalComponent implements OnInit {
    @ViewChild('form') public form: NgForm;
    @Input() public config: ISendEmailConfig;

    public email: string = '';

    public constructor(
        public sendEmailModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService
    ) { };

    public ngOnInit(): void {
        this.email = this.config.email;
    };

    public onConfirm() {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            this.sendEmailModal.close(this.email);
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.config.title, this.form, { windowClass: 'igo' });
        };
    };
};