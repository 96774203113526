import { Component, inject, Input, ViewChild } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IHalLink } from 'src/interfaces/hal-link.interface';
import { iGoUserView, UserRoleView } from 'src/models';
import { RolesService, SharedModalsService, UsersService } from 'src/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'create-user-modal',
    templateUrl: './create-user.modal.html'
})

export class CreateUserModalComponent {
    @ViewChild('form') public form: NgForm;
    @Input() public _links: IHalLink = {};
    public creating: boolean = false;
    public user: iGoUserView = new iGoUserView();

    private rolesService = inject(RolesService);

    public allRoles: UserRoleView[] = ArrayUtilities.sortAlphabetically(this.rolesService.getRoles()?.roles, 'name');

    private noDataChange = (): boolean => !this.form.dirty;

    public constructor(
        public createUserModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private usersService: UsersService
    ) { };

    public onCreate(): void {
        if (!this.creating) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.creating = true;
                this.usersService.createUserAsync(this._links.CreateUser.href, this.user).subscribe({
                    next: this.onSuccess,
                    error: this.onFailure
                });
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.create_user.create_user'), this.form);
            };
        };
    };

    public onSelectRole($event: MatSelectChange): void {
        this.user.roles = $event.value;
        this.user.rolesView = this.user.roles.map(i => this.allRoles.find(x => x.name == i));
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.creating = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.create_user.create_user'));
    };

    private onSuccess = (res: iGoUserView): void => this.createUserModal.close(res);
};