import { Component, Input, ViewChild } from '@angular/core';
import { AuthenticationService, SharedModalsService, UserService } from 'src/services';
import { ChangeUserPasswordPayload, UserView } from 'src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'current-user-profile-modal',
    templateUrl: './current-user-profile.modal.html'
})

export class CurrentUserProfileModalComponent {
    @ViewChild('form') public form: NgForm;
    @Input() public windowClass: string;
    public changePasswordPayload: ChangeUserPasswordPayload = new ChangeUserPasswordPayload();
    public confirming: boolean = false;
    public passwordRegex: RegExp = AuthenticationService.PasswordRegex;
    public user: UserView = null;

    public constructor(
        public currentUserProfileModal: NgbActiveModal,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        this.user = this.userService.getCurrentUser();
    };

    public onConfirm(): void {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            this.confirming = true;
            this.userService.changeUserPasswordAsync(this.changePasswordPayload).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        } else {
            this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.current_user_profile.my_details'), this.form, { windowClass: 'igo' });
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.current_user_profile.my_details'));
    };

    private onSuccess = (): void => {
        this.confirming = false;
        const message: string = this.translateService.instant('global.modals.current_user_profile.password_changed_message');
        const title: string = this.translateService.instant('global.modals.current_user_profile.password_changed_title');
        this.sharedModalsService.showAlertModal(title, message, { windowClass: this.windowClass }).result
            .then(() => this.currentUserProfileModal.close())
            .catch(() => this.currentUserProfileModal.close());
    };
};