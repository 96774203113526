import { NgModule } from '@angular/core';

import { AutofocusDirective } from './directives/autofocus.directive';
import { GhostValidateDirective } from './directives/ghost-validate.directive';
import { HoldableDirective } from './directives/holdable.directive';
import { HourOnlyDirective } from './directives/hour-only.directive';
import { MatchFieldValidatorDirective } from './directives/match-field.directive';
import { MaxNumberValidatorDirective } from './directives/max-number.directive';
import { MinNumberValidatorDirective } from './directives/min-number.directive';
import { MinuteOnlyDirective } from './directives/minute-only.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { ProceedOnEnterDirective } from './directives/proceedOnEnter.directive';
import { RegexMatchDirective } from './directives/regex-match.directive';
import { TypeofObjectValidatorDirective } from './directives/typeof-object.directive';

@NgModule({
    declarations: [
        AutofocusDirective,
        GhostValidateDirective,
        HoldableDirective,
        HourOnlyDirective,
        MatchFieldValidatorDirective,
        MaxNumberValidatorDirective,
        MinNumberValidatorDirective,
        MinuteOnlyDirective,
        NumberOnlyDirective,
        ProceedOnEnterDirective,
        RegexMatchDirective,
        TypeofObjectValidatorDirective
    ],
    exports: [
        AutofocusDirective,
        GhostValidateDirective,
        HoldableDirective,
        HourOnlyDirective,
        MatchFieldValidatorDirective,
        MaxNumberValidatorDirective,
        MinNumberValidatorDirective,
        MinuteOnlyDirective,
        NumberOnlyDirective,
        ProceedOnEnterDirective,
        RegexMatchDirective,
        TypeofObjectValidatorDirective
    ]
})
export class DirectivesModule { };