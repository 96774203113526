import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BankAccountInfoView, iGoCompanyOriginatorView, iGoCompanyOperatorView } from 'src/models';
import { BankingService, SharedModalsService } from 'src/services';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'bank-account-information-modal',
    templateUrl: './bank-account-information.modal.html'
})
export class BankAccountInformationModalComponent implements OnInit {
    @ViewChild('form') public form: NgForm;
    @Input() public bankAccountInfo: BankAccountInfoView;
    @Input() public createUrl: string;
    @Input() public partner: iGoCompanyOriginatorView | iGoCompanyOperatorView;
    @Input() public updateUrl: string;
    public bankAccountInfoCopy: BankAccountInfoView = new BankAccountInfoView();
    public confirming: boolean = false;
    public currencyCodes: string[] = [];
    public editMode: boolean = false;
    public isOriginator: boolean = false;
    public title: string = '';

    private noDataChange = () => ObjectUtilities.equal(this.bankAccountInfo, this.bankAccountInfoCopy);

    public constructor(
        public bankAccountInformationModal: NgbActiveModal,
        private bankingService: BankingService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) {
        this.currencyCodes = this.bankingService.getCurrencyCodes();
    };

    public ngOnInit(): void {
        this.editMode = !!this.bankAccountInfo;
        this.isOriginator = this.partner instanceof iGoCompanyOriginatorView;
        if (this.editMode) {
            this.title = this.translateService.instant('global.modals.bank_account_information.edit_bank_account_information').replace('{0}', this.bankAccountInfo.currencyCode);
            this.currencyCodes = [this.bankAccountInfo.currencyCode];
        } else {
            this.title = 'global.modals.bank_account_information.add_bank_account_information';
        };
        if (this.bankAccountInfo) {
            this.bankAccountInfoCopy = ObjectUtilities.deepCopy(this.bankAccountInfo);
        } else {
            this.bankAccountInfo = new BankAccountInfoView();
        };
    };

    public onConfirm(): void {
        if (!this.confirming) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.confirming = true;
                this.editMode ? this.updateBankAccountInformation() : this.createBankAccountInformation();
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.bank_account_information'), this.form, { windowClass: 'originator' });
            };
        };
    };

    public onSelectCurrencyCode($event: MatSelectChange): void {
        if (!this.editMode) {
            this.bankAccountInfoCopy.currencyCode = $event && $event.value;
        };
    };

    private createBankAccountInformation(): void {
        this.bankingService.createBankAccountInforamtionAsync(this.createUrl, this.bankAccountInfoCopy, this.partner instanceof iGoCompanyOriginatorView ? this.partner : null).subscribe({
            next: this.onSuccess,
            error: this.onFailure
        });
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant(this.title));
    };

    private onSuccess = (res: BankAccountInfoView | string): void => this.bankAccountInformationModal.close(res);

    private updateBankAccountInformation(): void {
        if (this.updateUrl) {
            this.bankingService.updateBankAccountInforamtionAsync(this.updateUrl, this.bankAccountInfoCopy).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        };
    };
};