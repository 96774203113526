import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[typeof-object]',
    providers: [
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => TypeofObjectValidatorDirective)
        }
    ]
})

export class TypeofObjectValidatorDirective implements Validator {
    @Input('typeof-object') objectToCheck;

    public constructor(@Attribute('typeofObject') public typeofObject: string) { }

    public validate(control: AbstractControl): { [key: string]: any } {
        let typeofObject = false;
        for (var key in this.objectToCheck) {
            if (this.objectToCheck.hasOwnProperty(key)) {
                typeofObject = true;
            };
        };
        return !control.value || (control.value && typeofObject) ? null : {
            typeofObject: false
        };
    };
};