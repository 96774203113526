import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from "@angular/core";
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordDetails } from "src/models/user.model";
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        NgbAlertModule,
        TranslateModule
    ],
    selector: 'password-requirement-details',
    templateUrl: './password-requirement-details.component.html'
})
export class PasswordRequirementDetailsComponent implements OnChanges {
    @Input() public password: string;
    public passwordDetails: PasswordDetails = new PasswordDetails();

    public constructor() { };

    public ngOnChanges() {
        this.onPasswordInput(this.password);
    };

    public onPasswordInput(password: string) {
        this.passwordDetails = new PasswordDetails();
        this.passwordDetails.length = password.length;
        for (let i = 0; i < password.length; i++) {
            if (password[i] >= 'A' && password[i] <= 'Z') {
                this.passwordDetails.upperCaseLetters++;
            } else if ((password[i] >= 'a' && password[i] <= 'z') || password[i] == ' ') {
                this.passwordDetails.lowerCaseLetters++;
            } else if (password[i] >= '0' && password[i] <= '9') {
                this.passwordDetails.digits++;
            } else {
                this.passwordDetails.specialCharacters++;
            };
        }
    }
};