import { Component, Input, OnInit } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { OperatorDocumentView } from 'src/models';

@Component({
    selector: 'document-selection-modal',
    styleUrls: ['document-selection.modal.css'],
    templateUrl: './document-selection.modal.html'
})

export class DocumentSelectionModalComponent implements OnInit {
    @Input() public documents: OperatorDocumentView[] = [];
    public documentsCopy: OperatorDocumentView[] = [];
    public filterSearchTerm: string = '';
    public selectedDocuments: OperatorDocumentView[] = [];

    public constructor(public documentSelectionModal: NgbActiveModal) { };

    public ngOnInit(): void {
        this.documentsCopy = ObjectUtilities.deepCopy(this.documents);
    };

    public onConfirm(): void {
        this.documentSelectionModal.close(this.selectedDocuments);
    };

    public onFilterDocuments($event): void {
        this.documentsCopy = ArrayUtilities.filterMultipleProperties(this.documents, ['name'], $event);
        this.documentsCopy.map(i => {
            const selectedDocument = this.selectedDocuments.find(x => x.id == i.id);
            i.selected = selectedDocument && selectedDocument.selected;
            return i;
        });
    };

    public onSelectDocument(selected: OperatorDocumentView): void {
        let document = this.documentsCopy.find(i => i.id == selected.id);
        if (!document.added) {
            document.selected = !document.selected;
            if (document.selected) {
                this.selectedDocuments.push(document);
            } else {
                const index = this.selectedDocuments.findIndex(i => i.id == document.id);
                this.selectedDocuments.splice(index, 1);
            };
        };
    };
};