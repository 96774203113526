import { HalLinksApi } from "./hal-link.model";

export class PartialNameAndId extends HalLinksApi {
    public id: string;
    public displayName: string;
    public name: string;

    public constructor(item?: PartialNameAndId) {
        super(item);
        this.id = (item && item.id) ? item.id : '';
        this.displayName = (item && item.id && item.name) ? `${item.id} - ${item.name}` : '';
        this.name = (item && item.name) ? item.name : '';
    };
};