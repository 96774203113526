import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../services';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html'
})

export class LoadingComponent implements OnDestroy {
    public description = 'Loading App Data';
    public progress = 0;

    private loadingSubscription: Subscription = null;

    public constructor(private loadingService: LoadingService) {
        this.loadingSubscription = this.loadingService.onAppDataLoadingChange().subscribe({
            next: (change) => {
                this.description = change.description;
                this.progress = change.progress;
            }
        });
    };

    public ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
        this.loadingService.resetProgress();
    };
};