import { Component, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { filter, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { iGoCompanyOperatorView, iGoCompanyOriginatorView, UserView } from 'src/models';
import { NavigationEnd, Router } from '@angular/router';
import { PageHelpService } from 'src/services/page-help.service';
import { SharedModalsService, UserService, StorageService, iGoCompaniesService } from '../../services';
import { Subject, Subscription, lastValueFrom } from 'rxjs';

export interface SidebarPath {
    abstract?: boolean;
    active: boolean;
    children?: { [key: string]: SidebarPath; };
    dataTestId: string;
    hideRoute?: boolean;
    name: string;
    path: string;
};

@Component({
    selector: 'sidebar',
    styleUrls: ['sidebar.component.css'],
    templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnDestroy {
    public activePath: string = '';
    public class: string = '';
    public cxAdminUrl = environment.cxAdminUrl;
    public location: string = null;
    public paths: { [key: string]: SidebarPath; } = {};
    public pageHelpKey: string = '';
    public selectedOperator: iGoCompanyOperatorView = null;
    public selectedOriginator: iGoCompanyOriginatorView = null;
    public isUserProfileCompleted: boolean = true;
    public user: UserView;
    public cornicheEnabled: boolean = true;

    private igoPortalRoutes: { [key: string]: SidebarPath; } = {
        network: { active: false, name: 'routes.network', path: '/igoportal/network', dataTestId: 'sidebar-network' },
        marketplaces: { active: false, name: 'routes.marketplaces', path: '/igoportal/marketplaces', dataTestId: 'sidebar-marketplaces' },
        companies: {
            active: false, name: 'routes.companies', path: '/igoportal/companies', dataTestId: 'sidebar-companies', children: {
                originators: { active: false, name: 'routes.originators', path: '/originators', dataTestId: 'sidebar-originators' },
                operators: { active: false, name: 'routes.operators', path: '/operators', dataTestId: 'sidebar-operators' },
            }
        },
        activity: { active: false, name: 'routes.activity', path: '/igoportal/activity', dataTestId: 'sidebar-activity' },
        analytics: { active: false, name: 'routes.analytics', path: '/igoportal/analytics', dataTestId: 'sidebar-analytics' },
        invoicing: { active: false, name: 'routes.invoicing', path: '/igoportal/invoicing', dataTestId: 'sidebar-invoicing' },
        profile: { active: false, name: 'routes.profile', path: '/igoportal/profile', dataTestId: 'sidebar-profile' },
        admin: { active: false, name: 'routes.admin', path: '/igoportal/admin', dataTestId: 'sidebar-admin' },
    };

    private operatorPortalRoutes: { [key: string]: SidebarPath; } = {
        activity: { active: false, name: 'routes.activity', path: '/operatorportal/activity', dataTestId: 'sidebar-activity' },
        accounts: { active: false, name: 'routes.accounts', path: '/operatorportal/accounts', dataTestId: 'sidebar-accounts' },
        demand: { active: false, name: 'routes.demand', path: '/operatorportal/demand', dataTestId: 'sidebar-demand' },
        analytics: { active: false, name: 'routes.analytics', path: '/operatorportal/analytics', dataTestId: 'sidebar-analytics' },
        invoicing: { active: false, name: 'routes.invoicing', path: '/operatorportal/invoicing', dataTestId: 'sidebar-invoicing' },
        profile: { active: false, name: 'routes.profile', path: '/operatorportal/profile', dataTestId: 'sidebar-profile' },
        admin: { active: false, name: 'routes.admin', path: '/operatorportal/admin', dataTestId: 'sidebar-admin' },
        // documents: { active: false, name: 'routes.documents', path: '/operatorportal/documents' },
    };

    private originatorPortalRoutes: { [key: string]: SidebarPath; } = {
        network: { active: false, name: 'routes.network', path: '/originatorportal/network', dataTestId: 'sidebar-network' },
        activity: { active: false, name: 'routes.activity', path: '/originatorportal/activity', dataTestId: 'sidebar-activity' },
        accounts: { active: false, name: 'routes.accounts', path: '/originatorportal/accounts', dataTestId: 'sidebar-accounts' },
        analytics: { active: false, name: 'routes.analytics', path: '/originatorportal/analytics', dataTestId: 'sidebar-analytics' },
        invoicing: { active: false, name: 'routes.invoicing', path: '/originatorportal/invoicing', dataTestId: 'sidebar-invoicing' },
        profile: { active: false, name: 'routes.profile', path: '/originatorportal/profile', dataTestId: 'sidebar-profile' },
        admin: { active: false, name: 'routes.admin', path: '/originatorportal/admin', dataTestId: 'sidebar-admin' },
        testemulator: { active: false, name: 'routes.test_emulator', path: '/originatorportal/testemulator', dataTestId: 'sidebar-testemulator' },
    };

    private backToIGo$: Subject<void> = new Subject<void>();
    private eventsSubscription: Subscription;
    private operatorSubscription: Subscription;
    private originatorSubscription: Subscription;

    public constructor(
        private iGoCompaniesService: iGoCompaniesService,
        private pageHelpService: PageHelpService,
        private router: Router,
        private sharedModalsService: SharedModalsService,
        private userService: UserService
    ) {
        this.location = this.router.url;
        this.activePath = this.router.url;
        this.eventsSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe({
            next: (event: NavigationEnd) => {
                if (this.router.url) {
                    this.location = this.router.url;
                    this.activePath = this.router.url;
                };

                // Set the correct routes in the sidebar
                if (this.location.includes('igoportal') || StorageService.getItem('originator')?.global) {
                    this.class = 'igo';
                    this.paths = this.igoPortalRoutes;
                } else if (this.location.includes('operatorportal') || StorageService.getItem('operator')) {
                    this.class = 'operator';
                    this.paths = this.operatorPortalRoutes;
                } else if (this.location.includes('originatorportal') || StorageService.getItem('originator')) {
                    this.class = 'originator';
                    this.paths = this.originatorPortalRoutes;
                };

                // Subscribe to changes in the selected operator or originator
                this.operatorSubscription = this.iGoCompaniesService.currentOperator.subscribe(x => {
                    this.selectedOperator = x ? new iGoCompanyOperatorView(x as iGoCompanyOperatorView) : null;
                });
                this.originatorSubscription = this.iGoCompaniesService.currentOriginator.subscribe(x => {
                    this.selectedOriginator = x ? new iGoCompanyOriginatorView(x as iGoCompanyOriginatorView) : null;
                    if (!!this.selectedOriginator) {
                        this.isUserProfileCompleted = this.selectedOriginator.global ? true : UserService.currentUser.isComplete;
                    };
                });

                // Set the currently selected operator or originator (on page refresh)
                this.iGoCompaniesService.currentOperator.next(StorageService.getItem('operator'));
                this.iGoCompaniesService.currentOriginator.next(StorageService.getItem('originator'));
                this.user = this.userService.getCurrentUser();
                this.cornicheEnabled = this.user._links?.GetCornicheAccount ? true : false;
            }
        });
    };

    public ngAfterViewInit(): void {
        this.backToIGo$.pipe(switchMap(res => this.iGoCompaniesService.returnUserAccessAsync())).subscribe({
            next: async () => {
                const iGoOriginator = await lastValueFrom(this.iGoCompaniesService.getGlobalOriginatorObservable());
                iGoOriginator && this.iGoCompaniesService.cacheCurrentOriginator(iGoOriginator);
                iGoOriginator && this.iGoCompaniesService.cacheCurrentOperator(null);
                await lastValueFrom(this.userService.getCurrentUserObservable());
                this.router.navigate(['/igoportal/network']);
            },
            error: this.onFailure
        });
    };

    public ngOnDestroy(): void {
        this.eventsSubscription?.unsubscribe();
        this.operatorSubscription?.unsubscribe();
        this.originatorSubscription?.unsubscribe();
    };

    public navigate(url: string, abstract?: boolean): void {
        if (!abstract) {
            this.router.navigate([url]);
        };
    };

    public onBackToIGoPortal(): void {
        this.backToIGo$.next(null);
    };

    public openPageHelp(): void {
        let routes = this.pageHelpKey.split('/');
        this.pageHelpKey && window.open(this.pageHelpService.links[routes[0]][routes[1]].pageUrl, '_blank');
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.sharedModalsService.showServerValidationErrorsModal(res);
    };

    public sortNull = (): void => { };
};