import { Component, Input, OnInit } from '@angular/core';
import { ActivityService, SharedModalsService, TimeRangesService, UserService } from 'src/services';
import { CXConnectionView, DateRangeTemplateView, GetBookingsPayload } from 'src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'cx-connection-modal',
    styleUrls: ['cx-connection.modal.css'],
    templateUrl: './cx-connection.modal.html'
})

export class CXConnectionModalComponent implements OnInit {
    @Input() public connection: CXConnectionView;
    @Input() public windowClass: string;
    public confirming: boolean = false;
    public currentPartnerType: string = '';
    public loadingCommissionExceptionDates: boolean = false;
    public loadingStats: boolean = false;
    public monthlyBookingsCount: number = null;
    public title: string = '';
    public totalBookingsCount: number = null;

    public constructor(
        public cxConnectionModal: NgbActiveModal,
        private activityService: ActivityService,
        private sharedModalsService: SharedModalsService,
        private timeRangesService: TimeRangesService,
        private translateService: TranslateService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
    };

    public ngOnInit(): void {
        switch (this.currentPartnerType) {
            case 'IGo':
                this.title = `${this.connection.originator?.name} & ${this.connection.operator?.name}`;
                break;
            case 'Originator':
                this.title = this.connection.operator?.name;
                break;
            default:
                this.title = this.connection.originator?.name;
                break;
        };
        this.loadAllConnectionBookingStatsAsync();
    };

    public onViewCommissionExceptionDates(dateRangeTemplateId: string): void {
        if (!this.loadingCommissionExceptionDates) {
            this.loadingCommissionExceptionDates = true;
            this.timeRangesService.getDateRangeTemplateByIdObservable(dateRangeTemplateId).subscribe({
                next: this.showCommissionExceptionDatesModal,
                error: this.onFailure
            });
        };
    };

    private getDefaultBookingStatsPayload(): GetBookingsPayload {
        const defaultPayload = new GetBookingsPayload();
        defaultPayload.marketplaceId = this.connection.marketplace;
        defaultPayload.originatorIds = [this.connection.originator.id];
        defaultPayload.operatorIds = [this.connection.operator.id];
        return defaultPayload;
    };

    private async loadAllConnectionBookingStatsAsync(): Promise<void> {
        this.loadingStats = true;
        await this.loadConnectionMonthlyBookingStats();
        await this.loadConnectionTotalBookingStats();
        this.loadingStats = false;
    };

    private async loadConnectionMonthlyBookingStats(): Promise<void> {
        const defaultPayload = this.getDefaultBookingStatsPayload();
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 31);
        defaultPayload.from = dateFrom;
        defaultPayload.to = new Date();
        this.monthlyBookingsCount = await lastValueFrom(this.activityService.getBookingsCountObservable(defaultPayload));
    };

    private async loadConnectionTotalBookingStats(): Promise<void> {
        const defaultPayload = this.getDefaultBookingStatsPayload();
        this.totalBookingsCount = await lastValueFrom(this.activityService.getBookingsCountObservable(defaultPayload));
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.loadingCommissionExceptionDates = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('originator_portal.marketplaces.connections.connection'));
    };

    private showCommissionExceptionDatesModal = (dateRangeTemplate: DateRangeTemplateView) => {
        this.loadingCommissionExceptionDates = false;
        this.sharedModalsService.showDateRangeTemplateDatesModal(dateRangeTemplate, { windowClass: this.windowClass });
    };
};