import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommissionTemplateView } from 'src/models';
import { FormGroupSectionHeaderComponent } from '../form-group-section-header/form-group-section-header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FormGroupSectionHeaderComponent,
        TranslateModule
    ],
    selector: 'commission-breakdown',
    templateUrl: './commission-breakdown.component.html'
})

export class CommissionBreakdownComponent {
    @Input() public commissionTemplate: CommissionTemplateView;
};