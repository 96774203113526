import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'parse-json-input-modal',
    templateUrl: './parse-json-input.modal.html'
})

export class ParseJsonInputModalComponent {
    @ViewChild('form') public form: NgForm;
    public jsonString: string;

    public constructor(
        public parseJsonInputModal: NgbActiveModal
    ) { };

    public onConfirm(): void {
        this.form.ngSubmit.emit();
        if (this.form.valid) {
            this.parseJsonInputModal.close(JSON.parse(JSON.parse(JSON.stringify(this.jsonString))));
        };
    };
};