import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface IWarningModalConfig {
    message: string;
    title: string;
};

@Component({
    selector: 'warning-modal',
    templateUrl: './warning.modal.html'
})

export class WarningModalComponent implements OnInit {
    @Input() public config = {} as IWarningModalConfig;

    public message: string = '';
    public title: string = '';

    public constructor(public warningModal: NgbActiveModal) { };

    public ngOnInit(): void {
        this.message = this.config.message;
        this.title = this.config.title;
    };
};