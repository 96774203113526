import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivityService, SharedModalsService, UserService } from 'src/services';
import { AuthenticationResponse, BookingView, CreateFundsRequestPayload, DisputeEscalatedEventView, DisputeFundsRequestView, DisputeMessageView, DisputeResolvedEventView, DisputeView, UserView } from 'src/models';
import { Endpoints } from 'src/shared/endpoints';
import { getCurrencySymbol } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IBookingModalConfig } from '../activity-booking/activity-booking.modal';
import { IDisputeReasonModalConfig } from '../dispute-reason/dispute-reason.modal';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'activity-dispute-modal',
    styleUrls: ['activity-dispute.modal.css'],
    templateUrl: './activity-dispute.modal.html'
})

export class ActivityDisputeModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('input') public input: ElementRef;
    @ViewChild('messagesList') public messagesList: ElementRef;
    @Input() public dispute: DisputeView;
    @Input() public showViewBookingButton: boolean = false;
    @Input() public windowClass: string;
    public chatEnabled: boolean = false;
    public currentPartnerType: string = '';
    public currencySymbol: string = '';
    public loadingBooking: boolean = false;
    public newMessage: string = '';
    public sendingMessage: boolean = false;
    public title: string = '';
    public user: UserView = null;

    private connection: HubConnection;
    private disputeReasonModalRef: NgbModalRef;

    public beforeDismiss = () => {
        this.activityDisputeModal.close(this.activityService.mapDisputeToViewModel(this.dispute));
        return false;
    };

    public constructor(
        public activityDisputeModal: NgbActiveModal,
        private activityService: ActivityService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        this.currentPartnerType = UserService.getPartnerTypeDetails().type;
    };

    public ngOnInit(): void {
        this.title = this.translateService.instant('global.modals.activity_dispute.title').replace('{0}', `${this.dispute.raisedBy.userName}, ${this.dispute.raisedBy.partnerName}`);
        this.user = this.userService.getCurrentUser();

        if (this.dispute._links.GenerateDisputeAccessToken?.href) {
            this.activityService.getDisputeAccessTokenObservable(this.dispute._links.GenerateDisputeAccessToken.href).subscribe(res => {
                this.createSignalRConnection(res);
                this.connection && this.listenToSignalREvents();
            });
        };

        this.currencySymbol = getCurrencySymbol(this.dispute.booking.pricing.currency, 'wide');
        this.dispute.disputeMessages && this.dispute.disputeMessages.forEach(message => {
            message.fundRequest.amountCurrency = this.currencySymbol + message.fundRequest.amount;
        });
    };

    public ngAfterViewInit(): void {
        this.scrollToBottom();
    };

    public ngOnDestroy(): void {
        this.connection?.stop();
    };

    public onApproveFundRequest(fundRequest: DisputeFundsRequestView): void {
        const config: IDisputeReasonModalConfig = {
            buttonText: this.translateService.instant('global.button.confirm'),
            callback: this.approveFundRequest,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.approve_fund_request.message').replaceAll('{0}', fundRequest.amountCurrency).replaceAll('{1}', fundRequest.requester.partnerName),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.approve_fund_request.title'),
            dispute: this.dispute,
            fundRequest,
            successMessage: this.translateService.instant('global.modals.activity_dispute.fund_request_approved.message'),
            successTitle: this.translateService.instant('global.modals.activity_dispute.fund_request_approved.title'),
            title: this.translateService.instant('global.modals.activity_dispute.approve_fund_request.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onCancelFundRequest(fundRequest: DisputeFundsRequestView): void {
        const config: IDisputeReasonModalConfig = {
            buttonText: this.translateService.instant('global.button.confirm'),
            callback: this.cancelFundRequest,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.cancel_fund_request.message').replace('{0}', fundRequest.amountCurrency).replace('{1}', fundRequest.respondent.name),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.cancel_fund_request.title'),
            dispute: this.dispute,
            fundRequest,
            successMessage: this.translateService.instant('global.modals.activity_dispute.fund_request_cancelled.message'),
            successTitle: this.translateService.instant('global.modals.activity_dispute.fund_request_cancelled.title'),
            title: this.translateService.instant('global.modals.activity_dispute.cancel_fund_request.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onCreateFundRequest(): void {
        const requestingFundsFromName: string = UserService.getPartnerTypeDetails().type == 'Operator' ? this.dispute.originator.name : this.dispute.operator.name;
        const config: IDisputeReasonModalConfig = {
            booking: this.dispute.booking,
            buttonText: this.translateService.instant('global.button.request_funds'),
            callback: this.requestFunds,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.request_funds.message'),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.request_funds.title'),
            currencyCode: this.dispute.booking.availableFundRequest.currency,
            dispute: this.dispute,
            requestingFundsFromName,
            showAmountField: true,
            successMessage: this.translateService.instant('global.modals.activity_dispute.funds_requested.message').replace('{0}', requestingFundsFromName),
            successTitle: this.translateService.instant('global.modals.activity_dispute.funds_requested.title'),
            title: this.translateService.instant('global.modals.activity_dispute.request_funds.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onEscalateDispute(): void {
        const config: IDisputeReasonModalConfig = {
            buttonText: this.translateService.instant('global.button.escalate'),
            callback: this.escalateDispute,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.escalate_dispute.message'),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.escalate_dispute.title'),
            dispute: this.dispute,
            successMessage: this.translateService.instant('global.modals.activity_dispute.dispute_escalated.message'),
            successTitle: this.translateService.instant('global.modals.activity_dispute.dispute_escalated.title'),
            title: this.translateService.instant('global.modals.activity_dispute.escalate_dispute.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onRejectFundRequest(fundRequest: DisputeFundsRequestView): void {
        const config: IDisputeReasonModalConfig = {
            buttonText: this.translateService.instant('global.button.confirm'),
            callback: this.rejectFundRequest,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.reject_fund_request.message').replace('{0}', fundRequest.amountCurrency).replace('{1}', fundRequest.requester.partnerName),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.reject_fund_request.title'),
            dispute: this.dispute,
            fundRequest,
            successMessage: this.translateService.instant('global.modals.activity_dispute.fund_request_rejected.message'),
            successTitle: this.translateService.instant('global.modals.activity_dispute.fund_request_rejected.title'),
            title: this.translateService.instant('global.modals.activity_dispute.reject_fund_request.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onResolveDispute(): void {
        const config: IDisputeReasonModalConfig = {
            buttonText: this.translateService.instant('global.button.resolve'),
            callback: this.resolveDispute,
            confirmationMessage: this.translateService.instant('global.modals.activity_dispute.resolve_dispute.message'),
            confirmationTitle: this.translateService.instant('global.modals.activity_dispute.resolve_dispute.title'),
            dispute: this.dispute,
            successMessage: this.translateService.instant('global.modals.activity_dispute.dispute_resolved.message'),
            successTitle: this.translateService.instant('global.modals.activity_dispute.dispute_resolved.title'),
            title: this.translateService.instant('global.modals.activity_dispute.resolve_dispute.title')
        };
        this.showDisputeReasonModal(config);
    };

    public onSendMessage(): void {
        if (this.chatEnabled && this.newMessage && !this.sendingMessage) {
            this.sendingMessage = true;
            this.activityService.sendDisputeMessageAsync(this.dispute._links.SendMessage.href, this.newMessage).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        };
    };

    public onViewBooking(): void {
        if (this.dispute.booking) {
            const config: IBookingModalConfig = { booking: this.dispute.booking, showDisputeOption: false };
            this.sharedModalsService.showActivityBookingModal(config, { windowClass: this.windowClass }).result
                .then(() => this.input.nativeElement.focus())
                .catch(() => this.input.nativeElement.focus());
        };
    };

    public onViewDisputeStatus(): void {
        this.sharedModalsService.showDisputeStatusModal(this.dispute, { windowClass: this.windowClass });
    };

    private approveFundRequest = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>, fundRequest: DisputeFundsRequestView): void => {
        this.activityService.approveFundsRequestAsync(fundRequest._links.ApproveFundRequest.href, payload).subscribe({
            next: async (res: DisputeFundsRequestView) => {
                const dispute: DisputeView = await this.getLatestDispute();
                modalRef.close(dispute);
            },
            error: this.onFailure
        });
    };

    private cancelFundRequest = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>, fundRequest: DisputeFundsRequestView): void => {
        this.activityService.cancelFundsRequestAsync(fundRequest._links.CancelFundRequest.href, payload).subscribe({
            next: async (res: DisputeFundsRequestView) => {
                const dispute: DisputeView = await this.getLatestDispute();
                modalRef.close(dispute);
            },
            error: this.onFailure
        });
    };

    private createSignalRConnection(res: AuthenticationResponse): void {
        const signalREndpoint: string = Endpoints.API_URL + Endpoints.SIGNALR._DISPUTE_CHAT;
        this.connection = new HubConnectionBuilder().withUrl(signalREndpoint, { accessTokenFactory: () => res.access_token }).build();
        this.connection.start().then(() => {
            this.chatEnabled = true;
        }).catch((err) => {
            this.connection?.stop();
        });
    };

    private escalateDispute = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>): void => {
        this.activityService.escalateDisputeAsync(this.dispute._links.EscalateDispute.href, payload).subscribe({
            next: (event: DisputeEscalatedEventView) => {
                this.handleDisputeEscalated(new DisputeEscalatedEventView(event));
                modalRef.close(this.dispute);
            },
            error: this.onFailure
        });
    };

    private async getLatestDispute(): Promise<DisputeView> {
        const dispute = await lastValueFrom(this.activityService.getDisputeByIdObservable(this.dispute.id));
        dispute.booking = ObjectUtilities.deepCopy(this.dispute.booking);
        dispute.disputeMessages && dispute.disputeMessages.forEach(message => message.fundRequest.amountCurrency = this.currencySymbol + message.fundRequest.amount);
        return dispute;
    };

    private handleDisputeMessage = (message: DisputeMessageView): void => {
        const messageView = new DisputeMessageView(message);
        if (messageView.fundsRemaining >= 0) {
            this.dispute.booking.availableFundRequest.updateFundsRemaining(Boolean(this.currentPartnerType == 'Operator'), message.fundsRemaining);
        };
        this.dispute.disputeMessages.push(messageView);
        this.dispute.lastMessage = messageView;
        this.scrollToBottom(1000);
    };

    private handleDisputeEscalated = (event: DisputeEscalatedEventView): void => {
        delete event['_links'];
        this.dispute = new DisputeView({ ...this.dispute, ...event });
    };

    private handleDisputeResolved = (event: DisputeResolvedEventView): void => {
        this.dispute = new DisputeView({ ...this.dispute, ...event });
    };

    private handleFundRequestStatusChange = async (message: DisputeMessageView): Promise<void> => {
        this.handleDisputeMessage(message);
        // Get the latest dispute and hal links to show the request funds button once the original request has been approved, rejected, or cancelled
        this.dispute = await this.getLatestDispute();
    };

    private listenToSignalREvents(): void {
        this.connection.on("DisputeEscalated", this.handleDisputeEscalated);
        this.connection.on("DisputeResolved", this.handleDisputeResolved);
        this.connection.on("FundRequestApproved", this.handleFundRequestStatusChange);
        this.connection.on("FundRequestCancelled", this.handleFundRequestStatusChange);
        this.connection.on("FundRequestCreated", this.handleDisputeMessage);
        this.connection.on("FundRequestRejected", this.handleFundRequestStatusChange);
        this.connection.on("NewDisputeMessage", this.handleDisputeMessage);
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.sendingMessage = false;
        if (this.disputeReasonModalRef) {
            this.disputeReasonModalRef.componentInstance.confirming = false;
        };
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('global.modals.activity_dispute.dispute'));
    };

    private onSuccess = (): void => {
        this.sendingMessage = false;
        this.newMessage = '';
        this.scrollToBottom();
    };

    private rejectFundRequest = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>, fundRequest: DisputeFundsRequestView): void => {
        this.activityService.rejectFundsRequestAsync(fundRequest._links.RejectFundRequest.href, payload).subscribe({
            next: async (res: DisputeFundsRequestView) => {
                const dispute: DisputeView = await this.getLatestDispute();
                modalRef.close(dispute);
            },
            error: this.onFailure
        });
    };

    private requestFunds = (modalRef: NgbModalRef, payload: CreateFundsRequestPayload): void => {
        this.activityService.createFundsRequestAsync(this.dispute._links.CreateFundRequest.href, payload).subscribe({
            next: async (res: DisputeFundsRequestView) => {
                const dispute: DisputeView = await this.getLatestDispute();
                modalRef.close(dispute);
            },
            error: this.onFailure
        });
    };

    private resolveDispute = (modalRef: NgbModalRef, payload: Partial<CreateFundsRequestPayload>): void => {
        this.activityService.resolveDisputeAsync(this.dispute._links.ResolveDispute.href, payload).subscribe({
            next: (event: DisputeResolvedEventView) => {
                this.handleDisputeResolved(event);
                modalRef.close(this.dispute);
            },
            error: this.onFailure
        });
    };

    private scrollToBottom(delayMilliseconds: number = 0): void {
        setTimeout(() => {
            this.messagesList.nativeElement.scrollTop = this.messagesList.nativeElement.scrollHeight;
        }, delayMilliseconds);
    };

    private showDisputeReasonModal(config: IDisputeReasonModalConfig): void {
        this.disputeReasonModalRef = this.sharedModalsService.showDisputeReasonModal(config, { windowClass: this.windowClass });
        this.disputeReasonModalRef.result.then((dispute: DisputeView) => {
            this.dispute = dispute;
            if (config.successTitle && config.successMessage) {
                this.sharedModalsService.showAlertModal(config.successTitle, config.successMessage, { windowClass: this.windowClass });
            };
            setTimeout(() => {
                this.messagesList.nativeElement.scrollTop = this.messagesList.nativeElement.scrollHeight;
            });
            this.disputeReasonModalRef = null;
        }).catch(() => { this.disputeReasonModalRef = null; });
    };
};