import { Injectable } from '@angular/core';
import { Router, RouteConfigLoadEnd } from '@angular/router';
import { forkJoin, lastValueFrom, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { GhostTranslationService } from './ghost-translation.service';
import { LoadingService } from './loading.service';
import { RouterPreloaderService } from './router-preloader.service';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class CacheLoaderService {
    public appCacheResolved: boolean = false;
    public eventsSubscription: Subscription;

    private cacheItemsTotal: number = null;
    private lazyModulesCount = 7;
    private firstPriorityObservables: Observable<any>[] = [];
    // private secondPriorityObservables: Observable<any>[] = [];
    private observablesInitialised: boolean = false;

    public constructor(
        private ghostTranslationService: GhostTranslationService,
        private loadingService: LoadingService,
        private router: Router,
        private routerPreloaderService: RouterPreloaderService,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        var asyncLoadCount = this.lazyModulesCount;
        this.eventsSubscription = this.router.events.subscribe({
            next: (event: any) => {
                if (event instanceof RouteConfigLoadEnd && event.route && event.route.loadChildren) {
                    --asyncLoadCount;
                    if (this.observablesInitialised) {
                        this.emitLoadingProgress(`Loaded ${event.route.data && event.route.data.title ? this.translateService.instant(event.route.data.title) : ''} Module`);
                    };

                    if (asyncLoadCount === 0) {
                        if (this.observablesInitialised) {
                            this.emitLoadingProgress('Finished Loading', true);
                        };
                    };
                    if (!this.observablesInitialised) {
                        --this.lazyModulesCount;
                    };
                };
            }
        });
    };

    public fetchAppData(): Observable<boolean> {
        this.initAppCacheObservables();
        this.ghostTranslationService.setDefaultLanguageTranslation().subscribe();
        return forkJoin(this.firstPriorityObservables).pipe(
            mergeMap(async () => {
                SettingsService.LanguageCode = StorageService.getItem('languageCode') || SettingsService.LanguageCode;
                this.ghostTranslationService.setSystemLanguageTranslation(SettingsService.LanguageCode).subscribe();
                await lastValueFrom(this.routerPreloaderService.preloadAllModules(), { defaultValue: null });
                this.ghostTranslationService.setTableTranslations();
                this.eventsSubscription?.unsubscribe();
                return this.appCacheResolved = true;
            })
        );
    };

    private emitLoadingProgress(desription: string, finishedLoading?: boolean): void {
        this.loadingService.emitProgress({
            description: this.translateService.instant(desription),
            progress: !finishedLoading ? this.loadingService.getProgress(this.cacheItemsTotal) : 100
        }, finishedLoading);
    };

    private initAppCacheObservables(): void {
        this.firstPriorityObservables = [
            this.userService.getCurrentUserObservable().pipe(tap(() => {
                this.emitLoadingProgress('loading.loaded_user_profile');
            }))

            // Types
        ];

        this.observablesInitialised = true;
        this.cacheItemsTotal = this.firstPriorityObservables.length + this.lazyModulesCount;
    };
};
