import { Injectable } from "@angular/core";
import { BankAccountInfoApi, BankAccountInfoView, GetAllBankAccountsResponseApi, GetAllBankAccountsResponseView, iGoCompanyOperatorView, iGoCompanyOriginatorView } from "src/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RequestService } from "./request.service";
import { Endpoints } from "src/shared/endpoints";

@Injectable({ providedIn: 'root' })
export class BankingService {
    private currencyCodes = [];
    
    public constructor(
        private http: RequestService,
    ) { };

    public getCurrencyCodes = (): string[] => this.currencyCodes;

    public createBankAccountInforamtionAsync(url: string, payload: BankAccountInfoView, originator?: iGoCompanyOriginatorView): Observable<BankAccountInfoView> {
        payload.agentId = originator?.id;
        return this.http.authenticatedPost<BankAccountInfoApi>(url, new BankAccountInfoApi(payload), {}, 'global.request.creating_bank_account_information').pipe(
            map(res => new BankAccountInfoView(res as BankAccountInfoView))
        )
    };

    public deleteBankAccountInforamtionAsync(partner: iGoCompanyOriginatorView | iGoCompanyOperatorView, currencyCode: string): Observable<string> {
        const url = partner instanceof iGoCompanyOriginatorView ? Endpoints.ORIGINATORS._ORIGINATORS : Endpoints.OPERATORS._OPERATORS;
        return this.http.authenticatedDelete<BankAccountInfoApi>(Endpoints.API_URL + url + `/${partner.id}` + Endpoints.BANK_ACCOUNT + `/${currencyCode}`, {}, 'global.request.deleting_bank_account_information').pipe(
            map(res => currencyCode)
        )
    };

    public getBankAccountsObservable(url: string): Observable<GetAllBankAccountsResponseView> {
        return this.http.authenticatedGet<GetAllBankAccountsResponseApi>(url, {}, 'global.request.loading_bank_accounts').pipe(
            map(res => new GetAllBankAccountsResponseView(res as GetAllBankAccountsResponseView))
        )
    };

    public getCurrencyCodesObservable(): Observable<void> {
        return this.http.authenticatedGet<{ currencyCodes: string[] }>(Endpoints.API_URL + Endpoints.TYPES._CURRENCY_CODES, {}, 'global.request.loading_currency_codes').pipe(
            map(res => {
                this.currencyCodes = res.currencyCodes;
            })
        );
    };
    
    public updateBankAccountInforamtionAsync(url: string, bankAccountInformation: BankAccountInfoView): Observable<BankAccountInfoView> {
        return this.http.authenticatedPut<BankAccountInfoApi>(url, new BankAccountInfoApi(bankAccountInformation), {}, 'global.request.updating_bank_account_information').pipe(
            map(res => new BankAccountInfoView(res as BankAccountInfoView))
        )
    };
};