import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.name == 'Production') {
  document.write(`<script type="text/javascript">${(function (n, t, a, e, co) {
    var i = "aptrinsic"; n[i] = n[i] || function () {
      (n[i].q = n[i].q || []).push(arguments)
    }, n[i].p = e; n[i].c = co;
    var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
    var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
  })(window, document, "https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js", "AP-0Z2AAXPYZKUC-2")}</script>`);
} else if (environment.name == 'Staging' || environment.name == 'Local') {
  document.write(`<script type="text/javascript">${(function (n, t, a, e, co) {
    var i = "aptrinsic"; n[i] = n[i] || function () {
      (n[i].q = n[i].q || []).push(arguments)
    }, n[i].p = e; n[i].c = co;
    var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
    var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
  })(window, document, "https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js", "AP-0Z2AAXPYZKUC-2-2")}</script>`);
} else if (environment.name == 'Alpha') {
  document.write(`<script type="text/javascript">${(function (n, t, a, e, co) {
    var i = "aptrinsic"; n[i] = n[i] || function () {
      (n[i].q = n[i].q || []).push(arguments)
    }, n[i].p = e; n[i].c = co;
    var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
    var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
  })(window, document, "https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js", "AP-0Z2AAXPYZKUC-2-4")}</script>`);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));