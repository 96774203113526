import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ArrayUtilities } from 'src/shared/array.utilities';
import { ConnectedCXMarketplaceApi, CreateCXConnectionPayloadView, CXConnectionView, CXVendorView } from 'src/models';
import { ConnectionsService, SharedModalsService } from 'src/services';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadInputComponent } from 'src/shared/components/typeahead-input/typeahead-input.component';
import { IHalLink } from 'src/interfaces/hal-link.interface';

@Component({
    selector: 'create-cx-connection-modal',
    templateUrl: './create-cx-connection.modal.html'
})

export class CreateCXConnectionModalComponent implements AfterViewInit {
    @ViewChild('operatorTypeahead') public operatorTypeahead: TypeaheadInputComponent;
    @ViewChild('marketplaceTypeahead') public marketplaceTypeahead: TypeaheadInputComponent;
    @Input() public allOperators: CXVendorView[] = [];
    @Input() public connectedMarketplaces: ConnectedCXMarketplaceApi[] = [];
    @Input() public payloadView: CreateCXConnectionPayloadView = new CreateCXConnectionPayloadView();
    @Input() public showMarketplace: boolean = true;

    public creating: boolean = false;

    public constructor(
        public createCXConnectionModal: NgbActiveModal,
        private connectionsService: ConnectionsService,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService
    ) { };

    public ngAfterViewInit(): void {
        this.initialiseOperatorTypeahead();
        this.initialiseMarketplaceTypeahead();
        this.showMarketplace && this.marketplaceTypeahead.initialiseFilteredOptions();
    };

    public onCreate(): void {
        if (!this.creating) {
            this.creating = true;
            this.connectionsService.createCXConnectionAsync(this.payloadView).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        };
    };

    private initialiseOperatorTypeahead(): void {
        this.operatorTypeahead.initialiseFilteredOptions();
    };

    private initialiseMarketplaceTypeahead(): void {
        this.connectedMarketplaces = ArrayUtilities.sortAlphabetically(this.connectedMarketplaces, 'name');
    };

    public onSelectOperator($event: CXVendorView) {
        this.payloadView.operatorId = $event && $event.id;
        this.payloadView.operatorName = $event && $event.name;
    };

    public onSelectMarketplace($event: ConnectedCXMarketplaceApi) {
        this.payloadView.name = $event && $event.name;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.creating = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('igo_portal.network.create_connection'));
    };

    private onSuccess = (res: CXConnectionView[]): void => this.createCXConnectionModal.close(res);
};