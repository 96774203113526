import { EventEmitter, HostListener } from "@angular/core";
import { Directive, Output } from "@angular/core";
import { interval, Observable, Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

@Directive({
    selector: '[holdable]'
})
export class HoldableDirective {
    @Output() public holdTime: EventEmitter<number> = new EventEmitter<number>();
    public cancel: Observable<string>;
    public state: Subject<string> = new Subject<string>();

    public constructor() {
        this.cancel = this.state.pipe(
            filter(v => v === 'cancel'),
            tap(v => this.holdTime.emit(0))
        );
    };

    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    public onExit(): void {
        this.state.next('cancel');
    };

    @HostListener('mousedown', ['$event'])
    public onHold(): void {
        this.state.next('start');
        const n = 100;
        interval(n).pipe(
            takeUntil(this.cancel),
            tap(v => this.holdTime.emit(v * n))
        ).subscribe();
    };
};