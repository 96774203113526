import { CoordinateApi } from "./coordinate.model";

export class AddressDetailsApi {
    public address1: string;
    public address2: string;
    public building: string;
    public city: string;
    public country: string;
    public postalCode: string;
    public province: string;

    public constructor(item?: AddressDetailsApi) {
        this.address1 = (item && item.address1) ? item.address1 : '';
        this.address2 = (item && item.address2) ? item.address2 : '';
        this.building = (item && item.building) ? item.building : '';
        this.city = (item && item.city) ? item.city : '';
        this.country = (item && item.country) ? item.country : '';
        this.postalCode = (item && item.postalCode) ? item.postalCode : '';
        this.province = (item && item.province) ? item.province : '';
    };
};

export class AddressSearchEntryApi {
    public bookingPriority: 0;
    public coordinate: CoordinateApi;
    public house: string;
    public id: number;
    public isCustom: boolean;
    public note: string;
    public postcode: string;
    public source: string;
    public street: string;
    public text: string;
    public town: string;
    public zone: ZonePartialApi;
    public zoneId: number;

    public constructor(item?: AddressSearchEntryApi) {
        this.bookingPriority = (item && item.bookingPriority);
        this.coordinate = (item && item.coordinate) ? new CoordinateApi(item.coordinate) : null;
        this.house = (item && item.house) ? item.house : '';
        this.id = (item && item.id) ? item.id : null;
        this.isCustom = (item && item.isCustom) ? true : false;
        this.note = (item && item.note) ? item.note : '';
        this.postcode = (item && item.postcode) ? item.postcode : '';
        this.source = (item && item.source) ? item.source : '';
        this.street = (item && item.street) ? item.street : '';
        this.text = (item && item.text) ? item.text : '';
        this.town = (item && item.town) ? item.town : '';
        this.zone = (item && item.zone) ? new ZonePartialApi(item.zone) : null;
        this.zoneId = (item && item.zoneId) ? item.zoneId : null;
    };
};

export class GoogleAddressApi {
    public description: string;
    public id: string;
    public place_id: string;
    public reference: string;

    public constructor(item: any) {
        this.description = item.description;
        this.id = item.id;
        this.place_id = item.place_id;
        this.reference = item.reference;
    };
};

export class GooglePlaceApi {
    public address_components: GooglePlaceApiAddressComponent;
    public formatted_address: string;
    public geometry: GooglePlaceApiGeometry;
    public place_id: string;
    public types: string[];

    public constructor(item: GooglePlaceApi) {
        this.address_components = new GooglePlaceApiAddressComponent(item.address_components);
        this.formatted_address = item.formatted_address;
        this.geometry = new GooglePlaceApiGeometry(item.geometry);
        this.place_id = item.place_id;
        this.types = item.types;
    };
};

export class GooglePlaceApiAddressComponent {
    public long_name: string;
    public short_name: string;
    public types: string[];

    public constructor(item: GooglePlaceApiAddressComponent) {
        this.long_name = item.long_name;
        this.short_name = item.short_name;
        this.types = item.types;
    };
};

export class GooglePlaceApiGeometry {
    public location: {
        lat: () => {},
        lng: () => {}
    };

    public constructor(item: GooglePlaceApiGeometry) {
        this.location.lat = item.location.lat;
        this.location.lng = item.location.lng;
    };
};

export class GoogleAddressView {
    public coordinate: CoordinateApi;
    public house: number;
    public source: string;
    public street: string;
    public text: string;
    public town: string;
    public zoneId: number;

    public constructor(item: GoogleAddressView) {
        this.coordinate = (item && item.coordinate) ? new CoordinateApi(item.coordinate) : new CoordinateApi();
        this.house = (item && typeof item.house == 'number') ? item.house : null;
        this.source = (item && item.source) ? item.source : '';
        this.street = (item && item.street) ? item.street : '';
        this.text = (item && item.text) ? item.text : '';
        this.town = (item && item.town) ? item.town : '';
        this.zoneId = (item && item.zoneId) ? item.zoneId : null;
    };
};

export class ZonePartialApi {
    public descriptor: string;
    public id: number;
    public name: string;

    public constructor(item?: ZonePartialApi) {
        this.descriptor = (item && item.descriptor) ? item.descriptor : '';
        this.id = (item && item.id) ? item.id : null;
        this.name = (item && item.name) ? item.name : '';
    };
};

export class TagAddressZoneApi {
    public descriptor: string;
    public id: number;
    public name: string;

    public constructor(item?: TagAddressZoneApi) {
        this.descriptor = (item && item.descriptor) ? item.descriptor : '';
        this.id = (item && item.id) ? item.id : null;
        this.name = (item && item.name) ? item.name : '';
    };
};

export class TagFullAddressApi {
    public bookingPriority: number;
    public coordinate: CoordinateApi;
    public house: string;
    public id: string;
    public isCustom: boolean;
    public note: string;
    public postCode: string;
    public searchTypeLetter: string;
    public source: string;
    public street: string;
    public text: string;
    public town: string;
    public zone: TagAddressZoneApi;
    public zoneId: number;

    public constructor(item?: TagFullAddressApi) {
        this.bookingPriority = (item && item.bookingPriority !== undefined) ? item.bookingPriority : 0;
        this.coordinate = (item && item.coordinate) ? new CoordinateApi(item.coordinate) : new CoordinateApi();
        this.house = (item && item.house) ? item.house : '';
        this.id = (item && item.id) ? item.id : '0';
        this.isCustom = (item && item.isCustom) ? true : false;
        this.note = (item && item.note) ? item.note : '';
        this.postCode = (item && item.postCode) ? item.postCode : '';
        this.searchTypeLetter = (item && item.searchTypeLetter) ? item.searchTypeLetter : '';
        this.source = (item && item.source) ? item.source : 'Empty';
        this.street = (item && item.street) ? item.street : '';
        this.text = (item && item.text) ? item.text : '';
        this.town = (item && item.town) ? item.town : '';
        this.zone = (item && item.zone) ? new TagAddressZoneApi(item.zone) : new TagAddressZoneApi();
        this.zoneId = (item && item.zoneId) ? item.zoneId : null;
    };
};