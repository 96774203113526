import { CoordinateApi } from "./coordinate.model";
import { CreditNoteApi } from "./invoice.model";
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { GenericDatePipe } from "src/shared/pipes/generic-date.pipe";
import { HalLinksApi } from "./hal-link.model";
import { NumberUtilities } from "src/shared/number.utilities";

const currencyPipe = new CurrencyGlobalPipe();
const datePipeShort = new GenericDatePipe();
datePipeShort.format = 'short';

export class GetBookingsPayload {
    public bookingReference: string;
    public count: number;
    public dropoff: string;
    public from: Date | string;
    public marketplaceId: string;
    public operatorIds: string[];
    public originatorIds: string[];
    public passengerName: string;
    public passengerEmail: string;
    public pickup: string;
    public statuses: string[];
    public to: Date | string;
    public totalFilters: number;

    public getTotalFilters(): void {
        const filterKeys: string[] = ['bookingReference', 'dropoff', 'marketplaceId', 'operatorIds', 'passengerName', 'passengerEmail', 'originatorIds', 'pickup', 'statuses'];
        const keys: string[] = Object.keys(this).filter(k => filterKeys.indexOf(k) >= 0);
        this.totalFilters = 0;
        keys.forEach(key => {
            if (typeof this[key] == 'number') {
                this[key] > 0 && this.totalFilters++;
            } else {
                this[key] && this[key].length && this.totalFilters++;
            };
        });
    };

    public constructor(item?: GetBookingsPayload) {
        this.bookingReference = (item && item.bookingReference) ? item.bookingReference : null;
        this.count = 100;
        this.dropoff = (item && item.dropoff) ? item.dropoff : null;
        this.from = (item && item.from) ? new Date(item.from) : null;
        this.marketplaceId = (item && item.marketplaceId) ? item.marketplaceId : null;
        this.operatorIds = (item && item.operatorIds) ? item.operatorIds : [];
        this.passengerName = (item && item.passengerName) ? item.passengerName : null;
        this.passengerEmail = (item && item.passengerEmail) ? item.passengerEmail : null;
        this.originatorIds = (item && item.originatorIds) ? item.originatorIds : [];
        this.pickup = (item && item.pickup) ? item.pickup : null;
        this.statuses = (item && item.statuses) ? item.statuses : [];
        this.to = (item && item.to) ? new Date(item.to) : null;
        this.getTotalFilters();
    };
};

export class GetAllBookingsResponseApi extends HalLinksApi {
    public bookings: BookingApi[];

    public constructor(item: GetAllBookingsResponseApi) {
        super(item);
        this.bookings = item.bookings.map(i => new BookingApi(i));
    };
};

export class GetAllBookingsResponseView extends GetAllBookingsResponseApi {
    declare public bookings: BookingView[];

    public constructor(item: GetAllBookingsResponseView) {
        super(item);
        this.bookings = item.bookings.map(i => new BookingView(i));
    };
};

export class BookingApi extends HalLinksApi {
    public authorizationReference: string;
    public availableFundRequest: BookingAvailableFundRequestApi;
    public bookingCreated: string;
    public bookingId: string;
    public bookingReference: string;
    public cabExchangeAgentId: string;
    public cabExchangeVendorId: string;
    public driver: BookingDriverDetailsApi;
    public id: string;
    public journey: BookingJourneyDetailsApi;
    public operatorId: string;
    public operatorName: string;
    public operatorPricing: BookingPricingApi;
    public originatorBookingReference: string;
    public originatorId: string;
    public originatorName: string;
    public passenger: BookingPassengerDetailsApi;
    public payment: BookingPaymentDetailsApi;
    public pricing: BookingPricingApi;
    public quotedEtaAccuracyInSeconds: number;
    public ride: BookingRideDetailsApi;
    public source: string;
    public status: string;
    public timing: BookingTimingApi;
    public vehicle: BookingVehicleDetailsApi;

    public constructor(item?: BookingApi) {
        super(item);
        this.authorizationReference = (item && item.authorizationReference) ? item.authorizationReference : '';
        this.availableFundRequest = (item && item.availableFundRequest) ? new BookingAvailableFundRequestApi(item.availableFundRequest) : new BookingAvailableFundRequestApi();
        this.bookingCreated = (item && item.bookingCreated) ? item.bookingCreated : '';
        this.bookingId = (item && item.bookingId) ? item.bookingId : '';
        this.bookingReference = (item && item.bookingReference) ? item.bookingReference : '';
        this.cabExchangeAgentId = (item && item.cabExchangeAgentId) ? item.cabExchangeAgentId : '';
        this.cabExchangeVendorId = (item && item.cabExchangeVendorId) ? item.cabExchangeVendorId : '';
        this.driver = (item && item.driver) ? new BookingDriverDetailsApi(item.driver) : new BookingDriverDetailsApi();
        this.id = (item && item.id) ? item.id : '';
        this.journey = (item && item.journey) ? new BookingJourneyDetailsApi(item.journey) : new BookingJourneyDetailsApi();
        this.operatorId = (item && item.operatorId) ? item.operatorId : '';
        this.operatorName = (item && item.operatorName) ? item.operatorName : '';
        this.operatorPricing = (item && item.operatorPricing) ? new BookingPricingApi(item.operatorPricing) : new BookingPricingApi();
        this.originatorBookingReference = (item && item.originatorBookingReference) ? item.originatorBookingReference : '';
        this.originatorId = (item && item.originatorId) ? item.originatorId : '';
        this.originatorName = (item && item.originatorName) ? item.originatorName : '';
        this.passenger = (item && item.passenger) ? new BookingPassengerDetailsApi(item.passenger) : new BookingPassengerDetailsApi();
        this.payment = (item && item.payment) ? new BookingPaymentDetailsApi(item.payment) : new BookingPaymentDetailsApi();
        this.pricing = (item && item.pricing) ? new BookingPricingApi(item.pricing) : new BookingPricingApi();
        this.quotedEtaAccuracyInSeconds = (item && typeof item.quotedEtaAccuracyInSeconds == 'number') ? item.quotedEtaAccuracyInSeconds : null;
        this.ride = (item && item.ride) ? new BookingRideDetailsApi(item.ride) : new BookingRideDetailsApi();
        this.source = (item && item.source) ? item.source : '';
        this.status = (item && item.status) ? item.status : '';
        this.timing = (item && item.timing) ? new BookingTimingApi(item.timing) : new BookingTimingApi();
        this.vehicle = (item && item.vehicle) ? new BookingVehicleDetailsApi(item.vehicle) : new BookingVehicleDetailsApi();
    };
};

export class BookingView extends BookingApi {
    declare public availableFundRequest: BookingAvailableFundRequestView;
    public baseFrom: CoordinateApi;
    public baseTo: CoordinateApi;
    public bookingCreatedView: string;
    declare public driver: BookingDriverDetailsView;
    public hasMovedMapMarker: boolean;
    declare public operatorPricing: BookingPricingView;
    declare public payment: BookingPaymentDetailsView;
    declare public pricing: BookingPricingView;
    public quotedEtaAccuracyIsLate: boolean;
    public quotedEtaAccuracyLateness: { minutes: number, seconds: number; };
    public quotedEtaAccuracyLatenessDisplayString: string;
    public quotedEtaAccuracyLatenessView: string;
    public statusView: { status: string, class: string; };
    declare public timing: BookingTimingView;
    public vendorPricing: BookingPricingView;

    public constructor(item?: BookingView) {
        super(item);
        this.availableFundRequest = (item && item.availableFundRequest) ? new BookingAvailableFundRequestView(item.availableFundRequest) : new BookingAvailableFundRequestView();
        this.baseFrom = (item && item.baseFrom) ? new CoordinateApi(item.baseFrom) : null;
        this.baseTo = (item && item.baseTo) ? new CoordinateApi(item.baseTo) : null;
        this.bookingCreatedView = (item && item.bookingCreated) ? datePipeShort.transform(item.bookingCreated) : '';
        this.driver = (item && item.driver) ? new BookingDriverDetailsView(item.driver) : new BookingDriverDetailsView();
        this.hasMovedMapMarker = (item && item.hasMovedMapMarker) ? true : false;
        this.operatorPricing = (item && item.operatorPricing) ? this.setBookingPrice(item.operatorPricing) : new BookingPricingView();
        this.payment = (item && item.payment) ? new BookingPaymentDetailsView(item.payment) : new BookingPaymentDetailsView();
        this.pricing = (item && item.pricing) ? this.setBookingPrice(item.pricing) : new BookingPricingView();
        this.quotedEtaAccuracyIsLate = (item && item.quotedEtaAccuracyInSeconds) ? Boolean(item.quotedEtaAccuracyInSeconds > 0) : false;
        this.quotedEtaAccuracyLateness = (item && item.quotedEtaAccuracyInSeconds) ? NumberUtilities.numberToMinutesAndSeconds(item.quotedEtaAccuracyInSeconds) : { minutes: 0, seconds: 0 };
        this.quotedEtaAccuracyLatenessView = this.quotedEtaAccuracyLateness && this.getQuotedEtaLateness(this.quotedEtaAccuracyLateness);
        this.quotedEtaAccuracyLatenessDisplayString = (this.quotedEtaAccuracyLatenessView && item && item.quotedEtaAccuracyInSeconds) ? this.quotedEtaAccuracyLatenessView + (item.quotedEtaAccuracyInSeconds > 0 ? ' Late' : ' Early') : '';
        this.statusView = (item && item.status) ? this.getBookingStatus() : { status: null, class: null };
        this.timing = (item && item.timing) ? new BookingTimingView(item.timing) : new BookingTimingView();
        this.vendorPricing = (item && item.vendorPricing) ? this.setBookingPrice(item.vendorPricing) : new BookingPricingView();
    };

    public setBookingPrice(pricing: BookingPricingView) {
        if (this.status == 'CancelledByAgent' || this.status == 'CancelledByVendor' || this.status == 'Cancelled' || this.status == 'NoFare') {
            const noFareCharge = pricing.noFareCharge ? pricing.noFareCharge : 0;
            const cancellationCharge = pricing.cancellationCharge ? pricing.cancellationCharge : 0;
            const originatorCommission = pricing.originatorCommission ? pricing.originatorCommission : 0;
            const serviceCharge = pricing.serviceCharge ? pricing.serviceCharge : 0;
            pricing.price = originatorCommission + serviceCharge + (cancellationCharge ? cancellationCharge : noFareCharge);
        }
        return new BookingPricingView(pricing);
    }

    public getBookingStatus(): { status: string, class: string; } {
        if (this.status == 'CancelledByAgent' || this.status == 'CancelledByVendor' || this.status == 'Cancelled') {
            const { cancellationCharge } = this.pricing;
            return { status: cancellationCharge ? 'Cancelled - charge applied' : 'Cancelled', class: 'bg-danger' };
        } else if (this.status == 'NoFare') {
            const { noFareCharge } = this.pricing;
            return { status: noFareCharge ? 'No Fare - charge applied' : 'No Fare', class: 'bg-warning' };
        } else if (this.status == 'AutoRejected' || this.status == 'ManualRejected') {
            return { status: 'Rejected', class: 'bg-warning' };
        } else if (this.status == 'AutoRecovered' || this.status == 'Dispatched') {
            return { status: 'Recovered', class: 'bg-info' };
        } else if (this.status == 'Completed') {
            return { status: this.status, class: 'bg-success' };
        } else {
            return { status: this.status, class: '' };
        };
    };

    private getQuotedEtaLateness(quotedEtaAccuracyLateness: { minutes: number, seconds: number; }): string {
        let minutesString: string = '';
        let secondsString: string = '';
        if (quotedEtaAccuracyLateness.minutes !== 0) {
            minutesString = `${Math.abs(quotedEtaAccuracyLateness.minutes)} ${quotedEtaAccuracyLateness.minutes == 1 || quotedEtaAccuracyLateness.minutes == -1 ? ' Minute, ' : 'Minutes, '}`;
        };
        if (quotedEtaAccuracyLateness.seconds !== 0) {
            secondsString = `${Math.abs(quotedEtaAccuracyLateness.seconds)} ${quotedEtaAccuracyLateness.seconds == 1 || quotedEtaAccuracyLateness.seconds == -1 ? ' Second' : 'Seconds'}`;
        };
        return `${minutesString}${secondsString}`;
    };
};

export class BookingAvailableFundRequestApi {
    public currency: string;
    public forOperator: number;
    public forOriginator: number;

    public constructor(item?: BookingAvailableFundRequestApi) {
        this.currency = (item && item.currency) ? item.currency : '';
        this.forOperator = (item && item.forOperator) ? item.forOperator : 0;
        this.forOriginator = (item && item.forOriginator) ? item.forOriginator : 0;
    };
};

export class BookingAvailableFundRequestView extends BookingAvailableFundRequestApi {
    public forOperatorCurrency: string;
    public forOriginatorCurrency: string;

    public constructor(item?: BookingAvailableFundRequestView) {
        super(item);
        const currencyPipe = new CurrencyGlobalPipe();
        currencyPipe.currency = item.currency;
        this.forOperatorCurrency = (item && typeof item.forOperator == 'number') ? currencyPipe.transform(item.forOperator) : '';
        this.forOriginatorCurrency = (item && typeof item.forOriginator == 'number') ? currencyPipe.transform(item.forOriginator) : '';
    };

    public updateFundsRemaining(isOperator: boolean, amount: number): void {
        if (isOperator) {
            this.forOperator = amount;
            this.forOperatorCurrency = new CurrencyGlobalPipe().transform(amount);
        } else {
            this.forOriginator = amount;
            this.forOriginatorCurrency = new CurrencyGlobalPipe().transform(amount);
        };
    };
};

export class BookingTimingApi {
    public bookedAtTime: string;
    public bookedAtTimeUTC: string;
    public completionTime: string;
    public completionTimeUTC: string;
    public dispatchTime: string;
    public dispatchTimeUTC: string;
    public etaAtDispatch: string;
    public etaAtDispatchUTC: string;
    public pickedUpTime: string;
    public pickedUpTimeUTC: string;
    public pickupDueTime: string;
    public pickupDueTimeUTC: string;
    public quotedETA: string;
    public quotedETAUTC: string;
    public vehicleArrivalTime: string;
    public vehicleArrivalTimeUTC: string;

    public constructor(item?: BookingTimingApi) {
        this.bookedAtTime = (item && item.bookedAtTime) ? item.bookedAtTime : '';
        this.bookedAtTimeUTC = (item && item.bookedAtTimeUTC) ? item.bookedAtTimeUTC : '';
        this.completionTime = (item && item.completionTime) ? item.completionTime : '';
        this.completionTimeUTC = (item && item.completionTimeUTC) ? item.completionTimeUTC : '';
        this.dispatchTime = (item && item.dispatchTime) ? item.dispatchTime : '';
        this.dispatchTimeUTC = (item && item.dispatchTimeUTC) ? item.dispatchTimeUTC : '';
        this.etaAtDispatch = (item && item.etaAtDispatch) ? item.etaAtDispatch : '';
        this.etaAtDispatchUTC = (item && item.etaAtDispatchUTC) ? item.etaAtDispatchUTC : '';
        this.pickedUpTime = (item && item.pickedUpTime) ? item.pickedUpTime : '';
        this.pickedUpTimeUTC = (item && item.pickedUpTimeUTC) ? item.pickedUpTimeUTC : '';
        this.pickupDueTime = (item && item.pickupDueTime) ? item.pickupDueTime : '';
        this.pickupDueTimeUTC = (item && item.pickupDueTimeUTC) ? item.pickupDueTimeUTC : '';
        this.quotedETA = (item && item.quotedETA) ? item.quotedETA : '';
        this.quotedETAUTC = (item && item.quotedETAUTC) ? item.quotedETAUTC : '';
        this.vehicleArrivalTime = (item && item.vehicleArrivalTime) ? item.vehicleArrivalTime : '';
        this.vehicleArrivalTimeUTC = (item && item.vehicleArrivalTimeUTC) ? item.vehicleArrivalTimeUTC : '';
    };
};

export class BookingTimingView extends BookingTimingApi {
    public bookedAtTimeView: string;
    public bookedAtTimeUTCView: string;
    public completionTimeView: string;
    public completionTimeUTCView: string;
    public dispatchTimeView: string;
    public dispatchTimeUTCView: string;
    public etaAtDispatchView: string;
    public etaAtDispatchUTCView: string;
    public pickedUpTimeView: string;
    public pickedUpTimeUTCView: string;
    public pickupDueTimeView: string;
    public pickupDueTimeUTCView: string;
    public quotedETAView: string;
    public quotedETAUTCView: string;
    public vehicleArrivalTimeView: string;
    public vehicleArrivalTimeUTCView: string;

    public constructor(item?: BookingTimingView) {
        super(item);
        this.bookedAtTimeView = (item && item.bookedAtTime) ? datePipeShort.transform(item.bookedAtTime) : '';
        this.bookedAtTimeUTCView = (item && item.bookedAtTimeUTC) ? datePipeShort.transform(item.bookedAtTimeUTC) : '';
        this.completionTimeView = (item && item.completionTime) ? datePipeShort.transform(item.completionTime) : '';
        this.completionTimeUTCView = (item && item.completionTimeUTC) ? datePipeShort.transform(item.completionTimeUTC) : '';
        this.dispatchTimeView = (item && item.dispatchTime) ? datePipeShort.transform(item.dispatchTime) : '';
        this.dispatchTimeUTCView = (item && item.dispatchTimeUTC) ? datePipeShort.transform(item.dispatchTimeUTC) : '';
        this.etaAtDispatchView = (item && item.etaAtDispatch) ? datePipeShort.transform(item.etaAtDispatch) : '';
        this.etaAtDispatchUTCView = (item && item.etaAtDispatchUTC) ? datePipeShort.transform(item.etaAtDispatchUTC) : '';
        this.pickedUpTimeView = (item && item.pickedUpTime) ? datePipeShort.transform(item.pickedUpTime) : '';
        this.pickedUpTimeUTCView = (item && item.pickedUpTimeUTC) ? datePipeShort.transform(item.pickedUpTimeUTC) : '';
        this.pickupDueTimeView = (item && item.pickupDueTime) ? datePipeShort.transform(item.pickupDueTime) : '';
        this.pickupDueTimeUTCView = (item && item.pickupDueTimeUTC) ? datePipeShort.transform(item.pickupDueTimeUTC) : '';
        this.quotedETAView = (item && item.quotedETA) ? datePipeShort.transform(item.quotedETA) : '';
        this.quotedETAUTCView = (item && item.quotedETAUTC) ? datePipeShort.transform(item.quotedETAUTC) : '';
        this.vehicleArrivalTimeView = (item && item.vehicleArrivalTime) ? datePipeShort.transform(item.vehicleArrivalTime) : '';
        this.vehicleArrivalTimeUTCView = (item && item.vehicleArrivalTimeUTC) ? datePipeShort.transform(item.vehicleArrivalTimeUTC) : '';
    };
};

export class BookingJourneyDetailsApi {
    public from: BookingJourneyPointApi;
    public to: BookingJourneyPointApi;
    public vias: BookingJourneyPointApi[];

    public constructor(item?: BookingJourneyDetailsApi) {
        this.from = (item && item.from) ? new BookingJourneyPointApi(item.from) : new BookingJourneyPointApi();
        this.to = (item && item.to) ? new BookingJourneyPointApi(item.to) : new BookingJourneyPointApi();
        this.vias = (item && item.vias) ? item.vias.map(i => new BookingJourneyPointApi(i)) : [];
    };
};

export class BookingJourneyPointApi {
    public address: string;
    public coordinate: CoordinateApi;

    public constructor(item?: BookingJourneyPointApi) {
        this.address = (item && item.address) ? item.address : '';
        this.coordinate = (item && item.coordinate) ? new CoordinateApi(item.coordinate) : new CoordinateApi();
    };
};

export class BookingPricingApi {
    public agentCommission: number;
    public agentCommissionVAT: number;
    public cancellationCharge: number;
    public currency: string;
    public extrasPrice: number;
    public fare: number;
    public fees: number;
    public feesVAT: number;
    public gratuity: number;
    public noFareCharge: number;
    public originatorCommission: number;
    public originatorCommissionVAT: number;
    public price: number;
    public priceNET: number;
    public quotedPrice: number;
    public serviceCharge: number;
    public serviceChargeVAT: number;
    public vat: number;
    public waitingTimeMinutes: number;
    public waitingTimePrice: number;

    public constructor(item?: BookingPricingApi) {
        this.agentCommission = (item && typeof item.agentCommission == 'number') ? item.agentCommission : null;
        this.agentCommissionVAT = (item && typeof item.agentCommissionVAT == 'number') ? item.agentCommissionVAT : null;
        this.cancellationCharge = (item && typeof item.cancellationCharge == 'number') ? item.cancellationCharge : null;
        this.currency = (item && item.currency) ? item.currency : '';
        this.extrasPrice = (item && typeof item.extrasPrice == 'number') ? item.extrasPrice : null;
        this.fare = (item && typeof item.fare == 'number') ? item.fare : null;
        this.fees = (item && typeof item.fees == 'number') ? item.fees : null;
        this.feesVAT = (item && typeof item.feesVAT == 'number') ? item.feesVAT : null;
        this.gratuity = (item && typeof item.gratuity == 'number') ? item.gratuity : null;
        this.noFareCharge = (item && typeof item.noFareCharge == 'number') ? item.noFareCharge : null;
        this.originatorCommission = (item && typeof item.originatorCommission == 'number') ? item.originatorCommission : null;
        this.originatorCommissionVAT = (item && typeof item.originatorCommissionVAT == 'number') ? item.originatorCommissionVAT : null;
        this.price = (item && typeof item.price == 'number') ? item.price : null;
        this.priceNET = (item && typeof item.priceNET == 'number') ? item.priceNET : null;
        this.quotedPrice = (item && typeof item.quotedPrice == 'number') ? item.quotedPrice : null;
        this.serviceCharge = (item && typeof item.serviceCharge == 'number') ? item.serviceCharge : null;
        this.serviceChargeVAT = (item && typeof item.serviceChargeVAT == 'number') ? item.serviceChargeVAT : null;
        this.vat = (item && typeof item.vat == 'number') ? item.vat : null;
        this.waitingTimeMinutes = (item && typeof item.waitingTimeMinutes == 'number') ? item.waitingTimeMinutes : null;
        this.waitingTimePrice = (item && typeof item.waitingTimePrice == 'number') ? item.waitingTimePrice : null;
    };
};

export class BookingPricingView extends BookingPricingApi {
    public agentCommissionCurrency: string;
    public agentCommissionVATCurrency: string;
    public cancellationChargeCurrency: string;
    public extrasPriceCurrency: string;
    public fareCurrency: string;
    public feesCurrency: string;
    public feesVATCurrency: string;
    public gratuityCurrency: string;
    public noFareChargeCurrency: string;
    public originatorCommissionCurrency: string;
    public originatorCommissionVATCurrency: string;
    public priceCurrency: string;
    public priceNETCurrency: string;
    public quotedPriceCurrency: string;
    public serviceChargeCurrency: string;
    public serviceChargeVATCurrency: string;
    public vatCurrency: string;
    public waitingTimeMinutesCurrency: string;
    public waitingTimePriceCurrency: string;

    public constructor(item?: BookingPricingView) {
        super(item);
        currencyPipe.currency = item?.currency;
        this.agentCommissionCurrency = (item && typeof item.agentCommission == 'number') ? currencyPipe.transform(item.agentCommission) : '';
        this.agentCommissionVATCurrency = (item && typeof item.agentCommissionVAT == 'number') ? currencyPipe.transform(item.agentCommissionVAT) : '';
        this.cancellationChargeCurrency = (item && typeof item.cancellationCharge == 'number') ? currencyPipe.transform(item.cancellationCharge) : '';
        this.extrasPriceCurrency = (item && typeof item.extrasPrice == 'number') ? currencyPipe.transform(item.extrasPrice) : '';
        this.fareCurrency = (item && typeof item.fare == 'number') ? currencyPipe.transform(item.fare) : '';
        this.feesCurrency = (item && typeof item.fees == 'number') ? currencyPipe.transform(item.fees) : '';
        this.feesVATCurrency = (item && typeof item.feesVAT == 'number') ? currencyPipe.transform(item.feesVAT) : '';
        this.gratuityCurrency = (item && typeof item.gratuity == 'number') ? currencyPipe.transform(item.gratuity) : '';
        this.noFareChargeCurrency = (item && typeof item.noFareCharge == 'number') ? currencyPipe.transform(item.noFareCharge) : '';
        this.originatorCommissionCurrency = (item && typeof item.originatorCommission == 'number') ? currencyPipe.transform(item.originatorCommission) : '';
        this.originatorCommissionVATCurrency = (item && typeof item.originatorCommissionVAT == 'number') ? currencyPipe.transform(item.originatorCommissionVAT) : '';
        this.priceCurrency = (item && typeof item.price == 'number') ? currencyPipe.transform(item.price) : '';
        this.priceNETCurrency = (item && typeof item.priceNET == 'number') ? currencyPipe.transform(item.priceNET) : '';
        this.quotedPriceCurrency = (item && typeof item.quotedPrice == 'number') ? currencyPipe.transform(item.quotedPrice) : '';
        this.serviceChargeCurrency = (item && typeof item.serviceCharge == 'number') ? currencyPipe.transform(item.serviceCharge) : '';
        this.serviceChargeVATCurrency = (item && typeof item.serviceChargeVAT == 'number') ? currencyPipe.transform(item.serviceChargeVAT) : '';
        this.vatCurrency = (item && typeof item.vat == 'number') ? currencyPipe.transform(item.vat) : '';
        this.waitingTimeMinutesCurrency = (item && typeof item.waitingTimeMinutes == 'number') ? currencyPipe.transform(item.waitingTimeMinutes) : '';
        this.waitingTimePriceCurrency = (item && typeof item.waitingTimePrice == 'number') ? currencyPipe.transform(item.waitingTimePrice) : '';
    };
};

export class BookingPaymentDetailsApi {
    public agentCurrency: string;
    public agentExchangeRate: number;
    public amountPaidByAgent: number;
    public amountPaidByVendor: number;
    public contractReference: string;
    public feesPaidByAgent: number;
    public feesPaidByVendor: number;
    public marketPlace: string;
    public paymentPoint: string;
    public type: string;
    public vendorCurrency: string;
    public vendorExchangeRate: number;

    public constructor(item?: BookingPaymentDetailsApi) {
        this.agentCurrency = (item && item.agentCurrency) ? item.agentCurrency : '';
        this.agentExchangeRate = (item && typeof item.agentExchangeRate == 'number') ? item.agentExchangeRate : null;
        this.amountPaidByAgent = (item && typeof item.amountPaidByAgent == 'number') ? item.amountPaidByAgent : null;
        this.amountPaidByVendor = (item && typeof item.amountPaidByVendor == 'number') ? item.amountPaidByVendor : null;
        this.contractReference = (item && item.contractReference) ? item.contractReference : '';
        this.feesPaidByAgent = (item && typeof item.feesPaidByAgent == 'number') ? item.feesPaidByAgent : null;
        this.feesPaidByVendor = (item && typeof item.feesPaidByVendor == 'number') ? item.feesPaidByVendor : null;
        this.marketPlace = (item && item.marketPlace) ? item.marketPlace : '';
        this.paymentPoint = (item && item.paymentPoint) ? item.paymentPoint : '';
        this.type = (item && item.type) ? item.type : '';
        this.vendorCurrency = (item && item.vendorCurrency) ? item.vendorCurrency : '';
        this.vendorExchangeRate = (item && typeof item.vendorExchangeRate == 'number') ? item.vendorExchangeRate : null;
    };
};

export class BookingPaymentDetailsView extends BookingPaymentDetailsApi {
    public agentExchangeRateCurrency: string;
    public amountPaidByAgentCurrency: string;
    public amountPaidByVendorCurrency: string;
    public feesPaidByAgentCurrency: string;
    public feesPaidByVendorCurrency: string;
    public vendorExchangeRateCurrency: string;

    public constructor(item?: BookingPaymentDetailsView) {
        super(item);
        const agentCurrencyPipe = new CurrencyGlobalPipe();
        const vendorCurrencyPipe = new CurrencyGlobalPipe();
        agentCurrencyPipe.currency = item?.agentCurrency;
        vendorCurrencyPipe.currency = item?.vendorCurrency;
        this.agentExchangeRateCurrency = (item && typeof item.agentExchangeRate == 'number') ? agentCurrencyPipe.transform(item.agentExchangeRate) : '';
        this.amountPaidByAgentCurrency = (item && typeof item.amountPaidByAgent == 'number') ? agentCurrencyPipe.transform(item.amountPaidByAgent) : '';
        this.amountPaidByVendorCurrency = (item && typeof item.amountPaidByVendor == 'number') ? vendorCurrencyPipe.transform(item.amountPaidByVendor) : '';
        this.feesPaidByAgentCurrency = (item && typeof item.feesPaidByAgent == 'number') ? agentCurrencyPipe.transform(item.feesPaidByAgent) : '';
        this.feesPaidByVendorCurrency = (item && typeof item.feesPaidByVendor == 'number') ? vendorCurrencyPipe.transform(item.feesPaidByVendor) : '';
        this.vendorExchangeRateCurrency = (item && typeof item.vendorExchangeRate == 'number') ? vendorCurrencyPipe.transform(item.vendorExchangeRate) : '';
    };
};

export class BookingPassengerDetailsApi {
    public emailAddress: string;
    public name: string;
    public telephoneNumber: string;

    public constructor(item?: BookingPassengerDetailsApi) {
        this.emailAddress = (item && item.emailAddress) ? item.emailAddress : '';
        this.name = (item && item.name) ? item.name : '';
        this.telephoneNumber = (item && item.telephoneNumber) ? item.telephoneNumber : '';
    };
};

export class BookingRideDetailsApi {
    public driverType: string;
    public facilities: string;
    public luggageCount: number;
    public passengerCount: number;
    public vehicleCategory: string;
    public vehicleType: string;

    public constructor(item?: BookingRideDetailsApi) {
        this.driverType = (item && item.driverType) ? item.driverType : '';
        this.facilities = (item && item.facilities) ? item.facilities : '';
        this.luggageCount = (item && typeof item.luggageCount == 'number') ? item.luggageCount : null;
        this.passengerCount = (item && typeof item.passengerCount == 'number') ? item.passengerCount : null;
        this.vehicleCategory = (item && item.vehicleCategory) ? item.vehicleCategory : '';
        this.vehicleType = (item && item.vehicleType) ? item.vehicleType : '';
    };
};

export class BookingDriverDetailsApi {
    public callsign: string;
    public forename: string;
    public surname: string;

    public constructor(item?: BookingDriverDetailsApi) {
        this.callsign = (item && item.callsign) ? item.callsign : '';
        this.forename = (item && item.forename) ? item.forename : '';
        this.surname = (item && item.surname) ? item.surname : '';
    };
};

export class BookingDriverDetailsView extends BookingDriverDetailsApi {
    public fullName: string;

    public constructor(item?: BookingDriverDetailsView) {
        super(item);
        this.fullName = (item && item.forename && item.surname) ? `${item.forename} ${item.surname}` : '';
    };
};

export class BookingVehicleDetailsApi {
    public callsign: string;
    public colour: string;
    public make: string;
    public model: string;
    public registration: string;
    public yearOfManufacture: string;

    public constructor(item?: BookingVehicleDetailsApi) {
        this.callsign = (item && item.callsign) ? item.callsign : '';
        this.colour = (item && item.colour) ? item.colour : '';
        this.make = (item && item.make) ? item.make : '';
        this.model = (item && item.model) ? item.model : '';
        this.registration = (item && item.registration) ? item.registration : '';
        this.yearOfManufacture = (item && item.yearOfManufacture) ? item.yearOfManufacture : '';
    };
};

export class BookingSLAResultDetailsApi extends HalLinksApi {
    public arrival: boolean;
    public driverAcceptance: boolean;
    public id: string;
    public maximumVehicleAge: boolean;
    public name: string;
    public pickupEtaAccuracy: boolean;
    public slaId: string;
    public type: string;

    public constructor(item?: BookingSLAResultDetailsApi) {
        super(item);
        this.arrival = (item && item.arrival) ? true : false;
        this.driverAcceptance = (item && item.driverAcceptance) ? true : false;
        this.id = (item && item.id) ? item.id : '';
        this.maximumVehicleAge = (item && item.maximumVehicleAge) ? true : false;
        this.name = (item && item.name) ? item.name : '';
        this.pickupEtaAccuracy = (item && item.pickupEtaAccuracy) ? true : false;
        this.slaId = (item && item.slaId) ? item.slaId : '';
        this.type = (item && item.type) ? item.type : '';
    };
};

export class GetAllDisputesResponseApi extends HalLinksApi {
    public disputes: DisputeApi[];

    public constructor(item?: GetAllDisputesResponseApi) {
        super(item);
        this.disputes = (item && item.disputes) ? item.disputes.map(i => new DisputeApi(i)) : [];
    };
};

export class GetAllDisputesResponseView extends GetAllDisputesResponseApi {
    declare public disputes: DisputeView[];

    public constructor(item?: GetAllDisputesResponseView) {
        super(item);
        this.disputes = (item && item.disputes) ? item.disputes.map(i => new DisputeView(i)) : [];
    };
};

export class DisputeApi extends HalLinksApi {
    public bookingId: string;
    public disputeMessages: DisputeMessageApi[];
    public escalated: boolean;
    public escalatedBy: DisputeStatusUpdateApi;
    public escalatedComment: string;
    public escalatedDateTime: Date | string;
    public id: string;
    public operator: DisputePartnerDetailsApi;
    public originator: DisputePartnerDetailsApi;
    public raisedBy: DisputeStatusUpdateApi;
    public raisedDateTime: Date | string;
    public reason: string;
    public resolved: boolean;
    public resolvedBy: DisputeStatusUpdateApi;
    public resolvedComment: string;
    public resolvedDateTime: Date | string;
    public respondent: DisputePartnerDetailsApi;

    public constructor(item?: DisputeApi) {
        super(item);
        this.bookingId = (item && item.bookingId) ? item.bookingId : '';
        this.disputeMessages = (item && item.disputeMessages) ? item.disputeMessages.map(i => new DisputeMessageApi(i)) : [];
        this.escalated = (item && item.escalated) ? true : false;
        this.escalatedBy = (item && item.escalatedBy) ? new DisputeStatusUpdateApi(item.escalatedBy) : null;
        this.escalatedComment = (item && item.escalatedComment) ? item.escalatedComment : '';
        this.escalatedDateTime = (item && item.escalatedDateTime) ? new Date(item.escalatedDateTime) : null;
        this.id = (item && item.id) ? item.id : '';
        this.operator = (item && item.operator) ? new DisputePartnerDetailsApi(item.operator) : new DisputePartnerDetailsApi();
        this.originator = (item && item.originator) ? new DisputePartnerDetailsApi(item.originator) : new DisputePartnerDetailsApi();
        this.raisedBy = (item && item.raisedBy) ? new DisputeStatusUpdateApi(item.raisedBy) : null;
        this.raisedDateTime = (item && item.raisedDateTime) ? new Date(item.raisedDateTime) : null;
        this.reason = (item && item.reason) ? item.reason : '';
        this.resolved = (item && item.resolved) ? true : false;
        this.resolvedBy = (item && item.resolvedBy) ? new DisputeStatusUpdateApi(item.resolvedBy) : null;
        this.resolvedComment = (item && item.resolvedComment) ? item.resolvedComment : '';
        this.resolvedDateTime = (item && item.resolvedDateTime) ? new Date(item.resolvedDateTime) : null;
        this.respondent = (item && item.respondent) ? new DisputePartnerDetailsApi(item.respondent) : new DisputePartnerDetailsApi();
    };
};

export class DisputePartnerDetailsApi {
    public id: string;
    public name: string;
    public type?: string;

    public constructor(item?: DisputePartnerDetailsApi) {
        this.id = (item && item.id) ? item.id : '';
        this.name = (item && item.name) ? item.name : '';
        this.type = (item && item.type) ? item.type : '';
    };
};

export class DisputeStatusUpdateApi {
    public partnerId: string;
    public partnerName: string;
    public partnerType: string;
    public userId: string;
    public userName: string;

    public constructor(item?: DisputeStatusUpdateApi) {
        this.partnerId = (item && item.partnerId) ? item.partnerId : '';
        this.partnerName = (item && item.partnerName) ? item.partnerName : '';
        this.partnerType = (item && item.partnerType) ? item.partnerType : '';
        this.userId = (item && item.userId) ? item.userId : '';
        this.userName = (item && item.userName) ? item.userName : '';
    };
};

export class DisputeView extends DisputeApi {
    public booking: BookingView;
    declare public disputeMessages: DisputeMessageView[];
    public escalatedDateTimeView: string = '';
    public lastMessage: DisputeMessageView;
    public raisedDateTimeView: string = '';
    public resolvedDateTimeView: string = '';
    public status: string = '';
    public statusDateTime: Date;

    public constructor(item?: DisputeView) {
        super(item);
        this.booking = (item && item.booking) ? new BookingView(item.booking) : null;
        this.disputeMessages = (item && item.disputeMessages) ? item.disputeMessages.map(i => new DisputeMessageView(i)) : [];
        this.escalatedDateTimeView = (item && item.escalatedDateTime) ? datePipeShort.transform(String(item.escalatedDateTime)) : '';
        this.lastMessage = (item && item.lastMessage) ? item.lastMessage : (item && item.disputeMessages.length) ? new DisputeMessageView(item.disputeMessages.pop()) : null;
        this.raisedDateTimeView = (item && item.raisedDateTime) ? datePipeShort.transform(String(item.raisedDateTime)) : '';
        this.resolvedDateTimeView = (item && item.resolvedDateTime) ? datePipeShort.transform(String(item.resolvedDateTime)) : '';
        if (item?.resolved) {
            this.status = 'Resolved';
            this.statusDateTime = new Date(item.resolvedDateTime);
        } else if (item?.escalated) {
            this.status = 'Escalated';
            this.statusDateTime = new Date(item.escalatedDateTime);
        } else {
            this.status = 'Open';
            this.statusDateTime = new Date(item.raisedDateTime);
        };
    };
};

export class DisputeMessageApi {
    public disputeId: string;
    public fundRequest?: DisputeFundsRequestApi;
    public fundsRemaining: number;
    public id: string;
    public sender: DisputeStatusUpdateApi;
    public text: string;
    public time: Date | string;

    public constructor(item?: DisputeMessageApi) {
        this.disputeId = (item && item.disputeId) ? item.disputeId : '';
        if (item && item.fundRequest) { this.fundRequest = new DisputeFundsRequestApi(item.fundRequest); };
        this.fundsRemaining = (item && typeof item.fundsRemaining == 'number') ? item.fundsRemaining : null;
        this.id = (item && item.id) ? item.id : '';
        this.sender = (item && item.sender) ? new DisputeStatusUpdateApi(item.sender) : new DisputeStatusUpdateApi();
        this.text = (item && item.text) ? item.text : '';
        this.time = (item && item.time) ? new Date(item.time) : null;
    };
};

export class DisputeMessageView extends DisputeMessageApi {
    declare public fundRequest?: DisputeFundsRequestView;
    public sentByCurrentUserPartner: boolean;
    public timeView: string;
    public messageSenderAndTime: string = '';

    public constructor(item?: DisputeMessageView) {
        super(item);
        if (item && item.fundRequest) { this.fundRequest = new DisputeFundsRequestView(item.fundRequest); };
        this.sentByCurrentUserPartner = (item && item.sentByCurrentUserPartner) ? true : false;
        this.timeView = (item && item.time) ? datePipeShort.transform(String(item.time)) : '';
        this.messageSenderAndTime = (item && (item.sender?.partnerName || item.sender?.userName) && this.timeView) ? `${this.timeView}\n${item.sender.userName}, ${item.sender.partnerName}:\n${item.text}` : '';
    };
};

export class CreateDisputePayload {
    public bookingId: string;
    public reason: string;

    public constructor(item?: CreateDisputePayload) {
        this.bookingId = (item && item.bookingId) ? item.bookingId : '';
        this.reason = (item && item.reason) ? item.reason : '';
    };
};

export class CreateFundsRequestPayload {
    public amount: number;
    public reason: string;

    public constructor(item?: CreateFundsRequestPayload) {
        this.amount = (item && typeof item.amount == 'number') ? item.amount : null;
        this.reason = (item && item.reason) ? item.reason : '';
    };
};

export class DisputeFundsRequestApi extends HalLinksApi {
    public amount: number;
    public approvedAt: string;
    public approvedReason: string;
    public approver: DisputeStatusUpdateApi;
    public bookingId: string;
    public cancelledByUserName: string;
    public createdAt: string;
    public creditNote: CreditNoteApi;
    public disputeId: string;
    public id: string;
    public operator: DisputePartnerDetailsApi;
    public originator: DisputePartnerDetailsApi;
    public reason: string;
    public rejectedByUserName: string;
    public requester: DisputeStatusUpdateApi;
    public respondent: DisputePartnerDetailsApi;
    public status: string;

    public constructor(item?: DisputeFundsRequestApi) {
        super(item);
        this.amount = (item && item.amount) ? item.amount : 0;
        this.approvedAt = (item && item.approvedAt) ? item.approvedAt : '';
        this.approvedReason = (item && item.approvedReason) ? item.approvedReason : '';
        this.approver = (item && item.approver) ? new DisputeStatusUpdateApi(item.approver) : null;
        this.bookingId = (item && item.bookingId) ? item.bookingId : '';
        this.cancelledByUserName = (item && item.cancelledByUserName) ? item.cancelledByUserName : '';
        this.createdAt = (item && item.createdAt) ? item.createdAt : '';
        this.creditNote = (item && item.creditNote) ? new CreditNoteApi(item.creditNote) : null;
        this.disputeId = (item && item.disputeId) ? item.disputeId : '';
        this.id = (item && item.id) ? item.id : '';
        this.operator = (item && item.operator) ? new DisputePartnerDetailsApi(item.operator) : new DisputePartnerDetailsApi();
        this.originator = (item && item.originator) ? new DisputePartnerDetailsApi(item.originator) : new DisputePartnerDetailsApi();
        this.reason = (item && item.reason) ? item.reason : '';
        this.rejectedByUserName = (item && item.rejectedByUserName) ? item.rejectedByUserName : '';
        this.requester = (item && item.requester) ? new DisputeStatusUpdateApi(item.requester) : new DisputeStatusUpdateApi();
        this.respondent = (item && item.respondent) ? new DisputePartnerDetailsApi(item.respondent) : new DisputePartnerDetailsApi();
        this.status = (item && item.status) ? item.status : '';
    };
};

export class DisputeFundsRequestView extends DisputeFundsRequestApi {
    public amountCurrency: string;
    public approvedAtTimeView: string;
    public createdAtTimeView: string;

    public constructor(item?: DisputeFundsRequestView) {
        super(item);
        this.amountCurrency = (item && item.amount) ? new CurrencyGlobalPipe().transform(item.amount) : '';
        this.approvedAtTimeView = (item && item.approvedAt) ? datePipeShort.transform(String(item.approvedAt)) : '';
        this.createdAtTimeView = (item && item.createdAt) ? datePipeShort.transform(String(item.createdAt)) : '';
    };
};

export class DisputeEscalatedEventApi {
    public escalated: boolean;
    public escalatedBy: DisputeStatusUpdateApi;
    public escalatedComment: string;
    public escalatedDateTime: Date | string;
    public id: string;

    public constructor(item?: DisputeEscalatedEventApi) {
        this.escalated = (item && item.escalated) ? true : false;
        this.escalatedBy = (item && item.escalatedBy) ? new DisputeStatusUpdateApi(item.escalatedBy) : null;
        this.escalatedComment = (item && item.escalatedComment) ? item.escalatedComment : '';
        this.escalatedDateTime = (item && item.escalatedDateTime) ? new Date(item.escalatedDateTime) : '';
        this.id = (item && item.id) ? item.id : '';
    };
};

export class DisputeEscalatedEventView extends DisputeEscalatedEventApi {
    public escalatedDateTimeView: string;

    public constructor(item?: DisputeEscalatedEventView) {
        super(item);
        this.escalatedDateTimeView = (item && item.escalatedDateTime) ? datePipeShort.transform(String(item.escalatedDateTime)) : '';
    };
};

export class DisputeResolvedEventApi {
    public id: string;
    public resolved: boolean;
    public resolvedBy: DisputeStatusUpdateApi;
    public resolvedComment: string;
    public resolvedDateTime: Date | string;

    public constructor(item?: DisputeResolvedEventApi) {
        this.resolved = (item && item.resolved) ? true : false;
        this.resolvedBy = (item && item.resolvedBy) ? new DisputeStatusUpdateApi(item.resolvedBy) : null;
        this.resolvedComment = (item && item.resolvedComment) ? item.resolvedComment : '';
        this.id = (item && item.id) ? item.id : '';
        this.resolvedDateTime = (item && item.resolvedDateTime) ? new Date(item.resolvedDateTime) : null;
    };
};


export class DisputeResolvedEventView extends DisputeResolvedEventApi {
    public resolvedDateTimeView: string;

    public constructor(item?: DisputeResolvedEventView) {
        super(item);
        this.resolvedDateTimeView = (item && item.resolvedDateTime) ? datePipeShort.transform(String(item.resolvedDateTime)) : '';
    };
};