import { StorageService } from "src/services";

export class OperatorSettingsApi {
    public culture: string;
    public invoiceDay: number;
    public invoiceEmail: string;
    public invoiceFrequency: string;
    public timeZone: string;

    public constructor(item?: OperatorSettingsApi) {
        this.culture = (item && item.culture) ? item.culture : null;
        this.invoiceDay = (item && item.invoiceDay) ? item.invoiceDay : null;
        this.invoiceEmail = (item && item.invoiceEmail) ? item.invoiceEmail : null;
        this.invoiceFrequency = (item && item.invoiceFrequency) ? item.invoiceFrequency : null;
        this.timeZone = (item && item.timeZone) ? item.timeZone : null;
    };
};

export class OriginatorSettingsApi extends OperatorSettingsApi {
    declare public culture: string;
    public invoiceCCEmail: string;
    public invoicePrefix: string;
    public originatorId: string;

    constructor(item?: OriginatorSettingsApi) {
        super(item);
        this.culture = (item && item.culture) ? item.culture : null;
        this.invoiceCCEmail = (item && item.invoiceCCEmail) ? item.invoiceCCEmail : null;
        this.invoicePrefix = (item && item.invoicePrefix) ? item.invoicePrefix : null;
        this.originatorId = (item && item.originatorId) ? item.originatorId : null;
    };
};

export class CompanySettingsApi {
    public companyId: string;
    public attemptThreshold: number;
    public lockoutDurationInMinutes: number;

    public constructor(item?: CompanySettingsApi) {
        this.companyId = StorageService.getItem('operator')?.companyId || StorageService.getItem('originator')?.companyId;
        this.attemptThreshold = (item && item.attemptThreshold) ? item.attemptThreshold : 0;
        this.lockoutDurationInMinutes = (item && item.lockoutDurationInMinutes) ? item.lockoutDurationInMinutes : 0;
    };
};