import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { HalLinksApi } from "./hal-link.model";

const currencyPipe = new CurrencyGlobalPipe();

export class GetAllBankAccountsResponseApi extends HalLinksApi {
    public bankAccountsInformation: BankAccountInfoApi[];

    public constructor(item?: GetAllBankAccountsResponseApi) {
        super(item);
        this.bankAccountsInformation = (item && item.bankAccountsInformation) ? item.bankAccountsInformation.map(i => new BankAccountInfoApi(i)) : [];
    };
};

export class GetAllBankAccountsResponseView extends GetAllBankAccountsResponseApi {
    declare public bankAccountsInformation: BankAccountInfoView[];

    public constructor(item?: GetAllBankAccountsResponseView) {
        super(item);
        this.bankAccountsInformation = (item && item.bankAccountsInformation) ? item.bankAccountsInformation.map(i => new BankAccountInfoView(i)) : [];
    };
};

export class BankAccountInfoApi extends HalLinksApi {
    public accountFees: OriginatorBankAccountBankFeesApi;
    public agentId: string;
    public clientGuid: string;
    public clientId: string;
    public currencyCode: string;
    public depositAccountGuid: string;
    public guarantorAccountGuid: string;
    public lowBalanceThreshold: number;
    public minimumBalanceThreshold: number;

    public constructor(item?: BankAccountInfoApi) {
        super(item);
        this.accountFees = (item && item.accountFees) ? new OriginatorBankAccountBankFeesApi(item.accountFees) : new OriginatorBankAccountBankFeesApi();
        this.agentId = (item && item.agentId) ? item.agentId : '';
        this.clientGuid = (item && item.clientGuid) ? item.clientGuid : '';
        this.clientId = (item && item.clientId) ? item.clientId : '';
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : '';
        this.depositAccountGuid = (item && item.depositAccountGuid) ? item.depositAccountGuid : '';
        this.guarantorAccountGuid = (item && item.guarantorAccountGuid) ? item.guarantorAccountGuid : '';
        this.lowBalanceThreshold = (item && typeof item.lowBalanceThreshold == 'number') ? item.lowBalanceThreshold : 0;
        this.minimumBalanceThreshold = (item && typeof item.minimumBalanceThreshold == 'number') ? item.minimumBalanceThreshold : 0;
    };
};

export class BankAccountDetailsApi {
    public accountName: string;
    public accountNumber: string;
    public bankName: string;
    public bankCountryCode: string;
    public bic: string;
    public currencyCode: string;
    public iban: string;
    public routingNumber: string;
    public sortCode: string;

    public constructor(item?: BankAccountDetailsApi) {
        this.accountNumber = (item && item.accountNumber) ? item.accountNumber : '';
        this.accountName = (item && item.accountName) ? item.accountName : '';
        this.bankName = (item && item.bankName) ? item.bankName : '';
        this.bankCountryCode = (item && item.bankCountryCode) ? item.bankCountryCode : '';
        this.bic = (item && item.bic) ? item.bic : '';
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : '';
        this.iban = (item && item.iban) ? item.iban : '';
        this.routingNumber = (item && item.routingNumber) ? item.routingNumber : '';
        this.sortCode = (item && item.sortCode) ? item.sortCode : '';
    };
};

export class BankAccountInfoView extends BankAccountInfoApi {
    public lowBalanceThresholdCurrency: string;
    public minimumBalanceThresholdCurrency: string;

    public constructor(item?: BankAccountInfoView) {
        super(item);
        currencyPipe.currency = item?.currencyCode;
        this.lowBalanceThresholdCurrency = (item && typeof item.lowBalanceThreshold == 'number') ? currencyPipe.transform(item.lowBalanceThreshold) : '';
        this.minimumBalanceThresholdCurrency = (item && typeof item.minimumBalanceThreshold == 'number') ? currencyPipe.transform(item.minimumBalanceThreshold) : '';
    };
};

export class OriginatorBankAccountBankFeesApi {
    public fixed: number;
    public percentage: number;
    public vatPercentage: number;

    public constructor(item?: OriginatorBankAccountBankFeesApi) {
        this.fixed = (item && typeof item.fixed == 'number') ? item.fixed : 0;
        this.percentage = (item && typeof item.percentage == 'number') ? item.percentage : null;
        this.vatPercentage = (item && typeof item.vatPercentage == 'number') ? item.vatPercentage : null;
    };
};