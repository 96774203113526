import { AddressDetailsApi } from './address.model';
import { CurrencyGlobalPipe } from "src/shared/pipes/currency.pipe";
import { GenericDatePipe } from 'src/shared/pipes/generic-date.pipe';
import { HalLinksApi } from './hal-link.model';

const currencyPipe = new CurrencyGlobalPipe();
const datePipeShortDate = new GenericDatePipe();
datePipeShortDate.format = 'shortDate';


export class DateRangeFilter {
    public from: Date | string;
    public to: Date | string;

    public constructor(item?: DateRangeFilter) {
        this.from = (item && item.from) ? new Date(item.from) : null;
        this.to = (item && item.to) ? new Date(item.to) : null;
    };
};

export class AccountTransactionFilter extends DateRangeFilter {
    public count: number;
    public offset: number;

    public constructor(item?: AccountTransactionFilter) {
        super(item);
        this.count = (item && item.count) ? item.count : 100;
        this.offset = (item && item.offset) ? item.offset : 0;
    };
};

export class AccountTransactionExportPayload {
    public currencyCode: string;
    public filter: DateRangeFilter;

    public constructor(item?: GetAccountTransactionPayload) {
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : 'GBP';
        this.filter = (item && item.filter) ? new DateRangeFilter(item.filter) : new DateRangeFilter();
    };
};

export class BeneficiaryAccountsApi {
    public accountName: string;
    public address: AddressDetailsApi;
    public accountNumber: string;
    public bankCountryCode: string;
    public bankName: string;
    public bic: string;
    public currencyCode: string;
    public iban: string;
    public routingNumber: string;
    public sortCode: string;

    public constructor(item?: BeneficiaryAccountsApi) {
        this.accountName = (item && item.accountName) ? item.accountName : '';
        this.address = (item && item.address) ? item.address : new AddressDetailsApi();
        this.accountNumber = (item && item.accountNumber) ? item.accountNumber : '';
        this.bankCountryCode = (item && item.bankCountryCode) ? item.bankCountryCode : '';
        this.bankName = (item && item.bankName) ? item.bankName : '';
        this.bic = (item && item.bic) ? item.bic : '';
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : '';
        this.iban = (item && item.iban) ? item.iban : '';
        this.routingNumber = (item && item.routingNumber) ? item.routingNumber : '';
        this.sortCode = (item && item.sortCode) ? item.sortCode : '';
    };
}

export class BeneficiaryAccountsView extends BeneficiaryAccountsApi {
    public accountNumberWithStars: string;
    public fullAddress: string;
    public sortCodeWithStars: string;

    public constructor(item?: BeneficiaryAccountsView) {
        super(item);
        this.accountNumberWithStars = (item && item.accountNumber) ? '***' + item.accountNumber.slice(-3) : '';
        this.fullAddress = (item && item.address) ? item.address.address1 + ' ' + item.address.address2 + ' ' + item.address.building + ' ' + item.address.city + ' ' + item.address.country : '';
        this.sortCodeWithStars = (item && item.sortCode) ? '***' + item.sortCode.slice(-2) : '';
    };
};

export class CornicheAccountApi {
    public account: CornicheAccountDetailsApi;
    public currency: string;

    public constructor(item?: CornicheAccountApi) {
        this.account = (item && item.account) ? new CornicheAccountDetailsApi(item.account) : null;
        this.currency = (item && item.currency) ? item.currency : '';
    };
};

export class CornicheAccountView extends CornicheAccountApi {
    declare public account: CornicheAccountDetailsView;

    public constructor(item?: CornicheAccountView) {
        super(item);
        this.account = (item && item.account) ? new CornicheAccountDetailsView(item.account) : null;
    };
};

export class CornicheAccountDetailsApi {
    public availableBalance: number;
    public currency: string;
    public currentBalance: number;
    public name: string;

    public constructor(item?: CornicheAccountDetailsApi) {
        this.availableBalance = (item && typeof item.availableBalance == 'number') ? item.availableBalance : null;
        this.currency = (item && item.currency) ? item.currency : '';
        this.currentBalance = (item && typeof item.currentBalance == 'number') ? item.currentBalance : null;
        this.name = (item && item.name) ? item.name : '';
    };
};

export class CornicheAccountDetailsView extends CornicheAccountDetailsApi {
    public availableBalanceCurrency: string;
    public currentBalanceCurrency: string;

    public constructor(item?: CornicheAccountDetailsView) {
        super(item);
        currencyPipe.currency = item?.currency;
        this.availableBalanceCurrency = (item && typeof item.availableBalance == 'number') ? currencyPipe.transform(item.availableBalance) : '';
        this.currentBalanceCurrency = (item && typeof item.currentBalance == 'number') ? currencyPipe.transform(item.currentBalance) : '';
    };
};

export class CornicheAccountTransactionApi {
    public amount: number;
    public balance: number;
    public date: Date;
    public description: string;
    public id: string;
    public notes: string;
    public vat: number;

    public constructor(item?: CornicheAccountTransactionApi) {
        this.amount = (item && typeof item.amount == 'number') ? item.amount : null;
        this.balance = (item && typeof item.balance == 'number') ? item.balance : null;
        this.date = (item && item.date) ? new Date(item.date) : null;
        this.description = (item && item.description) ? item.description : '';
        this.notes = (item && item.notes) ? item.notes : '';
        this.id = (item && item.id) ? item.id : '';
        this.vat = (item && typeof item.vat == 'number') ? item.vat : null;
    };
};

export class CornicheAccountTransactionView extends CornicheAccountTransactionApi {
    public amountCurrency: string;
    public balanceCurrency: string;
    public dateView: string;

    public constructor(item: CornicheAccountTransactionView, currency: string) {
        super(item);
        currencyPipe.currency = currency;
        this.amountCurrency = (item && typeof item.amount == 'number') ? currencyPipe.transform(item.amount) : '';
        this.balanceCurrency = (item && typeof item.balance == 'number') ? currencyPipe.transform(item.balance) : '';
        this.dateView = (item && item.date) ? new GenericDatePipe().transform(String(item.date)) : '';
    };
};

export class CornicheWithdrawFundsPayload {
    public authentication: Credentials;
    public amount: number;
    public currencyCode: string;
    public reason: string;

    public constructor(item?: CornicheWithdrawFundsPayload) {
        this.amount = (item && item.amount) ? item.amount : null;
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : 'GBP';
        this.reason = (item && item.reason) ? item.reason : 'Transfer out of my iGo wallet';
        this.authentication = (item && item.authentication) ? item.authentication : new Credentials();
    };
};

export class CornicheWithdrawFundsApi {
    public extraInfo: string;
    public status: number;
    public statusMsg: string;

    public constructor(item?: CornicheWithdrawFundsApi) {
        this.extraInfo = (item && item.extraInfo) ? item.extraInfo : '';
        this.status = (item && item.status) ? item.status : 0;
        this.statusMsg = (item && item.statusMsg) ? item.statusMsg : '';
    };
};

export class CornicheWithdrawHistoryFilter {
    public count: number;
    public from: Date;
    public offset: number;
    public to: Date;

    public constructor(item?: CornicheWithdrawHistoryFilter) {
        this.count = (item && item.count) ? item.count : 20;
        this.offset = (item && item.offset) ? item.offset : 0;
        this.to = (item && item.to) ? item.to : new Date();
        this.from = (item && item.from) ? item.from : new Date(this.to.getFullYear(), this.to.getMonth() - 12, this.to.getDate() + 1);
    };
};

export class GetAllCornicheWithdrawHistoryPayload {
    public currencyCode: string;
    public filter: CornicheWithdrawHistoryFilter;

    public constructor(item?: GetAllCornicheWithdrawHistoryPayload) {
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : 'GBP';
        this.filter = (item && item.filter) ? item.filter : new CornicheWithdrawHistoryFilter();
    };
};

export class GetAllCornicheWithdrawHistoryApi extends HalLinksApi {
    public withdrawals: CornicheWithdrawHistoryApi[];

    public constructor(item?: GetAllCornicheWithdrawHistoryApi) {
        super(item);
        this.withdrawals = (item && item.withdrawals) ? item.withdrawals.map(i => new CornicheWithdrawHistoryApi(i)) : [];
    };
};

export class GetAllCornicheWithdrawHistoryView extends GetAllCornicheWithdrawHistoryApi {
    declare public withdrawals: CornicheWithdrawHistoryView[];

    public constructor(item: GetAllCornicheWithdrawHistoryView, currency: string) {
        super(item);
        currencyPipe.currency = currency;
        this.withdrawals = (item && item.withdrawals) ? item.withdrawals.map(i => new CornicheWithdrawHistoryView(i)) : [];
    };
};

export class CornicheWithdrawHistoryApi extends HalLinksApi {
    public amount: number;
    public created: string;
    public id: string;
    public reason: string;
    public status: string;

    public constructor(item?: CornicheWithdrawHistoryApi) {
        super(item);
        this.amount = (item && item.amount) ? item.amount : 0;
        this.id = (item && item.id) ? item.id : '';
        this.reason = (item && item.reason) ? item.reason : '';
        this.status = (item && item.status) ? item.status : '';
    };
};

export class CornicheWithdrawHistoryView extends CornicheWithdrawHistoryApi {
    public amountCurrency: string;
    public date: Date;
    public dateView: string;

    public constructor(item?: CornicheWithdrawHistoryView) {
        super(item);
        this.amountCurrency = (item && typeof item.amount == 'number') ? currencyPipe.transform(item.amount) : '';
        this.date = (item && item.created) ? new Date(item.created) : new Date();
        this.dateView = (item && item.created) ? datePipeShortDate.transform(item.created) : '';
    };
};

export class Credentials {
    public email: string;
    public password: string;

    public constructor(item?: Credentials) {
        this.email = (item && item.email) ? item.email : '';
        this.password = (item && item.password) ? item.password : '';
    };
};

export class GetAccountTransactionPayload {
    public currencyCode: string;
    public filter: AccountTransactionFilter;

    public constructor(item?: GetAccountTransactionPayload) {
        this.currencyCode = (item && item.currencyCode) ? item.currencyCode : 'GBP';
        this.filter = (item && item.filter) ? new AccountTransactionFilter(item.filter) : new AccountTransactionFilter();
    };
};
