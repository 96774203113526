import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard'
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoordinateApi, RegionView } from 'src/models';
import { ObjectUtilities } from 'src/shared/object.utilities';
import { RegionBuilderMapComponent } from 'src/shared/components/region-builder-map/region-builder-map.component';
import { RegionsService, SharedModalsService, TestEmulatorService } from 'src/services';
import { SnackbarService } from 'src/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'region-modal',
    styleUrls: ['region.modal.css'],
    templateUrl: './region.modal.html'
})

export class RegionModalComponent implements OnInit, AfterViewInit {
    @ViewChild(RegionBuilderMapComponent) map: RegionBuilderMapComponent;
    @ViewChild('form') public form: NgForm;
    @Input() public region: RegionView;
    @Input() public windowClass: string;
    public centreCoords: CoordinateApi = new CoordinateApi({ latitude : 53.474414, longitude : -2.248014 });
    public confirming: boolean = false;
    public editMode: boolean = false;
    public environment: any = environment;
    public isTestEmulatorRegion: boolean = false;
    public regionCopy: RegionView = new RegionView();
    public title: string = '';

    private noDataChange = (): boolean => ObjectUtilities.equal(new RegionView(this.region), new RegionView(this.regionCopy));

    public constructor(
        public regionModal: NgbActiveModal,
        private clipboard: Clipboard,
        private regionService: RegionsService,
        private sharedModalsService: SharedModalsService,
        private snackbarService: SnackbarService,
        private testEmulatorService: TestEmulatorService,
        private translateService: TranslateService,
    ) { };

    public ngOnInit(): void {
        this.editMode = !!this.region;
        this.title = this.editMode ? this.region.name : 'global.modals.region.create_region';
        if (this.region) {
            this.regionCopy = ObjectUtilities.deepCopy(this.region);
        } else {
            navigator.geolocation.getCurrentPosition((res: GeolocationPosition) => {
                setTimeout(() => {
                    this.centreCoords = new CoordinateApi({ latitude: res.coords.latitude, longitude: res.coords.longitude });
                    this.map.moveCenter(this.centreCoords);
                });
            });
        };
    };

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.map.resize();
        });
    };

    public onConfirm(): void {
        if (!this.confirming) {
            this.form.ngSubmit.emit();
            if (this.form.valid) {
                this.confirming = true;
                this.editMode ? this.updateRegion() : this.createRegion();
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('global.modals.region.add_map_area'), this.form, { windowClass: this.windowClass });
            };
        };
    };

    public onCopyPolygonCoordinatesAsJson(): void {
        this.clipboard.copy(JSON.stringify(this.regionCopy.points));
        this.snackbarService.showSnackbar('global.modals.copied');
    };

    public onShowImportAsJsonModal(): void {
        this.sharedModalsService.showParseJsonInputModal({ windowClass: this.windowClass }).result.then((coordinates: CoordinateApi[]) => {
            let region: RegionView = new RegionView(this.regionCopy);
            region.points = coordinates;
            region.regionPolygonId = region.regionPolygonId || Math.random().toString();
            this.regionCopy = region;
            this.map.redrawRegion(region);
        }).catch(() => { });
    };

    private createRegion(): void {
        if (this.isTestEmulatorRegion) {
            this.testEmulatorService.createTestRegionAsync(this.regionCopy).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        } else {
            this.regionService.createRegionAsync(this.regionCopy).subscribe({
                next: this.onSuccess,
                error: this.onFailure
            });
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        const title = this.editMode ? 'global.modals.region.edit_region' : 'global.modals.region.create_region';
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant(title));
    };

    private onSuccess = (res: RegionView | string): void => {
        this.confirming = false;
        this.regionModal.close(res)
    };

    private updateRegion(): void {
        this.regionService.updateRegionAsync(this.regionCopy).subscribe({
            next: this.onSuccess,
            error: this.onFailure
        });
    };
};