import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService, SessionService, SharedModalsService, UserService, UsersService } from 'src/services';
import { DecodedJwtToken, iGoUserView, SetupIGoUserApi } from 'src/models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'setup-user',
    templateUrl: './setup-user.component.html'
})

export class SetupUserComponent implements OnInit, OnDestroy {
    public confirming: boolean = false;
    public form: UntypedFormGroup = null;
    public loading: boolean = false;
    public linkHasExpired: boolean = false;
    public passwordRegex: RegExp = AuthenticationService.PasswordRegex;
    public resendingEmail: boolean = false;
    public user: SetupIGoUserApi = new SetupIGoUserApi();

    private formSubscription: Subscription;
    private parsedToken: DecodedJwtToken = null;
    private queryParamsSubscription: Subscription;

    public constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService,
        private usersService: UsersService
    ) { };

    public ngOnInit(): void {
        this.authenticationService.unauthenticateUser();
        this.queryParamsSubscription = this.route.queryParams.subscribe(async (queryParams: Params) => {
            SessionService.setToken(queryParams?.token);
            const token = SessionService.getToken();
            try {
                this.parsedToken = this.authenticationService.parseToken(token);
                await lastValueFrom(this.userService.getCurrentUserObservable());
                this.user = new SetupIGoUserApi(this.userService.getCurrentUser());
                if (!this.user.email) {
                    this.markAsInvalid();
                } else {
                    this.setPayload();
                };
            } catch (error) {
                this.markAsInvalid();
                return;
            };
        });
    };

    public ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
        this.queryParamsSubscription?.unsubscribe();
    };

    public onSendRegistrationEmail(): void {
        if (!this.resendingEmail && this.parsedToken) {
            this.resendingEmail = true;
            this.usersService.sendRegistrationEmailAsync(this.parsedToken.nameid).subscribe({
                next: this.onResendEmailSuccess,
                error: this.onFailure
            });
        };
    };

    public onSetupUser() {
        if (!this.confirming) {
            if (this.form.valid) {
                this.confirming = true;
                this.usersService.setupUserAsync(this.userService.getCurrentUser().id, this.user).subscribe({
                    next: this.onSuccess,
                    error: this.onFailure
                });
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('setup_user.create_user'), this.form);
            };
        };
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'setup_user.form.first_name': [this.user.firstName, [Validators.required]],
            'setup_user.form.last_name': [this.user.lastName, [Validators.required]],
            'setup_user.form.email': [this.user.email, [Validators.required]],
            'setup_user.form.password': [this.user.newPassword, [Validators.required]],
            'setup_user.form.confirm_password': [this.user.confirmNewPassword, [Validators.required]],
        });
    };

    private markAsInvalid(): void {
        this.linkHasExpired = true;
        this.form = null;
        this.loading = false;
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.loading = false;
        this.resendingEmail = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('setup_user.create_user'));
    };

    private onResendEmailSuccess = (): void => {
        this.resendingEmail = false;
        const title: string = this.translateService.instant('setup_user.form.email_sent.title');
        const message: string = this.translateService.instant('setup_user.form.email_sent.message');
        this.sharedModalsService.showAlertModal(title, message, { windowClass: 'originator' });
    };

    private onSuccess = (res: iGoUserView): void => {
        this.router.navigate(['/login'], { queryParams: { email: res.email } });
    };

    private setPayload = (): void => {
        this.loading = false;
        this.form = this.buildForm();
        this.formSubscription?.unsubscribe();
        this.formSubscription = this.form.valueChanges.subscribe({
            next: (value: any) => {
                this.user.confirmNewPassword = value['setup_user.form.confirm_password'];
                this.user.firstName = value['setup_user.form.first_name'];
                this.user.lastName = value['setup_user.form.last_name'];
                this.user.newPassword = value['setup_user.form.password'];
            }
        });
    };
};