import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class FileUploadService {

    public parseCSVtoArray(text): string[] {
        const separator = ',';
        const array = [];
        const arrayResult: string[] = [];
        const lines = text.split('\n');
        lines.forEach(element => {
            const cols: string[] = element.split(separator);
            array.push(cols);
        });
        array.forEach(row => {
            row.forEach(col => {
                if (col.length) arrayResult.push(col)
            })
        });
        return arrayResult;
    };
};