import {
    AfterContentInit,
    ApplicationRef,
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    inject,
    Injector,
    NgZone,
    OnDestroy,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ValidationPopoverComponent } from '../../../shared/components/validation/validation-popover.component';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../services';

const ValidationPopoverConfig: any = {
    autoClose: false,
    placement: ['bottom'],
    triggers: 'manual',
    container: 'body',
    disablePopover: false,
    popoverClass: 'validation-popover',
    openDelay: 0,
    closeDelay: 0
};

@Directive({
    providers: [NgbPopover],
    selector: '[ghostValidate]'
})
export class GhostValidateDirective implements AfterContentInit, OnDestroy {
    private control = inject(NgModel);
    private elementRef = inject(ElementRef<HTMLInputElement>);
    private renderer = inject(Renderer2);
    private injector = inject(Injector);
    private componentFactoryResolver = inject(ComponentFactoryResolver);
    private viewContainerRef = inject(ViewContainerRef);
    private ngZone = inject(NgZone);
    private validationService = inject(ValidationService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private applicationRef = inject(ApplicationRef);

    @HostBinding('attr.ngbPopover') public ngbPopover = new NgbPopover(
        this.elementRef,
        this.renderer,
        this.injector,
        this.viewContainerRef,
        ValidationPopoverConfig,
        this.ngZone,
        document,
        this.changeDetectorRef,
        this.applicationRef
    );

    @HostListener('focus') private focus(): void {
        if (this.control && this.control.invalid && this.control.touched) {
            if (!this.isPopoverOpened) {
                this.openPopover();
            };
            if (!this.elementRef.nativeElement.classList.contains('has-error')) {
                this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
            };
        };
    };

    @HostListener('blur') private onblur(): void {
        if (this.ngbPopover && this.isPopoverOpened) {
            this.isPopoverOpened = false;
            this.ngbPopover.close();
        };
        if (this.control && this.control.invalid && !this.elementRef.nativeElement.classList.contains('has-error')) {
            this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
        };
    };

    private AfterContentInit = false;
    private controlStatusChangeSubscription: Subscription = null;
    private isPopoverOpened: boolean = false;
    private ngSubmitSubscription: Subscription = null;
    private validationPopoverComponentRef: ComponentRef<ValidationPopoverComponent> = null;

    public ngAfterContentInit(): void {
        this.controlStatusChangeSubscription = this.control.statusChanges.subscribe({
            next: (status: string | null) => {
                if (this.AfterContentInit && !this.control.disabled) {
                    status = status ? status.toLowerCase() : 'valid';
                    if (status === 'valid') {
                        if (this.elementRef.nativeElement.classList.contains('has-error')) {
                            this.renderer.removeClass(this.elementRef.nativeElement, 'has-error');
                        };
                        if (this.isPopoverOpened) {
                            this.ngbPopover.close();
                            this.isPopoverOpened = false;
                        };
                    } else {
                        let validationMessage = this.validationService.getValidationMessage(this.control);
                        let errorMessage = validationMessage && validationMessage.message;
                        if (this.validationPopoverComponentRef && this.validationPopoverComponentRef.instance && this.validationPopoverComponentRef.instance.errorMessage !== errorMessage) {
                            this.validationPopoverComponentRef.instance.errorMessage = errorMessage;
                        };
                        if (!this.elementRef.nativeElement.classList.contains('has-error')) {
                            this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
                        };
                        if (!this.isPopoverOpened) {
                            this.openPopover();
                        };
                    };
                };
                if (this.control.disabled) {
                    if (this.elementRef.nativeElement.classList.contains('has-error')) {
                        this.renderer.removeClass(this.elementRef.nativeElement, 'has-error');
                    };
                    this.ngbPopover.close();
                    this.isPopoverOpened = false;
                };
                this.AfterContentInit = true;
            }
        });
        this.ngSubmitSubscription = (this.control.formDirective as NgForm).ngSubmit.subscribe({
            next: () => {
                if (this.control.invalid && !this.elementRef.nativeElement.classList.contains('has-error')) {
                    this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
                };
            }
        });
    };

    public ngOnDestroy(): void {
        this.controlStatusChangeSubscription.unsubscribe();
        this.ngSubmitSubscription.unsubscribe();
        if (this.ngbPopover.isOpen()) {
            this.ngbPopover.close();
        };
        if (this.validationPopoverComponentRef) {
            this.validationPopoverComponentRef.destroy();
        };
    };

    private openPopover(): void {
        let validationMessage = this.validationService.getValidationMessage(this.control);
        if (!this.validationPopoverComponentRef) {
            let factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(ValidationPopoverComponent);
            this.validationPopoverComponentRef = this.viewContainerRef.createComponent(factory);
            this.validationPopoverComponentRef.instance.errorMessage = validationMessage && validationMessage.message;
            this.validationPopoverComponentRef.instance.getTemplateRef().subscribe({
                next: (template: TemplateRef<any>) => {
                    if (template) {
                        this.isPopoverOpened = true;
                        this.ngbPopover.ngbPopover = template;
                        this.ngbPopover.open();
                    };
                }
            });
        } else {
            this.validationPopoverComponentRef.instance.errorMessage = validationMessage && validationMessage.message;
            this.isPopoverOpened = true;
            this.ngbPopover.open();
        };
    };
};