import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService, SessionService, SharedModalsService, UserService } from 'src/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { iGoUserView } from 'src/models';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
    public confirming: boolean = false;
    public form: UntypedFormGroup = null;
    public loading: boolean = false;
    public linkHasExpired: boolean = false;
    public passwordRegex: RegExp = AuthenticationService.PasswordRegex;

    // Payload
    public NewPassword: string = '';
    public ConfirmNewPassword: string = '';

    private formSubscription: Subscription;
    private queryParamsSubscription: Subscription;

    public constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private sharedModalsService: SharedModalsService,
        private translateService: TranslateService,
        private userService: UserService
    ) { };

    public ngOnInit(): void {
        this.authenticationService.unauthenticateUser();
        this.queryParamsSubscription = this.route.queryParams.subscribe({
            next: (queryParams: Params) => {
                SessionService.setToken(queryParams?.token);
            }
        });
        this.setPayload();
    };

    public ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
        this.queryParamsSubscription?.unsubscribe();
    };

    public onResetPassword() {
        const token = SessionService.getToken();
        if (!token) {
            this.linkHasExpired = true;
            this.loading = false;
            return;
        };
        this.loading = true;
        this.userService.getCurrentUserObservable().subscribe({
            next: this.onConfirm,
            error: () => {
                this.linkHasExpired = true;
                this.loading = false;
            }
        });
    };

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            'reset_password.form.confirm_new_password': [this.ConfirmNewPassword, [Validators.required]],
            'reset_password.form.new_password': [this.NewPassword, [Validators.required]]
        });
    };

    private onConfirm = (): void => {
        if (!this.confirming) {
            if (this.form.valid) {
                this.confirming = true;
                this.authenticationService.resetUserPasswordAsync({ NewPassword: this.NewPassword, ConfirmNewPassword: this.ConfirmNewPassword }).subscribe({
                    next: this.onSuccess,
                    error: this.onFailure
                });
            } else {
                this.sharedModalsService.showFormValidationErrorsModal(this.translateService.instant('reset_password.reset_password'), this.form);
            };
        };
    };

    private onFailure = (res: HttpErrorResponse): void => {
        this.confirming = false;
        this.loading = false;
        this.sharedModalsService.showServerValidationErrorsModal(res, this.translateService.instant('reset_password.reset_password'));
    };

    private onSuccess = (res: iGoUserView): void => {
        this.router.navigate(['/login'], { queryParams: { email: res.email } });
    };

    private setPayload = (): void => {
        this.loading = false;
        this.form = this.buildForm();
        this.formSubscription?.unsubscribe();
        this.formSubscription = this.form.valueChanges.subscribe({
            next: (value: any) => {
                this.ConfirmNewPassword = value['reset_password.form.confirm_new_password'];
                this.NewPassword = value['reset_password.form.new_password'];
            }
        });
    };
};